/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { EventMessage } from './abstract-event-message';

enum UPDATE_TYPE {
  ACK_SEQUENCE = 0x00,
  UNEXPECTED_SEQUENCE = 0x01,
  CRC_MATCH = 0x02,
  CRC_FAIL = 0x03,
  FLASH_SUCCESS = 0x04,
  FLASH_FAILURE = 0x05,
  IMAGE_TIMEOUT = 0x06
}

export class FirmwareReloadProgressMessage extends EventMessage {
  public static readonly UPDATE_TYPE = UPDATE_TYPE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = EventMessage.EventMessageIdentifier.FW_RELOAD_PROGRESS;
    }

    public forUpdate(device: UPDATE_TYPE) {
        this.payload1 = device;
        return this;
    }

    override payload1String(): string {
        return UPDATE_TYPE[this.payload1];
    }

    public get sequence() {
        return this.getUint16(3, true);
    }

    override logMessage() {
        return `${this.typeString()} ${this.idString()} ${this.payload1String()} ${this.sequence}`;
    }
}
