/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Call, UserAuthenticationRecord} from "CalltakingCoreApi";
import {CallFunctions} from "./call-functions";
import { CallAlert } from "../model/audible-alert";

export class CallAlertFunctions {

    public static matchCall(call: Call, callAlert: CallAlert, user: UserAuthenticationRecord | undefined): boolean {
        return Boolean(user) && callAlert.filterConstraints.every((constraint) => this.MatchFunctions[constraint.function](call, constraint.value, user));
    }

    public static readonly MatchFunctions: { [key:string]:Function } = {
        STATUS: (call: Call, value: string, user: UserAuthenticationRecord) => {
            const currentStatus = CallFunctions.getCallStatus(call, user);
            return CallAlertFunctions.MatchFunctions.matches(currentStatus, value);
        },

        TYPE: (call: Call, value: string) => {
            return CallAlertFunctions.MatchFunctions.matches(call.type, value);
        },

        TEXT: (call: Call, value: string) => {
            return CallAlertFunctions.MatchFunctions.matches(call.text, value);
        },

        CALL_PRIORITY: (call: Call, value: string) => {
            return CallAlertFunctions.MatchFunctions.matches(call.priority?.name, value);
        },

        CALL_QUEUE: (call: Call, value: string) => {
            return CallAlertFunctions.MatchFunctions.matches(call.callQueues.some((q) => q === value), true);
        },

        ROLE: (call: Call, value: string, user: UserAuthenticationRecord) => {
            return CallAlertFunctions.MatchFunctions.matches(value, user.preferredRole);
        },

        ASSIGNED: (call: Call, value: string, user: UserAuthenticationRecord) => {
            const assigned = CallFunctions.isUserACDAssignedAgent(call, user.username);
            return CallAlertFunctions.MatchFunctions.matches(assigned, value);
        },

        ABANDONED: (call: Call, value: string) => {
            const abandoned = CallFunctions.isAbandoned(call.status);
            return CallAlertFunctions.MatchFunctions.matches(abandoned, value);
        },

        ACTIVE_PARTICIPANT: (call: Call, value: string, user: UserAuthenticationRecord) => {
            const activeParticipant = CallFunctions.isActiveParticipant(call, user.username);
            return CallAlertFunctions.MatchFunctions.matches(activeParticipant, value);
        },

        PARTICIPANT:  (call: Call, value: string, user: UserAuthenticationRecord) => {
            const participant = CallFunctions.isParticipant(call, user.username);
            return CallAlertFunctions.MatchFunctions.matches(participant, value);
        },

        RINGING_ME: (call: Call, value: string, user: UserAuthenticationRecord) => {
            const isRingingMe = CallFunctions.ringingMe(call, user);
            return CallAlertFunctions.MatchFunctions.matches(isRingingMe, value);
        },

        matches: (a: boolean | string | undefined | null, b: any) => {
            return CallAlertFunctions.MatchFunctions.getComparable(a) === CallAlertFunctions.MatchFunctions.getComparable(b);
        },

        getComparable: function(value: any) {
            // Treat non-numeric falsey values as false
            return value === null || value === undefined ? false.toString().toUpperCase() : value.toString().toUpperCase();
        }
    };
}
