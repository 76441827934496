/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { MetricsService } from '../../services/metrics.service';

@Injectable()
export class MetricsEffects {
    constructor(private metricsService: MetricsService) {}
}
