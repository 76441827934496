/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from "@ngrx/store";

export const updateTabIndex = createAction('[Main] Tab Index ', props<{ index: number }>());
export const updateOpenTabs = createAction('[Main] Open Tabs Update', props<{ openTabs: string[] }>());

export const updateRequestedTab = createAction('[Main] Tab Requested ', props<{ label: string | undefined }>());
