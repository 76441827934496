/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Destination } from "../model/destination";
import { SortFunctions } from "../../call/util/sort-functions";
import { Ringdown, RingdownAction, RingdownActionsMap, RingdownStatus } from '../directory-ringdowns-table/Ringdown';

export class DirectoryFunctions {

    public static getDefaultDestinationObject(destinations: Destination[]) {
        let orderedDestinations = [...destinations].sort(SortFunctions.isDefaultSort);
        return orderedDestinations.length ? orderedDestinations[0] : {} as Destination;
    }

    public static getDefaultDestination(destinations: Destination[]) {
        let orderedDestinations = [...destinations].sort(SortFunctions.isDefaultSort);
        return orderedDestinations.length ? orderedDestinations[0].destination : '';
    }

    public static getRingDownStatusBasedOnCallStatus(ringDown: Ringdown): RingdownStatus {
        const call = ringDown?.call;
        if (call?.status === 'RINGING') {
            return RingdownStatus.RINGING;
        }
        if (call?.status === 'CONNECTED') {
            return RingdownStatus.CONNECTED;
        }
        if(call?.status === 'ON_HOLD') {
            return RingdownStatus.ON_HOLD;
        }
        return RingdownStatus.IDLE;
    };

    public static getRingDownAction(ringDown: Ringdown, isOnCall: boolean): RingdownAction {
        if (!ringDown) {
            return undefined;
        }
        const ringDownStatus = DirectoryFunctions.getRingDownStatusBasedOnCallStatus(ringDown);
        return !isOnCall ? RingdownActionsMap.get(ringDownStatus) : (ringDownStatus === RingdownStatus.IDLE ? RingdownAction.ADD_TO_CALL : undefined);
    }
    public static isRingDownGreyedOut(ringDown: Ringdown, isUserOnCall: boolean): boolean {
        const ringDownStatus = DirectoryFunctions.getRingDownStatusBasedOnCallStatus(ringDown);
        return isUserOnCall ? ringDownStatus !== RingdownStatus.IDLE : ringDownStatus === RingdownStatus.RINGING;
    }
}
