/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import {
    acgEvent,
    alertToUsbHeadsets,
    irrToUsbHeadsets,
    jackSenseEvent,
    muteEvent,
    muteOnAnswer,
    rtiAttachedEvent,
    samAttachedEvent,
    thresholdEvent,
    usbAlertToDevice,
    usbDeviceInfoEvent,
    usbEffectsInitialized,
    usbIrrToDevice,
    volumeEvent
} from './usb.actions';
import { InputDevice, OutputDevice } from '../shared/model/device';
import { toastExpired } from '../../notification/+state/notification.actions';


export interface UsbState {
    effectsInitialized: boolean;
    samAttached: boolean;
    rtiAttached: boolean;
    hardware: string;
    firmware: string;
    serial: string;
    inputs: InputDevice[];
    outputs: OutputDevice[];
    jackSense: {[key: string]: boolean};
    mute: {[key: string]: boolean};
    volume: {[key: string]: number};
    acg: {[key: string]: boolean};
    threshold: {[key: string]: number};
    alertToHeadsets: boolean;
    alertToDevice: boolean;
    irrToHeadsets: boolean;
    irrToDevice: boolean;
    headsetDisconnectedAndTimedOut: boolean;
}

const initialState: UsbState = {
    effectsInitialized: false,
    samAttached: false,
    rtiAttached: false,
    hardware: '',
    firmware: '',
    serial: '',
    inputs: [
        {
            id: 'JACKBOX_1_MIC',
            volumeId: 'MICROPHONE_INPUTS',
            jackSenseId: 'JACKBOX_1',
            name: 'Headset 1 Mic',
            abbreviation: 'HS1',
            direction: 'input',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false,
            muted: false,
            muteOnAnswer: false
        },
        {
            id: 'JACKBOX_2_MIC',
            volumeId: 'MICROPHONE_INPUTS',
            jackSenseId: 'JACKBOX_2',
            name: 'Headset 2 Mic',
            abbreviation: 'HS2',
            direction: 'input',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false,
            muted: false,
            muteOnAnswer: false
        },
        {
            id: 'RPI_IN',
            volumeId: 'RPI_TRANSMIT_OUTPUT',
            jackSenseId: 'RADIO_CONSOLE',
            name: 'RPI Mic',
            abbreviation: 'RDI',
            direction: 'input',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false,
            muted: false,
            muteOnAnswer: false
        }
    ],
    outputs: [
        {
            id: 'JACKBOX_1_HEADSET',
            volumeId: 'JACKBOX_1_EAR_OUTPUT',
            jackSenseId: 'JACKBOX_1',
            name: 'Headset 1 Speaker',
            abbreviation: 'HS1',
            direction: 'output',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false
        },
        {
            id: 'JACKBOX_2_HEADSET',
            volumeId: 'JACKBOX_2_EAR_OUTPUT',
            jackSenseId: 'JACKBOX_2',
            name: 'Headset 2 Speaker',
            abbreviation: 'HS2',
            direction: 'output',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false
        },
        {
            id: 'RPI_OUT',
            volumeId: 'RPI_RECEIVE_INPUT',
            jackSenseId: 'RADIO_CONSOLE',
            name: 'RPI Speaker',
            abbreviation: 'RDI',
            direction: 'output',
            agc: false,
            volume: 0,
            boost: 0,
            threshold: 0,
            connected: false
        }
    ],
    jackSense: {},
    mute: {},
    volume: {},
    acg: {},
    threshold: {},
    alertToHeadsets: true,
    alertToDevice: false,
    irrToHeadsets: true,
    irrToDevice: false,
    headsetDisconnectedAndTimedOut: false
};

export const usbFeature = createFeature({
    name: 'usb',
    reducer: createReducer(
        initialState,
        on(usbEffectsInitialized, (state): UsbState => {
            return { ...state, effectsInitialized: true };
        }),
        on(usbDeviceInfoEvent, (state, { hardware, firmware, serial}): UsbState => {
            return { ...state, hardware: hardware, serial: serial, firmware: firmware };
        }),
        on(samAttachedEvent, (state, { isAttached }): UsbState => ({ ...state, samAttached: isAttached })),
        on(rtiAttachedEvent, (state, { isAttached }): UsbState => ({ ...state, rtiAttached: isAttached })),
        on(jackSenseEvent, (state, { msg }): UsbState => {
            const outputs = state.outputs.map(output => msg[output.jackSenseId] !== undefined ? { ...output, connected: Boolean(msg[output.jackSenseId]) } : output);
            const inputs = state.inputs.map(input => msg[input.jackSenseId] !== undefined ? { ...input, connected: Boolean(msg[input.jackSenseId]) } : input);
            const headsetConnected = Boolean(inputs.filter((input) => input.connected).length);
            return { ...state, jackSense: { ...state.jackSense, ...msg }, outputs: outputs, inputs: inputs, headsetDisconnectedAndTimedOut: headsetConnected ? false : state.headsetDisconnectedAndTimedOut };
        }),
        on(volumeEvent, (state, { msg }): UsbState => {
            const outputs = state.outputs.map(output => msg[output.volumeId] !== undefined ? { ...output, volume: msg[output.volumeId] } : output);
            const inputs = state.inputs.map(input => msg[input.volumeId] !== undefined ? { ...input, volume: msg[input.volumeId]  } : input);
            return { ...state, volume: { ...state.volume, ...msg }, outputs: outputs, inputs: inputs };
        }),
        on(acgEvent, (state, { msg }): UsbState => {
            return { ...state, acg: msg };
        }),
        on(thresholdEvent, (state, { msg }): UsbState => {
            return { ...state, threshold: { ...state.threshold, ...msg } };
        }),
        on(muteEvent, (state, { msg }): UsbState => {
            const inputs = state.inputs.map(input => msg[input.jackSenseId] !== undefined ? { ...input, muted: msg[input.jackSenseId]  } : input);
            return { ...state, mute: { ...state.mute, ...msg }, inputs: inputs };
        }),
        on(muteOnAnswer, (state, { jackSenseId, muteOnAnswer }): UsbState => {
            const inputs = state.inputs.map((input) => ({ ...input, muteOnAnswer: input.jackSenseId === jackSenseId ? muteOnAnswer : input.muteOnAnswer}));
            return { ...state, inputs: inputs };
        }),
        on(alertToUsbHeadsets, (state, { alert }): UsbState =>  ({ ...state, alertToHeadsets: alert })),
        on(usbAlertToDevice, (state, { alert }): UsbState => ({ ...state, alertToDevice: alert })),
        on(irrToUsbHeadsets, (state, { alert }): UsbState =>  ({ ...state, irrToHeadsets: alert })),
        on(usbIrrToDevice, (state, { alert }): UsbState => ({ ...state, irrToDevice: alert })),
        on(toastExpired, (state, { ref }): UsbState => {
            return ref === 'headsetTimeout' ? { ...state, headsetDisconnectedAndTimedOut: true } : { ...state };
        })
    )
});
