/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PresenceService } from '../services/presence.service';
import { mergeMap, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AcdService } from '../services/acd.service';
import { AgentStatus } from 'CalltakingCoreApi';
import {
    acdAnswerCompleteFailed,
    acdAnswerRequest,
    acdAnswerRequestFail,
    acdAnswerRequestSuccess,
    acdBid,
    acdBidFail,
    acdBidRemove,
    acdBidResponse,
    acdRequestRejection,
    acdRequestRejectionFail,
    acdRequestRejectionSuccess,
    acdStatusChange,
    acdStatusChangeFail,
    acdStatusChangeSuccess,
    connectionStatus,
    connectionStatusFail,
    connectionStatusSuccess,
    fetchUserAuthenticationRecords,
    fetchUserAuthenticationRecordsFail,
    fetchUserAuthenticationRecordsSuccess,
    initializeUserState,
    initUser,
    noAction,
    remoteLogoutEvent,
    removeIgnoredAcdCall,
    requestedAcdStatusChange,
    setIgnoredAcdCalls,
    updateCurrentRole,
    updateCurrentRoleSuccess
} from './user.actions';
import { AcdStatusService } from '../services/acd-status.service';
import { deleteCall } from '../../call/+state/call.actions';
import { selectCallsMap, selectMyCalls } from '../../call/+state/call.selectors';
import { Store } from '@ngrx/store';
import {
    selectMemoizedUserAuthenticationRecord,
    selectNotReadyOnOutbound,
    selectNotReadyOnRelease,
    selectPendingAcdAnswer,
    selectRequestedAgentStatus,
    selectRole,
    selectSub
} from './user.selectors';
import { selectCallQueueMap } from '../../configuration/+state/configuration.selectors';
import { hotKeyTriggered } from '../../configuration/+state/configuration.actions';
import { ToastType } from '@msi/cobalt';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { BidResult } from '../model/bid-result';
import { SkipperService } from '../../core/services/skipper.service';
import { concatLatestFrom } from '@ngrx/operators';
import { HotKeyAction } from '../../core/model/hotkey';
import { selectIsHubConnectedAndHeadsetDisconnected } from '../../call/+state/cchub.selectors';

@Injectable()
export class UserEffects {
    constructor(
        private userService: UserService,
        private acdService: AcdService,
        private acdStatusService: AcdStatusService,
        private presenceService: PresenceService,
        private skipperService: SkipperService,
        private actions$: Actions,
        private store: Store
    ) {}

    initializeUserState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initializeUserState),
            map(({ clusterName }) => fetchUserAuthenticationRecords({ clusterName: clusterName }))
        )
    );

    fetchUserAuthenticationRecords$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUserAuthenticationRecords),
            mergeMap(({ clusterName }) =>
                this.userService.requestUserAuthenticationRecords(clusterName).pipe(
                    map((response) => fetchUserAuthenticationRecordsSuccess({ clusterName: clusterName, users: response })),
                    catchError((err: Error) => of(fetchUserAuthenticationRecordsFail({ payload: err.message })))
                )
            )
        )
    );

    reportMediaStatusChange$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(connectionStatus),
            concatLatestFrom(() => this.store.select(selectMemoizedUserAuthenticationRecord)),
            filter(([, user]) => !!user),
            switchMap(([{ status }, user]) =>
                this.userService.reportConnectionStatus(status, user.clusterName).pipe(
                    map(() => connectionStatusSuccess()),
                    catchError((err: Error) => of(connectionStatusFail({ payload: err.message })))
                )
            )
        )
    );

    acdStatusChange$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdStatusChange),
            concatLatestFrom(() => this.store.select(selectMemoizedUserAuthenticationRecord)),
            filter(([, user]) => !!user),
            switchMap(([{ agentStatus, isAgentRequested }, user]) =>
                this.acdStatusService.reportAgentStatus(agentStatus, user.clusterName, isAgentRequested).pipe(
                    map((response) => acdStatusChangeSuccess({ agentStatus: response })),
                    catchError((err: Error) => of(acdStatusChangeFail({ payload: err.message })))
                )
            )
        )
    );

    overrideHeldCalls$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(requestedAcdStatusChange),
            filter(({ overrideHeldCalls }) => Boolean(overrideHeldCalls)),
            concatLatestFrom(() => this.store.select(selectMyCalls)),
            map(([{ overrideHeldCalls }, myCalls]) => setIgnoredAcdCalls({ calls: myCalls.map((c) => c.uuid) }))
        )
    );

    ignoreHeldCallsCleanup$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCall),
            map(({ call }) => removeIgnoredAcdCall({ callId: call.uuid }))
        )
    );

    acdBid$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdBid),
            concatLatestFrom(() => this.store.select(selectCallQueueMap)),
            switchMap(([{ bid }, callQueueMap]) => {
                console.debug(`Attempting ACD Bid: callId: ${bid.callId}`);
                return this.acdService.bidForCall(bid.callId, bid.clusterName, bid.selectionTime, bid.previouslyAssigned).pipe(
                    map((response) => {
                        let result = response as BidResult;
                        result.clusterName = bid.clusterName;
                        result.nenaId = bid.nenaId;
                        result.queueACD = response.queueName ? callQueueMap[response.queueName].queueACD : undefined;
                        result.previouslyAssigned = bid.previouslyAssigned;
                        result.auctionId = result.auctionId ? result.auctionId : bid.queueJoin.auctionId;
                        console.debug(`ACD Bid Result: ${response.result} for callId: ${response.callId}`);
                        return acdBidResponse({ result: result });
                    }),
                    catchError((err: Error) => {
                        console.error(`ACD Bid Error: ${err.message}`);
                        return of(acdBidFail({ payload: err.message }));
                    })
                );
            })
        )
    );

    acdBidCleanup$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCall),
            map(({ call }) => acdBidRemove({ callId: call.uuid }))
        )
    );

    acdBidCleanup2$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdAnswerRequestSuccess),
            map(({ callId }) => acdBidRemove({ callId: callId }))
        )
    );

    acdAnswer$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdAnswerRequest),
            switchMap(({ bid }) => {
                console.debug(`Attempting ACD Answer: callId: ${bid.callId}`);
                return this.acdService.acdAnswer(bid.callId, bid.clusterName, bid.queueName).pipe(
                    map(() => {
                        console.debug(`ACD Answer Successful: callId: ${bid.callId}`);
                        return acdAnswerRequestSuccess({ callId: bid.callId, nenaId: bid.nenaId, clusterName: bid.clusterName });
                    }),
                    catchError((err: Error) => {
                        console.error(`ACD Answer Failure: callId: ${bid.callId}, error: ${err.message}`);
                        return of(acdAnswerRequestFail({ callId: bid.callId, payload: err.message }));
                    })
                );
            })
        )
    );

    acdReject$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdRequestRejection),
            concatLatestFrom(() => this.store.select(selectCallsMap)),
            switchMap(([{ callId }, callMap]) => {
                let call = callMap[callId];
                console.debug(`Attempting ACD Reject: callId: ${callId}`);
                return this.acdService.acdReject(callId, call?.clusterName).pipe(
                    map(() => {
                        console.debug(`ACD Reject Successful: callId: ${callId}`);
                        return acdRequestRejectionSuccess({ callId: callId });
                    }),
                    catchError((err: Error) => of(acdRequestRejectionFail({ payload: err.message })))
                );
            })
        )
    );

    // Clean up pending acd answers that never complete
    timeOutPendingAcdAnswer$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdAnswerRequestSuccess),
            delay(5000),
            concatLatestFrom(() => this.store.select(selectPendingAcdAnswer)),
            filter(([{ callId }, pendingAnswer]) => !!pendingAnswer && pendingAnswer.callId === callId),
            map(([{ callId, clusterName }, pendingAnswer]) => acdAnswerCompleteFailed( { callId, clusterName } ))
        )
    );

    notifyServiceWorkersOfTokenUpdate$: Observable<{}> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initUser),
                tap(({ token }) =>
                    navigator.serviceWorker.ready
                        .then(() =>
                            navigator.serviceWorker
                                .getRegistrations()
                                .then((foo) => foo.forEach((reg) => reg.active?.postMessage({ token: token })))
                                .then(() => console.debug('Token sent to Service Worker'))
                                .catch((e) => console.error('Failed to send token information to the service worker: %', e))
                        )
                        .catch((e) => console.error('Service worker ready check error: %', e))
                )
            );
        },
        { dispatch: false }
    );

    acdAnswerFailureReject$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdAnswerRequestFail),
            map(({ callId }) => acdRequestRejection({ callId }))
        )
    );

    notReadyOnRelease$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdStatusChangeSuccess),
            concatLatestFrom(() => this.store.select(selectNotReadyOnRelease)),
            filter(([{ agentStatus }, notReadyOnRelease]) => notReadyOnRelease && agentStatus.status === 'ON_ACD_CALL'),
            map(() => requestedAcdStatusChange({ agentStatus: 'NOT_READY' }))
        )
    );

    notReadyOnOutbound$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(acdStatusChangeSuccess),
            concatLatestFrom(() => this.store.select(selectNotReadyOnOutbound)),
            filter(([{ agentStatus }, notReadyOnOutbound]) => notReadyOnOutbound && agentStatus.status === 'ON_CALL'),
            map(() => requestedAcdStatusChange({ agentStatus: 'NOT_READY' }))
        )
    );

    acdReadyToggleHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.READY_TOGGLE),
            concatLatestFrom(() => [
                this.store.select(selectRequestedAgentStatus),
                this.store.select(selectIsHubConnectedAndHeadsetDisconnected)
            ]),
            filter(([, , hubConnectedAndHeadsetDisconnected]) => !hubConnectedAndHeadsetDisconnected),
            map(([, { status }]) => requestedAcdStatusChange({ agentStatus: status === 'NOT_READY' ? 'READY' : 'NOT_READY', isAgentRequested: true }))
        )
    );

    acdStatusHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.STATUS),
            map(({ hotkey }) => requestedAcdStatusChange({ agentStatus: hotkey.params[0] as AgentStatus, isAgentRequested: true }))
        )
    );

    autoLogoffQueued$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(remoteLogoutEvent),
            map(() => displayToastNotification({ level: ToastType.warning, message: 'Remote Logoff Initiated' }))
        )
    );
    updateCurrentRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCurrentRole),
            withLatestFrom(this.store.select(selectSub), this.store.select(selectRole)),
            switchMap(([action, sub, currentRole]) => {
                if (sub && action.role !== currentRole) {
                    return this.skipperService.updateCurrentRole(action.role, sub).pipe(
                        map(() => updateCurrentRoleSuccess()),
                        catchError((err: Error) => of(noAction()))
                    );
                } else {
                    return of(noAction());
                }
            })
        )
    );
    updateCurrentRoleSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateCurrentRoleSuccess),
                tap(() => {
                    window.location.reload();
                })
            ),
        { dispatch: false }
    );
}
