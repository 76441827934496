/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ControlMessage} from './abstract-control-message';

enum TEST_TONE_FREQUENCY {
  NONE = 0x00,
  SINE_WAVE_404_HZ = 0x01,
  SINE_WAVE_704_HZ = 0x02,
  SINE_WAVE_1004_HZ = 0x03,
  SINE_WAVE_1904_HZ = 0x04,
  SINE_WAVE_2804_HZ = 0x05,
}

enum TEST_TONE_DEVICE_1 {
  NONE = 0x00,
  TELEPHONE = 0x01,
  JACK_BOXES = 0x02,
  RADIO_HEADSET = 0x08,
  CALL_DIRECTOR = 0x80,
  SOFT_RADIO = 0x10,
  INTERNAL_SPEAKER = 0x20,
  SELECT_RADIO_SPEAKER = 0x40
}

enum TEST_TONE_DEVICE_2 {
  NONE = 0x00,
  UNSELECT_1_SPEAKER = 0x01,
  UNSELECT_2_SPEAKER = 0x02,
  DUBBING_RECORDER = 0x04,
  ANALOG_RECORDER = 0x08,
  IRR_1_PHONE = 0x10,
  IRR_2_RADIO = 0x20,
  UNUSED = 0x40,
  LONG_TERM_RECORDER = 0x80
}

export class TestToneMessage extends ControlMessage {

  public static readonly TEST_TONE_FREQUENCY = TEST_TONE_FREQUENCY;
  public static readonly TEST_TONE_DEVICE_1 = TEST_TONE_DEVICE_1;
  public static readonly TEST_TONE_DEVICE_2 = TEST_TONE_DEVICE_2;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.TEST_TONE;
  }

  public withTone(tone: TEST_TONE_FREQUENCY) {
    this.payload1 = tone;
    return this;
  }

  public withDevice1(device: TEST_TONE_DEVICE_1) {
    this.payload2 = device;
    return this;
  }

  public withDevice2(device: TEST_TONE_DEVICE_2) {
    this.payload3 = device;
    return this;
  }

  override payload1String(): string {
    return TEST_TONE_FREQUENCY[this.payload1];
  }

  override payload2String() {
    return TEST_TONE_DEVICE_1[this.payload2];
  }

  override payload3String() {
    return TEST_TONE_DEVICE_2[this.payload3];
  }
}
