/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createAction, props } from '@ngrx/store';

export const usbEffectsInitialized = createAction('[Usb] Effects Initialized');

export const initRti = createAction('[Usb] Initializing RTI');
export const initSam = createAction('[Usb] Initializing SAM');

export const samAttachedEvent = createAction('[Usb] SAM Attached Detection', props<{ isAttached: boolean }>());
export const rtiAttachedEvent = createAction('[Usb] RTI Attached Detection', props<{ isAttached: boolean }>());

export const usbDeviceInfoEvent = createAction('[Usb] Device Information Event', props<{ hardware: string, firmware: string, serial: string }>());

export const jackSenseEvent = createAction('[Usb] Jacksense Event', props<{ msg: { [key: string]: boolean } }>());
export const volumeEvent = createAction('[Usb] Volume Event', props<{ msg: { [key: string]: number } }>());
export const acgEvent = createAction('[Usb] AGC Event', props<{ msg: { [key: string]: boolean } }>());
export const thresholdEvent = createAction('[Usb] Threshold Event', props<{ msg: { [key: string]: number } }>());
export const muteEvent = createAction('[Usb] Mute Event', props<{ msg: { [key: string]: boolean } }>());

export const sendUsbDeviceMute = createAction('[Usb] Send USB Device Mute', props<{ jackSenseId: string, muted: boolean }>());
export const sendUsbDeviceMuteSuccess = createAction('[Usb] Send USB Device Mute Success');
export const sendUsbDeviceMuteFailed = createAction('[Usb] Send USB Device Mute Failed', props<{ payload: string }>());

export const sendUsbDeviceVolume = createAction('[Usb] Send USB Device Volume', props<{ volumeId: string, volume: number }>());
export const sendUsbDeviceVolumeSuccess = createAction('[Usb] Send USB Device Volume Success');
export const sendUsbDeviceVolumeFailed = createAction('[Usb] Send USB Device Volume Failed', props<{ payload: string }>());

export const alertToUsbHeadsets = createAction('[Usb] Headset Alerts To Headset Update', props<{ alert: boolean }>());
export const usbAlertToDevice = createAction('[Usb] Alert To Device Update', props<{ alert: boolean }>());

export const irrToUsbHeadsets = createAction('[Usb] Headset IRR To Headset Update', props<{ alert: boolean }>());
export const usbIrrToDevice = createAction('[Usb] IRR To Device Update', props<{ alert: boolean }>());

export const muteOnAnswer = createAction('[Usb] Auto Mute On Answer Set', props<{ jackSenseId: string; muteOnAnswer: boolean }>());
