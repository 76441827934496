/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HealthService } from './health.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { ServiceName } from '../../../assets/config/environment';

@Injectable({ providedIn: 'root' })
export class MetricsHealthService extends HealthService {
    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService
    ) {
        super(http, ServiceName.QUEUE_METRICS, env.environment.SERVICES[ServiceName.QUEUE_METRICS],
            '/actuator/health/readiness', 0,
            !env.environment.featureFlags?.ctcQueueMetricsEnabled);
    }
}
