/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, throttleTime } from 'rxjs/operators';
import { defer, Observable, of, retry, timer } from 'rxjs';
import {
    clusterQuiescence,
    configurationEffectsInitialized,
    fetchApplicationLinks,
    fetchApplicationLinksFail,
    fetchApplicationLinksSuccess,
    fetchAudibleAlerts,
    fetchAudibleAlertsFail,
    fetchAudibleAlertsSuccess,
    fetchAvailableLanguages,
    fetchAvailableLanguagesFailure,
    fetchAvailableLanguagesSuccess,
    fetchCallAlerts,
    fetchCallAlertsFail,
    fetchCallAlertsSuccess,
    fetchCtcConfiguration,
    fetchCtcConfigurationSuccess,
    fetchCtcTime,
    fetchCtcTimeFail,
    fetchCtcTimeSuccess,
    fetchCtcVersion,
    fetchCtcVersionFail,
    fetchCtcVersionSuccess,
    fetchHotkeys,
    fetchHotkeysFail,
    fetchHotkeysSuccess,
    fetchLayoutConfiguration,
    fetchLayoutConfigurationFail,
    fetchLayoutConfigurationSuccess,
    fetchQueueConfiguration,
    fetchQueueConfigurationFail,
    fetchQueueConfigurationSuccess,
    fetchQuiescenceState,
    fetchQuiescenceStateFail,
    fetchQuiescenceStateSuccess,
    fetchUiConfiguration,
    fetchUiConfigurationFail,
    fetchUiConfigurationSuccess,
    fetchUserSelectableRoles,
    fetchUserSelectableRolesFail,
    fetchUserSelectableRolesSuccess,
    heartbeatRequest,
    heartbeatRequestFailed,
    heartbeatRequestSuccess,
    hotKeyEvent,
    hotKeyTriggered,
    requestClusterQuiescence,
    requestClusterQuiescenceFail,
    requestClusterQuiescenceSuccess,
    sipPeerPasswordRequest,
    sipPeerPasswordRequestSuccess,
    sipPeerPasswordRevokedEvent,
    sipPeerRequest,
    sipPeerRequestSuccess,
    techWebsocketDisable,
    techWebsocketDisableFailure,
    techWebsocketDisableSuccess,
    techWebsocketEnable,
    techWebsocketEnableFailure,
    techWebsocketEnableSuccess
} from './configuration.actions';
import { HotkeyService } from '../services/hotkey.service';
import { ConfigurationService } from '../services/configuration.service';
import { setTheme } from '../../settings/+state/settings.actions';
import { TimerService } from '../../core/services/timer.service';
import { StompService } from '../../core/services/stomp.service';
import { selectTheme } from '../../settings/+state/settings.selectors';
import { CallQuiesceService } from '../../call/services/call.quiesce.service';
import {
    selectClusterConfigurationByIdMap,
    selectClusterConfigurationMap,
    selectClusterNames,
    selectPrimaryConnectionClusterName
} from './configuration.selectors';
import { CtcHeartbeatService } from '../services/ctc-heartbeat.service';
import { fetchRecentCalls } from '../../call/+state/call.actions';
import { SkipperService } from '../../core/services/skipper.service';
import { concatLatestFrom } from '@ngrx/operators';
import { MediaFunctions } from '../../call/util/media-functions';
import { CallFunctions } from '../../call/util/call-functions';

@Injectable()
export class ConfigurationEffects implements OnInitEffects {
    constructor(
        private configurationService: ConfigurationService,
        private timerService: TimerService,
        private actions$: Actions,
        private hotkeyService: HotkeyService,
        private stompService: StompService,
        private callQuiesceService: CallQuiesceService,
        private heartbeatService: CtcHeartbeatService,
        private skipperService: SkipperService,
        private store: Store
    ) {}

    ngrxOnInitEffects(): Action {
        return configurationEffectsInitialized();
    }

    fetchCtcConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCtcConfiguration),
            switchMap(() =>
                defer(() => this.configurationService.requestCtcConfiguration()).pipe(
                    map((response) => fetchCtcConfigurationSuccess({ config: response })),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    fetchCtcVersions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCtcConfigurationSuccess),
            concatLatestFrom(() => this.store.select(selectClusterNames)),
            mergeMap(([, clusterNames]) => clusterNames.map((clusterName) => fetchCtcVersion({ clusterName: clusterName })))
        )
    );

    sipPeerRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sipPeerRequest),
            concatLatestFrom(() => this.store.select(selectClusterConfigurationMap)),
            mergeMap(([{ clusterName }, clusterMap]) =>
                defer(() => this.configurationService.requestCtcPeerConfiguration(clusterName)).pipe(
                    map((response) =>
                        sipPeerRequestSuccess({
                            uuid: clusterMap[clusterName].uuid,
                            clusterName: clusterName,
                            uri: MediaFunctions.getSipUri(response),
                            contact_uri: MediaFunctions.getContactSipUri(response)
                        })
                    ),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    sipPeerPasswordRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sipPeerPasswordRequest),
            concatLatestFrom(() => this.store.select(selectClusterConfigurationMap)),
            mergeMap(([{ clusterName }, clusterMap]) =>
                defer(() => this.configurationService.requestCtcPeerPassword(clusterName)).pipe(
                    map((response) =>
                        sipPeerPasswordRequestSuccess({
                            uuid: clusterMap[clusterName].uuid,
                            clusterName: clusterName,
                            password: response
                        })
                    ),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    sipPeerPasswordRevoked$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sipPeerPasswordRevokedEvent),
            map(({ event }) => sipPeerPasswordRequest({ clusterName: event.clusterName }))
        )
    );

    fetchCtcServerTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCtcTime),
            concatLatestFrom(() => this.store.select(selectPrimaryConnectionClusterName)),
            switchMap(([, clusterName]) =>
                this.timerService.requestServerTime(clusterName).pipe(
                    map((response) => fetchCtcTimeSuccess({ offset: new Date().getTime() - response })),
                    catchError((err: Error) => of(fetchCtcTimeFail({ payload: err.message })))
                )
            )
        )
    );

    fetchCtcVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCtcVersion),
            mergeMap(({ clusterName }) =>
                this.configurationService.requestCtcVersion(clusterName).pipe(
                    map((response) => fetchCtcVersionSuccess({ clusterName: clusterName, version: response })),
                    catchError((err: Error) => of(fetchCtcVersionFail({ payload: err.message })))
                )
            )
        )
    );

    fetchUiConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUiConfiguration),
            switchMap(() =>
                defer(() => this.skipperService.requestUiConfiguration()).pipe(
                    map((response) => fetchUiConfigurationSuccess({ config: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchUiConfigurationFail({ payload: err.message })))
                )
            )
        )
    );

    fetchLayoutConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLayoutConfiguration),
            switchMap(() =>
                defer(() => this.skipperService.requestLayoutConfiguration()).pipe(
                    map((response) => fetchLayoutConfigurationSuccess({ config: response })),
                    retry({ delay: () => timer(5000)}),
                    catchError((err: Error) => of(fetchLayoutConfigurationFail({ payload: err.message })))
                )
            )
        )
    );

    fetchAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAudibleAlerts),
            switchMap(() =>
                this.configurationService.requestAlerts().pipe(
                    map((response) => fetchAudibleAlertsSuccess({ alerts: response })),
                    catchError((err: Error) => of(fetchAudibleAlertsFail({ payload: err.message })))
                )
            )
        )
    );

    fetchCallAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCallAlerts),
            switchMap(() =>
                defer(() => this.skipperService.requestCallAlerts()).pipe(
                    map((response) => fetchCallAlertsSuccess({ alerts: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchCallAlertsFail({ payload: err.message })))
                )
            )
        )
    );

    fetchCallQueues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchQueueConfiguration),
            switchMap(() =>
                defer(() => this.skipperService.requestQueueConfiguration()).pipe(
                    map((response) => fetchQueueConfigurationSuccess({ queues: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchQueueConfigurationFail({ payload: err.message })))
                )
            )
        )
    );

    setTheme$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLayoutConfigurationSuccess),
            filter(({ config }) =>
                Boolean(
                    config.applicationHeader?.sessionManager?.theme &&
                        (config.applicationHeader.sessionManager.theme === 'dark' || config.applicationHeader.sessionManager.theme === 'light')
                )
            ),
            concatLatestFrom(() => this.store.select(selectTheme)),
            filter(([_, userDefinedTheme]) => !userDefinedTheme),
            map(([{ config }]) => setTheme({ theme: config.applicationHeader.sessionManager.theme }))
        )
    );

    fetchExternalApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchApplicationLinks),
            switchMap(() =>
                defer(() => this.skipperService.requestApplicationLinks()).pipe(
                    map((response) => fetchApplicationLinksSuccess({ apps: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchApplicationLinksFail({ payload: err.message })))
                )
            )
        )
    );

    fetchHotkeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchHotkeys),
            switchMap(() =>
                defer(() => this.skipperService.requestHotkeys()).pipe(
                    map((response) => fetchHotkeysSuccess({ hotkeys: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchHotkeysFail({ payload: err.message })))
                )
            )
        )
    );

    nonThrottledHotkeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyEvent),
            filter(({ hotkey }) => !CallFunctions.isCallHotkeyOperation(hotkey.action)),
            map(({ hotkey }) => hotKeyTriggered({ hotkey }))
        )
    );

    throttledHotkeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyEvent),
            filter(({ hotkey }) => CallFunctions.isCallHotkeyOperation(hotkey.action)),
            throttleTime(300),
            map(({ hotkey }) => hotKeyTriggered({ hotkey }))
        )
    );

    websocketEnable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(techWebsocketEnable),
            switchMap(() =>
                this.stompService.connectWebSocket().pipe(
                    map(() => techWebsocketEnableSuccess()),
                    catchError(() => of(techWebsocketEnableFailure()))
                )
            )
        )
    );

    websocketDisable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(techWebsocketDisable),
            switchMap(() =>
                this.stompService.disconnectWebSocket().pipe(
                    map(() => techWebsocketDisableSuccess()),
                    catchError(() => of(techWebsocketDisableFailure()))
                )
            )
        )
    );

    fetchQuiescentState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchQuiescenceState),
            mergeMap(({ clusterName }) =>
                this.callQuiesceService.requestCtcQuiescentState(clusterName).pipe(
                    map((response) => fetchQuiescenceStateSuccess({ clusterName: clusterName, quiescence: response })),
                    catchError((err: Error) => of(fetchQuiescenceStateFail({ payload: err.message })))
                )
            )
        )
    );

    quiescentState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clusterQuiescence),
            concatLatestFrom(() => this.store.select(selectClusterConfigurationByIdMap)),
            map(([{ quiescence }, clusterMap]) => {
                return fetchQuiescenceStateSuccess({ clusterName: clusterMap[quiescence.clusterId]?.name, quiescence: quiescence });
            })
        )
    );

    fetchAvailableLanguages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchAvailableLanguages),
            exhaustMap(() =>
                this.skipperService.fetchAvailableLanguages().pipe(
                    map((languages) => fetchAvailableLanguagesSuccess({ languages })),
                    catchError((err: Error) => of(fetchAvailableLanguagesFailure({ payload: err?.message })))
                )
            )
        );
    });

    fetchUserSelectableRoles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchUserSelectableRoles),
            exhaustMap((action) =>
                defer(() => this.skipperService.fetchUserSelectableRoles()).pipe(
                    map((roles) => fetchUserSelectableRolesSuccess({ roles: roles })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchUserSelectableRolesFail({ payload: err?.message })))
                )
            )
        );
    });

    heartbeat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(heartbeatRequest),
            mergeMap(({ clusterName }) =>
                defer(() => this.heartbeatService.ping(clusterName)).pipe(
                    map(() => heartbeatRequestSuccess({ clusterName })),
                    retry({ count: 2, delay: () => timer(1000) }),
                    catchError(() => of(heartbeatRequestFailed({ clusterName })))
                )
            )
        )
    );

    fetchRecentCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUiConfigurationSuccess),
            map(({ config }) => fetchRecentCalls({ hours: 24, maxCalls: config.recentCallLimit }))
        ));

    quiesce$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestClusterQuiescence),
            filter(({ quiesced }) => quiesced),
            mergeMap(({ clusterName }) =>
                this.callQuiesceService.quiesce(clusterName).pipe(
                    map((response) => requestClusterQuiescenceSuccess()),
                    catchError((err: Error) => of(requestClusterQuiescenceFail({ payload: err.message })))
                )
            )
        )
    );

    unQuiesce$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestClusterQuiescence),
            filter(({ quiesced }) => !quiesced),
            mergeMap(({ clusterName }) =>
                this.callQuiesceService.unquiesce(clusterName).pipe(
                    map((response) => requestClusterQuiescenceSuccess()),
                    catchError((err: Error) => of(requestClusterQuiescenceFail({ payload: err.message })))
                )
            )
        )
    );
}
