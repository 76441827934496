/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

export class Report2 extends DataView {
    public static readonly REPORT_ID = 2;

    public constructor(buffer?: ArrayBuffer) {
        super(buffer ? buffer : new ArrayBuffer(63));
    }

    public get reportId(): number {
        return Report2.REPORT_ID;
    }

    public get type(): number {
        return this.getUint8(0);
    }

    public get totalBytes(): number {
        return this.getUint8(1);
    }

    public get payload(): string {
        return new TextDecoder().decode(new Uint8Array(
            this.buffer,
            2,
            this.totalBytes
        ));
    }

    public logMessage() {
        return `Report 2: ${this.payload}`;
    }
}
