/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
    connectionStatus,
    deleteUserAuthenticationRecord,
    initializeUserState,
    initUser,
    newUserAuthenticationRecord,
    remoteLogoutEvent,
    updateUserAuthenticationRecord,
    updateUserSubscriptions
} from '../+state/user.actions';
import { BehaviorSubject, defer, from, Observable, retry, Subscription } from 'rxjs';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { StompService } from '../../core/services/stomp.service';
import { Message } from '@stomp/stompjs';
import { ConnectionStatus, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { catchError, debounceTime, delay, filter, map, take } from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { VestaKeycloakTokenParsed } from '../model/vesta-keycloak-token-parsed';
import { selectLogoffDisabled } from '../../call/+state/call.selectors';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { selectMediaConnectionStatus } from '../../call/+state/media.selectors';
import { selectClusterConfigurationMap } from '../../configuration/+state/configuration.selectors';
import { selectUserStateInitializationTime } from '../+state/user.selectors';
import { EnvironmentService } from '../../core/services/environment.service';
import { HeaderLoginService } from '@msi/commandcentral-user-authentication';
import { InterAppCommunicationChannelService } from '@msi/commandcentral-common-header';

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnDestroy, StompConsumer {
    private readonly USER_AUTHENTICATION_LOAD = '/api/users/v1/load';
    private readonly USER_CONNECTION_STATUS = '/api/users/v1/connection-status';
    private readonly USER_AUTHENTICATION_NEW_TOPIC = `/user/topic/v1/user-auth/new`;
    private readonly USER_AUTHENTICATION_EDIT_TOPIC = `/user/topic/v1/user-auth/*/edit`;
    private readonly USER_AUTHENTICATION_DELETE_TOPIC = `/user/topic/v1/user-auth/*/delete`;
    private readonly USER_LOGOUT_TOPIC = `/user/topic/v1/tpi-auth/logout`;
    private readonly newReceipt = uuid.v4();
    private readonly editReceipt = uuid.v4();
    private readonly deleteReceipt = uuid.v4();
    private readonly logoffReceipt = uuid.v4();
    private logoffDisabled$: Observable<boolean> | undefined;
    private subscriptions: { [topic: string]: { [receipt: string]: boolean } } = {
        [this.USER_AUTHENTICATION_NEW_TOPIC]: {
            [this.newReceipt]: false
        },
        [this.USER_AUTHENTICATION_EDIT_TOPIC]: {
            [this.editReceipt]: false
        },
        [this.USER_AUTHENTICATION_DELETE_TOPIC]: {
            [this.deleteReceipt]: false
        },
        [this.USER_LOGOUT_TOPIC]: {
            [this.logoffReceipt]: false
        }
    };
    private subscriptions$ = new BehaviorSubject<{ [topic: string]: { [receipt: string]: boolean } }>(this.subscriptions);
    private readonly newAuthTopicSubscription: Subscription | undefined;
    private readonly editAuthTopicSubscription: Subscription | undefined;
    private readonly deleteAuthTopicSubscription: Subscription | undefined;
    private readonly logoffRequestTopic!: Subscription;
    private interAppCommunicationChannelService: InterAppCommunicationChannelService;

    constructor(
        private http: HttpClient,
        private store: Store,
        private env: EnvironmentService,
        private stompService: StompService,
        private keycloakService: KeycloakService,
        private clusterUrlProviderService: ClusterUrlProviderService,
        private headerLoginService: HeaderLoginService
    ) {
        if (env.environment.featureFlags.ccAdminIntegrated) {
            this.interAppCommunicationChannelService = inject(InterAppCommunicationChannelService);
        }

        this.updateToken();
        keycloakService.keycloakEvents$.subscribe((keycloakEvent) => {
            if (keycloakEvent.type == KeycloakEventType.OnAuthRefreshSuccess) {
                this.updateToken();
            }
            if (keycloakEvent.type == KeycloakEventType.OnAuthLogout) {
                this.logoffDisabled$ = this.store.select(selectLogoffDisabled);
                this.logoffDisabled$.subscribe((logoffDisabled) => {
                    if (!logoffDisabled) {
                        window.location.reload();
                    }
                });
            }
            if (keycloakEvent.type == KeycloakEventType.OnTokenExpired) {
                defer(() => from(keycloakService.updateToken(-1)))
                    .pipe(
                        filter((success) => !success),
                        map(() => {
                            throw Error('Failed to refresh token');
                        }),
                        retry({ count: 3, delay: 5000 }),
                        catchError(() => this.logout())
                    )
                    .subscribe();
            }
        });
        this.newAuthTopicSubscription = this.stompService
            .watchAsync(this.USER_AUTHENTICATION_NEW_TOPIC, this.newReceipt, this)
            .subscribe((message: Message) => {
                this.store.dispatch(newUserAuthenticationRecord({ userAuthenticationRecord: JSON.parse(message.body) }));
            });
        this.editAuthTopicSubscription = this.stompService
            .watchAsync(this.USER_AUTHENTICATION_EDIT_TOPIC, this.editReceipt, this)
            .subscribe((message: Message) => {
                this.store.dispatch(updateUserAuthenticationRecord({ userAuthenticationRecord: JSON.parse(message.body) }));
            });
        this.deleteAuthTopicSubscription = this.stompService
            .watchAsync(this.USER_AUTHENTICATION_DELETE_TOPIC, this.deleteReceipt, this)
            .pipe(delay(1000))
            .subscribe((message: Message) => {
                this.store.dispatch(deleteUserAuthenticationRecord({ userAuthenticationRecord: JSON.parse(message.body) }));
            });
        this.logoffRequestTopic = this.stompService.watchAsync(this.USER_LOGOUT_TOPIC, this.logoffReceipt, this).subscribe((message: Message) => {
            this.store.dispatch(remoteLogoutEvent({ logoutRequest: JSON.parse(message.body) }));
        });

        this.subscriptions$.subscribe((subscriptionState) => {
            this.store.dispatch(updateUserSubscriptions({ subscriptions: subscriptionState }));
        });

        this.store
            .select(selectMediaConnectionStatus)
            .pipe(
                debounceTime(250),
                filter((val) => !!val)
            )
            .subscribe((mediaConnectionMap) => this.store.dispatch(connectionStatus({ status: mediaConnectionMap })));

        // Trigger (re)initialization of each cluster's user state
        this.store
            .select(selectClusterConfigurationMap)
            .pipe(
                filter((clusterMap) => Boolean(Object.keys(clusterMap).length)),
                take(1)
            )
            .subscribe((clusterMap) =>
                Object.keys(clusterMap).forEach((clusterName) =>
                    this.store
                        .select(selectUserStateInitializationTime(clusterName))
                        .pipe(filter((val) => !!val))
                        .subscribe((init) => this.store.dispatch(initializeUserState({ clusterName: clusterName, initializationTime: init })))
                )
            );
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`User Authentication Records topic ${topic} subscription success: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = true;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`User Authentication Records topic ${topic} subscription end: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = false;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`User Authentication Records topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.newAuthTopicSubscription) {
            this.newAuthTopicSubscription.unsubscribe();
        }
        if (this.editAuthTopicSubscription) {
            this.editAuthTopicSubscription.unsubscribe();
        }
        if (this.deleteAuthTopicSubscription) {
            this.deleteAuthTopicSubscription.unsubscribe();
        }
        if (this.logoffRequestTopic) {
            this.logoffRequestTopic.unsubscribe();
        }
    }

    private updateToken() {
        let parsedToken = this.keycloakService.getKeycloakInstance()?.tokenParsed;
        this.keycloakService
            .getToken()
            .then((token) => this.store.dispatch(initUser({ token: token, tokenParsed: parsedToken as VestaKeycloakTokenParsed })));
    }

    public requestUserAuthenticationRecords(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.USER_AUTHENTICATION_LOAD);
        return this.http.get<UserAuthenticationRecord[]>(url, { observe: 'body', responseType: 'json' });
    }

    public reportConnectionStatus(mediaStatus: { [asterisk: string]: ConnectionStatus }, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.USER_CONNECTION_STATUS}`);
        return this.http.post(`${url}`, mediaStatus);
    }

    public logout() {
        return this.env.environment.featureFlags?.ccAdminIntegrated ?
            from(this.headerLoginService.logout().then(() => this.interAppCommunicationChannelService.postMessage({ forceLogout: true }))) :
            from(this.keycloakService.updateToken(-1).then(() => this.keycloakService.logout()));
    }
}
