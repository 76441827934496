/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

export class ConfigurationOutOfRangeMessage extends ConfigurationMessage {
    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ConfigurationMessage.ConfigurationMessageIdentifier.OUT_OF_RANGE;
    }

    override payload1String(): string {
        return ConfigurationMessage.ConfigurationMessageIdentifier[this.payload1];
    }
}
