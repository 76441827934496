/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {HttpParameterCodec} from "@angular/common/http";

export class CustomHttpParamEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
