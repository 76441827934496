/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum VOLUME_DEVICE_ID {
    JACKBOX_1_EAR_OUTPUT = 0x01,
    JACKBOX_2_EAR_OUTPUT = 0x02,
    MICROPHONE_INPUTS = 0x04,
    IRR_PLAYBACK_TO_HEADSETS = 0x08,
    AUX_AUDIO_INPUT = 0x0B,
    ALERT_AUDIO_INPUT = 0x10,
    PHONE_EAR_INPUT = 0x65,
    PHONE_MIC_OUTPUT = 0x66,
    LTR_RECORD_OUTPUT = 0x6B,
    RPI_TRANSMIT_OUTPUT = 0x74,
    RPI_RECEIVE_INPUT = 0x75,
    TEST_TONE = 0x80,
    PHONE_SIDETONE = 0x87
}

export class VolumeMessage extends ControlMessage {
    public static readonly VOLUME_DEVICE_ID = VOLUME_DEVICE_ID;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.VOLUME;
    }

    public withDevice(state: VOLUME_DEVICE_ID) {
        this.payload2 = state;
        return this;
    }

    public withVolume(volume: number) {
        if (volume < -127 || volume > 127) {
            throw new Error('volume out of range');
        }
        this.payload3 = volume;
        return this;
    }

    // override get/set payload for volume level as it can be negative so should be signed byte
    // so instead of unsigned byte (0-255), it's a signed byte (-128-127)
    override set payload3(byte: number) {
        this.setInt8(4, byte);
    }
    override get payload3(): number {
        return this.getInt8(4);
    }
    override payload2String(): string {
        return VOLUME_DEVICE_ID[this.payload2];
    }
    override payload3String(): string {
        return String(this.payload3);
    }

}
