/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { observeAgent, openCall, updateActiveCall, updateSelectedCall } from '../../call/+state/call.actions';
import { updateRequestedTab } from './main.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class MainEffects {
    constructor(
        private actions$: Actions
    ) {}

    selectObserveTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(observeAgent),
            map(() => updateRequestedTab({ label: 'observer' }))
        )
    );

    monitorSelectedCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActiveCall, updateSelectedCall, openCall),
            map(({ callId }) => updateRequestedTab({ label: callId }))
        )
    );
}
