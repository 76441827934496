/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum THRESHOLD_TARGET_ID {
    PHONE_REC_AGC = 0xC9,
    ALERT_SOUNDS_AGC = 0xCA,
    AUX_AUDIO_AGC = 0xCB,
    HEADSET_MICS_AGC = 0xCC,
    RPI_REC_AGC = 0xCE,
    IRR_AGC = 0xD3
}

export class ThresholdMessage extends ControlMessage {
    public static readonly THRESHOLD_TARGET_ID = THRESHOLD_TARGET_ID;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.THRESHOLD;
    }

    public withDevice(state: THRESHOLD_TARGET_ID) {
        this.payload2 = state;
        return this;
    }

    override payload2String(): string {
        return THRESHOLD_TARGET_ID[this.payload2];
    }

    override payload3String(): string {
        return String(this.payload3);
    }

    public withThreshold(value: number) {
        if (value < -127 || value > 127) {
            throw new Error('threshold out of range');
        }
        this.payload3 = value;
        return this;
    }

    // override get/set payload for volume level as it can be negative so should be signed byte
    // so instead of unsigned byte (0-255), it's a signed byte (-128-127)
    override set payload3(byte: number) {
        this.setInt8(4, byte);
    }
    override get payload3(): number {
        return this.getInt8(4);
    }

}
