/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

enum AUDIO_SOURCE {
  SAM_HEADSET_PORT_SOFTWARE = 0x00,
  SAM_HEADSET_PORT_SOFTWARE_RPI = 0x01,
  CALL_DIRECTOR_PORT = 0x02,
  WORKSTATION = 0x03
}

export class PhoneSourceMessage extends ConfigurationMessage {
  public static readonly AUDIO_SOURCE = AUDIO_SOURCE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.PHONE_SOURCE;
  }

  public withAudioSource(audioSource: AUDIO_SOURCE) {
    this.payload1 = this.payload1 | audioSource;
    return this;
  }

  override payload1String(): string {
      return AUDIO_SOURCE[this.payload1];
  }
}
