/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum DEVICE {
  ALL = 0x00,
  JACKBOX_1 = 0x01,
  JACKBOX_2 = 0x02,
  JACKBOX_3 = 0x03
}
enum STATE {
    UNMUTE=0x00,
    MUTE= 0x01
}

export class JackboxMuteMessage extends ControlMessage {
    public static readonly DEVICE = DEVICE;
    public static readonly STATE = STATE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.JACKBOX_MUTE;
    }

    public withDevice(device: DEVICE) {
        this.payload1 = device;
        return this;
    }
    public withState(state: STATE) {
        this.payload2 = state;
        return this;
    }

    override payload1String(): string {
        return DEVICE[this.payload1];
    }

    override payload2String(): string {
        return STATE[this.payload2];
    }
}
