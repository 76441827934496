/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize } from '@ngrx/store';
import {
    messageFeature,
    MessageState,
    selectAllDefault,
    selectAllDtmf,
    selectAllSms,
    selectAllTdd,
    selectDefaultState,
    selectDtmfState,
    selectSmsState,
    selectTddState
} from './message.reducer';
import { selectApplicationPresence, selectLanguage } from '../../user/+state/user.selectors';
import { Call, Message, MessageType } from 'CalltakingCoreApi';
import { selectCallback } from '../../call/+state/call.selectors';

export const selectMessageState = createFeatureSelector<MessageState>(messageFeature.name);

export const selectMessageEffectInitialized = createSelector(selectMessageState, (state) => state.initialized);
export const selectMessageStateSubscriptions = createSelector(selectMessageState, (state) => state.subscriptions);
export const selectAllMessageStateSubscriptionsConfirmed = createSelector(selectMessageStateSubscriptions, (subscriptions) =>
    Object.values(subscriptions)
        .flatMap((sub) => Object.values(sub))
        .every((confirmed) => confirmed)
);
export const selectMessageStateInitializationTime = createSelector(
    selectAllMessageStateSubscriptionsConfirmed,
    selectApplicationPresence,
    (subscribed, presence) => (subscribed && presence.loggedIn && presence.loginTime ? presence.loginTime : 0)
);

// Since array.filter returns a new object, any selector that filters for example a message list, will emit when the input changes, regardless of whether the filtered results change
// In order to make our selectors smarter, we provide it with a custom memorization function to compare the contents of the array, thus the selectors utilizing array.filter can emit only when the result changes.

export const arrayFilterMemoize = (projectorFn: DefaultProjectorFn<Call[]>) => resultMemoize(projectorFn, arrayIsEqual);

export function arrayIsEqual(a: any[], b: any[]): boolean {
    return a?.length === b?.length && a?.every((item) => b?.includes(item));
}

export const selectSMSMessageState = createSelector(selectMessageState, selectSmsState);

export const selectTDDMessageState = createSelector(selectMessageState, selectTddState);

export const selectDTMFMessageState = createSelector(selectMessageState, selectDtmfState);

export const selectDefaultMessageState = createSelector(selectMessageState, selectDefaultState);

export const selectAllSMS = createSelector(selectSMSMessageState, selectAllSms);

export const selectAllTDD = createSelector(selectTDDMessageState, selectAllTdd);

export const selectAllDTMF = createSelector(selectDTMFMessageState, selectAllDtmf);

export const selectAllSystemMessages = createSelector(selectDefaultMessageState, selectAllDefault);

// SMS
export const selectSmsCallMap = createSelector(selectMessageState, (state) => state.smsCallMap);
export const selectCallSmsMessages = (id: string) =>
    createSelectorFactory<object, Message[]>(arrayFilterMemoize)(selectSmsCallMap, (smsCallMap: { [callId: string]: { [messageId: string]: Message } }) =>
        smsCallMap[id] ? Object.values(smsCallMap[id]) : []
    );

export const selectSmsHistoricalMap = createSelector(selectMessageState, (state) => state.historicalTextMap);
export const selectSmsHistoricalCallMessageMap = (callId: string) =>
    createSelector(
        selectCallback(callId),
        selectSmsHistoricalMap,
        (callback, historicalTextMap) => historicalTextMap[callback]);

export const selectPredefinedSms = createSelector(selectMessageState, (state) => state.predefinedSms);
export const selectLocalizedPredefinedSms = createSelector(selectPredefinedSms, selectLanguage, (messages, language) => {
    let exactMatch = messages.find((message) => message.language === language);
    return exactMatch ? exactMatch : messages.find((message) => new Intl.Locale(message.language).language === new Intl.Locale(language).language);
});

// TDD
export const selectTddCallMap = createSelector(selectMessageState, (state) => state.tddCallMap);
export const selectCallTddMessages = (id: string) =>
    createSelectorFactory<object, Message[]>(arrayFilterMemoize)(selectTddCallMap, (tddCallMap: { [callId: string]: { [messageId: string]: Message } }) =>
        tddCallMap[id] ? Object.values(tddCallMap[id]) : []
    );

export const selectPredefinedTdd = createSelector(selectMessageState, (state) => state.predefinedTdd);
export const selectLocalizedPredefinedTdd = createSelector(selectPredefinedTdd, selectLanguage, (messages, language) => {
    let exactMatch = messages.find((message) => message.language === language);
    return exactMatch ? exactMatch : messages.find((message) => new Intl.Locale(message.language).language === new Intl.Locale(language).language);
});

// DTMF
export const selectDtmfCallMap = createSelector(selectMessageState, (state) => state.dtmfCallMap);
export const selectCallDtmfMessages = (id: string) =>
    createSelectorFactory<object, Message[]>(arrayFilterMemoize)(selectDtmfCallMap, (smsCallMap: { [callId: string]: { [messageId: string]: Message } }) =>
        smsCallMap[id] ? Object.values(smsCallMap[id]) : []
    );

export const selectTddChallengeMessage = createSelector(selectLocalizedPredefinedTdd, (language) => {
    if (language) {
        const messages = Object.values(language.categories).flatMap((v) => v);
        return messages?.find((m) => m.uuid === language.queryTtyMessage);
    }
    return undefined;
});

// RTT
export const selectRttCallMap = createSelector(selectMessageState, (state) => state.rttCallMap);
export const selectCallRttMessages = (id: string) =>
    createSelectorFactory<object, Message[]>(arrayFilterMemoize)(selectRttCallMap, (smsCallMap: { [callId: string]: { [messageId: string]: Message } }) =>
        smsCallMap[id] ? Object.values(smsCallMap[id]) : []
    );

// Text Contextual
export const selectCallMessages = (id: string, type: MessageType) =>
    createSelectorFactory<object, Message[]>(arrayFilterMemoize)(
        selectCallTddMessages(id),
        selectCallSmsMessages(id),
        selectCallDtmfMessages(id),
        selectCallRttMessages(id),
        (tddMessages: Message[], smsMessages: Message[], dtmfMessages: Message[], rttMessages: Message[]) =>
            type === 'SMS' ? smsMessages : type === 'DTMF' ? dtmfMessages : type === 'TDD' ? tddMessages : type === 'RTT' ? rttMessages : ([] as Message[])
    );

export const selectTddMessageCount = (id: string) => createSelector(selectCallTddMessages(id), (messages) => messages.length);
export const selectRttMessageCount = (id: string) => createSelector(selectCallRttMessages(id), (messages) => messages.length);
export const selectSmsMessageCount = (id: string) => createSelector(selectCallSmsMessages(id), (messages) => messages.length);
export const selectDtmfMessageCount = (id: string) => createSelector(selectCallDtmfMessages(id), (messages) => messages.length);

export const selectLocalizedPredefinedMessages = (type: MessageType) =>
    createSelector(selectLocalizedPredefinedTdd, selectLocalizedPredefinedSms, (predefinedTddMessages, predefinedSmsMessages) =>
        type === 'TDD' ? predefinedTddMessages : type === 'SMS' ? predefinedSmsMessages : type === 'RTT' ? predefinedSmsMessages :  undefined);
