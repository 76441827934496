/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

enum INPUT_ID {
  NONE = 0x00,
  TELEPHONE_USB = 0x01,
  JACKBOX_MIC_1 = 0x03,
  JACKBOX_MIC_2 = 0x04,
  RPI = 0x07,
  DIGITAL_AUX_AUDIO = 0x0f
}

export class InputLevelMessage extends EventMessage {
  public static readonly INPUT_ID = INPUT_ID;

  constructor(arrayBuffer?: ArrayBuffer, messageId?: number) {
    super(arrayBuffer);
    this.id = messageId;
  }

  public forInput(device: INPUT_ID) {
    this.payload1 = device;
    return this;
  }

  override payload1String(): string {
    return INPUT_ID[this.payload1];
  }
}
