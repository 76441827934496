/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OutputReport1 } from '../output-report-1';
import { Report1 } from '../report-1';

enum ConfigurationMessageIdentifier {
  ARBITRATION_MODE= 0x00,
  PHONE_SOURCE = 0x01,
  AUX_AUDIO = 0x02,
  AUX_AUDIO_RESUMES = 0x03,
  ALERT_SOUNDS = 0x05,
  HEADSET_MIC = 0x07,
  JACK_BOX_AUDIO_BOOST = 0x0a,
  LTR_PHONE_AUDIO = 0x11,
  AGC = 0x1f,
  AGC_SILENCE = 0x20,
  PHONE_SIDE_TONE = 0x2c,
  ECHO_CANCELLER = 0x2d,
  OUT_OF_RANGE = 0xfe,
  ERROR = 0xff
}

export abstract class ConfigurationMessage extends Report1 {
  public static readonly ConfigurationMessageIdentifier = ConfigurationMessageIdentifier;

  protected constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.type = OutputReport1.MessageType.CONFIGURATION;
  }

  override get id() {
    return super.id;
  }

  override set id(id: ConfigurationMessageIdentifier) {
    super.id = id;
  }

  override idString(): string {
    return ConfigurationMessageIdentifier[this.id];
  }
}
