/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import {
    fetchLocationLayoutSuccess,
    fetchServiceRespondersSuccess,
    locationEffectsInitialized,
    manualQuery,
    manualQueryFail,
    manualQuerySuccess,
    purgeLocation,
    updateEnhancedLocation,
    updateStandardLocation
} from './location.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LocationEvent } from '../model/location';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { ServiceResponders } from '../model/service-responders';
import { SortFunctions } from '../../call/util/sort-functions';
import * as dayjs from 'dayjs';

export interface LocationState {
    initialized: boolean;
    standard: StandardLocationState;
    enhanced: EnhancedLocationState;
    standardLocationCallMap: { [callId: string]: { [locationId: string]: LocationEvent } };
    enhancedLocationCallMap: { [callId: string]: { [locationId: string]: LocationEvent } };
    layoutConfiguration: LocationDisplayTemplate | undefined;
    manualLocationNumber: string | undefined;
    manualLocationEvent: LocationEvent | undefined;
    manualLocationPending: boolean;
    serviceResponders: ServiceResponders | undefined;
}

export interface StandardLocationState extends EntityState<LocationEvent> {}

export interface EnhancedLocationState extends EntityState<LocationEvent> {}

export const standardLocationAdapter: EntityAdapter<LocationEvent> = createEntityAdapter<LocationEvent>({
    selectId: (locationEvent: LocationEvent) => locationEvent.locationId,
    sortComparer: SortFunctions.newestLocationEventSort
});

export const enhancedLocationAdapter: EntityAdapter<LocationEvent> = createEntityAdapter<LocationEvent>({
    selectId: (locationEvent: LocationEvent) => locationEvent.locationId,
    sortComparer: SortFunctions.newestLocationEventSort
});

const initialStandardLocationState: StandardLocationState = standardLocationAdapter.getInitialState({});

const initialEnhancedLocationState: EnhancedLocationState = enhancedLocationAdapter.getInitialState({});

const initialState: LocationState = {
    initialized: false,
    standard: initialStandardLocationState,
    enhanced: initialEnhancedLocationState,
    standardLocationCallMap: {},
    enhancedLocationCallMap: {},
    layoutConfiguration: undefined,
    manualLocationNumber: undefined,
    manualLocationEvent: undefined,
    manualLocationPending: false,
    serviceResponders: undefined
};

export const locationFeature = createFeature({
    name: 'location',
    reducer: createReducer(
        initialState,
        on(locationEffectsInitialized, (state): LocationState => {
            return { ...state, initialized: true };
        }),
        on(updateStandardLocation, (state, { locationEvent }) => {
            let existingEntity = state.standard.entities[locationEvent.locationId];
            if (!existingEntity || dayjs(existingEntity.createdDateTime).isBefore(dayjs(locationEvent.createdDateTime))) {
                let updatedState = state.standard;
                let updatedMap = structuredClone(state.standardLocationCallMap);
                let existingLocations = state.standardLocationCallMap[locationEvent.callId]
                    ? Object.values(state.standardLocationCallMap[locationEvent.callId])
                        .sort(SortFunctions.oldestLocationEventSort)
                        .map((l) => l.locationId)
                    : [];
                if (existingLocations.length >= 20) {
                    updatedState = standardLocationAdapter.removeOne(existingLocations[0], state.standard);
                    delete updatedMap[locationEvent.callId][existingLocations[0]];
                }
                return {
                    ...state,
                    standard: standardLocationAdapter.setOne(locationEvent, updatedState),
                    standardLocationCallMap: {
                        ...state.standardLocationCallMap,
                        [locationEvent.callId]: { ...updatedMap[locationEvent.callId], [locationEvent.locationId]: locationEvent }
                    }
                };
            }
            return state;
        }),
        on(updateEnhancedLocation, (state, { locationEvent }) => {
            let existingEntity = state.enhanced.entities[locationEvent.locationId];
            if (!existingEntity || dayjs(existingEntity.createdDateTime).isBefore(dayjs(locationEvent.createdDateTime))) {
                let updatedState = state.enhanced;
                let updatedMap = structuredClone(state.enhancedLocationCallMap);
                let existingLocations = state.enhancedLocationCallMap[locationEvent.callId]
                    ? Object.values(state.enhancedLocationCallMap[locationEvent.callId])
                        .sort(SortFunctions.oldestLocationEventSort)
                        .map((l) => l.locationId)
                    : [];
                if (existingLocations.length >= 20) {
                    updatedState = enhancedLocationAdapter.removeOne(existingLocations[0], state.enhanced);
                    delete updatedMap[locationEvent.callId][existingLocations[0]];
                }
                return {
                    ...state,
                    enhanced: enhancedLocationAdapter.setOne(locationEvent, updatedState),
                    enhancedLocationCallMap: {
                        ...state.enhancedLocationCallMap,
                        [locationEvent.callId]: { ...updatedMap[locationEvent.callId], [locationEvent.locationId]: locationEvent }
                    }
                };
            }
            return state;
        }),
        on(purgeLocation, (state, { uuid }) => {
            let standardLocationCallMap = { ...state.standardLocationCallMap };
            delete standardLocationCallMap[uuid];
            let enhancedLocationCallMap = { ...state.enhancedLocationCallMap };
            delete enhancedLocationCallMap[uuid];
            return {
                ...state,
                standard: standardLocationAdapter.removeMany((locationEvent) => locationEvent.callId === uuid, state.standard),
                enhanced: enhancedLocationAdapter.removeMany((locationEvent) => locationEvent.callId === uuid, state.enhanced),
                standardLocationCallMap: standardLocationCallMap,
                enhancedLocationCallMap: enhancedLocationCallMap
            };
        }),
        on(fetchLocationLayoutSuccess, (state, { response }): LocationState => {
            return { ...state, layoutConfiguration: response };
        }),
        on(manualQuery, (state, { number }): LocationState => {
            return { ...state, manualLocationNumber: number, manualLocationEvent: undefined, manualLocationPending: true };
        }),
        on(manualQuerySuccess, (state, { number, locationEvent }): LocationState => {
            return { ...state, manualLocationNumber: number, manualLocationEvent: locationEvent, manualLocationPending: false };
        }),
        on(manualQueryFail, (state, { number }): LocationState => {
            return { ...state, manualLocationNumber: number, manualLocationEvent: undefined, manualLocationPending: false };
        }),
        on(fetchServiceRespondersSuccess, (state, { response }): LocationState => {
            return { ...state, serviceResponders: response };
        })
    )
});

export const selectStandardLocationState = (state: LocationState) => state.standard;
export const selectEnhancedLocationState = (state: LocationState) => state.enhanced;

export const { selectEntities: selectStandardLocationEntities, selectAll: selectAllStandardLocationsReducer } = standardLocationAdapter.getSelectors();

export const { selectEntities: selectEnhancedLocationEntities, selectAll: selectAllEnhancedLocationsReducer } = enhancedLocationAdapter.getSelectors();
