/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';


export class ControlErrorMessage extends ControlMessage {

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.ERROR;
  }

  override payload1String(): string {
    return ControlMessage.ControlMessageIdentifier[this.payload1];
  }
}
