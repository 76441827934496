/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Message } from 'CalltakingCoreApi';

export class AnalyticsHistoricalTextMessage {

    public static toMessage(analyticsMessage: AnalyticsHistoricalTextMessage): Message {
        return {
            uuid: analyticsMessage.uuid,
            callId: analyticsMessage.ctcCallId,
            agencyId: analyticsMessage.agencyId,
            type: 'SMS',
            message: analyticsMessage.messageBody,
            privy: false,
            createdTimestamp: analyticsMessage.dateTime,
            updatedTimestamp: analyticsMessage.dateTime,
            sender: analyticsMessage.userName,
            senderParticipantId: '',
            messageSenderType: 'AGENT',
            predefined: false,
            predefinedLanguage: '',
            targetedUsers: []
        } as Message;
    }

    uuid: string;
    agencyId: string;
    nenaCallId: string;
    nenaIncidentTrackingId: string;
    tccIncidentNumber: string;
    callingNumber: string;
    messageBody: string;
    carrier: string;
    dateTime: string;
    ctcCallId: string;
    location: Location;
    direction: string;
    agent: string;
    userName: string;
    agentPosition: string;
}
