/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Theme } from '../../settings/model/theme';

export interface LayoutSettings {
    uuid: string;
    name: string;
    applicationHeader: ApplicationHeaderSettings;
    leftPane: LeftPaneSettings;
    centralPane: CentralPaneSettings;
    rightPane: RightPaneSettings;
    sidePane: SidePaneSettings;
}

export interface ApplicationHeaderSettings {
    uuid: string;
    help: boolean;
    notifications: boolean;
    agencyBranding: boolean;
    sessionManager: {
        uuid: string;
        changeTheme: boolean;
        theme: Theme;
        applicationHeader: string;
    };
    consoleLayout: string;
}

export interface LeftPaneSettings {
    uuid: string;
    acdStatus: boolean;
    calls: {
        uuid: string;
        leftPane: string;
    };
    consoleLayout: string;
}

export interface CentralPaneSettings {
    uuid: string;
    monitoring: boolean;
    callDetails: {
        uuid: string;
        historyTab: boolean;
        irrTab: boolean;
        callHeader: CallHeaderSettings;
        infoTab: InfoTabSettings;
        participantsTab: ParticipantsTabSettings;
        centralPane: string;
    };
    consoleLayout: string;
}

export interface RightPaneSettings {
    uuid: string;
    queueDisplay: boolean;
    directory: {
        uuid: string;
        agents: {
            enabled: boolean;
            message: boolean;
        };
        keypad: {
            enabled: boolean;
            sms: boolean;
        };
        suggestedSpeedDials: boolean;
        rightPane: string;
        ringdowns: boolean;
    };
    consoleLayout: string;
}

export interface SidePaneSettings {
    uuid: string;
    recentCalls: boolean;
    instantMessaging: {
        enabled: boolean;
        createConversation: boolean;
    };
    announcements: boolean;
    discrepancyReport: boolean;
    audioSettings: boolean;
    audioQuality: boolean;
    toolShortcuts: boolean;
    abandonedCalls: boolean;
    consoleLayout: string;
    locationQuery: string;
}

export interface InfoTabSettings {
    uuid: string;
    copy: boolean;
    rebid: boolean;
    report: boolean;
    callDetails: string;
}

export interface ParticipantsTabSettings {
    uuid: string;
    deafen: boolean;
    drop: boolean;
    callDetails: string;
}

export interface CallHeaderSettings {
    uuid: string;
    mute: boolean;
    hold: boolean;
    callDetails: string;
}

export interface Role {
    roleName: string;
}

export class Role implements Role {
    roleName: string;

    constructor(roleName: string) {
        this.roleName = roleName;
    }
}
