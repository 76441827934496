/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { Message } from 'CalltakingCoreApi';
import { PreDefinedMessage, PredefinedMessages } from '../model/pre-defined-messages';
import { AnalyticsHistoricalTextMessage } from '../model/analytics-historical-text-message';

export const messageEffectsInitialized = createAction('[Message] Effects Initialized');
export const updateMessageSubscriptions = createAction(
    '[Message] Stomp Status Update',
    props<{ subscriptions: { [topic: string]: { [receipt: string]: boolean } } }>()
);

export const initializeSmsMessageState = createAction('[Message] SMS State Initialization');
export const fetchSmsMessages = createAction('[Message] SMS Fetch', props<{ callId: string, clusterName: string }>());
export const fetchSmsMessagesSuccess = createAction('[Message] SMS Fetch Success', props<{ messages: Message[] }>());
export const fetchSmsMessagesFail = createAction('[Message] SMS Fetch Fail', props<{ payload: string }>());
export const fetchPredefinedSmsMessages = createAction('[Message] Fetch Predefined SMS ');
export const fetchPredefinedSmsMessagesSuccess = createAction('[Message] Fetch Predefined SMS Success', props<{ messages: PredefinedMessages[] }>());
export const fetchPredefinedSmsMessagesFail = createAction('[Message] Fetch Predefined SMS Fail', props<{ payload: string }>());
export const newSmsMessage = createAction('[Message] SMS New', props<{ message: Message }>());
export const updateSmsMessage = createAction('[Message] SMS Update', props<{ message: Message }>());
export const deleteSmsMessage = createAction('[Message] SMS Delete', props<{ message: Message }>());

export const initializeTddMessageState = createAction('[Message] TDD State Initialization');
export const fetchTddMessages = createAction('[Message] TDD Fetch', props<{ callId: string, clusterName: string }>());
export const fetchTddMessagesSuccess = createAction('[Message] TDD Fetch Success', props<{ messages: Message[] }>());
export const fetchTddMessagesFail = createAction('[Message] TDD Fetch Fail', props<{ payload: string }>());
export const fetchPredefinedTddMessages = createAction('[Message] Fetch Predefined TDD');
export const fetchPredefinedTddMessagesSuccess = createAction('[Message] Fetch Predefined TDD Success', props<{ messages: PredefinedMessages[] }>());
export const fetchPredefinedTddMessagesFail = createAction('[Message] Fetch Predefined TDD Fail', props<{ payload: string }>());
export const newTddMessage = createAction('[Message] TDD New', props<{ message: Message }>());
export const updateTddMessage = createAction('[Message] TDD Update', props<{ message: Message }>());
export const deleteTddMessage = createAction('[Message] TDD Delete', props<{ message: Message }>());

export const initializeRttMessageState = createAction('[Message] RTT State Initialization');
export const fetchRttMessages = createAction('[Message] RTT Fetch', props<{ callId: string, clusterName: string }>());
export const fetchRttMessagesSuccess = createAction('[Message] RTT Fetch Success', props<{ messages: Message[] }>());
export const fetchRttMessagesFail = createAction('[Message] RTT Fetch Fail', props<{ payload: string }>());
export const newRttMessage = createAction('[Message] RTT New', props<{ message: Message }>());
export const updateRttMessage = createAction('[Message] RTT Update', props<{ message: Message }>());
export const deleteRttMessage = createAction('[Message] RTT Delete', props<{ message: Message }>());

export const newSystemErrorMessage = createAction('[Message] System Error New', props<{ message: Message }>());
export const updateSystemErrorMessage = createAction('[Message] System Error Update', props<{ message: Message }>());
export const deleteSystemErrorMessage = createAction('[Message] System Error Delete', props<{ message: Message }>());

export const initializeDtmfMessageState = createAction('[Message] DTMF State Initialization');
export const fetchDtmfMessages = createAction('[Message] DTMF Fetch', props<{ callId: string, clusterName: string }>());
export const fetchDtmfMessagesSuccess = createAction('[Message] DTMF Fetch Success', props<{ messages: Message[] }>());
export const fetchDtmfMessagesFail = createAction('[Message] DTMF Fetch Fail', props<{ payload: string }>());
export const fetchPredefinedDtmfMessages = createAction('[Message] Fetch Predefined DTMF');
export const fetchPredefinedDtmfMessagesSuccess = createAction('[Message] Fetch Predefined DTMF Success', props<{ messages: PreDefinedMessage[] }>());
export const fetchPredefinedDtmfMessagesFail = createAction('[Message] Fetch Predefined DTMF Fail', props<{ payload: string }>());
export const newDtmfMessage = createAction('[Message] DTMF New', props<{ message: Message }>());
export const updateDtmfMessage = createAction('[Message] DTMF Update', props<{ message: Message }>());
export const deleteDtmfMessage = createAction('[Message] DTMF Delete', props<{ message: Message }>());

export const purgeMessages = createAction('[Message] Purge Messages for Call', props<{ uuid: string }>());

export const fetchTextCallHistory = createAction(
    '[Message] Fetch Historical Texts Messages for Call',
    props<{ callingNumber: string; callId: string; nenaCallId: string }>()
);
export const fetchTextCallHistorySuccess = createAction(
    '[Message] Fetch Historical Texts Messages for Call Success',
    props<{ referenceKey: string; messages: Message[] }>()
);
export const fetchTextCallHistoryFail = createAction('[Message] Fetch Historical Texts Messages for Call Fail', props<{ payload: string }>());

export const storeSmsHistoricalMessages = createAction(
    '[Message] Historical Texts Messages Conversion',
    props<{ referenceKey: string; messages: Message[] }>()
);
export const tddChallenge = createAction(
    '[Message] TDD Challenge', props<{
        callId: string,
        clusterName: string,
        predefined: boolean,
        language: string,
        message: string
    }>()
)
