/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OutputReport1 } from '../output-report-1';
import { Report1 } from '../report-1';

enum ControlMessageIdentifier {
  RESET = 0x00,
  REGISTER = 0x19,
  PHONE_ACTIVITY = 0x01,
  GREETING_RECORDING_REQUEST = 0x09,
  PLAYBACK_AUDIO = 0x0a,
  AUX_AUDIO = 0x0b,
  JACKBOX_MUTE = 0x0d,
  TEST_MODE = 0x0e,
  TEST_TONE = 0x0f,
  TEST_LOOPBACK = 0x10,
  TEST_LEVEL = 0x11,
  TEST_INPUT = 0x12,
  TEST_OUTPUT = 0x13,
  VOLUME = 0x14,
  THRESHOLD = 0x15,
  FIRMWARE_UPDATE = 0x17,
  DEVICE_IDENTIFICATION = 0x18,
  STATE_QUERY = 0x1b,
  IRR_PLAYBACK_TO_PHONE = 0x1c,
  CONFIGURATION_QUERY = 0x1d,
  IRR_PLAYBACK_PERMISSIONS = 0x1e,
  INPUT_TEST_TONE = 0x1f,
  OUT_OF_RANGE = 0xfe,
  ERROR = 0xff
}

export abstract class ControlMessage extends Report1 {
  public static readonly ControlMessageIdentifier = ControlMessageIdentifier;

  protected constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.type = OutputReport1.MessageType.CONTROL;
  }

  override get id() {
    return super.id;
  }

  override set id(id: ControlMessageIdentifier) {
    super.id = id;
  }

  override idString(): string {
    return ControlMessageIdentifier[this.id];
  }
}
