/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { selectToken } from '../../user/+state/user.selectors';
import { filter } from 'rxjs/operators';
import { RxStomp, RxStompConfig, RxStompState, StompHeaders } from '@stomp/rx-stomp';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { selectMetricsEnabled } from '../../configuration/+state/configuration.selectors';
import { MetricsHealthService } from '../../core/services/metrics-health.service';
import { EnvironmentService } from '../../core/services/environment.service';

@Injectable({ providedIn: 'root' })
export class MetricsStompService extends RxStomp {
    public initialized$ = new Subject<void>();
    private token!: string | null;
    private url: string;

    constructor(private store: Store,
                private metricsService: MetricsHealthService,
                private env: EnvironmentService) {
        super();
        this.metricsService.activeUrl$.subscribe((url) => {
            this.url = url;
        });
        this.initializeConnection();
    }

    private initializeConnection() {
        combineLatest([this.store.select(selectMetricsEnabled), this.store.select(selectToken), this.metricsService.activeUrl$])
            .pipe(filter(([enabled, token]) => !!enabled && !!token))
            .subscribe(([, token, activeUrl]) => {
                this.token = token;
                this.configure(this.getConfiguration(activeUrl));
                if (!this.active) {
                    this.initialized$.next();
                }
                if (!this.env.environment.featureFlags?.ctcQueueMetricsEnabled) {
                    this.deactivate().then(() => this.activate());
                }
            });
    }

    private getConfiguration(url: string): RxStompConfig {
        let brokerUrl = new URL(url.concat('/v1/queue-metrics/ws-server'));
        brokerUrl.protocol = brokerUrl.protocol.replace('https', 'wss');
        brokerUrl.protocol = brokerUrl.protocol.replace('http', 'ws');
        return {
            brokerURL: brokerUrl.href,
            connectHeaders: {
                'X-Bearer-Token': this.token!
            },
            reconnectDelay: 10000,
            heartbeatIncoming: 5000,
            heartbeatOutgoing: 5000
        };
    }

    private async handleConnectionState(connectionState: RxStompState) {
        console.log(`Metrics websocket connection state: `, `${RxStompState[connectionState]}`);
    }

    public watchAsync(topic: string, headers: StompHeaders, stompConsumer: StompConsumer) {
        const { receiptId } = headers;

        this.connected$.subscribe(() => {
            this.asyncReceipt(receiptId).then(() => {
                stompConsumer.handleSubscriptionInit(topic, receiptId);
            });
        });

        this.connectionState$?.pipe(filter((connectionState) => connectionState === RxStompState.CLOSED)).subscribe(() => {
            stompConsumer.handleSubscriptionEnd(topic, receiptId);
        });

        this.stompErrors$.subscribe((frame) => {
            stompConsumer.handleStompError(topic, receiptId, frame.body);
        });

        return super.watch(topic, headers);
    }

    private connectWebSocket(): void {
        if (!this.connected()) {
            console.info(`Activating metrics websocket`);
            this.activate();
        }
    }
}
