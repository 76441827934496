/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

enum ALERT_SOUNDS {
  NONE = 0x00,
  JACKBOX_WHEN_IDLE = 0x01,
  INTERNAL_SPEAKER = 0x02,
  JACKBOX_WHEN_BUSY = 0x04,
  JACKBOX_WHEN_IDLE_AND_BUSY = 0x05
}

export class AlertsMessage extends ConfigurationMessage {
  public static readonly ALERT_SOUNDS = ALERT_SOUNDS;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.ALERT_SOUNDS;
  }

  public withSounds(sounds: ALERT_SOUNDS) {
    this.payload1 = sounds;
    return this;
  }

  override payload1String(): string {
      return ALERT_SOUNDS[this.payload1];
  }
}
