/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum ACTIVITY {
  NOT_ON_CALL = 0x00,
  ON_CALL = 0x01
}

export class PhoneActivityMessage extends ControlMessage {
  public static readonly ACTIVITY = ACTIVITY;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.PHONE_ACTIVITY;
  }

  public withActivity(activity: ACTIVITY) {
    this.payload2 = activity;
    return this;
  }

  override payload2String(): string {
    return ACTIVITY[this.payload2];
  }
}
