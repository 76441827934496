/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Action, createAction, props } from '@ngrx/store';
import { Call, MessageType, UIDialSource } from 'CalltakingCoreApi';
import { ObservationState } from './call.reducer';
import { AnalyticsHistoricalCall, AnalyticsRecentCall } from '../model/analytics-historical-call';
import { SupervisorSettings } from '../../configuration/model/ui-settings';

export const updateCallSubscriptions = createAction(
    '[Call] Stomp Status Update',
    props<{ subscriptions: { [topic: string]: { [receipt: string]: boolean } } }>()
);
export const initializeCallState = createAction('[Call] State Initialization', props<{ clusterName: string, initializationTime: number }>());
export const fetchCalls = createAction('[Call] Fetch', props<{ clusterName: string }>());
export const fetchCallsSuccess = createAction('[Call] Fetch Success', props<{ calls: Call[], clusterName: string }>());
export const fetchCallsFail = createAction('[Call] Fetch Fail', props<{ payload: string }>());
export const fetchAnalyticsHistoricalCalls = createAction('[Call] Fetch Analytics Historical', props<{ callingNumber: string; callId: string }>());
export const fetchAnalyticsHistoricalCallsSuccess = createAction(
    '[Call] Fetch Analytics Historical Success',
    props<{ referenceKey: string; calls: AnalyticsHistoricalCall[] }>()
);
export const fetchAnalyticsHistoricalCallsFail = createAction('[Call] Fetch Analytics Historical Fail', props<{ payload: string }>());
export const fetchRecentCalls = createAction('[Call] Fetch Recent Calls', props<{ maxCalls?: number, hours?: number }>());
export const fetchRecentCallsSuccess = createAction('[Call] Fetch Recent Calls Success', props<{ calls: AnalyticsRecentCall[] }>());
export const fetchRecentCallsFailure = createAction('[Call] Fetch Recent Calls Failure', props<{ payload: string }>());
export const linkedCallUpdate = createAction('[Call] Remote Agency Call Update', props<{ call: Call }>());
export const newCall = createAction('[Call] New', props<{ call: Call }>());
export const updateCall = createAction('[Call] Update', props<{ call: Call }>());
export const deleteCall = createAction('[Call] Delete', props<{ call: Call; represented?: Call }>());
export const recordHistoricalCall = createAction('[Call] Historical Update', props<{ call: Call }>());
export const purgeHistoricalCall = createAction('[Call] Historical Purge', props<{ uuid: string, redialUuid: string }>());
export const purgeAnalyticsHistoricalCall = createAction('[Call] Analytics Historical Purge', props<{ uuid: string }>());
export const requestActiveCall = createAction('[Call] Request Active Call', props<{ callId: string | undefined }>());
export const updateActiveCall = createAction('[Call] Update Active Call', props<{ callId: string | undefined }>());
export const updateSelectedCall = createAction('[Call] Update Selected Call', props<{ callId: string | undefined }>());
export const toggleChat = createAction('[Call] Toggle Chat', props<{ open: boolean }>());
export const sendCallMessage = createAction(
    '[Call] Send Call Message',
    props<{ callId: string; clusterName: string; message: string; messageType: MessageType; predefined?: boolean; language?: string }>()
);
export const sendCallMessageFail = createAction('[Call] Send Call Message Fail', props<{ payload: string }>());
export const sendTddMessage = createAction(
    '[Call] Send TDD Message',
    props<{ callId: string; clusterName: string; message: string; predefined?: boolean; language?: string }>()
);
export const sendTddMessageSuccess = createAction('[Call] Send TDD Message Success');
export const sendTddMessageFail = createAction('[Call] Send TDD Message Fail', props<{ payload: string }>());
export const sendRttMessage = createAction(
    '[Call] Send RTT Message',
    props<{ callId: string; clusterName: string; message: string; predefined?: boolean; language?: string }>()
);
export const sendRttMessageSuccess = createAction('[Call] Send RTT Message Success');
export const sendRttMessageFail = createAction('[Call] Send RTT Message Fail', props<{ payload: string }>());
export const sendSmsMessage = createAction(
    '[Call] Send SMS Message',
    props<{ callId: string; clusterName: string; message: string; predefined?: boolean; language?: string }>()
);
export const sendSmsMessageSuccess = createAction('[Call] Send SMS Message Success');
export const sendSmsMessageFail = createAction('[Call] Send SMS Message Fail', props<{ payload: string }>());
export const sendDtmfMessage = createAction('[Call] Send DTMF Message', props<{ callId: string; clusterName: string; message: string }>());
export const sendDtmfMessageSuccess = createAction('[Call] Send DTMF Message Success');
export const sendDtmfMessageFail = createAction('[Call] Send DTMF Message Fail', props<{ payload: string }>());
export const observeAgent = createAction('[Call] Start Observe', props<{ username: string }>());
export const endObserveAgent = createAction('[Call] End Observe');
export const mute = createAction('[Call] Mute', props<{ callId: string; clusterName: string; participantId: string }>());
export const muteSuccess = createAction('[Call] Mute Success');
export const muteFail = createAction('[Call] Mute Fail', props<{ payload: string }>());
export const unmute = createAction('[Call] Unmute', props<{ callId: string; clusterName: string; participantId: string }>());
export const unmuteSuccess = createAction('[Call] Unmute Success');
export const unmuteFail = createAction('[Call] Unmute Fail', props<{ payload: string }>());
export const requestReleaseCall = createAction('[Call] Request Release', props<{ callId: string; clusterName: string }>());
export const requestReleaseCallSuccess = createAction('[Call] Request Release Success', props<{ callId: string; clusterName: string }>());
export const requestReleaseCallFail = createAction('[Call] Request Release Fail', props<{ callId: string; payload: string }>());
export const releaseCallComplete = createAction('[Call] Release Complete', props<{ callId: string }>());
export const releaseCallCompleteFailed = createAction('[User] Release Complete Failed', props<{ callId: string }>());
export const silentMonitor = createAction('[Call] Silent Monitor', props<{ callId: string; clusterName: string }>());
export const silentMonitorSuccess = createAction('[Call] Silent Monitor Success');
export const silentMonitorFail = createAction('[Call] Silent Monitor Fail', props<{ payload: string }>());
export const joinCall = createAction('[Call] Join', props<{ callId: string; clusterName: string }>());
export const joinCallSuccess = createAction('[Call] Join Success', props<{ callId: string, clusterName: string }>());
export const joinCallFail = createAction('[Call] Join Fail', props<{ payload: string }>());
export const requestJoin = createAction('[Call] Request Join', props<{ callId: string; clusterName: string; }>());
export const openCall = createAction('[Call] Open Call', props<{ callId: string, clusterName: string }>());
export const closeCall = createAction('[Call] Close', props<{ callId: string }>());
export const lockCall = createAction('[Call] Lock Call', props<{ callId: string }>());
export const lockCallSuccess = createAction('[Call] Lock Call Success', props<{ callId: string }>());
export const lockCallFailure = createAction('[Call] Lock Call Failure', props<{ payload: string }>());
export const unlockCall = createAction('[Call] Unlock Call', props<{ callId: string }>());
export const unlockCallSuccess = createAction('[Call] Unlock Call Success', props<{ callId: string }>());
export const unlockCallFailure = createAction('[Call] Unlock Call Failure', props<{ payload: string }>());
export const requestHold = createAction('[Call] Hold Request', props<{ callId: string; clusterName: string; callback?: Action }>());
export const requestHoldSuccess = createAction('[Call] Hold Request Success', props<{ callId: string; callback?: Action }>());
export const requestHoldFail = createAction('[Call] Hold Request Fail', props<{ payload: string }>());
export const holdSuccess = createAction('[Call] Hold Success', props<{ callId: string; callback?: Action }>());
export const requestUnhold = createAction('[Call] Unhold Request', props<{ callId: string; clusterName: string }>());
export const unhold = createAction('[Call] Unhold', props<{ callId: string; clusterName: string }>());
export const unholdSuccess = createAction('[Call] Unhold Success', props<{ callId: string, clusterName: string }>());
export const unholdFail = createAction('[Call] Unhold Fail', props<{ payload: string }>());
export const deafen = createAction('[Call] Deafen', props<{ callId: string; clusterName: string; participantId: string }>());
export const deafenSuccess = createAction('[Call] Deafen Success');
export const deafenFail = createAction('[Call] Deafen Fail', props<{ payload: string }>());
export const undeafen = createAction('[Call] Undeafen', props<{ callId: string; clusterName: string; participantId: string }>());
export const undeafenSuccess = createAction('[Call] Undeafen Success');
export const undeafenFail = createAction('[Call] Undeafen Fail', props<{ payload: string }>());
export const conference = createAction(
    '[Call] Conference',
    props<{
        clusterName: string;
        callId: string;
        number: string;
        source: UIDialSource;
        label?: string;
        contactId?: string;
        blindTransfer?: boolean;
        supervisorAssistance?: SupervisorSettings;
        destinations: string[];
    }>()
);
export const conferenceSuccess = createAction('[Call] Conference Success');
export const conferenceFail = createAction('[Call] Conference Fail', props<{ payload: string }>());
export const conferenceRelease = createAction('[Call] Conference Release', props<{ callId: string; clusterName: string; username: string }>());
export const dial = createAction('[Call] Dial', props<{ number: string; source: UIDialSource; label?: string; id?: string, destinations: string[] }>());
export const dialSuccess = createAction('[Call] Dial Success', props<{ callId: string, clusterName: string }>());
export const dialFail = createAction('[Call] Dial Fail', props<{ payload: string }>());
export const requestRedial = createAction('[Call] Request Redial', props<{ callId: string; clusterName: string; number?: string }>());
export const redial = createAction('[Call] Redial', props<{ callId: string; clusterName: string; number: string }>());
export const redialSuccess = createAction('[Call] Redial Success', props<{ callId: string, clusterName: string }>());
export const redialFail = createAction('[Call] Redial Fail', props<{ payload: string }>());
export const redialAbandoned = createAction('[Call] Redial Abandoned', props<{ callId: string; clusterName: string }>());
export const redialAbandonedSuccess = createAction('[Call] Redial Abandoned Success', props<{ callId: string }>());
export const redialAbandonedFail = createAction('[Call] Redial Abandoned Failure', props<{ payload: string }>());
export const requestAnswer = createAction('[Call] Request Answer', props<{ callId: string; clusterName: string }>());
export const answer = createAction('[Call] Answer', props<{ callId: string; clusterName: string }>());
export const answerSuccess = createAction('[Call] Answer Success', props<{ callId: string, clusterName: string }>());
export const answerFail = createAction('[Call] Answer Failure', props<{ payload: string }>());
export const releaseParticipant = createAction('[Call] Release Participant', props<{ callId: string; clusterName: string; participantId: string }>());
export const releaseParticipantSuccess = createAction('[Call] Release Participant Success');
export const releaseParticipantFail = createAction('[Call] Release Participant Fail', props<{ payload: string }>());
export const releasePendingParticipant = createAction(
    '[Call] Release Pending Participant',
    props<{ callId: string; clusterName: string; participantId: string }>()
);
export const releasePendingParticipantSuccess = createAction('[Call] Release Pending Participant Success');
export const releasePendingParticipantFail = createAction('[Call] Release Pending Participant Fail', props<{ payload: string }>());
export const releaseNetworkParticipant = createAction('[Call] Release Network Participant', props<{ callId: string; clusterName: string; participantId: string }>());
export const releaseNetworkParticipantSuccess = createAction('[Call] Release Network Participant Success');
export const releaseNetworkParticipantFail = createAction('[Call] Release Network Participant Fail', props<{ payload: string }>());
export const volumeChange = createAction(
    '[Call] Volume Adjusted',
    props<{ clusterName: string; callId: string; participantId: string; volume?: number; gain?: number }>()
);
export const volumeChangeSuccess = createAction('[Call] Volume Adjusted Success', props<{ callId: string }>());
export const volumeChangeFail = createAction('[Call] Volume Adjusted Fail', props<{ payload: string }>());
// hotkeys
export const answerNext = createAction('[Call] Answer Next');
export const releaseActiveCall = createAction('[Call] Release Active');
export const toggleHoldActiveCall = createAction('[Call] Toggle Hold Active');
export const toggleMuteActiveCall = createAction('[Call] Toggle Mute Active');
export const toggleDeafenActiveCall = createAction('[Call] Toggle Deafen Active');

export const requestResumeObserve = createAction('[Call] Resume Observation Request');
export const setObservationState = createAction('[Call] Change Observation State', props<{ observationState: ObservationState }>());
export const bargeIn = createAction('[Call] Barge In', props<{ callId: string; clusterName: string; participantId: string }>());

export const irrPlayback = createAction('[Call] IRR Playback State Change', props<{ isPlaying: boolean }>());
