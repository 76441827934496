/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNetworkDisconnectionAlert } from '../../configuration/+state/configuration.selectors';
import { selectIsNetworkDisconnected } from '../+state/media.selectors';
import { debounceTime, filter } from 'rxjs/operators';
import { AudibleAlert } from '../model/audible-alert';
import { AlertService } from '../../core/audio/alert.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkAlertService {

    private alert: AudibleAlert | undefined;
    private audio: HTMLAudioElement | undefined;
    private mediaElementAudioSourceNode: MediaElementAudioSourceNode;

    constructor(private store: Store, private alertService: AlertService) {
        this.store.select(selectNetworkDisconnectionAlert).pipe(filter((val) => !!val)).subscribe((alert) => {
            this.alert = alert;
            // TODO migrate to skipper when available. hard coded local files for now
            this.audio = this.alert ? new Audio(`assets/sounds/${this.alert.sound}.ogg`) : undefined;
            this.mediaElementAudioSourceNode = new MediaElementAudioSourceNode(this.alertService.audioContext, { mediaElement: this.audio });
            this.mediaElementAudioSourceNode.connect(this.alertService.mixerNode);
        });
        this.store.select(selectIsNetworkDisconnected)
            .pipe(debounceTime(3000), filter((val) => val))
            .subscribe(() => this.playAlert());
    }

    public playAlert() {
        this.alertService.checkContext();
        this.audio?.play().then(() => console.info(`playing network alert`)).catch((e) => console.warn(`Failed to play message alert audio: ${JSON.stringify(e, undefined, 1)}`));
    }

}
