/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
    ccHubAttachedEvent,
    ccHubEffectsInitialized,
    ccHubHeadsetConnectedEvent,
    ccHubInitiateUpgrade,
    ccHubUpgradeFailure,
    ccHubUpgradeSuccess,
    fetchWamConfig,
    fetchWamConfigFailed,
    fetchWamConfigSuccess,
    muteAction
} from './cchub.actions';
import { CchubService } from '../services/cchub.service';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { updateMediaDevices } from './media.actions';
import { CcHubAudioService } from '../services/cc-hub-audio.service';
import { WebUsbSwdlService } from '../services/web-usb-swdl.service';
import { hotKeyTriggered } from '../../configuration/+state/configuration.actions';
import { Headset, Headsets } from '../../configuration/model/headsets';
import { HotKeyAction } from '../../core/model/hotkey';
import { selectCCHubHeadsetConnected } from './cchub.selectors';

@Injectable()
export class CchubEffects implements OnInitEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private ccHubService: CchubService,
        private webUsbSwdlService: WebUsbSwdlService,
        private ccHubAudioService: CcHubAudioService
    ) {
        this.store.select(selectCCHubHeadsetConnected).pipe(
        ).subscribe((isConnected) => this.store.dispatch(ccHubHeadsetConnectedEvent({ isConnected })));
    }

    ngrxOnInitEffects(): Action {
        return ccHubEffectsInitialized();
    }

    fetchWamConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchWamConfig),
            switchMap(() =>
                this.ccHubService.requestWAMConfig().pipe(
                    map((response) => fetchWamConfigSuccess({ config: response })),
                    catchError((err: Error) => of(fetchWamConfigFailed({ payload: err.message })))
                )
            )
        )
    );

    //logic derived from web-usb-helper.js amICchub method.
    ccHubAttached$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            map(({ devices }) =>
                devices.findIndex((device) => device.label.includes(CchubService.CCH_ID)) >= 0
                    ? ccHubAttachedEvent({ isAttached: true })
                    : ccHubAttachedEvent({ isAttached: false })
            )
        )
    );

    ccHubUpgrading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ccHubInitiateUpgrade),
            switchMap(() =>
                this.webUsbSwdlService.upgrade().pipe(
                    map(() => ccHubUpgradeSuccess()),
                    catchError((err: Error) => of(ccHubUpgradeFailure({ payload: err.message })))
                )
            )
        )
    );

    muteCCHubDeviceHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.MUTE && hotkey.params[0] !== Headset.SYSTEM),
            map(({ hotkey }) => muteAction( { peripheral: Headsets.deviceMap[hotkey.params[0] as Headset] } ))
        )
    );

}
