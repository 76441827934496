/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HealthService } from './health.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { HotKey } from '../model/hotkey';
import { LayoutSettings, Role } from '../../configuration/model/layout-settings';
import { UiSettings } from '../../configuration/model/ui-settings';
import { CallAlert } from '../../call/model/audible-alert';
import { ExternalApplication } from '../../main/application-links/model/external-application';
import { CallQueue, CallQueueConfigFormData, QueueACD } from 'CalltakingCoreApi';
import { map } from 'rxjs/operators';
import { Contact } from '../../directory/model/contact';
import { Folder } from '../../directory/model/folder';
import { SpeedDial } from '../../directory/model/speed-dial';
import { ServiceName } from '../../../assets/config/environment';
import { ServiceResponders } from '../../location/model/service-responders';
import { LocationLayoutResponse } from '../../location/model/location-item-template';
import { PredefinedMessages } from '../../messages/model/pre-defined-messages';
import { CallListConfiguration, SupervisorAgencySettings, UserSettings } from '../../settings/model/settings.model';
import { SoftwareVersion } from '../../settings/model/software-version';
import { ConsolePosition } from '../../cad-config/model/console-position';
import { EMPTY } from 'rxjs';
import { Ringdown } from '../../directory/directory-ringdowns-table/Ringdown';

@Injectable({ providedIn: 'root' })
export class SkipperService extends HealthService {
    private readonly HOTKEY_URL = `/api/config-mgmt/v1/hotkeys/`;
    private readonly ALERTS_URI = '/api/config-mgmt/v1/call-alerts/';
    private readonly UI_CONFIG_URL = '/api/config-mgmt/v1/console-ui/settings';
    private readonly LANGUAGES_URL = '/api/config-mgmt/v1/roles/preferred/languages';
    private readonly USER_ROLES_BY_PERMISSION_URL = '/api/config-mgmt/v1/roles/role-by-permission';
    private readonly APPLICATION_LINKS_URL = '/api/config-mgmt/v1/settings/applications';
    private readonly CONSOLE_LAYOUT_URL = '/api/config-mgmt/v1/settings/layouts/preferred-role';
    private readonly QUEUE_CONFIG = '/api/config-mgmt/v1/call-queue';
    private readonly CONTACTS_API_URL = '/api/config-mgmt/v1/directory';
    private readonly CONTACTS_URL = `${this.CONTACTS_API_URL}/contacts`;
    private readonly SPEED_DIAL_FOLDER_URL = `${this.CONTACTS_API_URL}/speed-dial/display-order`;
    private readonly LOCATION_LAYOUT_URL = `/api/config-mgmt/v1/location-layout/`;
    private readonly SERVICE_RESPONDERS_URL = `/api/config-mgmt/v1/service-responders/`;
    private readonly PREDEFINED_SMS_MESSAGES = '/api/config-mgmt/v1/messages/sms';
    private readonly PREDEFINED_TTY_MESSAGES = '/api/config-mgmt/v1/messages/tty';
    private readonly USER_PREFIX = '/api/config-mgmt/v1/users';
    private readonly CURRENT_ROLE_SUFFIX = 'current-role';
    private readonly USER_SELECTABLE_ROLES_ENDPOINT = '/api/config-mgmt/v1/roles/role-users/selectable-roles';
    private readonly USER_SETTINGS_ENDPOINT = '/api/config-mgmt/v1/user-ui-settings';
    private readonly AGENCY_SETTINGS_ENDPOINT = '/api/config-mgmt/v1/settings/supervisor-views/preferred-role';
    private readonly CALL_LIST_ENDPOINT = '/api/config-mgmt/v1/call-lists/preferred-role';
    private readonly VERSION_ENDPOINT = '/api/config-mgmt/v1/about/components/all';
    private readonly POSITIONS_ENDPOINT = '/api/config-mgmt/v1/positions';
    private readonly SPEED_DIAL_LAYOUTS_URL = '/api/config-mgmt/v1/directory/speed-dial/esn-suggested';
    private readonly SPEED_DIAL_CONTACTS_URL = '/api/config-mgmt/v1/directory/speed-dial/contact';
    private readonly RINGDOWNS_URL = '/api/config-mgmt/v1/ringdowns/dictionary';

    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService
    ) {
        super(http, ServiceName.SKIPPER, env.environment.SERVICES[ServiceName.SKIPPER]);
    }

    public requestContacts() {
        return this.http.get<Contact[]>(`${this.activeUrl$.value}${this.CONTACTS_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestDirectoryEntries() {
        return this.http.get<(Folder | SpeedDial)[]>(`${this.activeUrl$.value}${this.SPEED_DIAL_FOLDER_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestSpeedDialLayouts() {
        return this.http.get<Record<string, SpeedDial[]>>(`${this.activeUrl$.value}${this.SPEED_DIAL_LAYOUTS_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestHotkeys() {
        return this.http.get<HotKey[]>(`${this.activeUrl$.value}${this.HOTKEY_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public fetchAvailableLanguages() {
        return this.http.get<string[]>(`${this.activeUrl$.value}${this.LANGUAGES_URL}`);
    }

    public requestUiConfiguration() {
        return this.http.get<UiSettings>(`${this.activeUrl$.value}${this.UI_CONFIG_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestCallAlerts() {
        return this.http.get<CallAlert[]>(`${this.activeUrl$.value}${this.ALERTS_URI}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestApplicationLinks() {
        return this.http.get<ExternalApplication[]>(`${this.activeUrl$.value}${this.APPLICATION_LINKS_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestLayoutConfiguration() {
        return this.http.get<LayoutSettings>(`${this.activeUrl$.value}${this.CONSOLE_LAYOUT_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestQueueConfiguration() {
        return this.http
            .get<CallQueueConfigFormData[]>(`${this.activeUrl$.value}${this.QUEUE_CONFIG}`, {
                observe: 'body',
                responseType: 'json'
            })
            .pipe(map((callQueueConfigFormData) => callQueueConfigFormData.map(this.convertSkipperQueuesToCtcQueues)));
    }

    public getServiceProvidersIcons() {
        return this.http.get<ServiceResponders>(`${this.activeUrl$.value}${this.SERVICE_RESPONDERS_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public getLocationLayout() {
        return this.http.get<LocationLayoutResponse>(`${this.activeUrl$.value}${this.LOCATION_LAYOUT_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestPredefinedSms() {
        return this.http.get<PredefinedMessages[]>(`${this.activeUrl$.value}${this.PREDEFINED_SMS_MESSAGES}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestPredefinedTdd() {
        return this.http.get<PredefinedMessages[]>(`${this.activeUrl$.value}${this.PREDEFINED_TTY_MESSAGES}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public updateCurrentRole(role: string, sub: string) {
        return this.http.put(`${this.activeUrl$.value}${this.USER_PREFIX}/${sub}/${this.CURRENT_ROLE_SUFFIX}`, { roleName: role });
    }

    public fetchUserSelectableRoles() {
        return this.http.get<string[]>(`${this.activeUrl$.value}${this.USER_SELECTABLE_ROLES_ENDPOINT}`, {
            observe: 'body',
            responseType: 'json'
        }).pipe(
            map((roleNames: string[]) => roleNames.map((roleName: string) => new Role(roleName)))
        );
    }

    public fetchSupervisorAgencySettings() {
        return this.http.get<SupervisorAgencySettings>(`${this.activeUrl$.value}${this.AGENCY_SETTINGS_ENDPOINT}`);
    }

    public updateUserSettings(settings: UserSettings) {
        return this.http.post<UserSettings>(`${this.activeUrl$.value}${this.USER_SETTINGS_ENDPOINT}`, settings);
    }

    public fetchUserSettings() {
        return this.http.get<UserSettings>(`${this.activeUrl$.value}${this.USER_SETTINGS_ENDPOINT}`);
    }

    public fetchStandaloneCallColumns() {
        return this.http.get<CallListConfiguration>(`${this.activeUrl$.value}${this.CALL_LIST_ENDPOINT}`);
    }

    public fetchVersionInformation() {
        return this.http.get<SoftwareVersion[]>(`${this.activeUrl$.value}${this.VERSION_ENDPOINT}`);
    }

    public fetchConsolePositions() {
        return this.http.get<ConsolePosition[]>(`${this.activeUrl$.value}${this.POSITIONS_ENDPOINT}`);
    }

    public fetchSpeedDialContact(speedDials: SpeedDial[]) {
        if (!speedDials) {
            return EMPTY;
        }
        return this.http.post<Record<string, Contact>>(`${this.activeUrl$.value}${this.SPEED_DIAL_CONTACTS_URL}`,
            speedDials.map((speedDial) => speedDial.uuid));
    }

    public fetchRingdowns(role: string) {
        return this.http.get<Ringdown[]>(`${this.activeUrl$.value}${this.RINGDOWNS_URL}?role=${role}`);
    }

    private convertSkipperQueuesToCtcQueues = (skipperQueue: CallQueueConfigFormData) => {
        return {
            acd: Boolean(skipperQueue.acd),
            callPriority: skipperQueue.callPriority.name,
            queueACD: skipperQueue.acd as QueueACD,
            name: skipperQueue.name,
            extension: skipperQueue.extension,
            displayName: skipperQueue.displayName,
            description: skipperQueue.description,
            maxQueueSize: skipperQueue.maxQueueSize
        } as CallQueue;
    };
}
