/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    fetchDtmfMessages,
    fetchDtmfMessagesFail,
    fetchDtmfMessagesSuccess,
    fetchPredefinedSmsMessages,
    fetchPredefinedSmsMessagesFail,
    fetchPredefinedSmsMessagesSuccess,
    fetchPredefinedTddMessages,
    fetchPredefinedTddMessagesFail,
    fetchPredefinedTddMessagesSuccess,
    fetchRttMessages,
    fetchRttMessagesFail,
    fetchRttMessagesSuccess,
    fetchSmsMessages,
    fetchSmsMessagesFail,
    fetchSmsMessagesSuccess,
    fetchTddMessages,
    fetchTddMessagesFail,
    fetchTddMessagesSuccess,
    fetchTextCallHistory,
    fetchTextCallHistoryFail,
    fetchTextCallHistorySuccess,
    messageEffectsInitialized,
    purgeMessages,
    tddChallenge
} from './message.actions';
import {
    answerSuccess,
    dialSuccess,
    joinCallSuccess,
    openCall,
    purgeHistoricalCall,
    redialSuccess,
    sendTddMessage,
    unholdSuccess
} from '../../call/+state/call.actions';
import { defer, Observable, of, retry, timer } from 'rxjs';
import { acdAnswerRequestSuccess } from '../../user/+state/user.actions';
import { TddMessageService } from '../services/tdd-message.service';
import { SmsMessageService } from '../services/sms-message.service';
import { SystemErrorMessageService } from '../services/system-error-message.service';
import { DtfmMessageService } from '../services/dtmf-message.service';
import { AnalyticsService } from '../../call/services/analytics.service';
import { RttMessageService } from '../services/rtt-message.service';
import { AnalyticsHistoricalTextMessage } from '../model/analytics-historical-text-message';
import { SkipperService } from '../../core/services/skipper.service';

@Injectable()
export class MessageEffects implements OnInitEffects {
    constructor(
        private smsMessageService: SmsMessageService,
        private skipperService: SkipperService,
        private tddMessageService: TddMessageService,
        private rttMessageService: RttMessageService,
        private dtmfMessageService: DtfmMessageService,
        private systemErrorMessageService: SystemErrorMessageService,
        private analyticsCallHistoryService: AnalyticsService,
        private actions$: Actions
    ) {}

    ngrxOnInitEffects(): Action {
        return messageEffectsInitialized();
    }

    initializeMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unholdSuccess, redialSuccess, joinCallSuccess, acdAnswerRequestSuccess, openCall, answerSuccess, dialSuccess),
            switchMap(({ callId, clusterName }) => [
                fetchTddMessages({ callId, clusterName }),
                fetchSmsMessages({
                    callId,
                    clusterName
                }),
                fetchRttMessages({ callId, clusterName }),
                fetchDtmfMessages({ callId, clusterName })
            ])
        )
    );

    tddChallenge$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tddChallenge),
            map(({ clusterName, callId, language, predefined, message }) =>
                sendTddMessage({
                    clusterName,
                    callId,
                    language,
                    predefined,
                    message
                })
            )
        )
    );

    fetchSmsMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchSmsMessages),
            switchMap((action) =>
                this.smsMessageService.requestSmsMessages(action.callId, action.clusterName).pipe(
                    map((response) => fetchSmsMessagesSuccess({ messages: response })),
                    catchError((err: Error) => of(fetchSmsMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    fetchRttMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchRttMessages),
            switchMap((action) =>
                this.rttMessageService.requestRttMessages(action.callId, action.clusterName).pipe(
                    map((response) => fetchRttMessagesSuccess({ messages: response })),
                    catchError((err: Error) => of(fetchRttMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    fetchSmsHistoricalMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchTextCallHistory),
            switchMap((action) =>
                this.analyticsCallHistoryService.requestTextCallHistory(action.callingNumber, action.callId, action.nenaCallId).pipe(
                    map((messages) =>
                        fetchTextCallHistorySuccess({
                            referenceKey: action.callingNumber,
                            messages: messages.map((analyticsMessage) =>
                                AnalyticsHistoricalTextMessage.toMessage(analyticsMessage))
                        })
                    ),
                    catchError((err: Error) => of(fetchTextCallHistoryFail({ payload: err.message })))
                )
            )
        )
    );

    fetchDtmfMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDtmfMessages),
            switchMap((action) =>
                this.dtmfMessageService.requestDtmfMessages(action.callId, action.clusterName).pipe(
                    map((response) => fetchDtmfMessagesSuccess({ messages: response })),
                    catchError((err: Error) => of(fetchDtmfMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    fetchTddMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchTddMessages),
            switchMap((action) =>
                this.tddMessageService.requestTddMessages(action.callId, action.clusterName).pipe(
                    map((response) => fetchTddMessagesSuccess({ messages: response })),
                    catchError((err: Error) => of(fetchTddMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    fetchPredefinedTddMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPredefinedTddMessages),
            switchMap(() =>
                defer(() => this.skipperService.requestPredefinedTdd()).pipe(
                    map((response) => fetchPredefinedTddMessagesSuccess({ messages: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchPredefinedTddMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    fetchPredefinedSmsMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPredefinedSmsMessages),
            switchMap(() =>
                defer(() => this.skipperService.requestPredefinedSms()).pipe(
                    map((response) => fetchPredefinedSmsMessagesSuccess({ messages: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchPredefinedSmsMessagesFail({ payload: err.message })))
                )
            )
        )
    );

    purgeHistoricalMessages$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(purgeHistoricalCall),
            map(({ uuid }) => purgeMessages({ uuid: uuid }))
        )
    );
}
