import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { StompService } from '../../core/services/stomp.service';
import { updateApplicationPresence } from '../+state/user.actions';

@Injectable({
  providedIn: 'root'
})
export class PresenceService implements OnDestroy, StompConsumer {

    private readonly APPLICATION_TOPIC = `/user/topic/v1/app/CallStationNM`;
    private readonly receipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    constructor(private http: HttpClient, private store: Store, private stompService: StompService) {
        this.topicSubscription = this.stompService.watchAsync(this.APPLICATION_TOPIC, this.receipt, this).subscribe();
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Application presence topic ${topic} subscription success: ${receiptId}`);
        this.store.dispatch(updateApplicationPresence({ loggedIn: true, loginTime: new Date().getTime() }));
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Application presence topic ${topic} subscription end: ${receiptId}`);
        this.store.dispatch(updateApplicationPresence({ loggedIn: false }));
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Application presence topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
    }
}
