/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { defer, mergeMap, Observable, of, retry, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectActiveCall, selectCallsMap, selectHistoricalCallsMap } from '../../call/+state/call.selectors';
import { LocationService } from '../services/location.service';
import {
    fetchLocation,
    fetchLocationFail,
    fetchLocationLayout,
    fetchLocationLayoutFail,
    fetchLocationLayoutSuccess,
    fetchLocationSuccess,
    fetchServiceResponders,
    fetchServiceRespondersFail,
    fetchServiceRespondersSuccess,
    locationEffectsInitialized,
    manualQuery,
    manualQueryFail,
    manualQuerySuccess,
    purgeLocation,
    rebid,
    rebidFail,
    rebidSuccess,
    receivedLocationEvent,
    updateEnhancedLocation,
    updateStandardLocation
} from './location.actions';
import { hotKeyTriggered } from '../../configuration/+state/configuration.actions';
import { answerSuccess, deleteCall, joinCallSuccess, openCall, purgeHistoricalCall, unholdSuccess } from '../../call/+state/call.actions';
import { acdAnswerRequestSuccess } from '../../user/+state/user.actions';
import { CtcAdapterService } from '../../core/services/ctc-adapter.service';
import { SkipperService } from '../../core/services/skipper.service';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { concatLatestFrom } from '@ngrx/operators';
import { HotKeyAction } from '../../core/model/hotkey';

@Injectable()
export class LocationEffects implements OnInitEffects {
    constructor(
        private skipperService: SkipperService,
        private ctcAdapterService: CtcAdapterService,
        private locationService: LocationService,
        private store: Store,
        private actions$: Actions
    ) {}

    ngrxOnInitEffects(): Action {
        return locationEffectsInitialized();
    }

    initializeLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unholdSuccess, joinCallSuccess, acdAnswerRequestSuccess, openCall, answerSuccess),
            map(({ callId }) => fetchLocation({ callId: callId }))
        )
    );

    fetchLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLocation),
            mergeMap((action) =>
                this.ctcAdapterService.load(action.callId).pipe(
                    map((response) => fetchLocationSuccess({ locations: response })),
                    catchError((err: Error) => of(fetchLocationFail({ payload: err.message })))
                )
            )
        )
    );

    fetchLocationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLocationSuccess),
            mergeMap(({ locations }) => locations.map((locationEvent) => receivedLocationEvent({ locationEvent: locationEvent })))
        )
    );

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(receivedLocationEvent),
            concatLatestFrom(() => this.store.select(selectCallsMap)),
            concatLatestFrom(() => this.store.select(selectHistoricalCallsMap)),
            filter(
                ([[{ locationEvent }, callsMap], historicalCallsMap]) =>
                    Boolean(callsMap[locationEvent.callId]) || Boolean(historicalCallsMap[locationEvent.callId])
            ),
            map(([[{ locationEvent }]]) =>
                !locationEvent.provider || locationEvent.provider === 'standard'
                    ? updateStandardLocation({ locationEvent })
                    : updateEnhancedLocation({ locationEvent })
            )
        );
    });

    purgeMyHistoricalLocation$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(purgeHistoricalCall),
            switchMap(({ uuid, redialUuid }) => [purgeLocation({ uuid: uuid }), purgeLocation({ uuid: redialUuid })])
        )
    );

    purgeOtherHistoricalLocation$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCall),
            concatLatestFrom(() => this.store.select(selectHistoricalCallsMap)),
            filter(([{ call }, historicalCallsMap]) => call.status !== 'ABANDONED_REDIAL' && !Boolean(historicalCallsMap[call.uuid])),
            map(([{ call }]) => purgeLocation({ uuid: call.uuid }))
        )
    );

    rebid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rebid),
            switchMap(({ callId }) =>
                this.ctcAdapterService.rebid(callId).pipe(
                    map(() => rebidSuccess({ callId })),
                    catchError((err: Error) => of(rebidFail({ payload: err.message })))
                )
            )
        )
    );

    rebidHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.REBID),
            concatLatestFrom(() => this.store.select(selectActiveCall)),
            filter(([, call]) => !!call),
            map(([, call]) => rebid({ callId: call.uuid }))
        )
    );

    manualAli$ = createEffect(() =>
        this.actions$.pipe(
            ofType(manualQuery),
            switchMap(({ number }) =>
                this.ctcAdapterService.manualQuery(number).pipe(
                    map((locationEvent) => manualQuerySuccess({ number: number, locationEvent })),
                    catchError((err: Error) => of(manualQueryFail({ number: number, payload: err.message })))
                )
            )
        )
    );

    serviceResponders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchServiceResponders),
            switchMap(() =>
                defer(() => this.skipperService.getServiceProvidersIcons()).pipe(
                    map((response) => fetchServiceRespondersSuccess({ response: response })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchServiceRespondersFail({ payload: err.message })))
                )
            )
        )
    );

    locationLayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLocationLayout),
            switchMap(() =>
                defer(() => this.skipperService.getLocationLayout()).pipe(
                    tap(({ template }) => this.locationService.createScriptTag(template)),
                    map((response) => fetchLocationLayoutSuccess({ response: JSON.parse(response.content) as LocationDisplayTemplate })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchLocationLayoutFail({ payload: err.message })))
                )
            )
        )
    );
}
