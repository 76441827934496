/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { QueueMetricObj } from '../queue-metrics/+state/metrics.model';
import { Store } from '@ngrx/store';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { v4 as uuid } from 'uuid';
import { selectAgencyFqdn, selectUsername } from '../../user/+state/user.selectors';
import { MetricsStompService } from './metrics-stomp.service';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { updateMetrics } from '../queue-metrics/+state/metrics.actions';
import { selectSupervisorRoleId } from '../../settings/+state/settings.selectors';
import { filter } from 'rxjs/operators';
import { EnvironmentService } from '../../core/services/environment.service';
import { StompService } from '../../core/services/stomp.service';
import { IMessage } from '@stomp/rx-stomp';

;

@Injectable({ providedIn: 'root' })
export class MetricsService implements StompConsumer {

    private CTC_QUEUE_METRICS_DATA_TOPIC: string = '/user/topic/v1/queue-metrics';
    private qmsDataReceipt = uuid();

    constructor(
        private store: Store,
        private metricsStompService: MetricsStompService,
        private stompService: StompService,
        private env: EnvironmentService) {

        const metricsObservable$: Observable<IMessage> = this.env.environment.featureFlags?.ctcQueueMetricsEnabled
            ? this.stompService.watchAsync(this.CTC_QUEUE_METRICS_DATA_TOPIC, this.qmsDataReceipt, this)
            : combineLatest([
                this.store.select(selectAgencyFqdn),
                this.store.select(selectUsername),
                this.store.select(selectSupervisorRoleId).pipe(filter((v) => !!v)),
                this.metricsStompService.initialized$
            ]).pipe(
                filter(([agencyName, username, roleId]) => !!agencyName),
                switchMap(([agencyName, username, roleId]) =>
                    this.metricsStompService.watchAsync(
                        `/client/${username}/queue-metrics`,
                        {
                            queueName: 'ALL',
                            agencyName,
                            roleId: roleId,
                            clientId: username,
                            receiptId: this.qmsDataReceipt
                        }, this
                    )
                )
            );
        metricsObservable$.subscribe(async (message) => {
            const parsed = JSON.parse(message.body) as QueueMetricObj[];
            const metrics = parsed.map((m) => ({ ...m, uuid: `${m.agencyId}_${m.queueId}` }));
            store.dispatch(updateMetrics({ metrics }));
        });
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Metrics topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Metrics ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Metrics ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

}
