/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from "@ngrx/store";
import { NotifyingAnnouncement } from "../model/notifying-announcement";
import { Announcement } from "CalltakingCoreApi";

export const sendAnnouncement = createAction('[Announcement] Send', props<{ announcement: Announcement }>());
export const sendAnnouncementSuccess = createAction('[Announcement] Send Success');
export const sendAnnouncementFail = createAction('[Announcement] Send Fail', props<{ payload: string }>());
export const discardAnnouncement = createAction('[Announcement] Discard', props<{ id: string }>());
export const discardAllAnnouncement = createAction('[Announcement] Discard All');
export const receivedAnnouncement = createAction('[Announcement] Received New', props<{ announcement: Announcement }>());
export const persistAnnouncement = createAction('[Announcement] Persist Announcement', props<{ announcement: NotifyingAnnouncement }>());
export const clearAnnouncementNotifications = createAction('[Announcement] Clear Notifications');
export const setAnnouncementStatus = createAction('[Announcement] Set Status', props<{ id: string, unread: boolean }>());
export const clearAnnouncement = createAction('[Announcement] Clear', props<{ id: string }>());
