import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { DiscrepancyReportService } from '../../call/services/discrepancy-report-service';
import {
    adr,
    adrFail,
    adrSuccess,
    bcf,
    bcfFail,
    bcfSuccess,
    chfe,
    chfeFail,
    chfeSuccess,
    esrp,
    esrpFail,
    esrpSuccess,
    lis,
    lisFail,
    lisSuccess,
    requestStatusUpdate,
    requestStatusUpdateFail,
    requestStatusUpdateSuccess
} from './discrepancy-report.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';

@Injectable()
export class DiscrepancyReportEffects {
    constructor(
        private discrepancyReportService: DiscrepancyReportService,
        private actions$: Actions
    ) {}

    bcf$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bcf),
            switchMap(({ clusterName, bcfReport }) =>
                this.discrepancyReportService.bcf(clusterName, bcfReport).pipe(
                    map(() => bcfSuccess({ clusterName })),
                    catchError((err: Error) => of(bcfFail({ payload: err.message })))
                )
            )
        )
    );

    esrp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(esrp),
            switchMap(({ clusterName, esrpReport }) =>
                this.discrepancyReportService.esrp(clusterName, esrpReport).pipe(
                    map(() => esrpSuccess({ clusterName })),
                    catchError((err: Error) => of(esrpFail({ payload: err.message })))
                )
            )
        )
    );

    lis$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lis),
            switchMap(({ clusterName, lisReport }) =>
                this.discrepancyReportService.lis(clusterName, lisReport).pipe(
                    map(() => lisSuccess({ clusterName })),
                    catchError((err: Error) => of(lisFail({ payload: err.message })))
                )
            )
        )
    );

    adr$ = createEffect(() =>
        this.actions$.pipe(
            ofType(adr),
            switchMap(({ clusterName, adrReport }) =>
                this.discrepancyReportService.adr(clusterName, adrReport).pipe(
                    map(() => adrSuccess({ clusterName })),
                    catchError((err: Error) => of(adrFail({ payload: err.message })))
                )
            )
        )
    );

    chfe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chfe),
            switchMap(({ clusterName, chfeReport }) =>
                this.discrepancyReportService.chfe(clusterName, chfeReport).pipe(
                    map(() => chfeSuccess({ clusterName })),
                    catchError((err: Error) => of(chfeFail({ payload: err.message })))
                )
            )
        )
    );

    success$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chfeSuccess, lisSuccess, esrpSuccess, bcfSuccess, adrSuccess),
            map(() => displayToastNotification({ message: 'Discrepancy report submitted successfully', level: ToastType.success }))
        )
    );

    failure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chfeFail, lisFail, esrpFail, bcfFail, adrFail),
            map(() => displayToastNotification({ message: 'Failed to submit discrepancy report. Please try again.', level: ToastType.error }))
        )
    );

    requestStatusUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestStatusUpdate),
            switchMap(({ clusterName, agencyName, discrepancyReportId }) =>
                this.discrepancyReportService.requestStatusUpdate(clusterName, agencyName, discrepancyReportId).pipe(
                    map(() => requestStatusUpdateSuccess({ discrepancyReportId })),
                    catchError((err: Error) => of(requestStatusUpdateFail({ payload: err.message })))
                )
            )
        )
    );
}
