/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Message } from 'CalltakingCoreApi';
import {
    fetchDtmfMessagesSuccess,
    fetchPredefinedSmsMessagesSuccess,
    fetchPredefinedTddMessagesSuccess,
    fetchRttMessagesSuccess,
    fetchSmsMessagesSuccess,
    fetchTddMessagesSuccess,
    fetchTextCallHistorySuccess,
    messageEffectsInitialized,
    newDtmfMessage,
    newRttMessage,
    newSmsMessage,
    newTddMessage,
    purgeMessages,
    updateDtmfMessage,
    updateMessageSubscriptions,
    updateRttMessage,
    updateSmsMessage,
    updateTddMessage
} from './message.actions';
import { SortFunctions } from '../../call/util/sort-functions';
import { PredefinedMessages } from '../model/pre-defined-messages';
import { AnalyticsHistoricalTextMessage } from '../model/analytics-historical-text-message';

export interface MessageState {
    subscriptions: { [topic: string]: { [receipt: string]: boolean } };
    initialized: boolean;
    sms: SMSMessageState;
    tdd: TDDMessageState;
    rtt: RTTMessageState;
    dtmf: DTMFMessageState;
    smsCallMap: { [callId: string]: { [messageId: string]: Message } };
    tddCallMap: { [callId: string]: { [messageId: string]: Message } };
    rttCallMap: { [callId: string]: { [messageId: string]: Message } };
    dtmfCallMap: { [callId: string]: { [messageId: string]: Message } };
    historicalTextMap: { [ref: string]: { [callId: string]: Message[] } };
    default: DefaultMessageState;
    predefinedSms: PredefinedMessages[];
    predefinedTdd: PredefinedMessages[];
    smsHistorical: SMSHistoricalMessageState;
}

export interface SMSMessageState extends EntityState<Message> {}

export interface TDDMessageState extends EntityState<Message> {}

export interface RTTMessageState extends EntityState<Message> {}

export interface DTMFMessageState extends EntityState<Message> {}

export interface DefaultMessageState extends EntityState<Message> {}

export interface SMSHistoricalMessageState extends EntityState<AnalyticsHistoricalTextMessage> {}

export const smsAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (message: Message) => message.uuid,
    sortComparer: SortFunctions.newestCreatedSort
});

export const tddAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (message: Message) => message.uuid,
    sortComparer: SortFunctions.newestCreatedSort
});

export const rttAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (message: Message) => message.uuid,
    sortComparer: SortFunctions.newestCreatedSort
});

export const dtmfAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (message: Message) => message.uuid,
    sortComparer: SortFunctions.newestCreatedSort
});

export const defaultAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (message: Message) => message.uuid,
    sortComparer: SortFunctions.newestCreatedSort
});

export const smsHistoricalAdapter: EntityAdapter<AnalyticsHistoricalTextMessage> = createEntityAdapter<AnalyticsHistoricalTextMessage>({
    selectId: (message: AnalyticsHistoricalTextMessage) => message.uuid
});

const initialSmsState: SMSMessageState = smsAdapter.getInitialState({});

const initialTddState: TDDMessageState = tddAdapter.getInitialState({});

const initialRttState: TDDMessageState = rttAdapter.getInitialState({});

const initialDtmfState: DTMFMessageState = dtmfAdapter.getInitialState({});

const initialDefaultState: DefaultMessageState = defaultAdapter.getInitialState({});

const initialSmsHistoricalState: SMSHistoricalMessageState = smsHistoricalAdapter.getInitialState({});

const initialState: MessageState = {
    subscriptions: {},
    initialized: false,
    sms: initialSmsState,
    tdd: initialTddState,
    rtt: initialRttState,
    dtmf: initialDtmfState,
    smsCallMap: {},
    tddCallMap: {},
    rttCallMap: {},
    dtmfCallMap: {},
    historicalTextMap: {},
    default: initialDefaultState,
    predefinedSms: [],
    predefinedTdd: [],
    smsHistorical: initialSmsHistoricalState
};

export const messageFeature = createFeature({
    name: 'messages',
    reducer: createReducer(
        initialState,
        on(messageEffectsInitialized, (state): MessageState => {
            return { ...state, initialized: true };
        }),
        on(updateMessageSubscriptions, (state, { subscriptions }): MessageState => {
            return { ...state, subscriptions: { ...state.subscriptions, ...subscriptions } };
        }),
        on(fetchSmsMessagesSuccess, (state, { messages }) => {
            return {
                ...state,
                sms: smsAdapter.upsertMany(messages, state.sms),
                smsCallMap: messages.reduce<{ [callId: string]: { [messageId: string]: Message } }>(
                    (map, message) => ({ ...map, [message.callId]: { ...map[message.callId], [message.uuid]: message } }),
                    { ...state.smsCallMap }
                )
            };
        }),
        on(fetchTddMessagesSuccess, (state, { messages }) => {
            return {
                ...state,
                tdd: tddAdapter.upsertMany(messages, state.tdd),
                tddCallMap: messages.reduce<{ [callId: string]: { [messageId: string]: Message } }>(
                    (map, message) => ({ ...map, [message.callId]: { ...map[message.callId], [message.uuid]: message } }),
                    { ...state.tddCallMap }
                )
            };
        }),
        on(fetchRttMessagesSuccess, (state, { messages }) => {
            return {
                ...state,
                rtt: rttAdapter.upsertMany(messages, state.rtt),
                rttCallMap: messages.reduce<{ [callId: string]: { [messageId: string]: Message } }>(
                    (map, message) => ({ ...map, [message.callId]: { ...map[message.callId], [message.uuid]: message } }),
                    { ...state.rttCallMap }
                )
            };
        }),
        on(fetchDtmfMessagesSuccess, (state, { messages }) => {
            return {
                ...state,
                dtmf: dtmfAdapter.upsertMany(messages, state.dtmf),
                dtmfCallMap: messages.reduce<{ [callId: string]: { [messageId: string]: Message } }>(
                    (map, message) => ({ ...map, [message.callId]: { ...map[message.callId], [message.uuid]: message } }),
                    { ...state.dtmfCallMap }
                )
            };
        }),
        on(newSmsMessage, updateSmsMessage, (state, { message }) => {
            return {
                ...state,
                sms: smsAdapter.setOne(message, state.sms),
                smsCallMap: { ...state.smsCallMap, [message.callId]: { ...state.smsCallMap[message.callId], [message.uuid]: message } }
            };
        }),
        on(newTddMessage, updateTddMessage, (state, { message }) => {
            return {
                ...state,
                tdd: tddAdapter.setOne(message, state.tdd),
                tddCallMap: { ...state.tddCallMap, [message.callId]: { ...state.tddCallMap[message.callId], [message.uuid]: message } }
            };
        }),
        on(newRttMessage, updateRttMessage, (state, { message }) => {
            return {
                ...state,
                rtt: rttAdapter.setOne(message, state.rtt),
                rttCallMap: { ...state.rttCallMap, [message.callId]: { ...state.rttCallMap[message.callId], [message.uuid]: message } }
            };
        }),
        on(newDtmfMessage, updateDtmfMessage, (state, { message }) => {
            return {
                ...state,
                dtmf: dtmfAdapter.setOne(message, state.dtmf),
                dtmfCallMap: { ...state.dtmfCallMap, [message.callId]: { ...state.dtmfCallMap[message.callId], [message.uuid]: message } }
            };
        }),
        on(fetchTextCallHistorySuccess, (state, { referenceKey, messages }): MessageState => {
            return {
                ...state,
                historicalTextMap: messages.reduce<{ [ref: string]: { [callId: string]: Message[] } }>(
                    (map, message) => ({
                        ...map,
                        [referenceKey]: {
                            ...map[referenceKey],
                            [message.callId]:
                                map[referenceKey] && map[referenceKey][message.callId] ? [...map[referenceKey][message.callId]].concat(message) : [message]
                        }
                    }),
                    {}
                )
            };
        }),
        on(purgeMessages, (state, { uuid }) => {
            let smsCallMap = { ...state.smsCallMap };
            delete smsCallMap[uuid];
            let tddCallMap = { ...state.tddCallMap };
            delete tddCallMap[uuid];
            let rttCallMap = { ...state.rttCallMap };
            delete rttCallMap[uuid];
            let dtmfCallMap = { ...state.dtmfCallMap };
            delete dtmfCallMap[uuid];
            return {
                ...state,
                sms: smsAdapter.removeMany((message) => message.callId === uuid, state.sms),
                tdd: tddAdapter.removeMany((message) => message.callId === uuid, state.tdd),
                rtt: rttAdapter.removeMany((message) => message.callId === uuid, state.rtt),
                dtmf: dtmfAdapter.removeMany((message) => message.callId === uuid, state.tdd),
                default: defaultAdapter.removeMany((message) => message.callId === uuid, state.default),
                tddCallMap: tddCallMap,
                rttCallMap: rttCallMap,
                smsCallMap: smsCallMap,
                dtmfCallMap: dtmfCallMap
            };
        }),
        on(fetchPredefinedSmsMessagesSuccess, (state, { messages }): MessageState => {
            return { ...state, predefinedSms: messages };
        }),
        on(fetchPredefinedTddMessagesSuccess, (state, { messages }): MessageState => {
            return { ...state, predefinedTdd: messages };
        })
    )
});

export const selectSmsState = (state: MessageState) => state.sms;
export const selectTddState = (state: MessageState) => state.tdd;
export const selectDtmfState = (state: MessageState) => state.dtmf;
export const selectDefaultState = (state: MessageState) => state.default;

export const { selectAll: selectAllSms } = smsAdapter.getSelectors();
export const { selectAll: selectAllTdd } = tddAdapter.getSelectors();
export const { selectAll: selectAllDtmf } = dtmfAdapter.getSelectors();
export const { selectAll: selectAllDefault } = defaultAdapter.getSelectors();
