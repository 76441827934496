/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { MessageService } from './message.service';
import { Message } from 'CalltakingCoreApi';
import { deleteDtmfMessage, fetchPredefinedDtmfMessages, initializeDtmfMessageState, newDtmfMessage, updateDtmfMessage } from '../+state/message.actions';
import { PreDefinedMessage } from '../model/pre-defined-messages';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class DtfmMessageService extends MessageService {
    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        super('DTMF', stompService, store);
    }

    protected effectsInitialized(): void {
        this.store.dispatch(fetchPredefinedDtmfMessages());
    }

    protected websocketInitialized(): void {
        this.store.dispatch(initializeDtmfMessageState());
    }

    protected deleteMessage(message: Message): void {
        this.store.dispatch(deleteDtmfMessage({ message: message }));
    }

    protected editMessage(message: Message): void {
        this.store.dispatch(updateDtmfMessage({ message: message }));
    }

    protected newMessage(message: Message): void {
        this.store.dispatch(newDtmfMessage({ message: message }));
    }

    public requestPredefinedDtmf() {
        return this.http.get<PreDefinedMessage[]>('assets/data/pre-defined-dtmf.json', { observe: 'body', responseType: 'json' });
    }

    public requestDtmfMessages(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}${callId}/load/${this.type}`);
        return this.http.get<Message[]>(url, { observe: 'body', responseType: 'json' });
    }
}
