/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum ITEM {
    ALL = 0x00
}
export class StateQueryMessage extends ControlMessage {
    public static readonly ITEM = ITEM;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.STATE_QUERY;
  }
    public withItem(activity: ITEM) {
        this.payload1 = activity;
        return this;
    }

    override payload1String(): string {
        return ITEM[this.payload1];
    }
}
