/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { ServiceName } from '../../../assets/config/environment';
import { KeycloakHealthService } from './keycloak-health.service';

@Injectable({ providedIn: 'root' })
export class KeycloakOidcProviderService {
    public initialized: Promise<any>;
    private keycloakHealthService: KeycloakHealthService;
    startingIndex = 0;

    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService
    ) {
        const startingIndex = localStorage.getItem('keycloak-token-starting-index');
        this.startingIndex = startingIndex ? Number(startingIndex) : 0;
        console.debug(`Initial idm url index: ${this.startingIndex}`);

        this.initialized = env.loadConfiguration().then((environment) => {
            if (!environment.featureFlags?.ccAdminIntegrated) {
                let serviceUrls = environment.SERVICES[ServiceName.IDM] ? environment.SERVICES[ServiceName.IDM] : [environment.AUTH_URL];
                let hintIndex = serviceUrls.findIndex((idmUrl) => idmUrl.includes(new URL(window.location.href).searchParams.get('hint_rack')));
                if (hintIndex >= 0) {
                    console.debug(`Setting initial idm url index to: ${hintIndex} based on 'hint_rack' parameter. Service url: ${serviceUrls[hintIndex]}`);
                    this.startingIndex = hintIndex;
                    localStorage.setItem('keycloak-token-starting-index', this.startingIndex.toString());
                }
                let healthEndpoint = `/realms/${environment.AUTH_REALM}/realm-info-endpoints/realm-health`;
                this.keycloakHealthService = new KeycloakHealthService(http, serviceUrls, healthEndpoint, this.startingIndex);
                return this.keycloakHealthService.initialized;
            }
            return Promise.resolve(true);
        });
    }

    public resetStartingIndex() {
        this.startingIndex = 0;
    }

    public incrementStartingIndex() {
        this.startingIndex = (this.startingIndex + 1) % this.env.environment.SERVICES.idm.length;
        localStorage.setItem('keycloak-token-starting-index', this.startingIndex.toString());
    }

    public getRealmUrl(url: string) {
        if (url && url.charAt(url.length - 1) == '/') {
            return url + 'realms/' + encodeURIComponent(this.env.environment.AUTH_REALM);
        } else {
            return url + '/realms/' + encodeURIComponent(this.env.environment.AUTH_REALM);
        }
    }

    get authorization_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/auth';
    }

    get token_endpoint() {
        return this.getRealmUrl(this.keycloakHealthService?.activeUrl$.value) + '/protocol/openid-connect/token';
    }

    get end_session_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/logout';
    }

    get check_session_iframe() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/login-status-iframe.html';
    }

    get userinfo_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/userinfo';
    }
}
