/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { provideState } from '@ngrx/store';
import { mainFeature } from './main/+state/main.reducer';
import { callFeature } from './call/+state/call.reducer';
import { mediaFeature } from './call/+state/media.reducer';
import { ccHubFeature } from './call/+state/cchub.reducer';
import { messageFeature } from './messages/+state/message.reducer';
import { intercomFeature } from './intercom/+state/intercom.reducer';
import { usbFeature } from './usb/+state/usb.reducer';
import { userFeature } from './user/+state/user.reducer';
import { configurationFeature } from './configuration/+state/configuration.reducer';
import { provideEffects } from '@ngrx/effects';
import { MainEffects } from './main/+state/main.effects';
import { CallEffects } from './call/+state/call.effects';
import { MediaEffects } from './call/+state/media.effects';
import { CchubEffects } from './call/+state/cchub.effects';
import { MessageEffects } from './messages/+state/message.effects';
import { IntercomEffects } from './intercom/+state/intercom.effects';
import { UsbEffects } from './usb/+state/usb.effects';
import { UserEffects } from './user/+state/user.effects';
import { ConfigurationEffects } from './configuration/+state/configuration.effects';
import { settingsFeature } from './settings/+state/settings.reducer';
import { SettingsEffects } from './settings/+state/settings.effects';
import { directoryFeature } from './directory/+state/directory.reducer';
import { DirectoryEffects } from './directory/+state/directory.effects';
import { announcementFeature } from './announcements/+state/announcement.reducer';
import { AnnouncementEffects } from './announcements/+state/announcement.effects';
import { locationFeature } from './location/+state/location.reducer';
import { LocationEffects } from './location/+state/location.effects';
import { MetricsEffects } from './supervisor/queue-metrics/+state/metrics.effects';
import { metricsFeature } from './supervisor/queue-metrics/+state/metrics.reducer';
import { DiscrepancyReportEffects } from './discrepancy-report/+state/discrepancy-report.effects';

const routes: Routes = [
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: '',
        canMatch: [() => inject(AuthGuard).canAccess()],
        loadComponent: () => import('./main/main.component').then((mod) => mod.MainComponent),
        providers: [
            provideState(mainFeature),
            provideState(callFeature),
            provideState(mediaFeature),
            provideState(ccHubFeature),
            provideState(messageFeature),
            provideState(intercomFeature),
            provideState(usbFeature),
            provideState(userFeature),
            provideState(configurationFeature),
            provideState(settingsFeature),
            provideState(directoryFeature),
            provideState(announcementFeature),
            provideState(locationFeature),
            provideState(metricsFeature),
            provideEffects([
                MainEffects,
                CallEffects,
                MediaEffects,
                CchubEffects,
                MessageEffects,
                IntercomEffects,
                UsbEffects,
                UserEffects,
                ConfigurationEffects,
                SettingsEffects,
                DirectoryEffects,
                AnnouncementEffects,
                LocationEffects,
                MetricsEffects,
                DiscrepancyReportEffects
            ])
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
