/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MediaConnection } from '../../core/model/media-connection';
import {
    createMediaConnection,
    deleteMediaConnections,
    getUserMediaFail,
    getUserMediaSuccess,
    mediaEffectsInitialized,
    selectedAlertOutputDevice,
    selectedIrrOutputDevice,
    selectedUserInputDevice,
    selectedUserOutputDevice,
    setAlertVolume,
    setCallVolume,
    setIrrVolume,
    setSipDebug,
    updateMediaConnectionRecord,
    updateMediaConnectionsExtraHeaders,
    updateMediaDevices,
    updateStompConnectionState
} from './media.actions';
import { RxStompState } from '@stomp/rx-stomp';
import { sipPeerPasswordRequestSuccess, sipPeerRequestSuccess } from '../../configuration/+state/configuration.actions';

export interface MediaState extends EntityState<MediaConnection> {
    effectsInitialized: boolean;
    hasUserMedia: boolean;
    userMediaInputDeviceId: string | undefined;
    userMediaOutputDeviceId: string | undefined;
    callVolume: number;
    audibleAlertOutputDeviceId: string | undefined;
    alertVolume: number;
    irrOutputDeviceId: string | undefined;
    irrVolume: number;
    userMediaDevices: MediaDeviceInfo[] | undefined;
    userMediaError: string;
    sipDebugEnable: boolean;
    webSocketConnection: RxStompState;
}

export const adapter: EntityAdapter<MediaConnection> = createEntityAdapter<MediaConnection>({
    selectId: (mediaConnection: MediaConnection) => mediaConnection.uuid
});

const initialState: MediaState = adapter.getInitialState({
    effectsInitialized: false,
    hasUserMedia: false,
    userMediaInputDeviceId: undefined,
    userMediaOutputDeviceId: undefined,
    callVolume: 5,
    audibleAlertOutputDeviceId: undefined,
    alertVolume: 5,
    irrOutputDeviceId: undefined,
    irrVolume: 5,
    userMediaDevices: [],
    userMediaError: '',
    headsetConnected: false,
    sipDebugEnable: false,
    webSocketConnection: RxStompState.CLOSED
});

export const mediaFeature = createFeature({
    name: 'media-connections',
    reducer: createReducer(
        initialState,
        on(mediaEffectsInitialized, (state): MediaState =>  ({ ...state, effectsInitialized: true })),
        on(createMediaConnection, (state, { mediaConnection }) => adapter.upsertOne(mediaConnection, state)),
        on(updateMediaConnectionRecord, (state, { mediaConnection }) => adapter.updateOne({ id: mediaConnection.uuid, changes: mediaConnection }, state)),
        on(updateMediaConnectionsExtraHeaders, (state, { headers }) => adapter.map((entity) => ({...entity, configuration: {...entity.configuration, extra_headers: headers } }), state)),
        on(deleteMediaConnections, (state) => adapter.removeAll(state)),
        on(getUserMediaSuccess, (state): MediaState => ({ ...state, hasUserMedia: true, userMediaError: '' })),
        on(getUserMediaFail, (state, action): MediaState => ({ ...state, hasUserMedia: false, userMediaError: action.error })),
        on(selectedUserInputDevice, (state, { deviceId }): MediaState => ({ ...state, userMediaInputDeviceId: deviceId })),
        on(selectedUserOutputDevice, (state, { deviceId }): MediaState => ({ ...state, userMediaOutputDeviceId: deviceId })),
        on(selectedAlertOutputDevice, (state, { deviceId }): MediaState => ({ ...state, audibleAlertOutputDeviceId: deviceId })),
        on(selectedIrrOutputDevice, (state, { deviceId }): MediaState => ({ ...state, irrOutputDeviceId: deviceId })),
        on(updateMediaDevices, (state, { devices }): MediaState => ({ ...state, userMediaDevices: devices })),
        on(setSipDebug, (state, { enable }): MediaState => ({ ...state, sipDebugEnable: enable })),
        on(updateStompConnectionState, (state, { rxStompState }): MediaState => ({ ...state, webSocketConnection: rxStompState })),
        on(setCallVolume, (state, { volume }): MediaState => ({ ...state, callVolume: volume })),
        on(setAlertVolume, (state, { volume }): MediaState => ({ ...state, alertVolume: volume })),
        on(setIrrVolume, (state, { volume }): MediaState => ({ ...state, irrVolume: volume })),
        on(sipPeerRequestSuccess, (state, { uuid, uri, contact_uri }): MediaState =>
            adapter.upsertOne( { ...state.entities[uuid], configuration: { ...state.entities[uuid].configuration, uri: uri, contact_uri: contact_uri } }, state)),
        on(sipPeerPasswordRequestSuccess, (state, { uuid, password }): MediaState =>
            adapter.upsertOne( { ...state.entities[uuid], configuration: { ...state.entities[uuid].configuration, password: password } }, state))
    )
});

const {
    selectEntities,
    selectAll
} = adapter.getSelectors();

export const selectAllMediaConnections = selectAll;
export const selectMediaConnectionEntities = selectEntities;
