/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

enum ARBITRATION {
  RPI_RADIO_ARBITRATION = 0x00,
  RPI_SAM_ARBITRATION = 0x01,
  RPI_TELEPHONE = 0x02,
  TELEPHONE_ONLY = 0x03
}

export class ArbitrationModeMessage extends ConfigurationMessage {
  public static readonly ARBITRATION = ARBITRATION;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.ARBITRATION_MODE;
  }

  public withArbitration(devices: ARBITRATION) {
    this.payload1 = devices;
    return this;
  }

  override payload1String(): string {
      return ARBITRATION[this.payload1];
  }
}
