/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize } from '@ngrx/store';
import { configurationFeature, ConfigurationState } from './configuration.reducer';
import { selectStandaloneCallTableColumnsConfiguration } from '../../settings/+state/settings.selectors';
import { Cluster } from 'CalltakingCoreApi';
import { ArrayFunctions } from '../../core/util/array-functions';

export const selectConfigurationState = createFeatureSelector<ConfigurationState>(configurationFeature.name);

export const selectConfigurationEffectInitialized = createSelector(selectConfigurationState, (state) => state.effectsInitialized);

export const selectCtcConfiguration = createSelector(selectConfigurationState, (state) => state.ctc);

export const selectServerTimeOffset = createSelector(selectConfigurationState, (state) => state.serverTimeOffset);

export const selectUiConfiguration = createSelector(selectConfigurationState, (state) => state?.ui);

export const selectHeadsetRecoverPeriod = createSelector(selectUiConfiguration, (config) => config.headsetDisconnectRecoveryPeriod);

export const selectConnectionRecoverPeriod = createSelector(selectUiConfiguration, (config) => config.systemReconnectPeriod);

export const selectLayoutConfiguration = createSelector(selectConfigurationState, (state) => state?.layouts);

export const selectClusterVersionMap = createSelector(selectConfigurationState, (state) => state.ctcVersion);

export const selectLayoutApplicationHeader = createSelector(selectLayoutConfiguration, (config) => config?.applicationHeader);

export const selectLayoutTheme = createSelector(selectLayoutApplicationHeader, (config) => config?.sessionManager?.theme);

export const selectLayoutLeftPane = createSelector(selectLayoutConfiguration, (config) => config.leftPane);

export const selectLayoutCentralPane = createSelector(selectLayoutConfiguration, (config) => config.centralPane);

export const selectLayoutRightPane = createSelector(selectLayoutConfiguration, (config) => config.rightPane);

export const selectLayoutSidePane = createSelector(selectLayoutConfiguration, (config) => config.sidePane);

export const selectDirectoryConfiguration = createSelector(selectLayoutRightPane, (right) => right?.directory);

export const selectDirectorySuggestedSpeedDialEnabled = createSelector(selectDirectoryConfiguration, (directory) => directory?.suggestedSpeedDials);

export const selectSupervisorTabEnabled = createSelector(selectLayoutCentralPane, (central) => Boolean(central?.monitoring));

export const selectCallListTabEnabled = createSelector(selectStandaloneCallTableColumnsConfiguration, (config) => config?.enabled);

export const selectMetricsEnabled = createSelector(selectSupervisorTabEnabled, (supervisorViewEnabled) => Boolean(supervisorViewEnabled));

export const selectRecentCallLimit = createSelector(selectUiConfiguration, (config) => config.recentCallLimit);

export const selectHistoryDepth = createSelector(selectUiConfiguration, (config) => config?.historyDepth);

export const selectApplicationLinks = createSelector(selectConfigurationState, (config) => config?.links);

export const selectSiteConfiguration = createSelector(selectCtcConfiguration, (config) => config?.siteConfiguration);

export const selectHostName = createSelector(selectSiteConfiguration, (siteConfig) => (siteConfig ? siteConfig.hostname : null));

export const selectClusterConfiguration = createSelector(selectCtcConfiguration, (config) => (config?.clusters ? config.clusters : []));

export const selectClusterNames = createSelector(selectClusterConfiguration, (clusters) => (clusters.map((cluster) => cluster.name)));

export const selectClusterNameToAddressMap = createSelector(selectClusterConfiguration, (clusters) =>
    clusters.reduce((map : { [name: string]: string }, cluster) => ({ ...map, [cluster.name]: `https://${cluster.ctcAddress}` }), {})
);

export const selectClusterConfigurationMap = createSelector(selectClusterConfiguration, (clusters) =>
    clusters.reduce((map: { [name: string]: Cluster }, cluster) => ({ ...map, [cluster.name]: cluster }), {})
);

export const selectClusterVersions = createSelector(selectClusterVersionMap, selectClusterConfigurationMap, (ctcVersionMap, clusterMap) => {
    return Object.values(clusterMap).map((cluster) => ({ uuid: cluster.uuid, label: cluster.clusterLabel, version: ctcVersionMap[cluster.name]}));
});

export const selectClusterConfigurationByIdMap = createSelector(selectClusterConfiguration, (clusters) =>
    clusters.reduce((map: { [name: string]: Cluster }, cluster) => ({ ...map, [cluster.uuid]: cluster }), {})
);

const _selectCtcStatus = createSelector(selectConfigurationState, (state) => state.ctcStatus);
export const selectCtcOverrideStatus = createSelector(selectConfigurationState, (state) => state.ctcOverrideStatus);

export const selectPrimaryConnectionClusterName = createSelector(selectConfigurationState, (state) => state.primaryConnectionClusterName);

export const selectCtcStatus = createSelector(_selectCtcStatus, selectCtcOverrideStatus, (status, overrideStatus) => ({...status, ...overrideStatus}));

export const selectAvailablePrimaryCtc = createSelector(selectCtcStatus, selectClusterConfigurationMap, (ctcStatusMap, clusterMap) =>
    Object.keys(ctcStatusMap)
        .filter((clusterName) => ctcStatusMap[clusterName])
        .map((clusterName) => ({ clusterName: clusterName, label: clusterMap[clusterName].clusterLabel }))
);

export const selectCtcStatusByName = (clusterName: string) => createSelector(selectCtcStatus, (ctcStatus) => ctcStatus[clusterName]);

export const selectAllCallQueues = createSelector(selectConfigurationState, (state) => (state?.callQueues ? state.callQueues : []));
export const selectCallQueueMap = createSelector(selectAllCallQueues, (callQueues) =>
    Object.fromEntries(callQueues.map((callQueue) => [callQueue.name, callQueue]))
);

export const selectFilteredCallQueues = createSelector(selectConfigurationState, (state) => state.selectedCallQueueFilter);

/* ******************* Hotkeys ****************** */

export const selectHotkeys = createSelector(selectConfigurationState, (state) => state.hotkeys);

/* ******************* Alerts ******************* */

export const selectAudibleAlerts = createSelector(selectConfigurationState, (state) => state.alerts);

export const selectIMAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.imAlert);

export const selectAnnouncementAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.announcementAlert);

export const selectNetworkDisconnectionAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.networkDisconnectionAlert);

export const selectCallAlerts = createSelector(selectAudibleAlerts, (audibleAlerts) => (audibleAlerts?.callAlerts ? audibleAlerts?.callAlerts : []));

export const selectHasInitialized = createSelector(selectConfigurationState, (state) => state.hasInitialized);

const _selectQuiescence = createSelector(selectConfigurationState, (state) => state.quiescence);

export const selectCtcOverrideQuiescence = createSelector(selectConfigurationState, (state) => state.ctcOverrideQuiescence);

export const selectQuiescence = createSelector(_selectQuiescence, selectCtcOverrideQuiescence, (status, overrideQuiescence) => ({...status, ...overrideQuiescence}));

export const isQuiesced = createSelector(selectPrimaryConnectionClusterName, selectQuiescence, (currentPrimaryClusterName, quiescence) => quiescence[currentPrimaryClusterName]);

export const selectNextPrimaryClusterName = createSelector(selectClusterNameToAddressMap, selectCtcStatus, selectQuiescence, selectPrimaryConnectionClusterName,
    (clusterAddressMap, ctcStatusMap, ctcQuiescenceMap, primaryDataConnectionClusterName) => {
        let availableClusterNames = Object.keys(ctcStatusMap).filter((clusterName) => ctcStatusMap[clusterName]);
        let availableNonQuiescedClusterNames = availableClusterNames.filter((clusterName) => !ctcQuiescenceMap[clusterName]);
        return availableClusterNames.length === 0 || Object.keys(clusterAddressMap).length === 0 ? undefined :
            availableClusterNames.includes(primaryDataConnectionClusterName) ? primaryDataConnectionClusterName :
                ArrayFunctions.selectRandom(availableNonQuiescedClusterNames);
    });

export interface ConnectionStatus {
    [key: string] : {
        label: string;
        heartbeat: boolean;
        quiesced: boolean;
    }
}

const connectionStatusMemoize = (
    projectorFn: DefaultProjectorFn<ConnectionStatus>
) => resultMemoize(projectorFn, connectionStatusIsEqual);

function connectionStatusIsEqual(a: ConnectionStatus, b: ConnectionStatus): boolean {
    let aKeys = Object.keys(a);
    let bKeys = Object.keys(b);
    return aKeys.length === bKeys.length && aKeys.every((aKey) => bKeys.includes(aKey)) &&
        aKeys.every((aKey) => a[aKey]?.label === b[aKey]?.label && a[aKey]?.heartbeat === b[aKey]?.heartbeat && a[aKey]?.quiesced === b[aKey]?.quiesced);
}

export const selectCtcConnectionStatusMap = createSelectorFactory<object, ConnectionStatus>(connectionStatusMemoize)(selectClusterConfigurationMap, selectCtcStatus, selectQuiescence,
    (clusterMap: { [key: string]: Cluster }, ctcStatusMap: { [key: string]: boolean }, ctcQuiescenceMap: { [key: string]: boolean }) =>
        Object.keys(clusterMap).reduce<ConnectionStatus>(
            (map, key) => ({ ...map, [key]: { label: clusterMap[key].clusterLabel, heartbeat: ctcStatusMap[key], quiesced: ctcQuiescenceMap[key] } }), {})
);

export const selectDRAudioQualityEnabled = createSelector(
    selectLayoutSidePane,
    (settings) => settings.audioQuality
);
