/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from '../../core/services/environment.service';
import { AnalyticsHistoricalCall, AnalyticsRecentCall } from '../model/analytics-historical-call';
import { CustomHttpParamEncoder } from '../../core/util/custom-http-param-encoder';
import { Store } from '@ngrx/store';
import { selectUiConfiguration } from '../../configuration/+state/configuration.selectors';
import { AnalyticsHistoricalTextMessage } from '../../messages/model/analytics-historical-text-message';
import { HealthService } from '../../core/services/health.service';
import { ServiceName } from '../../../assets/config/environment';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService extends HealthService {
    private numberOfDays = 0;
    private numberOfCalls = 30;
    // duration in minutes
    private duration = 1;
    private readonly API_CALL_HISTORY = `/backend/api/call-history`;
    private readonly API_TEXT_HISTORY = `/backend/api/text-messages`;
    private readonly API_RECENT_CALLS = '/backend/api/recent-calls';

    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService,
        private store: Store
    ) {
        super(http, ServiceName.ANALYTICS, env.environment.SERVICES[ServiceName.ANALYTICS], "/backend/health");
        this.store.select(selectUiConfiguration).subscribe((config) => {
            this.numberOfDays = config.historyAge ?? 1;
            this.numberOfCalls = config.historyDepth ?? 30;
            this.duration = config.correlationPeriod ?? 60;
        });
    }

    public requestRecentCalls(maxCalls = 30, hours = 23) {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        params = params.append('maxNumberOfCalls', maxCalls);
        params = hours ? params.append('numberOfHours', hours) : params;
        return this.http.get<AnalyticsRecentCall[]>(`${this.activeUrl$.value}${this.API_RECENT_CALLS}`, {
            params: params,
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestCallHistory(callingNumber: string, callId: string) {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        params = params.append('callingNumber', callingNumber);
        params = params.append('ctcCallId', callId);
        params = params.append('numberOfCalls', this.numberOfCalls);
        params = this.numberOfDays ? params.append('numberOfDays', this.numberOfDays) : params;
        return this.http.get<AnalyticsHistoricalCall[]>(`${this.activeUrl$.value}${this.API_CALL_HISTORY}`, {
            params: params,
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestTextCallHistory(callingNumber: string, callId: string, nenaCallId: string) {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        params = params.append('callingNumber', callingNumber);
        params = params.append('ctcCallId', callId);
        params = params.append('duration', this.duration);
        params = params.append('nenaCallId', nenaCallId);
        return this.http.get<AnalyticsHistoricalTextMessage[]>(`${this.activeUrl$.value}${this.API_TEXT_HISTORY}`, {
            params: params,
            observe: 'body',
            responseType: 'json'
        });
    }

    lockCall(callId: string) {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        params = params.append('chsCallId', callId);
        return this.http.post<void>(
            `${this.activeUrl$.value}/backend/api/lock-call`,
            {},
            {
                params
            }
        );
    }

    unlockCall(callId: string) {
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        params = params.append('chsCallId', callId);
        return this.http.post<void>(
            `${this.activeUrl$.value}/backend/api/unlock-call`,
            {},
            {
                params
            }
        );
    }
}
