/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { UsbBaseService } from '../../shared/services/usb-base.service';
import { Store } from '@ngrx/store';
import { SamAudioService } from './sam-audio.service';
import { filter, take } from 'rxjs/operators';
import { selectSamAttached } from '../../+state/usb.selectors';

@Injectable({
    providedIn: 'root'
})
export class SamService extends UsbBaseService {
    public readonly DEVICE_NAME = 'SAM';
    public readonly VENDOR_ID= 0x1694;
    public readonly PRODUCT_ID = 0x0008;
    constructor(protected store: Store, private audioService: SamAudioService) {
        super(store);

        this.store
            .select(selectSamAttached)
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.init();
                this.audioService.init();
            });
    }
}
