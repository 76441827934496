/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

enum AUDIO {
  AS_RECEIVED = 0x00,
  AS_HEARD_IN_JACKBOX_ONE = 0x01
}

export class LTRPhoneAudioMessage extends ConfigurationMessage {
  public static readonly AUDIO = AUDIO;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.LTR_PHONE_AUDIO;
  }

  public withAudio(audio: AUDIO) {
    this.payload1 = audio
    return this;
  }

  override payload1String(): string {
      return AUDIO[this.payload1];
  }
}
