/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */


import { InputReport1 } from '../model/report-1/input-report-1';
import { InputReport2 } from '../model/report-2/input-report-2';
import { DeviceIdentificationMessage } from '../model/report-2/device-identification-message';
import { ConfigurationMessage } from '../model/report-1/configuration/abstract-configuration-message';
import { ControlMessage } from '../model/report-1/control/abstract-control-message';
import { EventMessage } from '../model/report-1/event/abstract-event-message';
import { HeartbeatMessage } from '../model/report-1/event/heartbeat-message';
import { JackSenseMessage } from '../model/report-1/event/jack-sense-message';
import { InputStateMessage } from '../model/report-1/event/input-state-message';
import { InputLevelMessage } from '../model/report-1/event/input-level-message';
import { FirmwareReloadProgressMessage } from '../model/report-1/event/firmware-reload-message';
import { QueryResponseStatusMessage } from '../model/report-1/event/query-response-status-message';
import { RestartMessage } from '../model/report-1/event/restart-message';
import { HardwareFailureMessage } from '../model/report-1/event/hardware-failure-message';
import { EventErrorMessage } from '../model/report-1/event/event-error-message';
import { JackboxBoostMessage } from '../model/report-1/configuration/jackbox-boost-message';
import { AlertsMessage } from '../model/report-1/configuration/alerts-message';
import { ConfigurationOutOfRangeMessage } from '../model/report-1/configuration/configuration-out-of-range-message';
import { ConfigurationErrorMessage } from '../model/report-1/configuration/configuration-error-message';
import { ArbitrationModeMessage } from '../model/report-1/configuration/arbitration-mode-message';
import { AuxAudioMessage } from '../model/report-1/configuration/aux-audio-message';
import { AGCMessage } from '../model/report-1/configuration/agc-message';
import { AGCSilenceMessage } from '../model/report-1/configuration/agc-silence-message';
import { EchoCancellerMessage } from '../model/report-1/configuration/echo-canceller-message';
import { PhoneSidetoneMessage } from '../model/report-1/configuration/phone-sidetone-message';
import { LTRPhoneAudioMessage } from '../model/report-1/configuration/ltr-phone-audio-message';
import { HeadsetMicMessage } from '../model/report-1/configuration/headset-mic-message';
import { AuxAudioResumeMessage } from '../model/report-1/configuration/aux-audio-resume-message';
import { PhoneSourceMessage } from '../model/report-1/configuration/phone-source-message';
import { RegisterMessage } from '../model/report-1/control/register-message';
import { ResetMessage } from '../model/report-1/control/reset-message';
import { TestToneMessage } from '../model/report-1/control/test-tone-message';
import { GreetingRecordingRequestMessage } from '../model/report-1/control/greeting-recording-request-message';
import { PhoneActivityMessage } from '../model/report-1/control/phone-activity-message';
import { AuxAudioMessage2 } from '../model/report-1/control/aux-audio-message';
import { JackboxMuteMessage } from '../model/report-1/control/jackbox-mute-message';
import { TestInputMessage } from '../model/report-1/control/test-input-message';
import { TestLevelMessage } from '../model/report-1/control/test-level-message';
import { TestLoopbackMessage } from '../model/report-1/control/test-loopback-message';
import { TestModeMessage } from '../model/report-1/control/test-mode-message';
import { TestOutputMessage } from '../model/report-1/control/test-output-message';
import { VolumeMessage } from '../model/report-1/control/volume-message';
import { ControlOutOfRangeMessage } from '../model/report-1/control/control-out-of-range-message';
import { StateQueryMessage } from '../model/report-1/control/state-query-message';
import { DeviceInfoMessage } from '../model/report-1/control/device-info-message';
import { FirmwareUpdateMessage } from '../model/report-1/control/firmware-update-message';
import { PlaybackAudioMessage } from '../model/report-1/control/playback-audio-message';
import { ThresholdMessage } from '../model/report-1/control/threshhold-message';
import { IrrPlaybackToPhoneMessage } from '../model/report-1/control/irr-playback-to-phone-message';
import { ConfigurationQueryMessage } from '../model/report-1/control/configuration-query-message';
import { IrrPlaybackPermissionsMessage } from '../model/report-1/control/irr-playback-permissions-message';
import { InputTestToneMessage } from '../model/report-1/control/input-test-tone-message';
import { ControlErrorMessage } from '../model/report-1/control/control-error-message';

export class MessageMapper {

    public static fromReportEvent(event: HIDInputReportEvent): InputReport1 | InputReport2 {
        switch (event.reportId) {
            case InputReport1.REPORT_ID:
                return MessageMapper.toReport1Message(event.data);
            case InputReport2.REPORT_ID:
                return MessageMapper.toReport2Message(event.data);
            default:
                return undefined;
        }
    }

    public static toReport2Message(data: DataView) {
        switch (data.getUint8(0)) {
            case InputReport2.MessageType.DEVICE_ID:
                return new DeviceIdentificationMessage(data.buffer);
            default:
                return new InputReport2(data.buffer);
        }
    }

    public static toReport1Message(data: DataView) {
        switch (data.getUint8(0)) {
            case InputReport1.MessageType.EVENT:
                return this.getEventMessageClass(data);
            case InputReport1.MessageType.CONFIGURATION:
                return this.getConfigurationMessageClass(data as ConfigurationMessage);
            case InputReport1.MessageType.CONTROL:
                return this.getControlMessageClass(data as ControlMessage);
            default:
                return new InputReport1(data.buffer);
        }
    }

    private static getEventMessageClass(data: DataView): InputReport1 {
        switch (data.getUint8(1)) {
            case EventMessage.EventMessageIdentifier.HEARTBEAT:
                return new HeartbeatMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.JACK_SENSE:
                return new JackSenseMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.INPUT:
                return new InputStateMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.LEVEL_104:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_104);
            case EventMessage.EventMessageIdentifier.LEVEL_404:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_404);
            case EventMessage.EventMessageIdentifier.LEVEL_704:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_704);
            case EventMessage.EventMessageIdentifier.LEVEL_1004:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_1004);
            case EventMessage.EventMessageIdentifier.LEVEL_1904:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_1904);
            case EventMessage.EventMessageIdentifier.LEVEL_2804:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_2804);
            case EventMessage.EventMessageIdentifier.LEVEL_6804:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_6804);
            case EventMessage.EventMessageIdentifier.LEVEL_13804:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_13804);
            case EventMessage.EventMessageIdentifier.LEVEL_19804:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_19804);
            case EventMessage.EventMessageIdentifier.LEVEL_PEAK:
                return new InputLevelMessage(data.buffer, EventMessage.EventMessageIdentifier.LEVEL_PEAK);
            case EventMessage.EventMessageIdentifier.FW_RELOAD_PROGRESS:
                return new FirmwareReloadProgressMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.QUERY_RESPONSE_STATUS:
                return new QueryResponseStatusMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.RESTART:
                return new RestartMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.HARDWARE_FAILURE:
                return new HardwareFailureMessage(data.buffer);
            case EventMessage.EventMessageIdentifier.ERROR:
                return new EventErrorMessage(data.buffer);
            default:
                return new InputReport1(data.buffer);
        }
    }

    private static getConfigurationMessageClass(data: DataView) {
        switch (data.getUint8(1)) {
            case ConfigurationMessage.ConfigurationMessageIdentifier.JACK_BOX_AUDIO_BOOST:
                return new JackboxBoostMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.ALERT_SOUNDS:
                return new AlertsMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.OUT_OF_RANGE:
                return new ConfigurationOutOfRangeMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.ERROR:
                return new ConfigurationErrorMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.ARBITRATION_MODE:
                return new ArbitrationModeMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.AUX_AUDIO:
                return new AuxAudioMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.AGC:
                return new AGCMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.AGC_SILENCE:
                return new AGCSilenceMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.ECHO_CANCELLER:
                return new EchoCancellerMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.PHONE_SIDE_TONE:
                return new PhoneSidetoneMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.LTR_PHONE_AUDIO:
                return new LTRPhoneAudioMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.HEADSET_MIC:
                return new HeadsetMicMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.AUX_AUDIO_RESUMES:
                return new AuxAudioResumeMessage(data.buffer);
            case ConfigurationMessage.ConfigurationMessageIdentifier.PHONE_SOURCE:
                return new PhoneSourceMessage(data.buffer);
            default:
                return new InputReport1(data.buffer);
        }
    }

    private static getControlMessageClass(data: DataView) {
        switch (data.getUint8(1)) {
            case ControlMessage.ControlMessageIdentifier.REGISTER:
                return new RegisterMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.RESET:
                return new ResetMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_TONE:
                return new TestToneMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.GREETING_RECORDING_REQUEST:
                return new GreetingRecordingRequestMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.PHONE_ACTIVITY:
                return new PhoneActivityMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.AUX_AUDIO:
                return new AuxAudioMessage2(data.buffer);
            case ControlMessage.ControlMessageIdentifier.JACKBOX_MUTE:
                return new JackboxMuteMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_INPUT:
                return new TestInputMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_LEVEL:
                return new TestLevelMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_LOOPBACK:
                return new TestLoopbackMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_MODE:
                return new TestModeMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.TEST_OUTPUT:
                return new TestOutputMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.VOLUME:
                return new VolumeMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.OUT_OF_RANGE:
                return new ControlOutOfRangeMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.STATE_QUERY:
                return new StateQueryMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.DEVICE_IDENTIFICATION:
                return new DeviceInfoMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.FIRMWARE_UPDATE:
                return new FirmwareUpdateMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.PLAYBACK_AUDIO:
                return new PlaybackAudioMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.THRESHOLD:
                return new ThresholdMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.IRR_PLAYBACK_TO_PHONE:
                return new IrrPlaybackToPhoneMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.CONFIGURATION_QUERY:
                return new ConfigurationQueryMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.IRR_PLAYBACK_PERMISSIONS:
                return new IrrPlaybackPermissionsMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.INPUT_TEST_TONE:
                return new InputTestToneMessage(data.buffer);
            case ControlMessage.ControlMessageIdentifier.ERROR:
                return new ControlErrorMessage(data.buffer);
            default:
                return new InputReport1(data.buffer);
        }
    }
}
