/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import { createFeature, createReducer, on } from '@ngrx/store';
import {
    fetchAgencySettingsSuccess,
    fetchCallTableColumnsSuccess,
    fetchUserSettingsSuccess,
    fetchVersionsSuccess,
    setTheme,
    toggleTheme,
    updateFilters,
    updateUserSettingsSuccess
} from './settings.actions';
import { initUser } from '../../user/+state/user.actions';
import { SettingsState, SupervisorAgencySettings, SupervisorQueueColumn, SupervisorThreshold } from '../model/settings.model';
import { Theme } from '../model/theme';

const initialState: SettingsState = {
    softwareVersions: [],
    agencyColumns: [],
    availableMonitoredRoles: [],
    availableMonitoredQueues: [],
    monitoredRoleFilter: [],
    monitoredQueueFilter: [],
    summarySchemes: [],
    maxQueueDisplayRows: 3,
    supervisedQueues: [],
    selectedRoleId: null,
    callListConfig: {
      enabled: false,
      columns: [],
    },
    userSettings: {
        audioSettings: {
            mainInputDeviceId: 'default',
            mainOutputDeviceId: 'default',
            alertOutputDeviceId: 'default',
            irrOutputDeviceId: 'default',
            callOutputVolume: 5,
            alertOutputVolume: 5,
            irrOutputVolume: 5,
            alertToHeadsets: true,
            alertToDevice: false,
            irrToHeadsets: true,
            irrToDevice: false,
            volumeStatus: { }
        },
        theme: undefined
    },
    menuFilters: {
        agentTable: [],
        callTable: [],
        standaloneCallTable: [],
        recentCalls: [],
        announcements: []
    }
};

export const settingsFeature = createFeature({
    name: 'settings',
    reducer: createReducer(
        initialState,
        on(fetchAgencySettingsSuccess, (state, {settings}): SettingsState => {
            return {
                ...state,
                selectedRoleId: settings.roleDto?.uuid,
                monitoredQueueFilter: settings.monitoredQueuedFilter || [],
                monitoredRoleFilter: settings.monitoredRoleFilter || [],
                summarySchemes: settings.summarySchemes || [],
                supervisedQueues: settings.queues,
                agencyColumns: settings.queues.length ?
                    settings.columns.concat(settings.queues[0].metrics.map(combineColumnThresholds(settings))) :
                    settings.columns
            };
        }),
        on(
            fetchUserSettingsSuccess,
            updateUserSettingsSuccess,
            (state, {settings}): SettingsState => {
                const updatedSettings = {...settings};
                return {
                    ...state, userSettings: {
                        ...state.userSettings, ...updatedSettings
                    }
                };
            }),
        on(setTheme, (state, {theme}): SettingsState => ({
            ...state, userSettings: { ...state.userSettings, theme }
        })),
        on(fetchVersionsSuccess, (state, { versions }): SettingsState => ({
            ...state, softwareVersions: versions
        })),
        on(toggleTheme, (state): SettingsState => ({
            ...state,userSettings: { ...state.userSettings,
                theme: state.userSettings.theme === Theme.DARK ? Theme.LIGHT : Theme.DARK }
        })),
        on(initUser, (state, {tokenParsed}): SettingsState => {
            const supervisedRoles = (tokenParsed?.preferred_role_supervised_roles || []) as string[];
            const supervisedQueues = (tokenParsed?.preferred_role_supervised_queues || []) as string[];
            return {
                ...state,
                availableMonitoredQueues: supervisedQueues,
                availableMonitoredRoles: supervisedRoles,
                userSettings: {
                    ...state.userSettings,
                },
            };
        }),
        on(updateFilters, (state, {filterKey, filters}): SettingsState => {
            return {
                ...state,
                menuFilters: {
                    ...state.menuFilters,
                    [filterKey]: [...filters]
                }
            };
        }),
        on(fetchCallTableColumnsSuccess, (state, {config}): SettingsState => {
            return {
                ...state,
                callListConfig: config
            };
        })
    )
});


function combineColumnThresholds(settings: SupervisorAgencySettings) {
    return (m: SupervisorQueueColumn) => {
        let thresholds: SupervisorThreshold[] = [];
        settings.queues.forEach((queue, i) => {
            if (i > 0) {
                const foundMetric = queue.metrics.find((metric) => m.key === metric.key);
                if (foundMetric && foundMetric.thresholds?.length) {
                    thresholds.push(...foundMetric.thresholds.map((t: SupervisorThreshold) => ({...t, queueId: queue.key})));
                }
            }
        });

        const thresholdsWithQueue = m.thresholds.map((t) => ({...t, queueId: settings.queues[0].key}));

        return {...m, thresholds: [...thresholds, ...thresholdsWithQueue]};
    };
}
