/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { Announcement } from 'CalltakingCoreApi';
import { receivedAnnouncement } from '../+state/announcement.actions';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService implements StompConsumer, OnDestroy {
    private readonly ANNOUNCEMENT_MESSAGE_NEW_TOPIC = '/user/topic/v1/announcement/new';
    private readonly API_BASE_URL = '/api/imc/v1/';
    private readonly SEND_ANNOUNCEMENT_URL = `${this.API_BASE_URL}announcement`;
    private readonly receipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.topicSubscription = this.stompService.watchAsync(this.ANNOUNCEMENT_MESSAGE_NEW_TOPIC, this.receipt, this).subscribe((message: IMessage) => {
            this.store.dispatch(receivedAnnouncement({ announcement: JSON.parse(message.body) }));
        });
    }

    public ngOnDestroy() {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Announcement topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Call topic ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Announcement topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    public sendAnnouncement(announcement: Announcement, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.SEND_ANNOUNCEMENT_URL);
        return this.http.post(url, announcement);
    }
}
