/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

export class PhoneSidetoneMessage extends ConfigurationMessage {

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ConfigurationMessage.ConfigurationMessageIdentifier.PHONE_SIDE_TONE;
    }

    public withEnablement(enabled: boolean) {
        this.payload1 = enabled ? 0x01 : 0x00;
        return this;
    }

    override payload1String(): string {
        return this.payload1 == 0x01 ? "ENABLED" : "DISABLED";
    }
}
