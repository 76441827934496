/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { ClusterQuiescence } from '../model/cluster-quiescence';
import { HttpClient } from '@angular/common/http';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { selectClusterConfiguration } from '../../configuration/+state/configuration.selectors';
import { clusterQuiescence, fetchQuiescenceState } from '../../configuration/+state/configuration.actions';

@Injectable({
    providedIn: 'root'
})
export class CallQuiesceService implements OnDestroy, StompConsumer {
    private readonly QUIESCENCE_TOPIC = '/topic/v1/node-quiesced';
    private readonly QUIESCENCE_STATE_URL = '/api/config/v1/quiescent-state';
    private readonly CTC_QUIESCE_URL = '/api/icc/v1/maintenance/quiesce';
    private readonly CTC_UNQUIESCE_URL = '/api/icc/v1/maintenance/unquiesce';
    private readonly receipt = uuid.v4();
    private readonly quiesceTopicSubscription: Subscription | undefined;

    constructor(private stompService: StompService,
    private http: HttpClient,
    private store: Store,
    private clusterUrlProviderService: ClusterUrlProviderService) {
        this.quiesceTopicSubscription = this.stompService.watchAsync(this.QUIESCENCE_TOPIC, this.receipt, this).subscribe((message: IMessage) =>
            this.store.dispatch(clusterQuiescence({ quiescence: JSON.parse(message.body) })));

        this.store.select(selectClusterConfiguration).subscribe((clusters) => {
            clusters.forEach((cluster) => this.store.dispatch(fetchQuiescenceState( { clusterName: cluster.name  })))
        });
    }

    ngOnDestroy(): void {
        if (this.quiesceTopicSubscription) {
            this.quiesceTopicSubscription.unsubscribe();
        }
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Quiesce topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Quiesce topic ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Quiesce topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }
    public requestCtcQuiescentState(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.QUIESCENCE_STATE_URL);
        return this.http.get<ClusterQuiescence>(`${url}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public quiesce(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.CTC_QUIESCE_URL);
        return this.http.post(url, {});
    }

    public unquiesce(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.CTC_UNQUIESCE_URL);
        return this.http.post(url, {});
    }
}
