/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { Subject, Subscription } from 'rxjs';
import { ACDBidResponse } from 'CalltakingCoreApi';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { acdAnswerRequest, acdBid, acdRejection, acdRequestRejection } from '../+state/user.actions';
import { selectAutoAnswerAssignedWonBid, selectCallsToReject, selectNextBid } from '../../call/+state/call.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class AcdService implements OnDestroy, StompConsumer {
    private readonly ACD_REVOKE_TOPIC = `/user/topic/v1/acd/*/revoke`;
    private readonly API_BASE_URL = '/api/acd/v1/';

    private readonly receipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.topicSubscription = this.stompService
            .watchAsync(this.ACD_REVOKE_TOPIC, this.receipt, this)
            .subscribe((message: IMessage) => {
                let rejectionEvent = JSON.parse(message.body);
                console.warn(`ACD Assignment Revoked callId: ${rejectionEvent.callId}`)
                this.store.dispatch(acdRejection({ event: rejectionEvent }));
            });
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`ACD revoke topic ${topic} subscription success: ${receiptId}`);

        this.store
            .pipe(selectNextBid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bid) => this.store.dispatch(acdBid({ bid: bid })));

        this.store
            .select(selectAutoAnswerAssignedWonBid)
            .pipe(
                filter((bid) => !!bid),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((autoAnswerBid) => this.store.dispatch(acdAnswerRequest({ bid: autoAnswerBid })));

        this.store
            .select(selectCallsToReject)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((callsToReject) => callsToReject.forEach((assignedCall) => this.store.dispatch(acdRequestRejection({ callId: assignedCall.uuid }))));
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`ACD revoke ${topic} subscription end: ${receiptId}`);
        this.unsubscribe$.next();
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`ACD revoke topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public bidForCall(callId: string, clusterName: string | undefined, bid: number, previouslyAssigned: boolean) {
        let params = new HttpParams().append('previouslyAssigned', previouslyAssigned);
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}${callId}/bid/${bid}`);
        return this.http.post<ACDBidResponse>(url, {}, { params: params });
    }

    public acdAnswer(callId: string, clusterName: string | undefined, queueName: string | undefined) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}${callId}/${queueName}/answer`);
        return this.http.post(url, {});
    }

    public acdReject(callId: string, clusterName: string | undefined) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}${callId}/reject`);
        return this.http.post(url, {});
    }
}
