/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { InstantMessage, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { IntercomChat } from '../model/intercom-chat';

export const newInstantMessage = createAction('[Message] Intercom New', props<{ message: InstantMessage }>());
export const sendIntercomMessage = createAction('[Message] Send Intercom Message', props<{ chat: IntercomChat, message: string }>());
export const sendIntercomMessageSuccess = createAction('[Message] Send Intercom Message Success');
export const sendIntercomMessageFail = createAction('[Message] Send Intercom Message Fail', props<{ payload: string }>());

export const startChat = createAction('[Message] Intercom New Chat', props<{ chat: IntercomChat }>());
export const updateChat = createAction('[Message] Intercom Update Chat', props<{ chat: IntercomChat }>());
export const clearChatNotification = createAction('[Message] Intercom Chat Notification Cleared', props<{ chatId: string }>());

export const requestChat = createAction('[Message] Intercom Request Chat', props<{ users: UserAuthenticationRecord[] }>());
export const selectChat = createAction('[Message] Intercom Select Chat', props<{ chatId: string | undefined }>());

export const selectSidePanel = createAction('[Message] Side Panel Toggle', props<{ panel: string, toggle: boolean }>());
