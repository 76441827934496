/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

export class AGCSilenceMessage extends ConfigurationMessage {
  public static readonly RATE = 0.1;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.AGC_SILENCE;
  }

  public withMultiplier(multiplier: number) {
    let timeToConsiderAbsentInSeconds = multiplier * AGCSilenceMessage.RATE;
    if (timeToConsiderAbsentInSeconds > 4) {
      timeToConsiderAbsentInSeconds = 4
    }
    this.payload1 = timeToConsiderAbsentInSeconds;
    return this;
  }
}
