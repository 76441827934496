/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum STATE {
    STOP = 0x00,
    START = 0x01
}

export class TestInputMessage extends ControlMessage {
    public static readonly STATE = STATE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.TEST_INPUT;
    }

    public withState(state: STATE) {
        this.payload1 = state;
        return this;
    }

    override payload1String(): string {
        return STATE[this.payload1];
    }
}
