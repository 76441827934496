/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { effect, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { MediaService } from '../../../call/services/media.service';
import { AlertService } from '../../../core/audio/alert.service';
import { IrrService } from '../../../core/audio/irr.service';
import { InputDeviceInterface } from '../../../call/model/input-device-interface';
import { getUserMediaSuccess } from '../../../call/+state/media.actions';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectHeadsetAlertingEnabled } from '../../../call/+state/call.selectors';
import { selectUsbAlertOutputToHeadsets, selectUsbIrrOutputToHeadsets } from '../../+state/usb.selectors';
import { OutputDeviceInterface } from '../../../call/model/output-device-interface';

@Injectable({
    providedIn: 'root'
})
export class SamAudioService {
    public static readonly SAM_ID = `(1694:0008)`; // SAM usb vendor id and product id
    public static readonly SAM_INPUT_DEVICE_LABEL = 'SAM Recorder 3';
    public static readonly SAM_OUTPUT_DEVICE_LABEL = 'SAM Player 3';
    public static readonly SAM_ALERT_OUTPUT_DEVICE_LABEL = 'SAM Player 4';
    public static readonly SAM_IRR_OUTPUT_DEVICE_LABEL = 'SAM Player 1';
    private inputDeviceInterface: InputDeviceInterface;
    private outputDeviceInterface: OutputDeviceInterface;
    private readonly headsetAlertsEnabled$: Signal<boolean>;
    private headsetAlertOutputDeviceInterface: OutputDeviceInterface;
    private headsetIrrOutputDeviceInterface: OutputDeviceInterface;
    constructor(
        private store: Store,
        private mediaService: MediaService,
        private alertService: AlertService,
        private irrService: IrrService
    ) {
        this.headsetAlertsEnabled$ = toSignal(this.store.select(selectHeadsetAlertingEnabled));
        effect(() => this.headsetAlertOutputDeviceInterface ? this.headsetAlertOutputDeviceInterface.connected = this.headsetAlertsEnabled$() : this.headsetAlertsEnabled$());
    }

    public init() {
        console.debug('Initializing SAM Audio Service.');
        // prompt user for initial microphone access
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            // then find all media devices
            .then(() => navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    let samDevices = devices.filter((d) => d.label.includes(SamAudioService.SAM_ID));
                    let callInput = samDevices.find((d) => d.kind === 'audioinput' && d.label.includes(SamAudioService.SAM_INPUT_DEVICE_LABEL));
                    let callOutput = devices.find((d) => d.kind === 'audiooutput' && d.label.includes(SamAudioService.SAM_OUTPUT_DEVICE_LABEL));
                    let irrOutput = devices.find((d) => d.kind === 'audiooutput' && d.label.includes(SamAudioService.SAM_IRR_OUTPUT_DEVICE_LABEL));
                    let alertOutput = devices.find((d) => d.kind === 'audiooutput' && d.label.includes(SamAudioService.SAM_ALERT_OUTPUT_DEVICE_LABEL));
                    return [callInput, callOutput, irrOutput, alertOutput];
                })
            ).then(([callInput, callOutput, irrOutput, alertOutput]) => {
            this.inputDeviceInterface = new InputDeviceInterface(callInput);
            this.inputDeviceInterface
                .init()
                .then(() => this.mediaService.setLocalMediaStream(this.inputDeviceInterface.mediaStream))
                .then(() => this.store.dispatch(getUserMediaSuccess()));
            this.outputDeviceInterface = new OutputDeviceInterface(callOutput);
            this.outputDeviceInterface
                .init()
                .then(() => this.outputDeviceInterface.setRemoteMediaStream(this.mediaService.remoteMediaStream$.value));
            this.store
                .select(selectUsbAlertOutputToHeadsets)
                .subscribe((alertDevice) => {
                    this.headsetAlertOutputDeviceInterface?.destroy();
                    this.headsetAlertOutputDeviceInterface = undefined;
                    if (alertDevice) {
                        console.debug(`SAM: Sending Alert output to headsets`);
                        this.headsetAlertOutputDeviceInterface = new OutputDeviceInterface(alertOutput);
                        this.headsetAlertOutputDeviceInterface
                            .init()
                            .then(() => this.headsetAlertOutputDeviceInterface.setLocalMediaStreams([this.alertService.mediaStream]))
                            .then(() => (this.headsetAlertOutputDeviceInterface.connected = this.headsetAlertsEnabled$()));
                    }
                });
            this.store
                .select(selectUsbIrrOutputToHeadsets)
                .subscribe((irrDevice) => {
                    this.headsetIrrOutputDeviceInterface?.destroy();
                    this.headsetIrrOutputDeviceInterface = undefined;
                    if (irrDevice) {
                        console.debug(`SAM: Sending IRR output to headsets`);
                        this.headsetIrrOutputDeviceInterface = new OutputDeviceInterface(irrOutput);
                        this.headsetIrrOutputDeviceInterface.init().then(() => this.headsetIrrOutputDeviceInterface.setLocalMediaStreams([this.irrService.mediaStream]));
                    }
                });
        });
        this.mediaService.remoteMediaStream$.subscribe((remoteMediaStream) => this.outputDeviceInterface?.setRemoteMediaStream(remoteMediaStream));
    }

}
