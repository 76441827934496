/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usbFeature, UsbState } from './usb.reducer';
import { selectAudioSettings } from '../../settings/+state/settings.selectors';

export const selectUsbState = createFeatureSelector<UsbState>(usbFeature.name);
export const selectUsbEffectInitialized = createSelector(selectUsbState, (state) => state.effectsInitialized);

export const selectSamAttached = createSelector(selectUsbState, (state) => state.samAttached);
export const selectRtiAttached = createSelector(selectUsbState, (state) => state.rtiAttached);

export const selectUsbAttached = createSelector(selectSamAttached, selectRtiAttached, (samAttached, rtiAttached) => samAttached || rtiAttached);

export const selectUsbDeviceName = createSelector(selectSamAttached, selectRtiAttached, (samAttached, rtiAttached) => samAttached ? 'SAM' : rtiAttached ? 'RTI' : 'Unknown');

export const selectUsbHardware = createSelector(selectUsbState, (state) => state.hardware);
export const selectUsbFirmware = createSelector(selectUsbState, (state) => state.firmware);
export const selectUsbSerial = createSelector(selectUsbState, (state) => state.serial);

export const selectConnectedUsbCallAudioInputs = createSelector(selectUsbState, (state) => state.inputs.filter((input) => input.connected));

export const selectConnectedUsbCallAudioOutputs = createSelector(selectUsbState, (state) => state.outputs.filter((input) => input.connected));

export const selectMuteOnAnswerUsbInputs = createSelector(selectConnectedUsbCallAudioInputs, (inputs) => inputs.filter((input) => input.muteOnAnswer));

export const selectUsbAlertOutputToDevice = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToDevice !== undefined ? audioSettings.alertToDevice : state.alertToDevice);

export const selectUsbAlertOutputToHeadsets = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToHeadsets !== undefined ? audioSettings.alertToHeadsets : state.alertToHeadsets);

export const selectUsbIrrOutputToDevice = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToDevice !== undefined ? audioSettings.irrToDevice : state.irrToDevice);

export const selectUsbIrrOutputToHeadsets = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToHeadsets !== undefined ? audioSettings.irrToHeadsets : state.irrToHeadsets);

export const selectUsbInputs = createSelector(selectUsbState, (state) => state.inputs);
export const selectUsbJackSense = createSelector(selectUsbState, (state) => state.jackSense);

export const selectUsbHeadset1Connected = createSelector(selectUsbJackSense, (jackSense) => Boolean(jackSense['JACKBOX_1']));

export const selectUsbHeadset2Connected = createSelector(selectUsbJackSense, (jackSense) => Boolean(jackSense['JACKBOX_2']));

export const selectUsbRadioHeadsetConnected = createSelector(selectUsbJackSense, (jackSense) => Boolean(jackSense['RADIO_CONSOLE']));

export const selectUsbHeadsetConnected = createSelector(
    selectUsbHeadset1Connected,
    selectUsbHeadset2Connected,
    selectUsbRadioHeadsetConnected,
    (headset1Connected, headset2Connected, radioHeadsetConnected) => headset1Connected || headset2Connected || radioHeadsetConnected
);

export const selectUsbDisconnectedAndTimedOut = createSelector(selectUsbState, (state) => state.headsetDisconnectedAndTimedOut);

export const selectIsUsbAttachedAndHeadsetDisconnected = createSelector(
    selectUsbAttached,
    selectUsbHeadsetConnected,
    (usbAttached, headsetConnected) => usbAttached && !headsetConnected
);
