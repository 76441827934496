/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ControlMessage} from './abstract-control-message';

enum STATE {
  DEFAULT = 0x00
}

enum MODE {
  DEFAULT = 0x00,
  ONLINE_PASSIVE = 0x01,
  ONLINE_ACTIVE = 0x02
}

export class ResetMessage extends ControlMessage {
  public static readonly STATE = STATE;
  public static readonly MODE = MODE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.RESET;
  }

  public withState(state: STATE) {
    this.payload1 = state;
    return this;
  }

  public withMode(app: MODE) {
    this.payload2 = app;
    return this;
  }

  override payload1String(): string {
    return STATE[this.payload1];
  }

  override payload2String(): any {
    return MODE[this.payload2];
  }
}
