/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

export class RestartMessage extends EventMessage {

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.RESTART;
  }
}
