/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { AudioDevicesRoles, AudioDevicesRolesInternal } from 'wam-wrapper';

/* Enumerated values from m-console / skipper */
export enum Headset {
    HS1 = 'Headset 1 (HS1)',
    HS2 = 'Headset 2 (HS2)',
    RD1 = 'Radio 1 (RD1)',
    SYSTEM = 'System'
}

export class Headsets {
    public static deviceMap: Record<Headset, AudioDevicesRoles | AudioDevicesRolesInternal> = {
        [Headset.HS1]: AudioDevicesRoles.HEADSET_1_MIC,
        [Headset.HS2]: AudioDevicesRoles.HEADSET_2_MIC,
        [Headset.RD1]: AudioDevicesRolesInternal.RPI_IN,
        [Headset.SYSTEM]: undefined,
    }
}
