/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { StompService } from "../../core/services/stomp.service";
import { Store } from "@ngrx/store";
import { MessageService } from "./message.service";
import { Message } from "CalltakingCoreApi";
import { deleteSystemErrorMessage, newSystemErrorMessage, updateSystemErrorMessage } from "../+state/message.actions";

@Injectable({
  providedIn: 'root'
})
export class SystemErrorMessageService extends MessageService {

    constructor(private http: HttpClient, private stompService: StompService, private store: Store) {
        super('SYSTEM_ERROR', stompService, store);
    }

    protected effectsInitialized(): void {
    }

    protected websocketInitialized(): void {
    }

    protected deleteMessage(message: Message): void {
        this.store.dispatch(deleteSystemErrorMessage({ message: message }));
    }

    protected editMessage(message: Message): void {
        this.store.dispatch(updateSystemErrorMessage({ message: message }));
    }

    protected newMessage(message: Message): void {
        this.store.dispatch(newSystemErrorMessage({ message: message }));
    }
}
