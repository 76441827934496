/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { defer, of, retry, timer } from 'rxjs';
import {
    fetchAgencySettings,
    fetchAgencySettingsFailure,
    fetchAgencySettingsSuccess,
    fetchCallTableColumnsFailure,
    fetchCallTableColumnsSuccess,
    fetchUserSettings,
    fetchUserSettingsFailure,
    fetchUserSettingsSuccess,
    fetchVersions,
    fetchVersionsFailure,
    fetchVersionsSuccess,
    settingsEffectsInitialized,
    toggleTheme,
    updateAudioSettings,
    updateUserSettings,
    updateUserSettingsFailure,
    updateUserSettingsSuccess
} from './settings.actions';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { fetchLayoutConfigurationSuccess } from '../../configuration/+state/configuration.actions';
import { selectUserSettings } from './settings.selectors';
import { SkipperService } from '../../core/services/skipper.service';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class SettingsEffects implements OnInitEffects {
    constructor(private store: Store, private skipperService: SkipperService, private actions$: Actions) {}

    ngrxOnInitEffects() {
        return settingsEffectsInitialized();
    }

    init$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(settingsEffectsInitialized),
            mergeMap(() => [fetchUserSettings(), fetchVersions()])
        );
    });

    fetchAgencySettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAgencySettings),
            switchMap(() =>
                this.skipperService.fetchSupervisorAgencySettings().pipe(
                    map((settings) => fetchAgencySettingsSuccess({ settings })),
                    catchError((err: Error) => of(fetchAgencySettingsFailure({ payload: err.message })))
                )
            )
        )
    );

    fetchStandaloneCallColumns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUserSettingsSuccess),
            switchMap(() =>
                this.skipperService.fetchStandaloneCallColumns().pipe(
                    map((config) => fetchCallTableColumnsSuccess({ config })),
                    catchError((err: Error) => of(fetchCallTableColumnsFailure({ payload: err?.message })))
                )
            )
        )
    );

    fetchSupervisorLayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLayoutConfigurationSuccess),
            filter((l) => l && l.config?.centralPane?.monitoring),
            map(() => fetchAgencySettings())
        )
    );

    fetchUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUserSettings),
            switchMap(() =>
                defer(() => this.skipperService.fetchUserSettings()).pipe(
                    map((settings) => fetchUserSettingsSuccess({ settings })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchUserSettingsFailure({ payload: err.message })))
                )
            )
        )
    );

    fetchVersionInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchVersions),
            switchMap(() =>
                defer(() => this.skipperService.fetchVersionInformation()).pipe(
                    map((versions) => fetchVersionsSuccess({ versions: versions })),
                    retry({ delay: () => timer(5000) }),
                    catchError((err: Error) => of(fetchVersionsFailure({ payload: err.message })))
                )
            )
        )
    );

    updateUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserSettings),
            mergeMap(({ settings }) =>
                this.skipperService.updateUserSettings(settings).pipe(
                    map(() => updateUserSettingsSuccess({ settings })),
                    catchError((err: Error) => of(updateUserSettingsFailure({ payload: err.message })))
                )
            )
        )
    );

    saveSettingsFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserSettingsFailure),
            map(({ payload }) => displayToastNotification({ message: payload, level: ToastType.error }))
        )
    );

    updateAudioSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAudioSettings),
            concatLatestFrom(() => this.store.select(selectUserSettings)),
            map(([{ settings }, allSettings]) =>
                updateUserSettings({
                    settings: {
                        ...allSettings,
                        audioSettings: { ...allSettings.audioSettings, ...settings }
                    }
                })
            )
        )
    );

    saveTheme$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(toggleTheme),
            concatLatestFrom(() => this.store.select(selectUserSettings)),
            map(([_, settings]) => updateUserSettings({ settings }))
        );
    });
}
