/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum TEST_TONE_FREQUENCY {
  NONE = 0x00,
  SINE_WAVE_404_HZ = 0x01,
  SINE_WAVE_704_HZ = 0x02,
  SINE_WAVE_1004_HZ = 0x03,
  SINE_WAVE_1904_HZ = 0x04,
  SINE_WAVE_2804_HZ = 0x05,
}

enum TEST_TONE_DEVICE {
  ALL = 0x00,
  TELEPHONE = 0x01,
  JACK_BOX_MIC_1 = 0x03,
  JACK_BOX_MIC_2 = 0x04,
  RPI_INPUT = 0x07,
  IRR_PLAYBACK_INPUT = 0x08,
  ALERTS = 0x0a,
  DIGITAL_AUX_AUDIO = 0x0f
}

export class InputTestToneMessage extends ControlMessage {

  public static readonly TEST_TONE_FREQUENCY = TEST_TONE_FREQUENCY;
  public static readonly TEST_TONE_DEVICE = TEST_TONE_DEVICE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.INPUT_TEST_TONE;
  }

  public withTone(tone: TEST_TONE_FREQUENCY) {
    this.payload1 = tone;
    return this;
  }

  public withDevice(device: TEST_TONE_DEVICE) {
    this.payload2 = device;
    return this;
  }

  override payload1String(): string {
    return TEST_TONE_FREQUENCY[this.payload1];
  }

  override payload2String() {
    return TEST_TONE_DEVICE[this.payload2];
  }

}
