/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize } from '@ngrx/store';
import { selectAllUsers, selectBidEntities, selectBidState, userFeature, UserState } from './user.reducer';
import { AgentStatusRecord, UserAuthenticationRecord } from 'CalltakingCoreApi';
import {
    selectAllCallQueues,
    selectClusterConfiguration,
    selectClusterNameToAddressMap,
    selectConfigurationState,
    selectCtcStatusByName,
    selectFilteredCallQueues,
    selectNextPrimaryClusterName,
    selectUiConfiguration
} from '../../configuration/+state/configuration.selectors';
import { SupervisorSettings } from '../../configuration/model/ui-settings';
import { selectFilteredMonitoredRoles } from '../../settings/+state/settings.selectors';
import { QueueMetricObj } from '../../supervisor/queue-metrics/+state/metrics.model';
import { AcdFunctions } from '../util/acd-functions';
import { tableKey } from '../../settings/model/settings.model';
import { CallFunctions } from '../../call/util/call-functions';

export const selectUserState = createFeatureSelector<UserState>(userFeature.name);
export const selectBiddingState = createSelector(selectUserState, selectBidState);

export const selectUsers = createSelector(selectUserState, selectAllUsers);

export const selectUserMap = createSelector(selectUsers, (users) =>
    Object.fromEntries(users.filter((user) => !user.logoutDate).map((user) => [user.username, user]))
);

export function usersAreEqual(a: UserAuthenticationRecord[], b: UserAuthenticationRecord[]): boolean {
    return a?.length === b?.length && a?.every((item) => b?.some((a) => a.uuid === item.uuid));
}

export const usersMemoize = (projectorFn: DefaultProjectorFn<UserAuthenticationRecord[]>) => resultMemoize(projectorFn, usersAreEqual);

export const selectMemoizedUsers = createSelectorFactory(usersMemoize)(selectUsers, (users: UserAuthenticationRecord[]) => users);

// only use for selectors that don't rely on fields that get updated during a session (ie don't use for agentStatus)
export const selectMemoizedUserMap = createSelector(selectMemoizedUsers, (users: UserAuthenticationRecord[]) =>
    Object.fromEntries(users.filter((user) => !user.logoutDate).map((user) => [user.username, user]))
);

export const selectUsername = createSelector(selectUserState, (state) => state.tokenParsed?.preferred_username);
export const selectSub = createSelector(selectUserState, (state) => state.tokenParsed?.sub);
export const selectAgencyName = createSelector(selectUserState, (state) => state.agencyName);
export const selectAgencyFqdn = createSelector(selectUserState, (state) => state.agencyFqdn);
export const selectLoggedInUsers = createSelector(selectUsers, (users) => users.filter((user) => !user.logoutDate));
export const selectMemoizedLoggedInUsers = createSelectorFactory(usersMemoize)(selectLoggedInUsers, (users: UserAuthenticationRecord[]) => users);
export const selectLoggedInUsersWithoutMe = createSelector(selectLoggedInUsers, selectUsername, (users, username) =>
    users.filter((user) => user.username !== username)
);
export const selectMemoizedLoggedInUsersWithoutMe = createSelector(selectMemoizedLoggedInUsers, selectUsername, (users: UserAuthenticationRecord[], username) =>
    users.filter((user) => user.username !== username)
);
export const selectCurrentRole = createSelector(selectUserState, (state) => state.selectedRole);
export const selectFirstName = createSelector(selectUserState, (state) => state.tokenParsed?.given_name);
export const selectLastName = createSelector(selectUserState, (state) => state.tokenParsed?.family_name);
export const selectAgentStatusRecord = createSelector(selectUserState, (state) => state.agentStatus);
export const selectAgentStatus = createSelector(selectAgentStatusRecord, (agentStatusRecord: AgentStatusRecord | null) =>
    agentStatusRecord?.status ? agentStatusRecord.status : 'UNKNOWN'
);
export const selectRequestedAgentStatus = createSelector(selectUserState, (state) => state.requestedAgentStatus);
export const selectAcdIgnoredCalls = createSelector(selectUserState, (state) => state.acdIgnoredCalls);
export const selectPendingBid = createSelector(selectUserState, (state) => state.pendingBid);
export const selectPendingAcdAnswer = createSelector(selectUserState, (state) => state.pendingAcdAnswer);
export const selectHasPendingAcdAnswer = createSelector(selectUserState, (state) => Boolean(state.pendingAcdAnswer));
export const selectBidsMap = createSelector(selectBiddingState, selectBidEntities);
export const selectWrapUpStartTime = createSelector(selectUserState, (state) =>
    state.agentStatus && state.agentStatus.date && state.agentStatus?.status === 'WRAPUP' ? new Date(state.agentStatus.date).getTime() : 0
);
export const selectPendingWrapUpTime = createSelector(selectUserState, (state) => state.pendingWrapUpTime);
export const selectHasPendingWrapUp = createSelector(selectPendingWrapUpTime, (pendingWrapUp) => Boolean(pendingWrapUp));
export const selectNotReadyOnRelease = createSelector(selectUserState, (state) => state.notReadyOnRelease);
export const selectMostRecentlyAnsweredAcdNenaCallId = createSelector(selectUserState, (state) => state.mostRecentlyAnsweredAcdNenaCallId);

export const selectAutoLogoff = createSelector(selectUserState, (state) => state.autoLogoffRequest);
export const selectToken = createSelector(selectUserState, (state) => state.token);
export const selectHasTokenRoles = createSelector(selectUserState, (state) => state.tokenRoles && state.tokenRoles.length && state.tokenRoles.length > 0);
export const selectSelectableRolesWithoutCurrentRole = createSelector(selectCurrentRole, selectConfigurationState, (userCurrentRole, configurationState) =>
    configurationState.selectableRoles.filter((role) => role.roleName !== userCurrentRole)
);

export const selectUserAuthenticationRecord = createSelector(selectUsername, selectUserMap, (username, users) => (username ? users[username] : undefined));

const authRecordMemoize = (projectorFn: DefaultProjectorFn<UserAuthenticationRecord>) => resultMemoize(projectorFn, authRecordIsEqual);

function authRecordIsEqual(a: UserAuthenticationRecord, b: UserAuthenticationRecord): boolean {
    return a?.uuid === b?.uuid;
}

// only use for selectors that don't rely on fields that get updated during a session (ie don't use for agentStatus)
export const selectMemoizedUserAuthenticationRecord = createSelectorFactory<object, UserAuthenticationRecord>(authRecordMemoize)(
    selectUserAuthenticationRecord,
    (authRecord: UserAuthenticationRecord) => authRecord
);

export const selectPosition = createSelector(selectMemoizedUserAuthenticationRecord, (authRecord) => (authRecord ? authRecord.positionNumber : 0));

export const selectUserCallQueueNames = createSelector(selectMemoizedUserAuthenticationRecord, (authRecord) =>
    authRecord ? authRecord.associatedCallQueues : []
);
export const selectSupervisedUserCallQueueNames = createSelector(selectMemoizedUserAuthenticationRecord, (authRecord) =>
    authRecord ? authRecord.supervisedCallQueues : []
);
export const selectUserCallQueues = createSelector(selectUserCallQueueNames, selectAllCallQueues, (myCallQueues, allQueues) =>
    allQueues?.filter((cq) => myCallQueues.includes(cq.name))
);
export const selectUserCallQueueMap = createSelector(selectUserCallQueues, (callQueues) =>
    Object.fromEntries(callQueues.map((callQueue) => [callQueue.name, callQueue]))
);
export const selectAuthRecordCluster = createSelector(selectMemoizedUserAuthenticationRecord, selectClusterConfiguration, (authRecord, clusterConfig) =>
    clusterConfig.find((cluster) => cluster.name === authRecord?.clusterName)
);
export const selectAuthRecordClusterName = createSelector(selectAuthRecordCluster, (cluster) => cluster?.name);

export const selectAuthRecordClusterLabel = createSelector(selectAuthRecordCluster, (cluster) => cluster?.clusterLabel);

export const selectPrimaryCtcStompConnectionProperties = createSelector(selectClusterNameToAddressMap, selectToken, selectNextPrimaryClusterName,
    (clusterAddressMap, token, primaryClusterName) => ({ url: clusterAddressMap[primaryClusterName], token: token }));


export const selectNotReadyOnOutbound = createSelector(selectUserCallQueues, (callQueues) => callQueues.some((cq) => cq.queueACD?.notReadyOnOutbound));

export const selectSupervisorExtension = createSelector(selectUserState, (state) => state.supervisorQueue);
export const selectSupervisorAssistanceSettings = createSelector(
    selectUiConfiguration,
    selectSupervisorExtension,
    (config, extension) =>
        ({
            ...config.supervisorSettings,
            extension: extension
        }) as SupervisorSettings
);

export const selectApplicationPresence = createSelector(selectUserState, (state) => state.applicationPresence);

export const selectUserStateSubscriptions = createSelector(selectUserState, (state) => state.subscriptions);
export const selectUserStateSubscriptionsConfirmed = createSelector(selectUserStateSubscriptions, (subscriptions) =>
    Object.values(subscriptions)
        .flatMap((sub) => Object.values(sub))
        .every((confirmed) => confirmed)
);
export const selectUserStateInitializationTime = (clusterName: string) =>
    createSelector(
        selectUserStateSubscriptionsConfirmed,
        selectApplicationPresence,
        selectCtcStatusByName(clusterName),
        (subscribed, presence, ctcAvailable) => (Boolean(ctcAvailable && subscribed && presence.loggedIn && presence.loginTime) ? presence.loginTime : 0)
    );

const selectUserByUsername = (username: string) => createSelector(selectUserMap, (usersMap) => usersMap[username]);
// only use for selectors that don't rely on fields that get updated during a session (ie don't use for agentStatus)
export const selectMemoizedUserByUsername = (username: string) =>
    createSelectorFactory<object, UserAuthenticationRecord>(authRecordMemoize)(selectUserByUsername(username), (user: UserAuthenticationRecord) => user);

export const selectRole = createSelector(selectUserState, (state) => state.selectedRole);
export const selectPermissions = createSelector(selectUserState, (state) => state.permissions);

export const selectIsTechnician = createSelector(selectPermissions, (permissions) => permissions.includes('call-handling-ui-tech'));

export const selectLanguage = createSelector(selectUserState, (state) => state.language);

export const selectSupportedLanguages = createSelector(selectUserState, (state) => state.supportedLanguages);

export const selectSupervisorFilteredAgents = createSelector(
    selectLoggedInUsers,
    selectFilteredMonitoredRoles,
    selectFilteredCallQueues,
    (users, roleFilter, selectedCallQueues) => users
    .filter((u) => roleFilter.map((r) => r.toLowerCase()).includes(u.preferredRole?.toLowerCase()))
    .filter((u) => u.associatedCallQueues.some((callQueueName) => selectedCallQueues.includes(callQueueName)))
);

export const selectSupervisorFilteredAgentCount = createSelector(
    selectSupervisorFilteredAgents,
    (agents) => agents.length
);

export const selectFilteredAgentsReady = createSelector(selectSupervisorFilteredAgents, (agents) => agents.filter((a) => AcdFunctions.isReady(a.agentStatus)));
export const selectFilteredAgentsBusy = createSelector(selectSupervisorFilteredAgents, (agents) => agents.filter((a) => AcdFunctions.isBusy(a.agentStatus)));
export const selectFilteredAgentsOnACDCall = createSelector(selectSupervisorFilteredAgents, (agents) => agents.filter((a) => AcdFunctions.isOnCall(a.agentStatus)));
export const selectFilteredAgentsUnavailable = createSelector(selectSupervisorFilteredAgents, (agents) =>
    agents.filter((a) => AcdFunctions.isUnavailable(a.agentStatus))
);
export const selectFilteredAgentsNotReady = createSelector(selectSupervisorFilteredAgents, (agents) => agents.filter((a) => AcdFunctions.isNotReady(a.agentStatus)));

export const selectFilteredAgentsReadyCount = createSelector(selectFilteredAgentsReady, (agents) => agents.length);
export const selectFilteredAgentsBusyCount = createSelector(selectFilteredAgentsBusy, (agents) => agents.length);
export const selectFilteredAgentsLoggedInCount = createSelector(selectSupervisorFilteredAgents, (agents) => agents.length);
export const selectFilteredAgentsOnACDCallCount = createSelector(selectFilteredAgentsOnACDCall, (agents) => agents.length);
export const selectFilteredAgentsNotReadyCount = createSelector(selectFilteredAgentsNotReady, (agents) => agents.length);
export const selectFilteredAgentsUnavailableCount = createSelector(selectFilteredAgentsUnavailable, (agents) => agents.length);

export const selectLongestAgentReady = createSelector(selectFilteredAgentsReady, (agents) => CallFunctions.getOldestTimestamp(agents));
export const selectLongestAgentBusy = createSelector(selectFilteredAgentsBusy, (agents) => CallFunctions.getOldestTimestamp(agents));
export const selectLongestAgentNotReady = createSelector(selectFilteredAgentsNotReady, (agents) => CallFunctions.getOldestTimestamp(agents));

export const selectSupervisorAgentSummaryData = createSelector(
    selectFilteredAgentsReadyCount,
    selectFilteredAgentsBusyCount,
    selectFilteredAgentsLoggedInCount,
    selectFilteredAgentsOnACDCallCount,
    selectFilteredAgentsNotReadyCount,
    selectFilteredAgentsUnavailableCount,
    selectLongestAgentReady,
    selectLongestAgentBusy,
    selectLongestAgentNotReady,
    (
        agentsReady,
        agentsBusy,
        agentsLoggedIn,
        agentsOnAcdCall,
        agentsNotReady,
        agentsUnavailable,
        longestAgentReady,
        longestAgentBusy,
        longestAgentNotReady
    ) => {
        return {
            agentsBusy,
            agentsLoggedIn,
            agentsReady,
            agentsOnAcdCall,
            agentsUnavailable,
            agentsNotReady,
            longestAgentReady,
            longestAgentBusy,
            longestAgentNotReady
        } as Partial<QueueMetricObj>;
    }
);

export const selectFilteredAgentSummaryAsList = createSelector(
    selectSupervisorAgentSummaryData,
    (summary) => [{ uuid: 'SUMMARY_AGENTS' as tableKey, ...summary }] as QueueMetricObj[]
);

export const selectUserCount = createSelector(selectSupervisorFilteredAgents, (users) => users.length);

export const selectUserCanOutboundDial = createSelector(selectPermissions, (permissions) => permissions.includes('outbound-dial'));
export const selectUserCanOutboundRedial = createSelector(selectPermissions, (permissions) => permissions.includes('outbound-redial'));
