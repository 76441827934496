/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum AUX_OUTPUT {
  NONE = 0x00,
  WORKSTATION_DIGITAL_AUDIO = 0x03
}

export class AuxAudioMessage2 extends ControlMessage {
  public static readonly AUX_OUTPUT = AUX_OUTPUT;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.AUX_AUDIO;
    this.payload1 = 0x00;
  }

  public withOutput(activity: AUX_OUTPUT) {
    this.payload2 = activity;
    return this;
  }

  override payload2String(): string {
    return AUX_OUTPUT[this.payload2];
  }
}
