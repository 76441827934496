/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum INPUT {
    NONE = 0x00,
    TELEPHONE_INPUT_USB = 0x01,
    TELEPHONE_INPUT_ANALOG = 0x02,
    JACKBOX_MIC_1 = 0x03,
    JACKBOX_MIC_2 = 0x04,
    JACKBOX_MIC_3 = 0x05,
    RADIO_HEADSET_INPUT = 0x06,
    CALL_DIRECTOR_INPUT = 0x07,
    PLAYBACK_INPUT_USB = 0x08,
    PLAYBACK_INPUT_ANALOG = 0x09,
    SYSTEM_SOUNDS = 0x0a,
    DESK_MIC_INPUT = 0x0b,
    AUX_RADIO_INPUT = 0x0c,
    ANALOG_AUX_AUDIO_1 = 0x0d,
    ANALOG_AUX_AUDIO_2 = 0x0e,
    DIGITAL_AUX_AUDIO = 0x0f,
    SELECT_RADIO = 0x10,
    SELECT_TELEPHONE = 0x11,
    UNSELECT_1_AUDIO = 0x12,
    UNSELECT_2_AUDIO = 0x13,
    UNSELECT_3_AUDIO = 0x14,
    UNSELECT_4_AUDIO = 0x15,
    UNSELECT_5_AUDIO = 0x16,
    UNSELECT_6_AUDIO = 0x17,
    UNSELECT_7_AUDIO = 0x18,
    UNSELECT_8_AUDIO = 0x19,
    UNSELECT_9_AUDIO = 0x1a,
    UNSELECT_10_AUDIO = 0x1b,
    UNSELECT_11_AUDIO = 0x1c,
    UNSELECT_12_AUDIO = 0x1d,
    UNSELECT_13_AUDIO = 0x1e,
    UNSELECT_14_AUDIO = 0x1f,
    RADIO_PATCH_INPUT = 0x20
}
enum MEASUREMENT {
    STOP = 0x00,
    ONCE = 0x01,
    REPEAT = 0x02
}
export class TestLevelMessage extends ControlMessage {
    public static readonly INPUT = INPUT;
    public static readonly MEASUREMENT = MEASUREMENT;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.TEST_LEVEL;
    }

    public withMeasurement(measurement: MEASUREMENT) {
        this.payload1 = measurement;
        return this;
    }

    public withInput(input: INPUT) {
        this.payload2 = input;
        return this;
    }

    override payload1String(): string {
        return MEASUREMENT[this.payload1];
    }

    override payload2String(): string {
        return INPUT[this.payload2];
    }
}
