/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { selectClusterNameToAddressMap } from '../../configuration/+state/configuration.selectors';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ClusterUrlProviderService implements OnDestroy {
    public _clusterMap: { [clusterName: string]: string } = {};
    private unsubscribe$ = new Subject<void>();

    constructor(
        private env: EnvironmentService,
        private store: Store,
        protected http: HttpClient
    ) {
        this.store
            .select(selectClusterNameToAddressMap)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((clusterMap) => {
                this._clusterMap = clusterMap;
            });
    }

    get clusterMap() {
        return this._clusterMap;
    }

    public getClusterSpecificUrl(clusterName: string, path?: string): string {
        return `${this.clusterMap[clusterName]}${path || ''}`;
    }

    //@deprecated
    public getCtcUrl(): string {
        return this.env.environment.CTC_URL ? this.env.environment.CTC_URL : '';
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
