/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

enum DEVICES {
  NONE = 0x00,
  JACKBOX_ONLY = 0x01,
  RPI_ONLY = 0x02,
  JACKBOX_AND_RPI = 0x03
}

export class AuxAudioMessage extends ConfigurationMessage {
  public static readonly DEVICES = DEVICES;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.AUX_AUDIO;
  }

  public withDevices(devices: DEVICES) {
    this.payload1 = devices;
    return this;
  }

  override payload1String(): string {
      return DEVICES[this.payload1];
  }
}
