/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { BehaviorSubject, filter, firstValueFrom, fromEvent, map, mergeScan, Observable, of, ReplaySubject, shareReplay, Subject, takeUntil } from 'rxjs';
import { MessageMapper } from '../util/message-mapper';
import { skip, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { acgEvent, jackSenseEvent, muteEvent, thresholdEvent, usbDeviceInfoEvent, volumeEvent } from '../../+state/usb.actions';
import { selectHasActiveVoiceCall } from '../../../call/+state/call.selectors';
import { HeartbeatMessage } from '../model/report-1/event/heartbeat-message';
import { RegisterMessage } from '../model/report-1/control/register-message';
import { DeviceInfoMessage } from '../model/report-1/control/device-info-message';
import { PhoneSourceMessage } from '../model/report-1/configuration/phone-source-message';
import { ArbitrationModeMessage } from '../model/report-1/configuration/arbitration-mode-message';
import { ResetMessage } from '../model/report-1/control/reset-message';
import { JackSenseMessage } from '../model/report-1/event/jack-sense-message';
import { ConfigurationQueryMessage } from '../model/report-1/control/configuration-query-message';
import { StateQueryMessage } from '../model/report-1/control/state-query-message';
import { OutputReport1 } from '../model/report-1/output-report-1';
import { InputReport1 } from '../model/report-1/input-report-1';
import { InputReport2 } from '../model/report-2/input-report-2';
import { EventMessage } from '../model/report-1/event/abstract-event-message';
import { DeviceIdentificationMessage } from '../model/report-2/device-identification-message';
import { JackboxMuteMessage } from '../model/report-1/control/jackbox-mute-message';
import { VolumeMessage } from '../model/report-1/control/volume-message';
import { AGCMessage } from '../model/report-1/configuration/agc-message';
import { ThresholdMessage } from '../model/report-1/control/threshhold-message';
import { PhoneActivityMessage } from '../model/report-1/control/phone-activity-message';
import { OutputReport2 } from '../model/report-2/output-report-2';
import { Report1 } from '../model/report-1/report-1';
import { AlertsMessage } from '../model/report-1/configuration/alerts-message';
import { IrrPlaybackPermissionsMessage } from '../model/report-1/control/irr-playback-permissions-message';
import { PlaybackAudioMessage } from '../model/report-1/control/playback-audio-message';
import { selectUsbHeadset1Connected, selectUsbHeadset2Connected, selectUsbRadioHeadsetConnected } from '../../+state/usb.selectors';
import { displayToastNotification } from '../../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { QueryResponseStatusMessage } from '../model/report-1/event/query-response-status-message';

export abstract class UsbBaseService {
    public abstract readonly VENDOR_ID: number;
    public abstract readonly PRODUCT_ID: number;
    public abstract readonly DEVICE_NAME: string;

    protected device!: HIDDevice;
    protected unsubscribe$ = new Subject<void>();
    protected heartbeatMessage = new HeartbeatMessage()
        .withApp(HeartbeatMessage.APP_REGISTRATION.TELEPHONY_APP)
        .withState(HeartbeatMessage.OPERATIONAL_STATE.ONLINE_ACTIVE)
        .withTestMode(HeartbeatMessage.TEST_MODE.OFF);
    protected registerMessage = new RegisterMessage()
        .withApp(RegisterMessage.APP_REGISTRATION.TELEPHONY_APP)
        .withSession(RegisterMessage.SESSION.BEGIN);
    protected versionMessage = new DeviceInfoMessage();
    protected phoneSourceMessage = new PhoneSourceMessage()
        .withAudioSource(PhoneSourceMessage.AUDIO_SOURCE.WORKSTATION);
    protected arbitrationMessage = new ArbitrationModeMessage()
        .withArbitration(ArbitrationModeMessage.ARBITRATION.TELEPHONE_ONLY);
    protected alertSoundsMessage = new AlertsMessage()
        .withSounds(AlertsMessage.ALERT_SOUNDS.JACKBOX_WHEN_IDLE_AND_BUSY);
    protected playbackMessage = new PlaybackAudioMessage()
        .withState(PlaybackAudioMessage.STATE.END);
    protected playbackPermissionMessage = new IrrPlaybackPermissionsMessage()
        .withState(IrrPlaybackPermissionsMessage.STATE.JACK_BOX_PERMITTED);
    protected unRegisterMessage = new RegisterMessage()
        .withApp(RegisterMessage.APP_REGISTRATION.TELEPHONY_APP)
        .withSession(RegisterMessage.SESSION.END);
    protected resetMessage = new ResetMessage()
        .withMode(ResetMessage.MODE.ONLINE_ACTIVE)
        .withState(ResetMessage.STATE.DEFAULT);
    protected jackSense1Message = new JackSenseMessage()
        .forDevice(JackSenseMessage.DEVICE_ID.JACKBOX_1);
    protected jackSense2Message = new JackSenseMessage()
        .forDevice(JackSenseMessage.DEVICE_ID.JACKBOX_2);
    protected jackSenseRadioConsoleMessage  = new JackSenseMessage()
        .forDevice(JackSenseMessage.DEVICE_ID.RADIO_CONSOLE);
    protected configurationQueryMessage  = new ConfigurationQueryMessage()
        .withItem(ConfigurationQueryMessage.ITEM.ALL);
    protected stateQueryMessage  = new StateQueryMessage()
        .withItem(StateQueryMessage.ITEM.ALL);
    public sentMessage$ = new ReplaySubject<OutputReport1>(100);
    public sentMessageLog$ = new Observable<OutputReport1[]>();
    public receivedMessageLog$ = new Observable<InputReport1[]>();
    public showHeartbeats: boolean;
    public initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deviceEvents$: Observable<InputReport1>;

    protected readonly gestureListener = () => this.requestDevice();

    protected constructor(protected store: Store) {
    }

    protected init() {
        navigator.hid
            .getDevices()
            .then((devices) => (this.device = devices.find((d) => d.vendorId === this.VENDOR_ID && d.productId === this.PRODUCT_ID) as HIDDevice))
            .then(() => this.checkDevicePermission())
            .catch((e) => console.error(e));
        window.addEventListener('unload', () => this.disconnect());
    }

    //TODO look for audio device first, otherwise it pops empty list
    public requestDevice() {
        navigator.hid
            .requestDevice({ filters: [{ vendorId: this.VENDOR_ID, productId: this.PRODUCT_ID }] })
            .then((device) => (this.device = device[0]))
            .then(() => window.removeEventListener('click', this.gestureListener))
            .then(() => this.connect())
            .catch((e) => console.error(e));
    }
    public connect() {
        console.debug(`${this.DEVICE_NAME}: connect`);
        if (this.device && !this.device.opened) {
            this.device
                .open()
                .then(() => console.debug('opening device: ', this.device))
                .then(() => this.monitorConnectionStatus())
                .then(() => this.monitor())
                .catch((e) => console.error(e));
        }
    }
    private monitorConnectionStatus() {
        console.debug(`${this.DEVICE_NAME}: monitorConnectionStatus: callbacks added`);
        navigator.hid.addEventListener('connect', (e) => {
            console.debug(`${e.device.productName} connected`);
            if (e.device.vendorId === this.VENDOR_ID && e.device.productId === this.PRODUCT_ID) {
                this.checkDevicePermission();
            }
        });
        navigator.hid.addEventListener('disconnect', (e) => {
            console.warn(`${e.device.productName} disconnected`);
            if (e.device.vendorId === this.VENDOR_ID && e.device.productId === this.PRODUCT_ID) {
                this.disconnect();
            }
        });
    }

    public checkDevicePermission() {
        if (!this.device) {
            this.waitForUserGesture();
        } else {
            this.connect();
        }
    }

    disconnect() {
        console.debug(`${this.DEVICE_NAME}: disconnect`);
        this.unsubscribe$.next();
        this.unregister();
        this.sendReport1(new ResetMessage()
            .withState(ResetMessage.STATE.DEFAULT)
            .withMode(ResetMessage.MODE.DEFAULT));
        this.device
            .close()
            .then(() => console.debug(`Disconnected from ${this.DEVICE_NAME}`))
            .catch((e) => console.error(e));
    }

    public waitForUserGesture() {
        window.addEventListener('click', this.gestureListener);
    }

    public monitor() {
        console.debug(`${this.DEVICE_NAME}: monitor`);
        console.debug(`monitoring ${this.DEVICE_NAME} events`);
        let events$ = fromEvent(this.device, 'inputreport').pipe(
            map((event) => event as HIDInputReportEvent),
            filter(({ device }) => device.vendorId == this.VENDOR_ID && device.productId == this.PRODUCT_ID),
            map((event) => MessageMapper.fromReportEvent(event)),
            takeUntil(this.unsubscribe$)
        );
        this.deviceEvents$ = events$.pipe(
            filter((report) => report.reportId === 1),
            map((report) => report as InputReport1)
        );

        let inputReport2Events$ = events$.pipe(
            filter((report) => report.reportId === 2),
            map((report) => report as InputReport2),
            tap((message) => this.logReport2(message))
        );
        inputReport2Events$.subscribe();

        // respond to heartbeats
        this.deviceEvents$
            .pipe(filter((message) => message.type === OutputReport1.MessageType.EVENT && message.id === EventMessage.EventMessageIdentifier.HEARTBEAT))
            .subscribe(() => this.sendReport1(this.heartbeatMessage));

        // log events
        this.sentMessageLog$ = this.sentMessage$.pipe(
            filter((message) => this.showHeartbeats || !(message instanceof HeartbeatMessage)),
            tap((message) => this.logReport1(message, true)),
            mergeScan((acc: OutputReport1[], message: OutputReport1) => of([...acc, message]), []),
            shareReplay(1)
        );
        this.sentMessageLog$.subscribe();
        this.receivedMessageLog$ = this.deviceEvents$.pipe(
            filter((message) => this.showHeartbeats || !(message instanceof HeartbeatMessage)),
            tap((message) => this.logReport1(message, false)),
            mergeScan((acc: InputReport1[], message: InputReport1) => of([...acc, message]), []),
            shareReplay(1)
        );
        this.receivedMessageLog$.subscribe();

        inputReport2Events$
            .pipe(
                filter((message) => message instanceof DeviceIdentificationMessage),
                map((message) => message as DeviceIdentificationMessage)
            )
            .subscribe((message) =>
                this.store.dispatch(usbDeviceInfoEvent({ hardware: message.hardware, firmware: message.firmware, serial: message.serial })));

        this.deviceEvents$
            .pipe(filter((message) => message instanceof JackSenseMessage))
            .subscribe((message) =>
                this.store.dispatch(jackSenseEvent({ msg: { [message.payload1String()]: message.payload2String() === 'CONNECTED' } })));

        this.deviceEvents$
            .pipe(filter((message) => message instanceof JackboxMuteMessage))
            .subscribe((message) =>
                this.store.dispatch(muteEvent({ msg: { [message.payload1String()]: message.payload2String() === 'MUTE' } })));

        this.deviceEvents$
            .pipe(filter((message) => message instanceof VolumeMessage))
            .subscribe((message) =>
                this.store.dispatch(volumeEvent({ msg: { [message.payload2String()]: Number(message.payload3String()) } })));

        this.deviceEvents$
            .pipe(filter((message) => message instanceof AGCMessage))
            .subscribe((message) =>
                this.store.dispatch(acgEvent({ msg: (message as AGCMessage).sources })));

        this.deviceEvents$
            .pipe(filter((message) => message instanceof ThresholdMessage))
            .subscribe((message) =>
                this.store.dispatch(thresholdEvent({ msg: { [message.payload2String()]: Number(message.payload3String()) } })));



        this.makeActive()
            .then(() => this.monitorOffHook())
            .then(() => this.monitorHeadsets())
            .then(() => this.initialized.next(true));
    }

    private monitorOffHook() {
        this.store.select(selectHasActiveVoiceCall).subscribe((onCall) =>
            this.sendReport1(new PhoneActivityMessage()
                .withActivity(onCall ? PhoneActivityMessage.ACTIVITY.ON_CALL : PhoneActivityMessage.ACTIVITY.NOT_ON_CALL))
        );
    }

    public makeActive() {
        let queryEnd = new QueryResponseStatusMessage()
            .withQueryStatus(QueryResponseStatusMessage.QUERY_STATUS.END_OF_QUERY);

        return this.sendSynchronousReport1(this.resetMessage, this.resetMessage)
            .then(() => this.sendSynchronousReport1(this.configurationQueryMessage, queryEnd))
            .then(() => this.sendSynchronousReport1(this.stateQueryMessage, queryEnd))
            .then(() => this.sendReport1(this.versionMessage))
            .then(() => this.sendReport1(this.registerMessage))
            .then(() => this.sendReport1(this.arbitrationMessage))
            .then(() => this.sendReport1(this.phoneSourceMessage))
            .then(() => this.sendReport1(this.alertSoundsMessage))
            .then(() => this.sendReport1(this.playbackMessage))
            .then(() => this.sendReport1(this.playbackPermissionMessage))
            .then(() => this.sendReport1(this.jackSense1Message))
            .then(() => this.sendReport1(this.jackSense2Message))
            .then(() => this.sendReport1(this.jackSenseRadioConsoleMessage));
    }

    public unregister() {
        this.sendReport1(this.unRegisterMessage)
            .then(() => console.debug(`${this.DEVICE_NAME}: Unregistered`));
    }

    public sendReport1(message: OutputReport1) {
        return this.device?.sendReport(1, message)
            .then(() => this.sentMessage$.next(message))
            .catch((e) => console.error('Failed to send message: ', e));
    }

    public sendSynchronousReport1(message: OutputReport1, expectedResponse: InputReport1) {
        return Promise.all([
            this.device.sendReport(1, message)
                .then(() => this.sentMessage$.next(message))
                .catch((e) => console.error('Failed to send message: ', e)),
            firstValueFrom(this.deviceEvents$.pipe(
                filter((message) =>
                    message.id === expectedResponse.id &&
                    message.payload1String() === expectedResponse.payload1String() &&
                    message.payload2String() === expectedResponse.payload2String() &&
                    message.payload3String() === expectedResponse.payload3String())
                )
            )
        ]).catch((e) => console.error('Failed to send synchronous message: ', e));
    }

    public sendReport2(message: OutputReport2) {
        return this.device.sendReport(2, message)
            .catch((e) => console.error('Failed to send report 2 message: ', e));
    }

    public logReport1(message: Report1, sent: boolean) {
        if (!(message.type === OutputReport1.MessageType.EVENT && (message.id === EventMessage.EventMessageIdentifier.HEARTBEAT || message.id === EventMessage.EventMessageIdentifier.FW_RELOAD_PROGRESS))) {
            console.debug(`${sent ?  'Sent: ' : 'Received: '} ${message.logMessage()}`);
        }
    }

    public logReport2(message: InputReport2) {
        console.debug(message.logMessage());
    }

    private monitorHeadsets() {
        this.store
            .select(selectUsbHeadset1Connected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Headset 1 Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Headset 1 Disconnected.' }))
            );
        this.store
            .select(selectUsbHeadset2Connected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Headset 2 Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Headset 2 Disconnected.' }))
            );
        this.store
            .select(selectUsbRadioHeadsetConnected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Radio Headset Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Radio Headset Disconnected.' }))
            );
    }
}
