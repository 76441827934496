/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

enum APP_REGISTRATION {
  NONE = 0x00,
  TEST_APP = 0x01,
  TELEPHONY_APP = 0x02,
  RADIO_APP = 0x04,
  GENERIC_APP = 0X08,
  IRR_APP = 0x10
}

enum OPERATIONAL_STATE {
  OFFLINE = 0x00,
  ONLINE_PASSIVE = 0x01,
  ONLINE_ACTIVE = 0x02
}

enum TEST_MODE {
  OFF = 0x00,
  ON = 0x01
}

export class HeartbeatMessage extends EventMessage {
  public static readonly APP_REGISTRATION = APP_REGISTRATION;
  public static readonly OPERATIONAL_STATE = OPERATIONAL_STATE;
  public static readonly TEST_MODE = TEST_MODE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.HEARTBEAT;
  }

  public withApp(app: APP_REGISTRATION) {
    this.payload1 = app;
    return this;
  }

  public withState(state: OPERATIONAL_STATE) {
    this.payload2 = state;
    return this;
  }

  public withTestMode(mode: TEST_MODE) {
    this.payload3 = mode;
    return this;
  }

  override payload1String(): string {
    return APP_REGISTRATION[this.payload1];
  }

  override payload2String() {
    return OPERATIONAL_STATE[this.payload2];
  }

  override payload3String() {
    return TEST_MODE[this.payload3];
  }
}
