/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { NotificationEffects } from './+state/notification.effects';

@NgModule({
    imports: [],
    providers: [
        provideEffects([NotificationEffects])
    ]
})
export class NotificationModule {}
