/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import {v5} from "uuid";

export class ChatFunctions {

    public static readonly CHAT_ID_NAMESPACE = '57d2e9a5-15c6-402e-9ad2-1584564fdc00';

    public static generateUUID(input: string) {
        return v5(input, ChatFunctions.CHAT_ID_NAMESPACE);
    }
}
