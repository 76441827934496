/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mainFeature, MainState } from './main.reducer';
import { selectActiveCallId, selectObservedUser, selectOpenCalls } from '../../call/+state/call.selectors';
import { selectCallListTabEnabled, selectHasInitialized, selectSupervisorTabEnabled } from '../../configuration/+state/configuration.selectors';
import { selectConnectionStatus, selectHasUserMedia, selectHasWebsocketConnection, selectIsPhoneRegistered } from '../../call/+state/media.selectors';
import {
    selectCCHubAttached,
    selectCCHubConnected,
    selectCCHubHeadsetConnected,
    selectCCHubInitialized,
    selectCCHubState,
    selectHeadsetDisconnectedAndTimedOut
} from '../../call/+state/cchub.selectors';
import { selectPosition } from '../../user/+state/user.selectors';
import { selectUsbAttached, selectUsbDisconnectedAndTimedOut, selectUsbHeadsetConnected } from '../../usb/+state/usb.selectors';

export interface ApplicationState {
    initialized: boolean;
    upgrading: boolean;
    position: number;
    media: boolean;
    websocket: boolean;
    registered: boolean;
    usbAttached: boolean;
    usbHeadsetConnected: boolean;
    usbDisconnectedAndTimedOut: boolean;
    ccHubAttached: boolean;
    ccHubConnected: boolean;
    ccHubHeadsetConnected: boolean;
    ccHubDisconnectedAndTimedOut: boolean;
    ccHubInitialized: boolean;
    activeCall: boolean;
}

export const selectMainState = createFeatureSelector<MainState>(mainFeature.name);

export const selectTabIndex = createSelector(selectMainState, (state) => state.tabIndex);
export const selectOpenTabs = createSelector(selectMainState, (state) => state.openTabs);

export const selectCurrentSelectedTab = createSelector(selectTabIndex, selectOpenTabs, (index, openTabs) => openTabs[index]);

export const selectEmptyObserverTabOpen = createSelector(selectObservedUser, (observedAgent) => Boolean(observedAgent));
export const selectMonitoringTabAvailable = createSelector(selectSupervisorTabEnabled, (supervisorViewEnabled) => !!supervisorViewEnabled);
export const selectMonitoringTabSelected = createSelector(
    selectMonitoringTabAvailable,
    selectCurrentSelectedTab,
    (tabAvailable, selectedTab) => !!tabAvailable && selectedTab === 'supervisor'
);

export const selectMakeCallTabShown = createSelector(
    selectMonitoringTabAvailable,
    selectCallListTabEnabled,
    (supervisor, dispatcher) => !supervisor && !dispatcher
);

export const selectComputeSelectedCallId = createSelector(
    selectCurrentSelectedTab,
    selectOpenCalls,
    (selectedTab, myOpenCalls) => myOpenCalls.find((call) => call.uuid === selectedTab)?.uuid
);

export const selectFirmwareUpgrading = createSelector(selectCCHubState, (state) => state.firmwareUpgrading);

export const selectApplicationState = createSelector(
    selectCCHubHeadsetConnected,
    selectPosition,
    selectHasUserMedia,
    selectHasWebsocketConnection,
    selectIsPhoneRegistered,
    selectCCHubAttached,
    selectCCHubConnected,
    selectCCHubInitialized,
    selectHeadsetDisconnectedAndTimedOut,
    selectHasInitialized,
    selectFirmwareUpgrading,
    selectConnectionStatus,
    selectActiveCallId,
    selectUsbAttached,
    selectUsbHeadsetConnected,
    selectUsbDisconnectedAndTimedOut,
    (ccHubHeadsetConnected, position, media, websocket, registered, ccHubAttached, ccHubConnected, ccHubInitialized, ccHubDisconnectedAndTimedOut, initialized, upgrading, connectionState, activeCallId, usbAttached, usbHeadsetConnected, usbDisconnectedAndTimedOut) => {
        return {
            initialized,
            upgrading,
            position,
            media,
            websocket,
            registered,
            usbAttached,
            usbHeadsetConnected,
            usbDisconnectedAndTimedOut,
            ccHubAttached,
            ccHubConnected,
            ccHubHeadsetConnected,
            ccHubDisconnectedAndTimedOut,
            ccHubInitialized,
            connectionState,
            activeCall: !!activeCallId
        } as ApplicationState;
    }
);
