/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

enum INPUT_ID {
  JACKBOX_1_JACKSENSE = 0x01,
  JACKBOX_2_JACKSENSE = 0x02,
  RADIO_HEADSET_PRESENCE = 0x10
}

enum INPUT_ACTIVE {
  INACTIVE = 0x00,
  ACTIVE = 0x01
}

export class InputStateMessage extends EventMessage {
  public static readonly INPUT_ID = INPUT_ID;
  public static readonly INPUT_ACTIVE = INPUT_ACTIVE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.INPUT;
  }

  public forInput(device: INPUT_ID) {
    this.payload1 = device;
    return this;
  }

  override payload1String(): string {
    return INPUT_ID[this.payload1];
  }

  override payload2String(): any {
    return INPUT_ACTIVE[this.payload2];
  }
}
