/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum FROM_INPUT {
    NONE = 0x00,
    TELEPHONE_INPUT_USB = 0x01,
    TELEPHONE_INPUT_ANALOG = 0x02,
    JACKBOX_MIC_1 = 0x03,
    JACKBOX_MIC_2 = 0x04,
    JACKBOX_MIC_3 = 0x05,
    RADIO_HEADSET_INPUT = 0x06,
    CALL_DIRECTOR_INPUT = 0x07,
    PLAYBACK_INPUT_USB = 0x08,
    PLAYBACK_INPUT_ANALOG = 0x09,
    SYSTEM_SOUNDS = 0x0a,
    DESK_MIC_INPUT = 0x0b,
    AUX_RADIO_INPUT = 0x0c,
    ANALOG_AUX_AUDIO_1 = 0x0d,
    ANALOG_AUX_AUDIO_2 = 0x0e,
    DIGITAL_AUX_AUDIO = 0x0f,
    SELECT_RADIO = 0x10,
    SELECT_TELEPHONE = 0x11,
    UNSELECT_1_AUDIO = 0x12,
    UNSELECT_2_AUDIO = 0x13,
    UNSELECT_3_AUDIO = 0x14,
    UNSELECT_4_AUDIO = 0x15,
    UNSELECT_5_AUDIO = 0x16,
    UNSELECT_6_AUDIO = 0x17,
    UNSELECT_7_AUDIO = 0x18,
    UNSELECT_8_AUDIO = 0x19,
    UNSELECT_9_AUDIO = 0x1a,
    UNSELECT_10_AUDIO = 0x1b,
    UNSELECT_11_AUDIO = 0x1c,
    UNSELECT_12_AUDIO = 0x1d,
    UNSELECT_13_AUDIO = 0x1e,
    UNSELECT_14_AUDIO = 0x1f,
    RADIO_PATCH_INPUT = 0x20
}
enum TO_OUTPUT {
    NONE = 0x00,
    TELEPHONE_OUTPUT_USB = 0x01,
    TELEPHONE_OUTPUT_ANALOG = 0x02,
    JACKBOX_EAR_1 = 0x03,
    JACKBOX_EAR_2 = 0x04,
    JACKBOX_EAR_3 = 0x05,
    RADIO_HEADSET_OUTPUT = 0x06,
    CALL_DIRECTOR_OUTPUT = 0x07,
    DUBBING_RECORDER_OUTPUT = 0x08,
    USB_IRR_1 = 0x09,
    USB_IRR_2 = 0x0a,
    UNUSED = 0x0b,
    ANALOG_IRR = 0x0c,
    LONG_TERM_RECORDER = 0x0d,
    INTERNAL_SPEAKER = 0x0e,
    SELECT_RADIO_SPEAKER = 0x0f,
    SELECT_TELEPHONE_SPEAKER = 0x10,
    UNSELECT_1_SPEAKER = 0x11,
    UNSELECT_2_SPEAKER = 0x12,
    RADIO_TRANSMIT = 0x13,
    RADIO_PATCH_OUTPUT = 0x14
}
export class TestLoopbackMessage extends ControlMessage {
    public static readonly FROM_INPUT = FROM_INPUT;
    public static readonly TO_OUTPUT = TO_OUTPUT;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.TEST_LOOPBACK;
    }

    public from(input: FROM_INPUT) {
        this.payload1 = input;
        return this;
    }

    public to(output: TO_OUTPUT) {
        this.payload2 = output;
        return this;
    }

    override payload1String(): string {
        return FROM_INPUT[this.payload1];
    }

    override payload2String(): string {
        return TO_OUTPUT[this.payload2];
    }
}
