/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { provideStore } from '@ngrx/store';
import { NotificationModule } from './app/notification/notification.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
    AppSpecificConfigToken,
    CommandCentralHeaderComponentsModule,
    HeaderComponentModule,
    IamAuthGrantConfigToken,
    UserAuthenticationModule
} from '@msi/commandcentral-common-header';
import { EnvironmentService } from './app/core/services/environment.service';
import { KeycloakOidcProviderService } from './app/core/services/keycloak-oidc-provider.service';
import { SessionGuardService } from './app/session-guard.service';
import { AppInitializer } from './app/core/util/app-initializer';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorToastInterceptor } from './app/interceptors/error-toast.interceptor';
import { KeycloakBearerInterceptor } from './app/interceptors/keycloak-bearer-interceptor';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './app/core/window/directives/custom-overlay-container';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [
        provideStore(),
        environment.providers,
        importProvidersFrom(
            BrowserModule,
            AppRoutingModule,
            HotkeyModule.forRoot(),
            NotificationModule,
            // Security
            KeycloakAngularModule,
            ServiceWorkerModule.register('msi-service-worker.js'),
            UserAuthenticationModule,
            HeaderComponentModule,
            CommandCentralHeaderComponentsModule
        ),
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [EnvironmentService, KeycloakService, KeycloakOidcProviderService, SessionGuardService],
            useFactory: AppInitializer
        },
        {
            provide: IamAuthGrantConfigToken,
            useValue: {
                scopes: ['CCAdmin'],
                clientId: 'ccch_calltaking_ui',
                singlePreprod: true,
                postLogoutRedirectUrl: window.location.origin
            }
        },
        {
            provide: AppSpecificConfigToken,
            useValue: {
                scopes: ['CCAdmin'],
                clientId: 'ccch_calltaking_ui',
                handleLegacyAdmin: false,
                developmentMode: !environment.production,
                singlePreprod: true,
                postLogoutRedirectUrl: window.location.origin
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorToastInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakBearerInterceptor,
            multi: true
        },
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: RetryInterceptor,
        //     multi: true
        // }
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
};
