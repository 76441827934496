/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { EventMessage } from './abstract-event-message';


export class EventErrorMessage extends EventMessage {

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.ERROR;
  }

  override payload1String(): string {
    return EventMessage.EventMessageIdentifier[this.payload1];
  }
}
