/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { acdStatusChange, requestedAcdStatusChange } from '../+state/user.actions';
import { selectComputedAgentStatus } from '../../call/+state/call.selectors';
import { AgentStatus, AgentStatusRecord } from 'CalltakingCoreApi';
import { filter, map, takeUntil } from 'rxjs/operators';
import { selectIsHubConnectedAndHeadsetDisconnected } from '../../call/+state/cchub.selectors';
import { selectAuthRecordClusterLabel } from '../+state/user.selectors';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { selectIsUsbAttachedAndHeadsetDisconnected } from '../../usb/+state/usb.selectors';

@Injectable({
    providedIn: 'root'
})
export class AcdStatusService implements OnDestroy, StompConsumer {
    private readonly TOPIC = `/user/topic/v1/request-user-status`;
    private readonly API_BASE_URL = '/api/acd/v1/';

    private readonly receipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.topicSubscription = this.stompService
            .watchAsync(this.TOPIC, this.receipt, this)
            .subscribe((message: IMessage) => this.handleRequestStatusUpdate(JSON.parse(message.body)));
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`ACD status topic ${topic} subscription success: ${receiptId}`);
        combineLatest([this.store.select(selectIsHubConnectedAndHeadsetDisconnected), this.store.select(selectIsUsbAttachedAndHeadsetDisconnected)])
            .pipe(
                map(([ccHubHeadsetDisconnected, usbHeadsetDisconnected]) => ccHubHeadsetDisconnected || usbHeadsetDisconnected),
                filter((val) => !!val),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.store.dispatch(requestedAcdStatusChange({ agentStatus: 'NOT_READY' })));
        this.store
            .select(selectAuthRecordClusterLabel)
            .pipe(
                filter((val) => !!val),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() =>
                this.store
                    .select(selectComputedAgentStatus)
                    .pipe(
                        filter((val) => !!val),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(({ status, isAgentRequested }) =>
                        this.store.dispatch(acdStatusChange({ agentStatus: status, isAgentRequested: isAgentRequested }))
                    )
            );
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`ACD status topic ${topic} subscription end: ${receiptId}`);
        this.unsubscribe$.next();
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`ACD status topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private handleRequestStatusUpdate(agentStatus: AgentStatus) {
        this.store.dispatch(acdStatusChange({ agentStatus: agentStatus }));
    }

    public reportAgentStatus(status: AgentStatus, clusterName: string, isAgentRequested: boolean) {
        let params = new HttpParams();
        params = isAgentRequested ? params.append('agentRequested', isAgentRequested) : params;
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}report-status/${status}`);
        return this.http.post<AgentStatusRecord>(url, {}, { params: params });
    }
}
