/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

enum MALFUNCTION {
  FLASH_WRITE_FAILURE = 0x00,
  EEPROM_WRITE_FAILURE = 0x01,
  SDRAM_SELF_TEST_WARNING = 0x02,
  CODECS_CLOCK_FAILURE = 0x03,
  CODECS_FRAME_SYNC_STROBE_FAILURE = 0x04
}

enum SEVERITY {
  ERROR = 0x00,
  WARNING = 0x01,
  INFORMATIONAL = 0x02
}

export class HardwareFailureMessage extends EventMessage {
  public static readonly MALFUNCTION = MALFUNCTION;
  public static readonly SEVERITY = SEVERITY;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.HARDWARE_FAILURE;
  }

  override payload1String(): string {
    return MALFUNCTION[this.payload1];
  }

  override payload2String(): any {
    return SEVERITY[this.payload2];
  }
}
