/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import { InstantMessage } from 'CalltakingCoreApi';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SortFunctions } from '../../call/util/sort-functions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { clearChatNotification, newInstantMessage, selectChat, selectSidePanel, startChat, updateChat } from './intercom.actions';
import { IntercomChat } from '../model/intercom-chat';

export interface IntercomState {
    messages: IntercomMessageState,
    chats: IntercomChatState,
    selectedChat: string | undefined,
    selectedSidePanel: string | undefined;
}

export interface IntercomMessageState extends EntityState<InstantMessage> {
}

export const messageAdapter: EntityAdapter<InstantMessage> = createEntityAdapter<InstantMessage>({
    selectId: (message: InstantMessage) => `${message.from}-${message.timestamp}`,
    sortComparer: SortFunctions.oldestTimestampSort
});

const initialIntercomMessageState: IntercomMessageState = messageAdapter.getInitialState({
});

export interface IntercomChatState extends EntityState<IntercomChat> {

}

export const chatAdapter: EntityAdapter<IntercomChat> = createEntityAdapter<IntercomChat>({
    selectId: (chat: IntercomChat) => chat.id,
    sortComparer: SortFunctions.oldestTimestampSort
});

const initialIntercomChatState: IntercomChatState = chatAdapter.getInitialState({

});

const initialState: IntercomState = {
    messages: initialIntercomMessageState,
    chats: initialIntercomChatState,
    selectedChat: undefined,
    selectedSidePanel: undefined
};

export const intercomFeature = createFeature({
    name: 'intercom',
    reducer: createReducer(
        initialState,
        on(newInstantMessage, (state, { message }) => {
            return { ...state, messages: messageAdapter.setOne(message, state.messages)};
        }),
        on(startChat, (state, {chat}) => {
            let exists = Boolean(state.chats.entities[chat.id]);
            if (!exists) {
                return { ...state, chats: chatAdapter.addOne(chat, state.chats) };
            }
            return state;
        }),
        on(updateChat, (state, {chat}) => {
            return { ...state, chats: chatAdapter.upsertOne(chat, state.chats) };
        }),
        on(clearChatNotification, (state, {chatId}) => {
            return { ...state, chats: chatAdapter.updateOne({id: chatId, changes: { notifying: false} }, state.chats)};
        }),
        on(selectChat, (state, {chatId}): IntercomState => {
            return { ...state, selectedChat: chatId };
        }),
        on(selectSidePanel, (state, {panel, toggle}): IntercomState => {
            return { ...state, selectedSidePanel: toggle && state.selectedSidePanel === panel ? undefined : panel };
        })
    )
});

export const intercomMessageState = (state: IntercomState) => state.messages;
export const intercomChatState = (state: IntercomState) => state.chats;

export const {
    selectEntities: selectIntercomMessageEntities,
    selectAll: selectAllIntercomMessages
} = messageAdapter.getSelectors();

export const {
    selectEntities: selectIntercomChatEntities,
    selectAll: selectAllIntercomChats
} = chatAdapter.getSelectors();

