/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import {
    callAlertDelete,
    callAlertUpdate,
    configurationEffectsInitialized,
    ctcHeartBeatOverride,
    ctcQuiescenceOverride,
    ctcTimeUpdate,
    fetchApplicationLinksSuccess,
    fetchAudibleAlertsSuccess,
    fetchCallAlertsSuccess,
    fetchCtcConfigurationSuccess,
    fetchCtcTimeSuccess,
    fetchCtcVersionSuccess,
    fetchHotkeysSuccess,
    fetchLayoutConfigurationSuccess,
    fetchQueueConfigurationSuccess,
    fetchQuiescenceStateSuccess,
    fetchUiConfigurationSuccess,
    fetchUserSelectableRolesSuccess,
    hasInitialized,
    heartbeatRequestFailed,
    heartbeatRequestSuccess,
    updateCallQueueFilter,
    updatePrimaryDataConnection
} from './configuration.actions';
import { CallQueue, Configuration } from 'CalltakingCoreApi';
import { HotKey } from '../../core/model/hotkey';
import { UiSettings } from '../model/ui-settings';
import { ExternalApplication } from '../../main/application-links/model/external-application';
import { LayoutSettings, Role } from '../model/layout-settings';
import { AudibleAlerts } from '../../call/model/audible-alert';

export interface ConfigurationState {
    effectsInitialized: boolean;
    ctc: Configuration | null;
    callQueues: CallQueue[];
    ui: UiSettings;
    layouts: LayoutSettings;
    links: ExternalApplication[];
    alerts: AudibleAlerts | undefined;
    hotkeys: HotKey[];
    selectedCallQueueFilter: string[];
    serverTimeOffset: number;
    hasInitialized: boolean;
    quiescence: { [clusterName: string]: boolean };
    ctcStatus: { [clusterName: string]: boolean };
    ctcOverrideStatus: { [clusterName: string]: boolean };
    ctcOverrideQuiescence: { [clusterName: string]: boolean };
    primaryConnectionClusterName: string | undefined;
    ctcVersion: { [clusterName: string]: string };
    selectableRoles: Role[];
}

const initialState: ConfigurationState = {
    effectsInitialized: false,
    ctc: null,
    callQueues: [],
    ui: {} as UiSettings,
    layouts: {} as LayoutSettings,
    links: [],
    alerts: undefined,
    hotkeys: [],
    selectedCallQueueFilter: [],
    serverTimeOffset: 0,
    hasInitialized: false,
    quiescence: {},
    ctcStatus: {},
    ctcOverrideStatus: {},
    ctcOverrideQuiescence: {},
    primaryConnectionClusterName: undefined,
    ctcVersion: {},
    selectableRoles: []
};

export const configurationFeature = createFeature({
    name: 'configuration',
    reducer: createReducer(
        initialState,
        on(configurationEffectsInitialized, (state): ConfigurationState => {
            return { ...state, effectsInitialized: true };
        }),
        on(fetchCtcConfigurationSuccess, (state, { config }) => {
            return {
                ...state,
                ctc: config,

                // Set initial selected call queue filter to ALL for agent supervisor list
                // Will this be saved in localStorage or db?
                selectedCallQueueFilter: config?.callQueues.map((cq) => cq.name)
            };
        }),
        on(fetchCtcTimeSuccess, ctcTimeUpdate, (state, { offset }): ConfigurationState => {
            return { ...state, serverTimeOffset: offset };
        }),
        on(fetchCtcVersionSuccess, (state, { clusterName, version }): ConfigurationState => {
            let ctcVersion = { ...state.ctcVersion };
            ctcVersion[clusterName] = version;
            return { ...state, ctcVersion: ctcVersion };
        }),
        on(fetchUiConfigurationSuccess, (state, { config }): ConfigurationState => {
            return { ...state, ui: config };
        }),
        on(fetchQueueConfigurationSuccess, (state, { queues }): ConfigurationState => {
            return { ...state, callQueues: queues };
        }),
        on(fetchLayoutConfigurationSuccess, (state, { config }): ConfigurationState => {
            return { ...state, layouts: config };
        }),
        on(fetchAudibleAlertsSuccess, (state, { alerts }): ConfigurationState => {
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    imAlert: alerts?.imAlert,
                    announcementAlert: alerts?.announcementAlert,
                    networkDisconnectionAlert: alerts?.networkDisconnectionAlert
                }
            };
        }),
        on(fetchCallAlertsSuccess, (state, { alerts }): ConfigurationState => {
            return { ...state, alerts: { ...state.alerts, callAlerts: alerts } };
        }),
        on(callAlertUpdate, (state, { callAlert }): ConfigurationState => {
            let auditableAlerts = { ...state.alerts };
            auditableAlerts.callAlerts = auditableAlerts.callAlerts
                .filter((ca) => ca.uuid !== callAlert.uuid)
                .concat(callAlert);
            return { ...state, alerts: auditableAlerts };
        }),
        on(callAlertDelete, (state, { callAlert }): ConfigurationState => {
            let auditableAlerts = { ...state.alerts };
            auditableAlerts.callAlerts = auditableAlerts.callAlerts
                .filter((ca) => ca.uuid === callAlert.uuid);
            return { ...state, alerts: auditableAlerts };
        }),
        on(fetchCallAlertsSuccess, (state, { alerts }): ConfigurationState => {
            return { ...state, alerts: { ...state.alerts, callAlerts: alerts } };
        }),
        on(fetchApplicationLinksSuccess, (state, { apps }): ConfigurationState => {
            return { ...state, links: apps };
        }),
        on(updateCallQueueFilter, (state, { selectedCallQueues }): ConfigurationState => {
            return {
                ...state,
                selectedCallQueueFilter: selectedCallQueues
            };
        }),
        on(fetchHotkeysSuccess, (state, { hotkeys }): ConfigurationState => {
            return { ...state, hotkeys: hotkeys };
        }),
        on(hasInitialized, (state): ConfigurationState => {
            return { ...state, hasInitialized: true };
        }),
        on(fetchQuiescenceStateSuccess, (state, { clusterName, quiescence }): ConfigurationState => {
            let qState = { ...state.quiescence };
            qState[clusterName] = quiescence.quiesced;
            return { ...state, quiescence: qState };
        }),
        on(heartbeatRequestSuccess, (state, { clusterName }): ConfigurationState =>  {
            let ctcStatus = {...state.ctcStatus};
            ctcStatus[clusterName] = true;
            return { ...state, ctcStatus: ctcStatus };
        }),
        on(heartbeatRequestFailed, (state, { clusterName }): ConfigurationState =>  {
            let ctcStatus = {...state.ctcStatus};
            ctcStatus[clusterName] = false;
            return { ...state, ctcStatus: ctcStatus };
        }),
        on(ctcHeartBeatOverride, (state, { clusterName, override }): ConfigurationState =>  {
            let ctcOverrideStatus = {...state.ctcOverrideStatus};
            ctcOverrideStatus[clusterName] = override;
            return { ...state, ctcOverrideStatus: ctcOverrideStatus };
        }),
        on(ctcQuiescenceOverride, (state, { clusterName, override }): ConfigurationState =>  {
            let ctcOverrideQuiescence = {...state.ctcOverrideQuiescence};
            ctcOverrideQuiescence[clusterName] = override;
            return { ...state, ctcOverrideQuiescence: ctcOverrideQuiescence };
        }),
        on(updatePrimaryDataConnection, (state, { clusterName }) : ConfigurationState => {
            return { ...state, primaryConnectionClusterName: clusterName };
        }),
        on(fetchUserSelectableRolesSuccess, (state, { roles }): ConfigurationState => {
            return { ...state, selectableRoles: roles };
        })
    )
});
