/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SortFunctions } from '../../call/util/sort-functions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
    clearAnnouncement,
    clearAnnouncementNotifications,
    discardAllAnnouncement,
    discardAnnouncement,
    persistAnnouncement,
    setAnnouncementStatus
} from './announcement.actions';
import { NotifyingAnnouncement } from '../model/notifying-announcement';

export interface AnnouncementState extends EntityState<NotifyingAnnouncement> {
}

export const adapter: EntityAdapter<NotifyingAnnouncement> = createEntityAdapter<NotifyingAnnouncement>({
    selectId: (announcement: NotifyingAnnouncement) => announcement.elementId,
    sortComparer: SortFunctions.newestTimestampSort
});

const initialState: AnnouncementState = adapter.getInitialState({});

export const announcementFeature = createFeature({
    name: 'announcement',
    reducer: createReducer(
        initialState,
        on(persistAnnouncement, (state, {announcement}) => {
            return adapter.upsertOne(announcement, state);
        }),
        on(discardAnnouncement, (state, {id}) => {
            return adapter.removeOne(id, state);
        }),
        on(discardAllAnnouncement, (state) => {
            return adapter.removeAll(state);
        }),
        on(setAnnouncementStatus, (state, { id, unread }) => {
            return adapter.updateOne({
                id, changes: {
                    unread
                }
            }, state);
        }),
        on(clearAnnouncement, (state, { id }) => {
            return adapter.updateOne({
                id, changes: {
                    cleared: true
                }
            }, state);
        }),
        on(clearAnnouncementNotifications, (state) => {
            return adapter.map((entity) => ({
                ...entity,
                unread: false,
            }), state);
        })
    )
});

export const {
    selectEntities: selectAnnouncementEntities,
    selectAll: selectAnnouncementMessages
} = adapter.getSelectors();

