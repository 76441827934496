/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize } from '@ngrx/store';
import {
    intercomChatState,
    intercomFeature,
    intercomMessageState,
    IntercomState,
    selectAllIntercomChats,
    selectAllIntercomMessages,
    selectIntercomChatEntities,
    selectIntercomMessageEntities
} from './intercom.reducer';
import * as dayjs from 'dayjs';
import { IntercomChat, IntercomMessage } from '../model/intercom-chat';

export const selectMessageState = createFeatureSelector<IntercomState>(intercomFeature.name);

export const selectIntercomMessageState = createSelector(selectMessageState, intercomMessageState);
export const selectIntercomMessageMap = createSelector(selectIntercomMessageState, selectIntercomMessageEntities);

export const selectIntercomChatState = createSelector(selectMessageState, intercomChatState);
export const selectIntercomChatMap = createSelector(selectIntercomChatState, selectIntercomChatEntities);

export const selectIntercomMessages = createSelector(selectIntercomMessageState, selectAllIntercomMessages);
export const selectIntercomChats = createSelector(selectIntercomChatState, selectAllIntercomChats);

export const selectIntercomChat = (id: string) => createSelector(selectIntercomChatMap, (chatMap) => chatMap[id]);

export const selectChatMessages = (id: string) => createSelector(selectIntercomChat(id), (chat) => (chat ? chat.messages : []));
export const selectNotifyingChats = createSelector(selectIntercomChats, (chats) => chats.filter((chat) => chat.notifying));

export const selectNotifyingChatsCount = createSelector(selectNotifyingChats, (chats) => chats.length);

const intercomMessageMemoize = (projectorFn: DefaultProjectorFn<IntercomMessage>) => resultMemoize(projectorFn, intercomMessageIsEqual);

function intercomMessageIsEqual(a: IntercomMessage, b: IntercomMessage): boolean {
    return a?.from === b?.from && a?.message === b?.message && a?.timestamp === b?.timestamp;
}

export const selectChatNotifying = createSelector(selectIntercomChats, (chats) => chats.filter((chat) => chat.notifying).length);

export const selectSelectedChat = createSelector(selectMessageState, (state) => state.selectedChat);

export const selectSelectedSidePanel = createSelector(selectMessageState, (state) => state.selectedSidePanel);

export const selectPopupIntercomMessage = createSelectorFactory<object, IntercomMessage>(intercomMessageMemoize)(
    selectNotifyingChats,
    selectSelectedChat,
    selectSelectedSidePanel,
    (notifyingChats: IntercomChat[], selectedChatId: string, selectedSidePanel: string) =>
    {
        const chat = notifyingChats.flatMap((a) => a.messages).sort((a, b) => (dayjs(a.timestamp).isAfter(b.timestamp) ? -1 : 1))[0];
        const chatAlreadyOpen = selectedSidePanel === 'message' && chat?.chatId === selectedChatId;
        return chat && !chatAlreadyOpen ? chat : undefined;
    }
);
