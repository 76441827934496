/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    AdrDiscrepancyReport,
    BcfDiscrepancyReport,
    ChfeDiscrepancyReport,
    EsrpDiscrepancyReport,
    LisDiscrepancyReport
} from '../../discrepancy-report/model/discrepancy-report-model';
import { StatusUpdate } from '../../discrepancy-report/model/status-update';
import { EnvironmentService } from '../../core/services/environment.service';
import { HealthService } from '../../core/services/health.service';
import { ServiceName } from '../../../assets/config/environment';

@Injectable({
    providedIn: 'root'
})
export class DiscrepancyReportService extends HealthService {
    protected healthEndpoint = '/q/health/ready';

    private readonly DISCREPANCY_REPORT_PATH = `/v1/discrepancyreport`;

    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService
    ) {
        super(http, ServiceName.DRM, env.environment.SERVICES[ServiceName.DRM], "/q/health/ready");
    }

    public bcf(clusterName: string, bcfReport: BcfDiscrepancyReport) {
        return this.http.post(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/bcf`, bcfReport);
    }

    public esrp(clusterName: string, esrpReport: EsrpDiscrepancyReport) {
        return this.http.post(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/esrp`, esrpReport);
    }

    public lis(clusterName: string, lisReport: LisDiscrepancyReport) {
        return this.http.post(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/lis`, lisReport);
    }

    public adr(clusterName: string, adrReport: AdrDiscrepancyReport) {
        return this.http.post(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/adr`, adrReport);
    }

    public chfe(clusterName: string, chfeReport: ChfeDiscrepancyReport) {
        return this.http.post(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/chfe`, chfeReport);
    }

    public requestStatusUpdate(clusterName: string, agencyName: string, discrepancyReportId: string) {
        let params = new HttpParams();
        params.append('agencyName', agencyName);
        params.append('discrepancyReportId', discrepancyReportId);
        return this.http.get<StatusUpdate>(`${this.activeUrl$.value}${this.DISCREPANCY_REPORT_PATH}/statusupdates`, { observe: 'response', params });
    }
}
