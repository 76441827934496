/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Injectable } from '@angular/core';
import { CallService } from '../services/call.service';
import { Action, Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delayWhen, Observable, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
    answer,
    answerFail,
    answerNext,
    answerSuccess,
    bargeIn,
    closeCall,
    conference,
    conferenceFail,
    conferenceRelease,
    conferenceSuccess,
    deafen,
    deafenFail,
    deafenSuccess,
    deleteCall,
    dial,
    dialFail,
    dialSuccess,
    endObserveAgent,
    fetchAnalyticsHistoricalCalls,
    fetchAnalyticsHistoricalCallsFail,
    fetchAnalyticsHistoricalCallsSuccess,
    fetchCalls,
    fetchCallsFail,
    fetchCallsSuccess,
    fetchRecentCalls,
    fetchRecentCallsFailure,
    fetchRecentCallsSuccess,
    holdSuccess,
    initializeCallState,
    joinCall,
    joinCallFail,
    joinCallSuccess,
    lockCall,
    lockCallFailure,
    lockCallSuccess,
    mute,
    muteFail,
    muteSuccess,
    newCall,
    observeAgent,
    purgeAnalyticsHistoricalCall,
    purgeHistoricalCall,
    recordHistoricalCall,
    redial,
    redialAbandoned,
    redialAbandonedFail,
    redialAbandonedSuccess,
    redialFail,
    redialSuccess,
    releaseActiveCall,
    releaseCallCompleteFailed,
    releaseNetworkParticipant,
    releaseNetworkParticipantFail,
    releaseNetworkParticipantSuccess,
    releaseParticipant,
    releaseParticipantFail,
    releaseParticipantSuccess,
    releasePendingParticipant,
    releasePendingParticipantFail,
    releasePendingParticipantSuccess,
    requestActiveCall,
    requestAnswer,
    requestHold,
    requestHoldFail,
    requestHoldSuccess,
    requestJoin,
    requestRedial,
    requestReleaseCall,
    requestReleaseCallFail,
    requestReleaseCallSuccess,
    requestResumeObserve,
    requestUnhold,
    sendCallMessage,
    sendCallMessageFail,
    sendDtmfMessage,
    sendDtmfMessageFail,
    sendDtmfMessageSuccess,
    sendRttMessage,
    sendRttMessageFail,
    sendRttMessageSuccess,
    sendSmsMessage,
    sendSmsMessageFail,
    sendSmsMessageSuccess,
    sendTddMessage,
    sendTddMessageFail,
    sendTddMessageSuccess,
    setObservationState,
    silentMonitor,
    silentMonitorFail,
    silentMonitorSuccess,
    toggleDeafenActiveCall,
    toggleHoldActiveCall,
    toggleMuteActiveCall,
    undeafen,
    undeafenFail,
    undeafenSuccess,
    unhold,
    unholdFail,
    unholdSuccess,
    unlockCall,
    unlockCallFailure,
    unlockCallSuccess,
    unmute,
    unmuteFail,
    unmuteSuccess,
    updateCall,
    volumeChange,
    volumeChangeFail,
    volumeChangeSuccess
} from './call.actions';
import { CallAlertService } from '../services/call-alert.service';
import {
    selectActiveCall,
    selectActiveOrSelectedCall,
    selectActiveVoiceCall,
    selectCallback,
    selectCallsMap,
    selectHoldDisabled,
    selectLiveCalls,
    selectMyCalls,
    selectNextCallToAnswer,
    selectObservedUserCalls,
    selectOpenedCallIds,
    selectPendingReleasedCall,
    selectSelectedCall,
    selectUnOpenedUnlockedHistoricalCalls
} from './call.selectors';
import { requestedAcdStatusChange } from '../../user/+state/user.actions';
import { selectUsername } from '../../user/+state/user.selectors';
import { CallFunctions } from '../util/call-functions';
import { SortFunctions } from '../util/sort-functions';
import { hotKeyTriggered } from '../../configuration/+state/configuration.actions';
import { AnalyticsService } from '../services/analytics.service';
import { selectRecentCallLimit } from '../../configuration/+state/configuration.selectors';
import { dialPadButtonPress } from '../../directory/+state/directory.actions';
import { selectMediaConnectionsByClusterNameMap, selectPreferredOutboundCallClusterName } from './media.selectors';
import { Headset } from '../../configuration/model/headsets';
import { tearDownMedia, tearDownMediaSuccess } from './media.actions';
import { HotKeyAction } from '../../core/model/hotkey';
import { selectDisplayToastForRingdownNotAvailable } from '../../directory/+state/directory-calls.selectors';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { RemoteAgencyCallSyncService } from '../services/remote-agency-call-sync.service';

@Injectable()
export class CallEffects {
    constructor(
        private callService: CallService,
        private analyticsCallHistoryService: AnalyticsService,
        private alertService: CallAlertService,
        private store: Store,
        private actions$: Actions,
        private remoteAgencyCallSyncService: RemoteAgencyCallSyncService
    ) {}

    initializeCallState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initializeCallState),
            map(({ clusterName }) => fetchCalls({ clusterName: clusterName }))
        )
    );

    recordHistorical$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCall),
            concatLatestFrom(() => [this.store.select(selectUsername), this.store.select(selectOpenedCallIds)]),
            filter(
                ([{ call }, username, viewingCalls]) =>
                    CallFunctions.isHistorical(call.status) && (CallFunctions.isParticipant(call, username) || viewingCalls.includes(call.uuid))
            ),
            map(([{ call }]) => recordHistoricalCall({ call }))
        )
    );

    purgeHistoricalRecord$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(recordHistoricalCall, closeCall),
            concatLatestFrom(() => [this.store.select(selectUnOpenedUnlockedHistoricalCalls), this.store.select(selectRecentCallLimit)]),
            filter(([, historicalCalls, recentCallLimit]) => historicalCalls.length > recentCallLimit),
            map(([, historicalCalls]) =>
                purgeHistoricalCall({
                    uuid: historicalCalls[historicalCalls.length - 1].uuid,
                    redialUuid: historicalCalls[historicalCalls.length - 1].redialUUID
                })
            )
        )
    );

    purgeAnalyticsHistoricalRecords$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(purgeHistoricalCall),
            map(({ uuid }) => purgeAnalyticsHistoricalCall({ uuid: uuid }))
        )
    );

    sendCallMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendCallMessage),
            map(({ callId, clusterName, message, messageType, predefined, language }) => {
                switch (messageType) {
                    case 'TDD':
                        return sendTddMessage({ message, clusterName, callId, predefined, language });
                    case 'RTT':
                        return sendRttMessage({ message, clusterName, callId, predefined, language });
                    case 'SMS':
                        return sendSmsMessage({ message, clusterName, callId, predefined, language });
                    case 'DTMF':
                        return sendDtmfMessage({ message, clusterName, callId });
                    default:
                        return sendCallMessageFail({ payload: 'Unable to find or determine call message type.' });
                }
            })
        )
    );

    answerNext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answerNext),
            concatLatestFrom(() => this.store.select(selectNextCallToAnswer)),
            filter(([, call]) => !!call),
            map(([, call]) => requestAnswer({ callId: call.uuid, clusterName: call.clusterName }))
        )
    );

    releaseActiveCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(releaseActiveCall),
            concatLatestFrom(() => this.store.select(selectActiveCall)),
            filter(([, call]) => !!call),
            map(([, call]) => requestReleaseCall({ callId: call.uuid, clusterName: call.clusterName }))
        )
    );

    toggleHoldActiveCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(toggleHoldActiveCall),
            concatLatestFrom(() => [this.store.select(selectActiveOrSelectedCall), this.store.select(selectUsername), this.store.select(selectHoldDisabled)]),
            filter(([, call, username, holdDisabled]) => !!call && !!username && !holdDisabled),
            map(([, call, username]) =>
                CallFunctions.isHeld(call, username)
                    ? requestUnhold({ callId: call.uuid, clusterName: call.clusterName })
                    : requestHold({ callId: call.uuid, clusterName: call.clusterName })
            )
        )
    );

    toggleMuteActiveCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(toggleMuteActiveCall),
            concatLatestFrom(() => [this.store.select(selectActiveCall), this.store.select(selectUsername)]),
            filter(([, call, username]) => !!call && !!username),
            map(([, call, username]) =>
                CallFunctions.isMuted(call, username)
                    ? unmute({ callId: call.uuid, clusterName: call.clusterName, participantId: call.participants[username].uuid })
                    : mute({ callId: call.uuid, clusterName: call.clusterName, participantId: call.participants[username].uuid })
            )
        )
    );

    toggleDeafenActiveCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(toggleDeafenActiveCall),
            concatLatestFrom(() => this.store.select(selectActiveCall)),
            filter(([, call]) => !!call && Boolean(call?.remoteParticipants)),
            map(([, call]) => ({ call: call, participantId: [...call.remoteParticipants].sort(SortFunctions.joinedOnSort)[0]?.uuid })),
            map(({ call, participantId }) =>
                call.deafened
                    ? undeafen({ callId: call.uuid, clusterName: call.clusterName, participantId: participantId })
                    : deafen({ callId: call.uuid, clusterName: call.clusterName, participantId: participantId })
            )
        )
    );

    sendTddMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendTddMessage),
            switchMap(({ callId, clusterName, message, predefined, language }) =>
                this.callService.tddMessage(callId, clusterName, message, predefined, language).pipe(
                    map(() => sendTddMessageSuccess()),
                    catchError((err: Error) => of(sendTddMessageFail({ payload: err.message })))
                )
            )
        )
    );

    sendRttMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendRttMessage),
            switchMap(({ callId, clusterName, message, predefined, language }) =>
                this.callService.rttMessage(callId, clusterName, message, predefined, language).pipe(
                    map(() => sendRttMessageSuccess()),
                    catchError((err: Error) => of(sendRttMessageFail({ payload: err.message })))
                )
            )
        )
    );

    sendSmsMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendSmsMessage),
            switchMap(({ callId, clusterName, message, predefined, language }) =>
                this.callService.smsMessage(callId, clusterName, message, predefined, language).pipe(
                    map(() => sendSmsMessageSuccess()),
                    catchError((err: Error) => of(sendSmsMessageFail({ payload: err.message })))
                )
            )
        )
    );

    dialPadButtonPress$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(dialPadButtonPress),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            filter(([, activeCall]) => !!activeCall),
            map(([{ digit }, activeCall]) => sendDtmfMessage({ callId: activeCall.uuid, clusterName: activeCall.clusterName, message: digit }))
        )
    );

    sendDtmfMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendDtmfMessage),
            switchMap(({ callId, clusterName, message }) =>
                this.callService.dtmfMessage(callId, clusterName, message).pipe(
                    map(() => sendDtmfMessageSuccess()),
                    catchError((err: Error) => of(sendDtmfMessageFail({ payload: err.message })))
                )
            )
        )
    );

    monitorCallAlertUpdates$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(newCall, updateCall, deleteCall),
                tap((action) => this.alertService.handleCallUpdate(action.call, action.type === deleteCall.type))
            ),
        { dispatch: false }
    );

    initializeCallAlerts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchCallsSuccess),
                tap((action) => this.alertService.initialize(action.calls))
            ),
        { dispatch: false }
    );

    fetchAnalyticsHistoricalCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAnalyticsHistoricalCalls),
            switchMap(({ callingNumber, callId }) =>
                this.analyticsCallHistoryService.requestCallHistory(callingNumber, callId).pipe(
                    map((response) => fetchAnalyticsHistoricalCallsSuccess({ referenceKey: callingNumber, calls: response })),
                    catchError((err: Error) => of(fetchAnalyticsHistoricalCallsFail({ payload: err.message })))
                )
            )
        )
    );

    fetchRecentCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchRecentCalls),
            switchMap((({ hours, maxCalls }) => this.analyticsCallHistoryService.requestRecentCalls(maxCalls, hours).pipe(
                map((response) => fetchRecentCallsSuccess({ calls: response })),
                catchError((err: Error) => of(fetchRecentCallsFailure({ payload: err.message })))
            )))
        ));

    fetchCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCalls),
            mergeMap(({ clusterName }) =>
                this.callService.requestCalls(clusterName).pipe(
                    map((response) => fetchCallsSuccess({ calls: response, clusterName: clusterName })),
                    catchError((err: Error) => of(fetchCallsFail({ payload: err.message })))
                )
            )
        )
    );

    mute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mute),
            switchMap(({ callId, clusterName, participantId }) =>
                this.callService.mute(callId, clusterName, participantId).pipe(
                    map(() => muteSuccess()),
                    catchError((err: Error) => of(muteFail({ payload: err.message })))
                )
            )
        )
    );

    unmute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unmute),
            switchMap(({ callId, clusterName, participantId }) =>
                this.callService.unmute(callId, clusterName, participantId).pipe(
                    map(() => unmuteSuccess()),
                    catchError((err: Error) => of(unmuteFail({ payload: err.message })))
                )
            )
        )
    );

    bargeIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bargeIn),
            concatLatestFrom(() => this.store.select(selectCallsMap)),
            mergeMap(([{ callId, clusterName, participantId }, callsMap]) =>
                 CallFunctions.isHeld(callsMap[callId]) ?
                     [setObservationState({ observationState: 'suspended' }), requestUnhold({ callId, clusterName })] :
                     [setObservationState({ observationState: 'suspended' }), joinCall({ callId, clusterName })]
            )
        )
    );

    releaseCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestReleaseCall),
            mergeMap(({ callId, clusterName }) =>
                this.callService.release(callId, clusterName).pipe(
                    map(() => requestReleaseCallSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(requestReleaseCallFail({ callId, payload: err.message })))
                )
            )
        )
    );

    // Clean up pending release attempts that never complete
    timeOutPendingReleaseCall$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(requestReleaseCallSuccess),
            delay(5000),
            concatLatestFrom(() => this.store.select(selectPendingReleasedCall)),
            filter(([{ callId }, pendingRelease]) => pendingRelease && pendingRelease === callId),
            map(([{ callId, clusterName }]) => releaseCallCompleteFailed( { callId } ))
        )
    );

    endObservationState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(endObserveAgent),
            concatLatestFrom(() => this.store.select(selectMyCalls)),
            concatLatestFrom(() => this.store.select(selectUsername)),
            map(([[, calls], username]) => calls.filter((call) => CallFunctions.isSilentMonitoring(call, username))),
            switchMap((calls) => {
                return [...calls.map((call) => requestReleaseCall({ callId: call.uuid, clusterName: call.clusterName }))];
            })
        )
    );

    silentMonitor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(silentMonitor),
            switchMap(({ callId, clusterName }) =>
                this.callService.silentMonitor(callId, clusterName).pipe(
                    map(() => silentMonitorSuccess()),
                    catchError((err: Error) => of(silentMonitorFail({ payload: err.message })))
                )
            )
        )
    );

    silentMonitorRequestStatusChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(observeAgent),
            map(() => requestedAcdStatusChange({ agentStatus: 'NOT_READY' }))
        )
    );

    requestJoin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestJoin),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            mergeMap(([action, callToHold]) =>
                callToHold
                    ? [setObservationState({ observationState: 'suspended' }),
                        requestHold({ callId: callToHold.uuid, clusterName: callToHold.clusterName, callback: action })]
                    : [setObservationState({ observationState: 'suspended' }),
                        joinCall({ callId: action.callId, clusterName: action.clusterName })]
            )
        )
    );

    joinCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(joinCall),
            switchMap(({ callId, clusterName }) =>
                this.callService.join(callId, clusterName).pipe(
                    map(() => joinCallSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(joinCallFail({ payload: err.message })))
                )
            )
        )
    );

    deafen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deafen),
            switchMap(({ callId, clusterName, participantId }) =>
                this.callService.deafen(callId, clusterName, participantId).pipe(
                    map(() => deafenSuccess()),
                    catchError((err: Error) => of(deafenFail({ payload: err.message })))
                )
            )
        )
    );

    undeafen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(undeafen),
            switchMap(({ callId, clusterName, participantId }) =>
                this.callService.undeafen(callId, clusterName, participantId).pipe(
                    map(() => undeafenSuccess()),
                    catchError((err: Error) => of(undeafenFail({ payload: err.message })))
                )
            )
        )
    );

    /* delayWhen defers execution until the observable emits
     this is used to confirm the call state is not actively
     being mutated before we check for calls to hold.
     debounceTime then takes the most recent answer request and
      ignores any others that may have been queued */
    requestAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestAnswer),
            delayWhen(() => this.store.select(selectPendingReleasedCall).pipe(filter((val) => !Boolean(val)))),
            debounceTime(100),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            mergeMap(([action, callToHold]) =>
                callToHold && callToHold.uuid !== action.callId
                    ? [setObservationState({ observationState: 'suspended' }),
                        requestHold({ callId: callToHold.uuid, clusterName: callToHold.clusterName, callback: action })]
                    : [setObservationState({ observationState: 'suspended' }),
                        answer({ callId: action.callId, clusterName: action.clusterName })]
            )
        )
    );

    requestUnhold$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUnhold),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            mergeMap(([action, callToHold]) =>
                callToHold
                    ? [setObservationState({ observationState: 'suspended' }),
                        requestHold({ callId: callToHold.uuid, clusterName: callToHold.clusterName, callback: action })]
                    : [setObservationState({ observationState: 'suspended' }),
                        unhold({ callId: action.callId, clusterName: action.clusterName })]
            )
        )
    );

    requestResumeObserve$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestResumeObserve),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            map(([action, callToHold]) =>
                callToHold
                    ? requestHold({ callId: callToHold.uuid, clusterName: callToHold.clusterName, callback: action })
                    : setObservationState({ observationState: 'active' })
            )
        )
    );

    /* text calls can't be held, so they won't re-trigger silent-monitor automatically from observationState being 'active' */
    resumeTextCallSilentMonitoring$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setObservationState),
            filter(({ observationState }) => observationState === 'active'),
            concatLatestFrom(() => this.store.select(selectObservedUserCalls)),
            concatLatestFrom(() => this.store.select(selectUsername)),
            mergeMap(([[, callsToSilentMonitor], username]) =>
                callsToSilentMonitor.filter((call) => call.text && CallFunctions.isActiveParticipant(call, username) && !CallFunctions.isSilentMonitoring(call, username))
                    .map((call) => silentMonitor({ callId: call.uuid, clusterName: call.clusterName}))
            )
        )
    );


    holdCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestHold),
            switchMap(({ callId, clusterName, callback }) =>
                this.callService.hold(callId, clusterName).pipe(
                    map(() => requestHoldSuccess({ callId, callback })),
                    catchError((err: Error) => of(requestHoldFail({ payload: err.message })))
                )
            )
        )
    );

    holdCallCallback$ = createEffect(() =>
        this.actions$.pipe(
            ofType(holdSuccess),
            filter(({ callback }) => !!callback),
            map(({ callback }) => callback as Action)
        )
    );

    unholdCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unhold),
            switchMap(({ callId, clusterName }) =>
                this.callService.unhold(callId, clusterName).pipe(
                    map(() => unholdSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(unholdFail({ payload: err.message })))
                )
            )
        )
    );

    lockCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lockCall),
            switchMap(({ callId }) =>
                this.analyticsCallHistoryService.lockCall(callId).pipe(
                    map(() => lockCallSuccess({ callId })),
                    catchError((err: Error) => of(lockCallFailure({ payload: err.message })))
                ))
        ));

    unlockCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unlockCall),
            switchMap(({ callId }) =>
                this.analyticsCallHistoryService.unlockCall(callId).pipe(
                    map(() => unlockCallSuccess({ callId })),
                    catchError((err: Error) => of(unlockCallFailure({ payload: err.message })))
                ))
        ));

    conference$ = createEffect(() =>
        this.actions$.pipe(
            ofType(conference),
            switchMap(({ clusterName, callId, number, source, label, contactId, blindTransfer, supervisorAssistance, destinations }) =>
                this.callService.conference(clusterName, callId, number, source, label, contactId, blindTransfer, supervisorAssistance, destinations).pipe(
                    map(() => conferenceSuccess()),
                    catchError((err: Error) => of(conferenceFail({ payload: err.message })))
                )
            )
        )
    );

    conferenceRelease$ = createEffect(() =>
        this.actions$.pipe(
            ofType(conferenceRelease),
            concatLatestFrom(() => this.store.select(selectCallsMap)),
            map(([{ callId, clusterName, username }, callsMap]) => ({
                callId: callId,
                clusterName: clusterName,
                lastParticipant: CallFunctions.lastParticipant(callsMap[callId], username),
                lastPendingParticipant: CallFunctions.lastPendingParticipant(callsMap[callId])
            })),
            map(({ callId, clusterName, lastParticipant, lastPendingParticipant }) =>
                lastPendingParticipant
                    ? releasePendingParticipant({ callId: callId, clusterName: clusterName, participantId: lastPendingParticipant.uuid })
                    : releaseParticipant({ callId: callId, clusterName: clusterName, participantId: lastParticipant.uuid })
            )
        )
    );

    dial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dial),
            concatLatestFrom(() => this.store.select(selectPreferredOutboundCallClusterName)),
            switchMap(([{ number, source, label, id, destinations }, clusterName]) =>
                this.callService.dial(number, clusterName, source, label, id, destinations).pipe(
                    map(({ callId }) => dialSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(dialFail({ payload: err.message })))
                )
            )
        )
    );

    requestRedial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestRedial),
            concatLatestFrom((action) => [this.store.select(selectActiveVoiceCall), this.store.select(selectCallsMap), this.store.select(selectDisplayToastForRingdownNotAvailable(action.number))]),
            map(([action, callToHold, callsMap, displayToast]) =>
                displayToast ? displayToastNotification({ message: 'The callback cannot be executed because the ringdown is busy', level: ToastType.error }) :
                callToHold
                    ? requestHold({ callId: callToHold.uuid, clusterName: callToHold.clusterName, callback: action })
                    : CallFunctions.isAbandoned(callsMap[action.callId]?.status)
                        ? redialAbandoned({ callId: action.callId, clusterName: action.clusterName })
                        : redial({ callId: action.callId, clusterName: action.clusterName, number: action.number })
            )
        )
    );

    redial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redial),
            switchMap(({ callId, clusterName, number }) =>
                this.callService.redial(callId, clusterName, number).pipe(
                    map(({ callId }) => redialSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(redialFail({ payload: err.message })))
                )
            )
        )
    );

    redialAbandoned$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redialAbandoned),
            switchMap(({ callId, clusterName }) =>
                this.callService.redialAbandoned(callId, clusterName).pipe(
                    map(() => redialAbandonedSuccess({ callId })),
                    catchError((err: Error) => of(redialAbandonedFail({ payload: err.message })))
                )
            )
        )
    );

    releaseParticipant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(releaseParticipant),
            mergeMap(({ callId, clusterName, participantId }) =>
                this.callService.releaseParticipant(callId, clusterName, participantId).pipe(
                    map(() => releaseParticipantSuccess()),
                    catchError((err: Error) => of(releaseParticipantFail({ payload: err.message })))
                )
            )
        )
    );

    releasePendingParticipant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(releasePendingParticipant),
            mergeMap(({ callId, clusterName, participantId }) =>
                this.callService.releasePendingParticipant(callId, clusterName, participantId).pipe(
                    map(() => releasePendingParticipantSuccess()),
                    catchError((err: Error) => of(releasePendingParticipantFail({ payload: err.message })))
                )
            )
        )
    );

    releaseNetworkParticipant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(releaseNetworkParticipant),
            mergeMap(({ callId, clusterName, participantId }) =>
                this.callService.releaseNetworkParticipant(callId, clusterName, participantId).pipe(
                    map(() => releaseNetworkParticipantSuccess()),
                    catchError((err: Error) => of(releaseNetworkParticipantFail({ payload: err.message })))
                )
            )
        )
    );

    answerCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answer),
            switchMap(({ callId, clusterName }) =>
                this.callService.answer(callId, clusterName).pipe(
                    map(() => answerSuccess({ callId, clusterName })),
                    catchError((err: Error) => of(answerFail({ payload: err.message })))
                )
            )
        )
    );

    stopViewing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(answerSuccess, joinCallSuccess, unholdSuccess),
            map(({ callId }) => closeCall({ callId }))
        )
    );

    requestActiveCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialSuccess, redialSuccess),
            map(({ callId }) => requestActiveCall({ callId: callId }))
        )
    );

    volume$ = createEffect(() =>
        this.actions$.pipe(
            ofType(volumeChange),
            switchMap(({ clusterName, callId, participantId, volume, gain }) =>
                this.callService.volume(clusterName, callId, participantId, volume, gain).pipe(
                    map(() => volumeChangeSuccess({ callId })),
                    catchError((err: Error) => of(volumeChangeFail({ payload: err.message })))
                )
            )
        )
    );

    answerHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.ANSWER),
            map(({ hotkey }) => answerNext())
        )
    );

    releaseHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.RELEASE),
            map(({ hotkey }) => releaseActiveCall())
        )
    );

    holdHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.HOLD),
            map(({ hotkey }) => toggleHoldActiveCall())
        )
    );

    muteHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.MUTE && hotkey.params[0] === Headset.SYSTEM),
            map(({ hotkey }) => toggleMuteActiveCall())
        )
    );

    deafenHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.DEAFEN),
            map(({ hotkey }) => toggleDeafenActiveCall())
        )
    );

    conferenceReleaseHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.CONFERENCE_RELEASE),
            concatLatestFrom(() => [this.store.select(selectUsername), this.store.select(selectActiveCall)]),
            filter(([, username, call]) => !!call && !!username),
            map(([, username, call]) => conferenceRelease({ callId: call.uuid, clusterName: call.clusterName, username: username }))
        )
    );

    volumeUpHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.VOLUME_UP),
            concatLatestFrom(() => [this.store.select(selectUsername), this.store.select(selectActiveCall)]),
            filter(([, username, call]) => !!call && !!username && call.participants[username].volume < 10),
            map(([, username, call]) => ({
                call: call,
                participant: call.participants[username],
                volume: Number(call.participants[username].volume) + 1
            })),
            map(({ call, participant, volume }) =>
                volumeChange({ clusterName: call.clusterName, callId: call.uuid, participantId: participant.uuid, volume: volume, gain: undefined })
            )
        )
    );

    volumeDownHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.VOLUME_DOWN),
            concatLatestFrom(() => [this.store.select(selectUsername), this.store.select(selectActiveCall)]),
            filter(([, username, call]) => !!call && !!username && call.participants[username].volume > -10),
            map(([, username, call]) => ({
                call: call,
                participant: call.participants[username],
                volume: Number(call.participants[username].volume) - 1
            })),
            map(({ call, participant, volume }) =>
                volumeChange({ clusterName: call.clusterName, callId: call.uuid, participantId: participant.uuid, volume: volume, gain: undefined })
            )
        )
    );

    callbackSelectedHotkeyHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.CALLBACK_SELECTED),
            concatLatestFrom(() => this.store.select(selectSelectedCall)),
            concatLatestFrom(([, { uuid }]) => this.store.select(selectCallback(uuid))),
            filter(([, selectedCall]) => !!selectedCall),
            map(([[, selectedCall], callback]) => requestRedial({ callId: selectedCall.uuid, clusterName: selectedCall.clusterName, number: callback }))
        )
    );

    /* In order to detect and respond to call re-presentation more quickly, detect represented calls that correlate
     with currently tracked calls that appear on different clusters and trigger removal of the stale records */
    representedCallEarlyCleanup$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(newCall),
            filter(({ call }) => call.rePresented && Boolean(call.nenaCallId)),
            concatLatestFrom(() => this.store.select(selectLiveCalls)),
            mergeMap(([{ call }, calls]) =>
                calls.filter((c) => c.clusterName !== call.clusterName && c.nenaCallId === call.nenaCallId).map((c) => {
                    console.warn(`Re-presented call ${call.uuid} from ${call.clusterName} detected with nenaId: ${call.nenaCallId}. Removing call ${c.uuid} from cluster ${c.clusterName} as a stale record.`);
                    return deleteCall({ call: c, represented: call });
                })
            )
        )
    );

    /* Calls deleted due to representation detection should trigger any associated media sessions to terminate
     such that we can attempt to bid on and/or answer the re-presented call. */
    representedCallSessionCleanup$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCall),
            concatLatestFrom(() => this.store.select(selectMediaConnectionsByClusterNameMap)),
            filter(([{ call, represented }, mediaConnectionsMap]) => represented && mediaConnectionsMap[call.clusterName].session?.callId === call.uuid),
            tap(([{ call, represented }]) => console.warn(`Call ${call.uuid} from ${call.clusterName} removed, initiating cleanup of media session.`)),
            map(([{ call, represented }]) => tearDownMedia( { callId: call.uuid, clusterName: call.clusterName, represented: represented } ))
        )
    );

    /* Directly trigger an answer attempt for re-presented non-acd calls after the media session has been cleaned up. */
    representedCallNonAcdAutoAnswer$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(tearDownMediaSuccess),
            filter(({ represented }) => represented && !represented.acd),
            tap(({ represented }) => console.warn(`Attempting to auto-answer represented call ${represented.uuid}`)),
            map(({ represented }) => requestAnswer({ callId: represented.uuid, clusterName: represented.clusterName }))
        )
    );
}
