/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
    rtiAttachedEvent,
    samAttachedEvent,
    sendUsbDeviceMute,
    sendUsbDeviceMuteFailed,
    sendUsbDeviceMuteSuccess,
    sendUsbDeviceVolume,
    sendUsbDeviceVolumeFailed,
    sendUsbDeviceVolumeSuccess,
    usbEffectsInitialized
} from './usb.actions';
import { updateMediaDevices } from '../../call/+state/media.actions';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { SamAudioService } from '../sam/services/sam-audio.service';
import { SamService } from '../sam/services/sam.service';
import { from, of } from 'rxjs';
import { RTIService } from '../rti/services/rti.service';
import { UsbBaseService } from '../shared/services/usb-base.service';
import { acdAnswerRequestSuccess } from '../../user/+state/user.actions';
import { answerSuccess, irrPlayback } from '../../call/+state/call.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { selectMuteOnAnswerUsbInputs } from './usb.selectors';
import { JackboxMuteMessage } from '../shared/model/report-1/control/jackbox-mute-message';
import { VolumeMessage } from '../shared/model/report-1/control/volume-message';
import { PlaybackAudioMessage } from '../shared/model/report-1/control/playback-audio-message';
import { RtiAudioService } from '../rti/services/rti-audio.service';
import { EnvironmentService } from '../../core/services/environment.service';

@Injectable()
export class UsbEffects implements OnInitEffects {
    private usbService: UsbBaseService;
    constructor(
        private samService: SamService,
        private rtiService: RTIService,
        private actions$: Actions,
        private store: Store,
        private env: EnvironmentService
    ) {
        this.rtiService.initialized.subscribe(() => (this.usbService = this.rtiService));
        this.samService.initialized.subscribe(() => (this.usbService = this.samService));
    }

    ngrxOnInitEffects(): Action {
        return usbEffectsInitialized();
    }

    samAttached$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            filter(() => !this.env.environment.featureFlags?.ccAdminIntegrated || this.env.environment.featureFlags?.enableSAM),
            map(({ devices }) => samAttachedEvent({ isAttached: devices.findIndex((device) => device.label.includes(SamAudioService.SAM_ID)) >= 0 }))
        )
    );

    rtiAttached$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            filter(() => !this.env.environment.featureFlags?.ccAdminIntegrated || this.env.environment.featureFlags?.enableRTI),
            map(({ devices }) => rtiAttachedEvent({ isAttached: devices.findIndex((device) => device.label.includes(RtiAudioService.RTI_ID)) >= 0 }))
        )
    );

    muteUsbDeviceAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendUsbDeviceMute),
            switchMap(({ jackSenseId, muted }) =>
                from(this.usbService.sendReport1(new JackboxMuteMessage()
                    // @ts-ignore
                    .withDevice(JackboxMuteMessage.DEVICE[jackSenseId])
                    .withState(muted ? JackboxMuteMessage.STATE.MUTE : JackboxMuteMessage.STATE.UNMUTE))
                ).pipe(
                    map(() => sendUsbDeviceMuteSuccess()),
                    catchError((err: Error) => of(sendUsbDeviceMuteFailed({ payload: err.message })))
                )
            )
        )
    );

    volumeUsbDeviceAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendUsbDeviceVolume),
            switchMap(({ volumeId, volume }) =>
                from(this.usbService.sendReport1(new VolumeMessage()
                    // @ts-ignore
                    .withDevice(VolumeMessage.VOLUME_DEVICE_ID[volumeId])
                    .withVolume(volume))
                ).pipe(
                    map(() => sendUsbDeviceVolumeSuccess()),
                    catchError((err: Error) => of(sendUsbDeviceVolumeFailed({ payload: err.message })))
                )
            )
        )
    );

    muteOnAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(acdAnswerRequestSuccess, answerSuccess),
            concatLatestFrom(() => this.store.select(selectMuteOnAnswerUsbInputs)),
            mergeMap(([, inputs]) => inputs.map((input) => sendUsbDeviceMute({ jackSenseId: input.jackSenseId, muted: true })))
        )
    );

    irrPlayback$ = createEffect(() =>
        this.actions$.pipe(
            ofType(irrPlayback),
            map(({ isPlaying}) => this.usbService.sendReport1(new PlaybackAudioMessage()
                .withState( isPlaying ? PlaybackAudioMessage.STATE.BEGIN : PlaybackAudioMessage.STATE.END)))
        ),
        { dispatch: false }
    );
}
