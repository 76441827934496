/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum STATE {
    ABORT = 0x00,
    BEGIN = 0x01
}

export class FirmwareUpdateMessage extends ControlMessage {
  public static readonly STATE = STATE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.FIRMWARE_UPDATE;
  }

  public withState(activity: STATE) {
    this.payload1 = activity;
    return this;
  }

  override payload1String(): string {
    return STATE[this.payload1];
  }
}
