/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectClusterNames } from '../+state/configuration.selectors';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { heartbeatRequest } from '../+state/configuration.actions';
import { defer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CtcHeartbeatService {
    public static readonly HEARTBEAT_ENDPOINT = `/api/sync-server-time`;
    private static readonly HEARTBEAT_INTERVAL = 10000;
    private timeout: number;
    private clusterNames: string[] = [];
    private doHeartbeat = () => this.clusterNames.forEach((clusterName) => this.store.dispatch(heartbeatRequest({ clusterName: clusterName })));

    constructor(
        private http: HttpClient,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.store
            .select(selectClusterNames)
            .pipe(filter((val) => Boolean(val.length)))
            .subscribe((clusterNames) => {
                this.clusterNames = clusterNames;
                this.doHeartbeat();
                this.scheduleHeartbeats();
            });
    }

    scheduleHeartbeats() {
        window.clearTimeout(this.timeout);
        this.timeout = window.setInterval(this.doHeartbeat, CtcHeartbeatService.HEARTBEAT_INTERVAL);
    }

    public ping(clusterName: string) {
        return this.http.get<number>(this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, CtcHeartbeatService.HEARTBEAT_ENDPOINT), {
            observe: 'body',
            responseType: 'json'
        });
    }
}
