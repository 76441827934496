/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { effect, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { MediaService } from './media.service';
import {
    selectAlertMediaOutputDevice,
    selectAlertVolume,
    selectCallVolume,
    selectIrrOutputDevice,
    selectIrrVolume,
    selectUserMediaInputDevice,
    selectUserMediaOutputDevice
} from '../+state/media.selectors';
import { getUserMediaFail, getUserMediaSuccess, updateMediaDevices } from '../+state/media.actions';
import { OutputDeviceInterface } from '../model/output-device-interface';
import { InputDeviceInterface } from '../model/input-device-interface';
import { AlertService } from '../../core/audio/alert.service';
import { IrrService } from '../../core/audio/irr.service';
import { debounceTime } from 'rxjs/operators';
import { selectDefaultAudioServiceAlertingEnabled } from '../+state/call.selectors';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class DefaultAudioService {
    private readonly alertsEnabled$: Signal<boolean>;
    private inputDeviceInterface: InputDeviceInterface;
    private outputDeviceInterface: OutputDeviceInterface;
    private alertOutputDeviceInterface: OutputDeviceInterface;
    private irrOutputDeviceInterface: OutputDeviceInterface;
    constructor(
        private store: Store,
        private mediaService: MediaService,
        private alertService: AlertService,
        private irrService: IrrService
    ) {
        this.initializeUserMedia();
        this.monitorMediaDeviceChanges();
        this.mediaService.remoteMediaStream$.subscribe((remoteMediaStream) => this.outputDeviceInterface?.setRemoteMediaStream(remoteMediaStream));
        this.monitorVolume();
        this.alertsEnabled$ = toSignal(this.store.select(selectDefaultAudioServiceAlertingEnabled));
        effect(() => this.alertOutputDeviceInterface ? this.alertOutputDeviceInterface.connected = this.alertsEnabled$() : this.alertsEnabled$());
    }

    private initializeUserMedia() {
        // prompt user for initial microphone access
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            // then find all media devices
            .then(() => navigator.mediaDevices.enumerateDevices().then((devices) => this.store.dispatch(updateMediaDevices({ devices }))))
            .catch((error) => this.store.dispatch(getUserMediaFail({ error: `${error.name} - ${error.message}` })));
    }

    private monitorMediaDeviceChanges() {
        // note: to avoid cc-hub wam code from overriding our devicechange listener, can't use navigator.mediaDevices.ondevicechange
        navigator.mediaDevices.addEventListener('devicechange', () => this.initializeUserMedia());

        this.store
            .select(selectUserMediaInputDevice)
            .pipe(debounceTime(200))
            .subscribe((device) => {
                this.inputDeviceInterface?.destroy();
                this.inputDeviceInterface = undefined;
                if (device) {
                    console.debug(`Input device selected: ${device?.label}`);
                    this.inputDeviceInterface = new InputDeviceInterface(device);
                    this.inputDeviceInterface
                        .init()
                        .then(() => this.mediaService.setLocalMediaStream(this.inputDeviceInterface.mediaStream))
                        .then(() => this.store.dispatch(getUserMediaSuccess()));
                }
            });

        this.store
            .select(selectUserMediaOutputDevice)
            .pipe(debounceTime(200))
            .subscribe((device) => {
                this.outputDeviceInterface?.destroy();
                this.outputDeviceInterface = undefined;
                if (device) {
                    console.debug(`Input device selected: ${device?.label}`);
                    this.outputDeviceInterface = new OutputDeviceInterface(device);
                    this.outputDeviceInterface.init().then(() => this.outputDeviceInterface.setRemoteMediaStream(this.mediaService.remoteMediaStream$.value));
                }
            });

        this.store
            .select(selectAlertMediaOutputDevice)
            .pipe(debounceTime(200))
            .subscribe((alertDevice) => {
                this.alertOutputDeviceInterface?.destroy();
                this.alertOutputDeviceInterface = undefined;
                if (alertDevice) {
                    console.debug(`Alert output device selected: ${alertDevice?.label}`);
                    this.alertOutputDeviceInterface = new OutputDeviceInterface(alertDevice);
                    this.alertOutputDeviceInterface
                        .init()
                        .then(() => this.alertOutputDeviceInterface.setLocalMediaStreams([this.alertService.mediaStream]))
                        .then(() => (this.alertOutputDeviceInterface.connected = this.alertsEnabled$()));
                }
            });

        this.store
            .select(selectIrrOutputDevice)
            .pipe(debounceTime(200))
            .subscribe((irrDevice) => {
                this.irrOutputDeviceInterface?.destroy();
                this.irrOutputDeviceInterface = undefined;
                if (irrDevice) {
                    console.debug(`Irr output device selection changed: ${irrDevice?.label}`);
                    this.irrOutputDeviceInterface = new OutputDeviceInterface(irrDevice);
                    this.irrOutputDeviceInterface.init().then(() => this.irrOutputDeviceInterface.setLocalMediaStreams([this.irrService.mediaStream]));
                }
            });
    }

    public monitorVolume() {
        this.store.select(selectCallVolume).subscribe((volume) => this.outputDeviceInterface?.setAudioVolume(volume));
        this.store.select(selectAlertVolume).subscribe((volume) => this.alertOutputDeviceInterface?.setAudioVolume(volume));
        this.store.select(selectIrrVolume).subscribe((volume) => this.irrOutputDeviceInterface?.setAudioVolume(volume));
    }
}
