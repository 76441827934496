/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Call, CallStatus, DialableEntity, DialedContact, LocalParticipant, QueueJoin, RemoteParticipant, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { SortFunctions } from './sort-functions';
import { callFilter } from '../../settings/model/settings.model';
import { MediaFunctions } from './media-functions';
import { Bid } from '../../user/model/bid';
import { BidResult } from '../../user/model/bid-result';
import { Dictionary } from '@ngrx/entity';
import { HotKeyAction } from '../../core/model/hotkey';
import * as dayjs from 'dayjs';
import { AnalyticsRecentCall } from '../model/analytics-historical-call';

export class CallFunctions {
    public static readonly liveStatuses: CallStatus[] = ['CONNECTED', 'RINGING', 'ON_HOLD', 'TRANSFERRED'];

    public static readonly historicalStatuses: CallStatus[] = ['RELEASED', 'ABANDONED_REDIALING', 'ABANDONED_REDIAL', 'ABANDONED_CLEARED'];

    private static readonly abandonedStatuses: CallStatus[] = ['ABANDONED', 'ABANDONED_INVALID_REDIAL'];

    private static readonly activeCallStatuses: CallStatus[] = ['CONNECTED', 'ON_HOLD'];

    private static readonly callHotkeyOperations: HotKeyAction[] = [HotKeyAction.ANSWER, HotKeyAction.DIAL, HotKeyAction.DIAL_CONTACT, HotKeyAction.CONFERENCE_RELEASE, HotKeyAction.RELEASE, HotKeyAction.CALLBACK_SELECTED, HotKeyAction.DEAFEN, HotKeyAction.HOLD, HotKeyAction.MUTE, HotKeyAction.VOLUME_DOWN, HotKeyAction.VOLUME_UP];

    public static readonly VESTA_ROUTER_CALLER = '911-caller';
    public static readonly VESTA_ROUTER_CALLEE = '911-callee';
    public static readonly VESTA_ROUTER_REFERRED = '911-referred';

    public static isRinging(call: Call, username?: string): boolean {
        return username && call.participants[username] ? call.participants[username].status === 'RINGING' : call.status === 'RINGING';
    }

    public static isActive(callStatus: CallStatus) {
        return this.activeCallStatuses.includes(callStatus);
    }

    public static isConnected(status: CallStatus): boolean {
        return status === 'CONNECTED';
    }

    public static isHeld(call: Call, username?: string): boolean {
        return username && call.participants[username]
            ? Boolean(call.participants[username].held && call.participants[username].leftOn)
            : call.status === 'ON_HOLD';
    }

    public static isMuted(call: Call, username?: string): boolean {
        return username && call.participants[username] ? call.participants[username].muted : false;
    }

    public static isReleased(status: CallStatus): boolean {
        return status === 'RELEASED' || status === 'TRANSFERRED' || this.isAbandoned(status);
    }

    public static isLive(status: CallStatus): boolean {
        return this.liveStatuses.includes(status);
    }

    public static isAbandoned(status: CallStatus): boolean {
        return this.abandonedStatuses.includes(status);
    }

    public static isHistorical(status: CallStatus): boolean {
        return this.historicalStatuses.includes(status);
    }

    public static isParticipant(call: Call, username: string | undefined): boolean {
        return Boolean(username && call.participants && call.participants[username]);
    }

    public static isHeldParticipant(call: Call, username: string | undefined): boolean {
        return username !== undefined && this.isParticipant(call, username) && call.participants[username].held && !call.participants[username].silentMonitor;
    }

    public static isActiveParticipant(call: Call, username: string | undefined): boolean {
        return call && username !== undefined && this.isParticipant(call, username) && !call.participants[username].leftOn;
    }

    public static isInactiveParticipant(call: Call, username: string | undefined): boolean {
        return username !== undefined && this.isParticipant(call, username) && Boolean(call.participants[username].leftOn);
    }

    public static hasActiveParticipants(call: Call): boolean {
        return Object.keys(call.participants)?.length > 0;
    }

    public static getFirstCallTaker(call: Call): string | undefined {
        let participants = Object.values(call.participants);
        return participants?.sort(SortFunctions.joinedOnSort).find((participant) => !participant.leftOn)?.name;
    }

    public static isUserACDAssignedAgent(call: Call, username: string): boolean {
        return Boolean(call.acdAssignedAgent && call.acdAssignedAgent.toUpperCase() === username.toUpperCase());
    }

    public static isUserAssignedOrInvolved(call: Call, username: string) {
        return this.isUserACDAssignedAgent(call, username) || this.isParticipant(call, username);
    }

    public static isUserHolding(call: Call, username: string | undefined): boolean {
        return (
            username !== undefined &&
            this.isActiveParticipant(call, username) &&
            // check user participant held or holding
            (call.participants[username].held || call.participants[username].holding || call.participants[username].status === 'ON_HOLD') &&
            // check no active local participants
            // @ts-ignore
            Object.values(call.participants).every((p) => !!p.leftOn) &&
            // check one remote active participant
            // @ts-ignore
            call.remoteParticipants.filter((p) => !p.leftOn).length === 1
        );
    }

    public static isDialedByUser(call: Call, username: string | undefined): boolean {
        // Sanity Check
        if (!username || !call || call.name !== username || call.text) {
            return false;
        }
        return (
            call.type === 'INTERNAL' ||
            (call.status === 'RINGING' &&
                // @ts-ignore
                !Object.values(call.participants).filter((p) => !p.leftOn).length)
        );
    }

    public static getCallStatus(call: Call, user: UserAuthenticationRecord | undefined): CallStatus | null {
        let callStatus: CallStatus | null;
        if (CallFunctions.ringingMe(call, user)) {
            callStatus = 'RINGING';
        } else if (call && call.externalTransfer) {
            callStatus = 'TRANSFERRED';
        } else {
            callStatus = this.getUserSpecificPropertyFromCall(call, user?.username, 'status') as CallStatus;
        }
        return callStatus;
    }

    public static isCallRingingUser(call: Call, user: UserAuthenticationRecord) {
        const notParticipating = !CallFunctions.isActiveParticipant(call, user?.username) || CallFunctions.isSilentMonitoring(call, user?.username);
        const ringingMe = CallFunctions.ringingMe(call, user);
        return notParticipating && ringingMe;
    }

    public static isRingingPrimaryQueue(call: Call, user: UserAuthenticationRecord) {
        let primaryQueue = CallFunctions.getCurrentQueueJoin(call);
        return Boolean(primaryQueue && user.associatedCallQueues.includes(primaryQueue.queueName));
    }

    public static getOldestTimestamp<T extends { updatedTimestamp: string }>(obj: T[]) {
        if (!obj.length) return 0;
        return obj.reduce((oldest, curr) => {
            return dayjs(curr.updatedTimestamp).isBefore(dayjs(oldest.updatedTimestamp)) ? curr : oldest;
        }).updatedTimestamp;
    }

    public static ringingMe(call: Call, user: UserAuthenticationRecord | undefined) {
        return (
            call &&
            user &&
            call.status !== 'RELEASED' &&
            (call.ringingUsers.includes(user.username) ||
                CallFunctions.getRingingCallQueues(call).some((cq) => user.associatedCallQueues.includes(cq)) ||
                call.acdAssignedAgent === user.username)
        );
    }

    public static getUserSpecificPropertyFromCall(call: Call, username: string | undefined, property: keyof Call | keyof LocalParticipant) {
        if (!call) {
            return null;
        }
        if (username && this.isActiveParticipant(call, username)) {
            let participantProperty = property as keyof LocalParticipant;
            return call.participants[username][participantProperty];
        } else {
            let callProperty = property as keyof Call;
            return call[callProperty];
        }
    }

    // input is list of live calls with username already a participant
    // probably use output list as input for an effect that monitors current active call and the 'priority' list
    // in order to determine if the 'active' call should change
    static prioritySort(username: string | undefined) {
        return (a: Call, b: Call) => {
            return (
                Number(this.isActiveParticipant(b, username)) - Number(this.isActiveParticipant(a, username)) ||
                Number(this.isDialedByUser(b, username)) - Number(this.isDialedByUser(a, username)) ||
                Number(this.isUserHolding(b, username)) - Number(this.isUserHolding(a, username))
            );
        };
    }

    static findMostRecentUserVisibleACDQueueJoin(queueJoins: QueueJoin[] | undefined, userCallQueues: string[] | undefined) {
        return queueJoins && userCallQueues
            ? [...queueJoins]
                  .sort(SortFunctions.oldestCreatedSort)
                  .find((queueJoin) => queueJoin.acd && userCallQueues.includes(queueJoin.queueName) && queueJoin.joinedOn)
            : undefined;
    }

    static isSilentMonitoring(call: Call, username: string | undefined): boolean {
        return Boolean(call && username && this.isActiveParticipant(call, username) && call.participants[username]?.silentMonitor);
    }

    public static getCallTypeIndication(call: Call) {
        const latestQueueJoin = CallFunctions.getLatestQueueJoin(call);
        if (call.agencyDiversion) {
            return 'Diversion';
        } else if (latestQueueJoin?.supervisorAssistance) {
            return 'Assistance';
        } else if (call.type === 'INTERNAL') {
            return 'Intercom';
        } else if (call.rePresented) {
            return 'Recovery';
        }
        return undefined;
    }

    public static isAssistanceRequest(call: Call) {
        const latestQueueJoin = CallFunctions.getLatestQueueJoin(call);
        return latestQueueJoin?.supervisorAssistance;
    }

    public static getOriginatingUsername(call: Call) {
        // Internal calls do not have queues
        if (call.type === 'INTERNAL') {
            return Object.values(call.participants).sort(SortFunctions.joinedOnSort)[0]?.name;
        } else {
            const latestQueueJoin = CallFunctions.getLatestQueueJoin(call);
            if (latestQueueJoin) {
                return latestQueueJoin.originatingUsername;
            }
        }
        return undefined;
    }

    public static getLatestQueueJoin(call: Call) {
        return [...call.queueJoins]?.sort(SortFunctions.joinedOnSort).reverse()[0];
    }

    public static getCurrentQueueJoin(call: Call) {
        return call.queueJoins.find((queueJoin) => !queueJoin.leftOn);
    }

    public static getRingingCallQueues(call: Call) {
        let currentQueue = CallFunctions.getCurrentQueueJoin(call);
        return currentQueue ? [currentQueue.queueName, ...currentQueue.cascades] : [];
    }

    public static filterCallsByTableFilter(calls: Call[], filters: callFilter[]) {
        return calls.filter((call) => {

            if (call.status && filters.includes(call.status.toLowerCase() as callFilter)) {
                return false;
            }

            if (call.type && filters.includes(call.type.toLowerCase() as callFilter)) {
                return false;
            }

            const callType = call.priority.emergency ? 'emergency' : 'nonEmergency';

            return !(call.priority && filters.includes(callType));
        });
    }

    public static filterRecentCalls(calls: Call[], filters: callFilter[], lockedCallIds: string[]) {
        return CallFunctions.filterCallsByTableFilter(calls, filters).filter((call) => {
            return filters.includes('locked') ? !lockedCallIds.includes(call.uuid)
                : filters.includes('unlocked') ? lockedCallIds.includes(call.uuid) : true;
        });
    }

    public static networkParticipants(call: Call) {
        // Vesta router fallback: assume unmatched network participant is '911-callee'
        let callFound = call.conferenceSnapshot?.participants
            .some((participant) => MediaFunctions.hasAgencySubdomain(participant.uri, call.agencyId));

        return call.conferenceSnapshot
            ? call.conferenceSnapshot.participants
                .filter((participant) => !MediaFunctions.hasAgencySubdomain(participant.uri, call.agencyId))
                .filter((participant) => callFound || !callFound && participant.displayName !== CallFunctions.VESTA_ROUTER_CALLEE)
                .filter((participant) => !participant.leaveTime || call.status === 'RELEASED')
            : [];
    }

    public static mapNetworkParticipantsToLinkedCalls(call: Call, linkedCalls: Call[]) {
        let calls = [call, ...linkedCalls];
        let conferenceSnapshot = call?.conferenceSnapshot;
        let result: { [participantId: string]: Call } = {};
        // associate network participants with calls via sip uri's containing the agency id
        conferenceSnapshot?.participants
            .filter((p) => !p.leaveTime || CallFunctions.isReleased(call.status))
            .forEach((p) => (result[p.id] = calls.find((call) => MediaFunctions.hasAgencySubdomain(p.uri, call.agencyId))));

        // Vesta router fallback: assume unmatched network participant '911-callee' is associated with the unmatched linked call
        let vestaRouterCallee = conferenceSnapshot?.participants
            .filter((p) => !p.leaveTime || CallFunctions.isReleased(call.status))
            .filter((p) => p.displayName === '911-callee')[0];

        if (vestaRouterCallee) {
            // find unmatched calls that couldn't be associated with a sip uri and assocaite it with 911-callee
            result[vestaRouterCallee.id] = calls.filter((call) =>
                !Object.entries(result).filter(([,matchedCall]) => !!matchedCall)
                    .map(([, matchedCall]) => matchedCall).includes(call))[0];
        }

        return result;
    }

    public static dialedContactsFilter() {
        return (c: DialedContact) =>
            !c.dialEnd || (c.sipRefer && c.status === 'ANSWERED' && !c.conferenceParticipantAnswered);
    }

    public static localParticipantFilter(username: string, call: Call) {
        return (lp: LocalParticipant) =>
            (!lp.silentMonitor || lp.name === username) && ((call.status === 'ON_HOLD' && lp.held) || !lp.leftOn || call.status === 'RELEASED');
    }

    public static remoteParticipantFilter(call: Call) {
        return (rp: RemoteParticipant) =>
            !call.conferenceSnapshot && (call.status === 'RELEASED' || !rp.leftOn && !rp.remoteAgencyId);
    }

    // Ignores all remote participants if this call has a focus server being used to bridge.
    public static participantCount(call: Call, username: string) {
        return (
            call.dialedContacts.filter(CallFunctions.dialedContactsFilter()).length +
            Object.values(call.participants).filter(CallFunctions.localParticipantFilter(username, call)).length +
            call.remoteParticipants.filter(CallFunctions.remoteParticipantFilter(call)).length
        );
    }

    public static lastParticipant(call: Call, username: string) {
        let participants: (LocalParticipant | RemoteParticipant)[] = [];
        participants = participants.concat(call.remoteParticipants.filter((participant) => !participant.leftOn));
        participants = participants.concat(
            Object.values(call.participants).filter((participant) => !participant.leftOn && participant.name !== username)
        );
        participants.sort(SortFunctions.joinedOnSort).reverse();
        return participants[0];
    }

    public static lastPendingParticipant(call: Call) {
        return call.dialedContacts?.filter(c => !c.dialEnd).sort(SortFunctions.newestCreatedSort)[0];
    }

    public static isVestaNetworkDisplayName(displayName: string) {
        return displayName === CallFunctions.VESTA_ROUTER_CALLER ||displayName === CallFunctions.VESTA_ROUTER_CALLEE || displayName === CallFunctions.VESTA_ROUTER_REFERRED;
    }

    public static isCallHotkeyOperation(action: HotKeyAction) {
        return this.callHotkeyOperations.includes(action);
    }

    public static createBids(ringingCalls: Call[], bidsMap: Dictionary<BidResult>, previousNenaId: string) {
        return ringingCalls
            .sort(SortFunctions.acdPrioritySort)
            .flatMap((call) =>
                call.queueJoins
                    .filter((qj) => qj.acd && !qj.leftOn && qj.auctionId && !Boolean(bidsMap[call.uuid] && bidsMap[call.uuid]?.auctionId === qj.auctionId))
                    .map(
                        (queueJoin) =>
                            ({
                                callId: call.uuid,
                                nenaId: call.nenaCallId,
                                clusterName: call.clusterName,
                                queueJoin: queueJoin,
                                previouslyAssigned: Boolean(call.nenaCallId && call.nenaCallId === previousNenaId)
                            }) as Bid
                    )
            );
    }

    public static fromAnalyticsRecentCall(call: AnalyticsRecentCall, username: string) {
        return {
            uuid: call.chsCallId,
            clusterName: call.clusterName,
            callQueues: [call.queue],
            type: call.direction === 'Outgoing' ? 'OUTBOUND' : 'INBOUND',
            text: call.callMedia === 'SMS',
            queueJoins:
                call.queue && call.queue.length
                    ? [
                        {
                            queueName: call.queue,
                            leftOn: call.callEndDateTime
                        }
                    ]
                    : [],
            participants: {
                [username]: {
                    joinedOn: call.agentStartDateTime,
                    leftOn: call.agentEndDateTime
                }
            },
            callback: call.callbackNumber,
            priority: { name: call.priority },
            createdTimestamp: call.callStartDateTime,
            releasedOn: call.callEndDateTime,
            status: 'HISTORICAL'
        } as unknown as Call;
    }

    public static hasParticipantWithNumber(call: Call, number: string) {
        if (!call || !number) {
            return false;
        }
        let participants : DialableEntity[] = [...call.remoteParticipants];
        participants.push(...Object.values(call.participants).map((lp) => (lp as DialableEntity)));
        participants.push(...call.dialedContacts.map((dc) => (dc as DialableEntity)));
        return participants.some((p) => p.number?.includes(number)) || call.callback?.includes(number);
    }
}
