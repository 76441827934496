/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, skip, switchMap, tap } from 'rxjs/operators';
import {
    createMediaConnection,
    mediaEffectsInitialized,
    selectedAlertOutputDevice,
    selectedAlertOutputDeviceFailed,
    selectedIrrOutputDevice,
    selectedIrrOutputDeviceFailed,
    selectedUserInputDevice,
    selectedUserInputDeviceFailed,
    selectedUserOutputDevice,
    selectedUserOutputDeviceFailed,
    setAlertVolume,
    setCallVolume,
    setIrrVolume,
    setSipDebug,
    tearDownMedia,
    tearDownMediaFailure,
    tearDownMediaSuccess,
    updateMediaDevices
} from './media.actions';
import { from, of } from 'rxjs';
import * as JsSIP from 'jssip';
import { Action, Store } from '@ngrx/store';
import { MediaService } from '../services/media.service';
import { updateAudioSettings } from '../../settings/+state/settings.actions';
import {
    selectAudioSettings,
    selectPreferredAlertDevice,
    selectPreferredInputDevice,
    selectPreferredOutputDevice
} from '../../settings/+state/settings.selectors';
import { DefaultAudioService } from '../services/default-audio.service';
import { NetworkAlertService } from '../services/network-alert.service';
import { fetchCtcConfigurationSuccess, sipPeerPasswordRequest, sipPeerRequest } from '../../configuration/+state/configuration.actions';
import { MediaConnection } from '../../core/model/media-connection';
import { alertToDevice, alertToHeadsets, irrToDevice, irrToHeadsets, setPeripheralVolume } from './cchub.actions';
import { CchubService } from '../services/cchub.service';
import { concatLatestFrom } from '@ngrx/operators';
import { alertToUsbHeadsets, irrToUsbHeadsets, usbAlertToDevice, usbIrrToDevice } from '../../usb/+state/usb.actions';

@Injectable()
export class MediaEffects implements OnInitEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private mediaService: MediaService,
        private networkAlertService: NetworkAlertService,
        private defaultAudioService: DefaultAudioService
    ) {}

    ngrxOnInitEffects(): Action {
        return mediaEffectsInitialized();
    }

    createMediaConnections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCtcConfigurationSuccess),
            mergeMap(({ config }) =>
                config.clusters.map((cluster) => createMediaConnection({ mediaConnection: ({...cluster, socket: MediaService.getSocketUrl(cluster) } as MediaConnection) }))
            )
        )
    );

    initializeMediaConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createMediaConnection),
            mergeMap(({ mediaConnection }) =>
                [sipPeerRequest( { clusterName: mediaConnection.name } ), sipPeerPasswordRequest({ clusterName: mediaConnection.name })]
            )
        )
    );


    setPreferredInput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            concatLatestFrom(() => this.store.select(selectPreferredInputDevice)),
            switchMap(([, deviceId]) =>
                from(navigator.mediaDevices.enumerateDevices()).pipe(
                    map((mediaDevices) =>
                        selectedUserInputDevice({
                            deviceId: mediaDevices
                                .filter((md) => md.kind === 'audioinput' && !md.label.includes(CchubService.CCH_ID))
                                .sort(MediaService.mediaDevicePrioritySort)
                                .sort((a, b) => Number(b.deviceId === deviceId) - Number(a.deviceId === deviceId))[0].deviceId
                        })
                    ),
                    catchError((err: Error) => of(selectedUserInputDeviceFailed({ payload: err.message })))
                )
            )
        )
    );

    setPreferredOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            concatLatestFrom(() => this.store.select(selectPreferredOutputDevice)),
            switchMap(([, deviceId]) =>
                from(navigator.mediaDevices.enumerateDevices()).pipe(
                    map((mediaDevices) =>
                        selectedUserOutputDevice({
                            deviceId: mediaDevices
                                .filter((md) => md.kind === 'audiooutput' && !md.label.includes(CchubService.CCH_ID))
                                .sort(MediaService.mediaDevicePrioritySort)
                                .sort((a, b) => Number(b.deviceId === deviceId) - Number(a.deviceId === deviceId))[0].deviceId
                        })
                    ),
                    catchError((err: Error) => of(selectedUserOutputDeviceFailed({ payload: err.message })))
                )
            )
        )
    );

    setPreferredAlertOutput$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaDevices),
            concatLatestFrom(() => this.store.select(selectPreferredAlertDevice)),
            switchMap(([, deviceId]) =>
                from(navigator.mediaDevices.enumerateDevices()).pipe(
                    map((mediaDevices) =>
                        selectedAlertOutputDevice({
                            deviceId: mediaDevices
                                .filter((md) => md.kind === 'audiooutput' && !md.label.includes(CchubService.CCH_ID))
                                .sort(MediaService.mediaDeviceDefaultSort)
                                .sort((a, b) => Number(b.deviceId === deviceId) - Number(a.deviceId === deviceId))[0].deviceId
                        })
                    ),
                    catchError((err: Error) => of(selectedAlertOutputDeviceFailed({ payload: err.message })))
                )
            )
        )
    );

    setPreferredIrrOutput$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateMediaDevices),
            switchMap(() =>
                from(navigator.mediaDevices.enumerateDevices()).pipe(
                    map((mediaDevices) => selectedIrrOutputDevice({ deviceId: mediaDevices
                        .filter((md) => md.kind === 'audiooutput' && !md.label.includes(CchubService.CCH_ID))
                        .sort(MediaService.mediaDeviceDefaultSort)[0].deviceId })),
                    catchError((err: Error) => of(selectedIrrOutputDeviceFailed({ payload: err.message })))
                )
            )
        );
    });

    initializeSipDebug$  = createEffect(() =>
        this.actions$.pipe(
            ofType(mediaEffectsInitialized),
            map(() => setSipDebug({ enable: Boolean(JsSIP.debug.disable()) })),
        )
    );

    toggleSipDebug$ = createEffect(() =>
            this.actions$.pipe(
                ofType(setSipDebug),
                tap(({ enable }) => (enable ? JsSIP.debug.enable('JsSIP:*') : JsSIP.debug.disable()))
            ),
        { dispatch: false }
    );

    saveInputDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectedUserInputDevice),
            skip(1),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ deviceId }, audioSettings]) => deviceId !== audioSettings.mainInputDeviceId),
            map(([{ deviceId }]) => updateAudioSettings({ settings: { mainInputDeviceId: deviceId || 'default' } }))
        )
    );

    saveOutputDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectedUserOutputDevice),
            skip(1),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ deviceId }, audioSettings]) => deviceId !== audioSettings.mainOutputDeviceId),
            map(([{ deviceId }]) => updateAudioSettings({ settings: {mainOutputDeviceId: deviceId || 'default' } }))
        )
    );

    saveAlertOutputDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectedAlertOutputDevice),
            skip(1),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ deviceId }, audioSettings]) => deviceId !== audioSettings.alertOutputDeviceId),
            map(([{ deviceId }]) => updateAudioSettings({ settings: {alertOutputDeviceId: deviceId || 'default' } }))
        )
    );

    saveIRROutputDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(selectedIrrOutputDevice),
            skip(1),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ deviceId }, audioSettings]) => deviceId !== audioSettings.irrOutputDeviceId),
            map(([{ deviceId }]) => updateAudioSettings({ settings: {irrOutputDeviceId: deviceId || 'default' } }))
        );
    });

    saveCallVolume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setCallVolume),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ volume }, audioSettings]) => volume !== audioSettings.callOutputVolume),
            map(([{ volume }]) => updateAudioSettings({ settings: { callOutputVolume: volume || 5 }}))
        );
    });
    saveAlertVolume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setAlertVolume),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ volume }, audioSettings]) => volume !== audioSettings.alertOutputVolume),
            map(([{ volume }]) => updateAudioSettings({ settings: { alertOutputVolume: volume || 5 }}))
        );
    });
    saveIRRVolume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setIrrVolume),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ volume }, audioSettings]) => volume !== audioSettings.irrOutputVolume),
            map(([{ volume }]) => updateAudioSettings({ settings: { irrOutputVolume: volume || 5 }}))
        );
    });
    saveAlertToDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alertToDevice, usbAlertToDevice),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ alert }, audioSettings]) => alert !== audioSettings.alertToDevice),
            map(([{ alert }]) => updateAudioSettings({ settings: { alertToDevice: alert }}))
        );
    });
    saveIrrToDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(irrToDevice, usbIrrToDevice),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ alert }, audioSettings]) => alert !== audioSettings.irrToDevice),
            map(([{ alert }]) => updateAudioSettings({ settings: { irrToDevice: alert }}))
        );
    });
    saveAlertToHeadset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alertToHeadsets, alertToUsbHeadsets),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ alert }, audioSettings]) => alert !== audioSettings.alertToHeadsets),
            map(([{ alert }]) => updateAudioSettings({ settings: { alertToHeadsets: alert }}))
        );
    });
    saveIrrToHeadset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(irrToHeadsets, irrToUsbHeadsets),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ alert }, audioSettings]) => alert !== audioSettings.irrToHeadsets),
            map(([{ alert }]) => updateAudioSettings({ settings: { irrToHeadsets: alert }}))
        );
    });
    saveVolumeStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setPeripheralVolume),
            concatLatestFrom(() => this.store.select(selectAudioSettings)),
            filter(([{ peripheral, volume }, audioSettings]) => !audioSettings.volumeStatus || volume !== audioSettings.volumeStatus[peripheral]?.volume),
            map(([{ peripheral, volume }, audioSettings]) => {
                let volumeStatus = { ...audioSettings.volumeStatus };
                volumeStatus[peripheral] = { volume: volume };
                return updateAudioSettings({ settings: { volumeStatus: volumeStatus } });
            })
        );
    });

    orphanedSessionTeardown$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tearDownMedia),
            switchMap(({ represented }) =>
                this.mediaService.destroySession().pipe(
                    map(() => tearDownMediaSuccess({ represented })),
                    catchError((err: Error) => of(tearDownMediaFailure()))
                )
            )
        )
    );

}
