/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
    conferenceAdrSpeedDial,
    dialAgent,
    dialContact,
    dialNumber,
    dialOrConference,
    dialPadBackspace,
    dialPadClear,
    dialPadDial,
    dialPadInput,
    dialSpeedDial,
    directoryEffectsInitialized,
    fetchContacts,
    fetchContactsSuccess,
    fetchDirectory,
    fetchDirectoryEntries,
    fetchDirectoryEntriesSuccess,
    fetchSpeedDialContactsSuccess,
    fetchSpeedDialLayouts,
    fetchSpeedDialLayoutsFailure,
    fetchSpeedDialLayoutsSuccess,
    fetchRingdowns,
    fetchRingdownsSuccess,
    hookFlash,
    hookFlashFailure,
    hookFlashSuccess,
    initiateSMSCallForContact,
    initiateSMSCallForContactFail,
    initiateSMSCallForNumber,
    initiateSMSCallForNumberFail,
    initiateSMSCallForNumberSuccess,
    requestInitiateSMSCallForNumber,
    dialRingDown
} from './directory.actions';
import { catchError, debounceTime, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { CallService } from '../../call/services/call.service';
import {
    selectActiveVoiceCall,
    selectLiveSmsCallForNumber,
    selectSelectedCall
} from '../../call/+state/call.selectors';
import { selectCurrentRole, selectUsername } from '../../user/+state/user.selectors';
import { DirectoryFunctions } from '../util/directory-functions';
import { selectContactsMap, selectRestrictedSpeedDials } from './directory.selectors';
import { defer, Observable, of, retry, timer } from 'rxjs';
import {
    conference,
    dial,
    requestHold,
    requestJoin,
    requestUnhold,
    setObservationState
} from '../../call/+state/call.actions';
import { hotKeyTriggered } from '../../configuration/+state/configuration.actions';
import { selectPreferredOutboundCallClusterName } from '../../call/+state/media.selectors';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { SkipperService } from '../../core/services/skipper.service';
import { concatLatestFrom } from '@ngrx/operators';
import { HotKeyAction } from '../../core/model/hotkey';
import { SortFunctions } from '../../call/util/sort-functions';
import { RingdownAction } from '../directory-ringdowns-table/Ringdown';

@Injectable()
export class DirectoryEffects implements OnInitEffects {
    constructor(
        private skipperService: SkipperService,
        private callService: CallService,
        private store: Store,
        private actions$: Actions
    ) {
    }

    ngrxOnInitEffects(): Action {
        return directoryEffectsInitialized();
    }

    initiateDirectory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDirectory),
            switchMap(() => [fetchContacts(), fetchDirectoryEntries(), fetchSpeedDialLayouts(), fetchRingdowns()])
        )
    );

    fetchContacts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchContacts),
            switchMap(() =>
                defer(() => this.skipperService.requestContacts()).pipe(
                    map((response) => fetchContactsSuccess({ contacts: response ? response : [] })),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    fetchRingdowns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchRingdowns),
            switchMap(() =>
                this.store.select(selectCurrentRole).pipe(
                    filter((role) => !!role),
                    switchMap((currentRole) =>
                        defer(() => this.skipperService.fetchRingdowns(currentRole)).pipe(
                            map((response) => fetchRingdownsSuccess({ ringdowns: response ? response : [] })),
                            retry({ delay: () => timer(5000) })
                        )
                    )))
        ));

    fetchSpeedDialLayouts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchSpeedDialLayouts),
            switchMap(() =>
                defer(() => this.skipperService.requestSpeedDialLayouts()).pipe(
                    retry({ delay: () => timer(5000) }),
                    map((res) =>
                        fetchSpeedDialLayoutsSuccess({
                            layouts: Object.entries(res).map(([esn, speedDials]) => ({
                                esn,
                                speedDials: speedDials
                            }))
                        })
                    ),
                    catchError((err) => of(fetchSpeedDialLayoutsFailure({ payload: err.message })))
                )
            )
        )
    );

    fetchFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDirectoryEntries),
            switchMap(() =>
                defer(() => this.skipperService.requestDirectoryEntries()).pipe(
                    map((response) => fetchDirectoryEntriesSuccess({ entries: response ? response : [] })),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    fetchSpeedDialContacts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDirectoryEntriesSuccess, fetchContactsSuccess),
            debounceTime(500),
            concatLatestFrom(() => [this.store.select(selectRestrictedSpeedDials)]),
            mergeMap(([, speedDials]) =>
                this.skipperService.fetchSpeedDialContact(speedDials).pipe(
                    map((response) => fetchSpeedDialContactsSuccess({ contacts: response ? response : {} })),
                    retry({ delay: () => timer(5000) })
                )
            )
        )
    );

    dialSpeedDial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialSpeedDial),
            concatLatestFrom(() => this.store.select(selectContactsMap)),
            map(([{ speedDial }, contactsMap]) =>
                dialOrConference({
                    source: 'SPEED_DIAL',
                    contactId: speedDial.uuid,
                    number: speedDial.contact.destination,
                    label: speedDial.name,
                    blindTransfer: speedDial.blindTransfer,
                    destinations: contactsMap[speedDial.contact.uuid] ?
                        [...contactsMap[speedDial.contact.uuid].destinations]
                            .sort(SortFunctions.isDefaultSort)
                            .map((dest) => dest.destination) :
                        [speedDial.contact.destination]
                })
            )
        )
    );

    conferenceAdrSpeedDial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(conferenceAdrSpeedDial),
            concatLatestFrom(() => this.store.select(selectActiveVoiceCall)),
            map(([{ speedDial }, call]) =>
                // @ts-ignore
                conference({
                    callId: call.uuid,
                    clusterName: call.clusterName,
                    number: speedDial.uri,
                    source: 'SPEED_DIAL',
                    label: speedDial.name,
                    destinations: Array.of(speedDial.uri)
                })
            )
        )
    );

    dialContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialContact),
            map(({ contact }) =>
                dialOrConference({
                    number: DirectoryFunctions.getDefaultDestination(contact.destinations),
                    source: 'DIRECTORY_CONTACT',
                    label: contact.name,
                    contactId: contact.uuid,
                    blindTransfer: false,
                    destinations: [...contact.destinations]
                        .sort(SortFunctions.isDefaultSort)
                        .map((dest) => dest.destination)
                })
            )
        )
    );

    dialAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialAgent),
            map(({ agent }) =>
                dialOrConference({
                    number: agent.peerId,
                    source: 'AGENT_LIST',
                    label: agent.lastName + ', ' + agent.firstName,
                    destinations: Array.of(agent.peerId)
                })
            )
        )
    );

    dialNumber$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialNumber),
            map(({ number, label }) => dialOrConference({
                number,
                source: 'DIAL_PAD',
                label,
                destinations: Array.of(number)
            }))
        )
    );

    dialOrConference$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialOrConference),
            concatLatestFrom(() => [this.store.select(selectActiveVoiceCall), this.store.select(selectSelectedCall), this.store.select(selectUsername)]),
            mergeMap(([action, activeVoiceCall, selectedCall, username]) => {
                let callToHold =
                    activeVoiceCall && (!selectedCall || (selectedCall && selectedCall.uuid !== activeVoiceCall.uuid)) ? activeVoiceCall : undefined;
                let callToConference =
                    selectedCall && username && selectedCall.participants[username] && !selectedCall.participants[username].leftOn ? selectedCall : undefined;
                return callToHold
                    ? [
                        setObservationState({ observationState: 'suspended' }),
                        requestHold({
                            callId: callToHold.uuid,
                            clusterName: callToHold.clusterName,
                            callback: action
                        })
                    ]
                    : callToConference
                        ? [
                            setObservationState({ observationState: 'suspended' }),
                            conference({
                                callId: callToConference.uuid,
                                clusterName: callToConference.clusterName,
                                number: action.number,
                                source: action.source,
                                label: action.label,
                                contactId: action?.contactId,
                                blindTransfer: action?.blindTransfer,
                                destinations: action?.destinations
                            })
                        ]
                        : [
                            setObservationState({ observationState: 'suspended' }),
                            dial({
                                number: action.number,
                                source: action.source,
                                label: action.label,
                                id: action?.contactId,
                                destinations: action?.destinations
                            })
                        ];
            })
        )
    );

    initiateSMSCallForContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initiateSMSCallForContact),
            map(({ contact }) => {
                let number = DirectoryFunctions.getDefaultDestination(contact.destinations);
                return number ? requestInitiateSMSCallForNumber({ number }) : initiateSMSCallForContactFail({ payload: 'No number found for this contact.' });
            })
        )
    );

    requestInitiateSMSCallForNumber$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(requestInitiateSMSCallForNumber),
            concatLatestFrom(({ number }) => this.store.select(selectLiveSmsCallForNumber(number))),
            map(([{ number }, call]) =>
                call
                    ? displayToastNotification({
                        message: 'Denied: there is already an active outbound text session with that phone number',
                        level: ToastType.error
                    })
                    : initiateSMSCallForNumber({ number: number })
            )
        )
    );

    initiateSMSCallForNumber$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(initiateSMSCallForNumber),
            concatLatestFrom(() => this.store.select(selectPreferredOutboundCallClusterName)),
            switchMap(([{ number }, clusterName]) =>
                this.callService.createTextCall(number, clusterName).pipe(
                    map(() => initiateSMSCallForNumberSuccess({ number })),
                    catchError((err: Error) => of(initiateSMSCallForNumberFail({ payload: err.message })))
                )
            )
        )
    );

    inputHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.INPUT),
            map(({ hotkey }) => dialPadInput({ input: hotkey.params[0] }))
        )
    );

    backspaceHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.BACKSPACE),
            map(() => dialPadBackspace())
        )
    );

    clearHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.CLEAR),
            map(() => dialPadClear())
        )
    );

    dialHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.DIAL),
            map(() => dialPadDial())
        )
    );

    dialContactHandler$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(hotKeyTriggered),
            filter(({ hotkey }) => hotkey.action === HotKeyAction.DIAL_CONTACT),
            concatLatestFrom(() => this.store.select(selectContactsMap)),
            map(([{ hotkey }, contactsMap]) => dialContact({ contact: contactsMap[hotkey.params[0]] }))
        )
    );

    hookFlash$ = createEffect(() =>
        this.actions$.pipe(
            ofType(hookFlash),
            concatLatestFrom(() => this.store.select(selectSelectedCall)),
            filter((v) => !!v),
            switchMap(([, call]) =>
                this.callService.hookFlash(call.clusterName, call.uuid).pipe(
                    map(() => hookFlashSuccess()),
                    catchError((err) => of(hookFlashFailure({ payload: err?.message })))
                )
            )
        )
    );

    dialRingDown$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dialRingDown),
            filter(({ringDown}) => !!ringDown),
            map((({ringDown, callSelected}) => {
                const action = DirectoryFunctions.getRingDownAction(ringDown, callSelected);
                switch (action) {
                    case RingdownAction.UNHOLD:
                        return requestUnhold({
                            callId: ringDown.call.uuid,
                            clusterName: ringDown.call.clusterName
                        });
                    case RingdownAction.JOIN:
                        return requestJoin({
                            callId: ringDown.call.uuid,
                            clusterName: ringDown.call.clusterName
                        });
                    default:
                        return dialOrConference({
                            contactId: null,
                            label: ringDown.did,
                            number: ringDown.did,
                            destinations: Array.of(ringDown.did),
                            source: 'RINGDOWN'
                        });
                }
            })
        ))
    );
}
