/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { EventMessage } from './abstract-event-message';

enum QUERY_STATUS {
    END_OF_QUERY = 0x00,
    BEGINNING_OF_QUERY = 0x01
}

export class QueryResponseStatusMessage extends EventMessage {
    public static readonly QUERY_STATUS = QUERY_STATUS;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = EventMessage.EventMessageIdentifier.QUERY_RESPONSE_STATUS;
    }

    public withQueryStatus(status: QUERY_STATUS) {
        this.payload1 = status;
        return this;
    }

    override payload1String(): string {
        return QUERY_STATUS[this.payload1];
    }
}
