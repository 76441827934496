/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIMAlert } from '../../configuration/+state/configuration.selectors';
import { AudibleAlert } from '../../call/model/audible-alert';
import { filter } from 'rxjs/operators';
import { AlertService } from '../../core/audio/alert.service';

@Injectable({
    providedIn: 'root'
})
export class IntercomAlertService {
    private alert: AudibleAlert | undefined;
    private audio: HTMLAudioElement | undefined;
    private mediaElementAudioSourceNode: MediaElementAudioSourceNode;

    constructor(
        private store: Store,
        private alertService: AlertService
    ) {
        this.store
            .select(selectIMAlert)
            .pipe(filter((val) => !!val))
            .subscribe((alert) => {
                this.alert = alert;
                // TODO migrate to skipper when available. hard coded local files for now
                this.audio = this.alert ? new Audio(`assets/sounds/${this.alert.sound}.ogg`) : undefined;
                this.mediaElementAudioSourceNode = new MediaElementAudioSourceNode(this.alertService.audioContext, { mediaElement: this.audio });
                this.mediaElementAudioSourceNode.connect(this.alertService.mixerNode);
            });
    }

    public playAlert() {
        this.alertService.checkContext();
        this.audio
            ?.play()
            .then(() => console.info(`playing intercom alert`))
            .catch((e) => console.warn(`Failed to play message alert audio: ${JSON.stringify(e, undefined, 1)}`));
    }
}
