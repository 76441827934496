/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

enum DETECTION {
  AUTO = 0x00,
  ELECTRET_ONLY = 0x01,
  CARBON_ONLY = 0x02
}

export class HeadsetMicMessage extends ConfigurationMessage {
  public static readonly DETECTION = DETECTION;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.HEADSET_MIC;
  }

  public withDetection(detection: DETECTION) {
    this.payload1 = detection;
    return this;
  }

  override payload1String(): string {
      return DETECTION[this.payload1];
  }
}
