/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

export class ControlOutOfRangeMessage extends ControlMessage {

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.OUT_OF_RANGE;
    }

    override payload1String(): string {
        return ControlMessage.ControlMessageIdentifier[this.payload1];
    }
}
