/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCallAlerts } from '../../configuration/+state/configuration.selectors';
import { CallAlertTracker } from '../model/call-alert-tracker';
import { Call, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { selectMemoizedUserAuthenticationRecord } from '../../user/+state/user.selectors';
import { CallAlertFunctions } from '../util/call-alert-functions';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import { CallAlert } from '../model/audible-alert';
import { AlertService } from '../../core/audio/alert.service';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import {
    callAlertDelete,
    callAlertUpdate
} from '../../configuration/+state/configuration.actions';
import * as uuid from 'uuid';
import { StompService } from '../../core/services/stomp.service';
import { StompConsumer } from '../../core/abstract/stomp-consumer';

@Injectable({
    providedIn: 'root'
})
export class CallAlertService implements OnDestroy, StompConsumer {
    private readonly CALL_ALERT_UPDATE_TOPIC = '/topic/v1/call-alert/update';
    private readonly CALL_ALERT_NEW_TOPIC = '/topic/v1/call-alert/new';
    private readonly CALL_ALERT_DELETE_TOPIC = '/topic/v1/call-alert/delete';

    private user: UserAuthenticationRecord | undefined;
    private callAlertTrackers: CallAlertTracker[] = [];
    private readonly updateReceipt = uuid.v4();
    private readonly newReceipt = uuid.v4();
    private readonly deleteReceipt = uuid.v4();
    private readonly callAlertUpdateTopicSubscription: Subscription | undefined;
    private readonly callAlertNewTopicSubscription: Subscription | undefined;
    private readonly callAlertDeleteTopicSubscription: Subscription | undefined;

    constructor(
        private http: HttpClient,
        private store: Store,
        private alertService: AlertService,
        private stompService: StompService
    ) {
        this.store.select(selectMemoizedUserAuthenticationRecord).subscribe((user) => (this.user = user));
        this.store.select(selectCallAlerts)
            .pipe(switchMap((callAlerts) =>
                forkJoin(callAlerts.filter((callAlert) =>
                    callAlert.filterConstraints.every((c) =>
                        CallAlertFunctions.MatchFunctions.hasOwnProperty(c.function)))
                    .map((callAlert) => this.getCallAlertTracker(callAlert)))))
            .subscribe((callAlertTrackers) => this.callAlertTrackers = callAlertTrackers);

        this.callAlertUpdateTopicSubscription = this.stompService
                .watchAsync(this.CALL_ALERT_UPDATE_TOPIC, this.updateReceipt, this).subscribe((message: IMessage) =>
            this.store.dispatch(callAlertUpdate({ callAlert: JSON.parse(message.body) })));

        this.callAlertNewTopicSubscription = this.stompService
            .watchAsync(this.CALL_ALERT_NEW_TOPIC, this.newReceipt, this).subscribe((message: IMessage) =>
                this.store.dispatch(callAlertUpdate({ callAlert: JSON.parse(message.body) })));

        this.callAlertDeleteTopicSubscription = this.stompService
            .watchAsync(this.CALL_ALERT_DELETE_TOPIC, this.deleteReceipt, this).subscribe((message: IMessage) =>
                this.store.dispatch(callAlertDelete({ callAlert: JSON.parse(message.body) })));

    }

    ngOnDestroy(): void {
        if (this.callAlertUpdateTopicSubscription) {
            this.callAlertUpdateTopicSubscription.unsubscribe();
        }
        if (this.callAlertNewTopicSubscription) {
            this.callAlertNewTopicSubscription.unsubscribe();
        }
        if (this.callAlertDeleteTopicSubscription) {
            this.callAlertDeleteTopicSubscription.unsubscribe();
        }
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Call alert topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Call alert topic ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Call alert topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    public getCallAlertTracker(callAlert: CallAlert) {
        return this.getAudioBuffer(`${this.alertService.audioFileUrl}${callAlert.fileId}`).pipe(
            map((audioBuffer) => {
                return { ...callAlert, audioBuffer: audioBuffer, calls: {} } as CallAlertTracker;
            })
        );
    }

    private getAudioBuffer(url: string) {
        return this.http.get(url, { responseType: 'arraybuffer' }).pipe(switchMap((arrayBuffer) => this.alertService.audioContext.decodeAudioData(arrayBuffer)));
    }

    public initialize(calls: Call[]) {
        calls.forEach((call) => this.handleCallUpdate(call, false));
    }

    public handleCallUpdate(call: Call, isDeleted: boolean) {
        this.callAlertTrackers.forEach((tracker) => {
            if (tracker.calls.hasOwnProperty(call.uuid)) {
                if (isDeleted || !CallAlertFunctions.matchCall(call, tracker, this.user)) {
                    delete tracker.calls[call.uuid];
                    if (tracker.repeat) {
                        this.handleUpdate(tracker);
                    }
                }
            } else if (CallAlertFunctions.matchCall(call, tracker, this.user)) {
                tracker.calls[call.uuid] = call.uuid;
                this.handleUpdate(tracker);
            }
        });
    }

    private handleUpdate(tracker: CallAlertTracker) {
        let hasPlayers = this.hasPlayers(tracker);
        let isPlaying = tracker.isPlaying;
        if (hasPlayers && !isPlaying) {
            this.requestPlay(tracker);
        }
        if (!hasPlayers && tracker.repeat && isPlaying) {
            this.requestStop(tracker);
        }
    }

    public requestPlay(alertTracker: CallAlertTracker) {
        this.alertService.checkContext();
        console.debug(`Requesting play on alert: ${alertTracker.uuid} audio: ${alertTracker.fileName}`);
        alertTracker.isPlaying = true;
        alertTracker.node = this.alertService.audioContext.createBufferSource();
        alertTracker.node.buffer = alertTracker.audioBuffer;
        alertTracker.node.loop = alertTracker.repeat;
        alertTracker.node.onended = () => alertTracker.isPlaying = false;
        alertTracker.node.connect(this.alertService.mixerNode);
        alertTracker.node.start(this.alertService.audioContext.currentTime);
    }

    public requestStop(alertTracker: CallAlertTracker) {
        console.debug(`Requesting stop on alert: ${alertTracker.uuid} audio: ${alertTracker.fileName}`);
        alertTracker.node?.stop();
        alertTracker.node?.disconnect();
        alertTracker.isPlaying = false;
    }

    public hasPlayers(alert: CallAlertTracker) {
        return Boolean(Object.keys(alert.calls).length);
    }
}
