/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { UsbBaseService } from '../../shared/services/usb-base.service';
import { Store } from '@ngrx/store';
import { selectRtiAttached } from '../../+state/usb.selectors';
import { filter, take } from 'rxjs/operators';
import { RtiAudioService } from './rti-audio.service';

@Injectable({ providedIn: 'root' })
export class RTIService extends UsbBaseService {

    public readonly DEVICE_NAME = 'RTI';
    public readonly VENDOR_ID= 0x1694;
    public readonly PRODUCT_ID = 0x0010;

    constructor(protected store: Store, private audioService: RtiAudioService) {
        super(store);
        this.store
            .select(selectRtiAttached)
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.init();
                this.audioService.init();
            });
    }
}
