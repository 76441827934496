/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {EventMessage} from './abstract-event-message';

enum DEVICE_ID {
  JACKBOX_1 = 0x00,
  JACKBOX_2 = 0x01,
  RADIO_CONSOLE = 0x03
}

enum CONNECTION_STATE {
  DISCONNECTED = 0x00,
  CONNECTED = 0x01
}

enum MICROPHONE_TYPE {
  NOT_APPLICABLE = 0x00,
  ELECTRET = 0x01,
  CARBON = 0x02
}

export class JackSenseMessage extends EventMessage {
  public static readonly DEVICE_ID = DEVICE_ID;
  public static readonly CONNECTION_STATE = CONNECTION_STATE;
  public static readonly MICROPHONE_TYPE = MICROPHONE_TYPE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = EventMessage.EventMessageIdentifier.JACK_SENSE;
  }

  public forDevice(device: DEVICE_ID) {
    this.payload1 = device;
    return this;
  }

  override payload1String(): string {
    return DEVICE_ID[this.payload1];
  }

  override payload2String(): any {
    return CONNECTION_STATE[this.payload2];
  }

  override payload3String(): any {
    return MICROPHONE_TYPE[this.payload3];
  }
}
