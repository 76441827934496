import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app.config';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => {
    let errorDisplay = document.querySelector('#initialization-error');
    errorDisplay.classList.add('show');
    if (!errorDisplay.textContent) {
        errorDisplay.textContent = err;
    }
    console.error(err);
});
