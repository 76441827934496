/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';

import { ConnectionStatusActionEnum, IAudioConfiguration, IWebUsbConnectionStatus, UsbButtonStateActionEnum } from 'wam-wrapper/definitions';
import { IPeripheralButton } from 'web-usb-common';
import { IDeviceEndpointBinder } from 'web-audio-module';
import {
    alertToDevice,
    alertToHeadsets,
    ccHubAttachedEvent,
    ccHubAudioDeviceConnectionEvent,
    ccHubEffectsInitialized,
    ccHubHeadsetConnectedEvent,
    ccHubInitialized,
    ccHubSerialNumber,
    ccHubUsbButtonConnectionStatus,
    ccHubUsbButtonState,
    ccWebUsbConnectionStatus,
    fetchWamConfigSuccess,
    irrToDevice,
    irrToHeadsets,
    muteAction,
    muteOnAnswerUpdate,
    overrideCcHubHeadsetRequirement,
    rpiDebugToggle,
    setFirmwareUpgrading,
    setFirmwareVersion,
    setPeripheralVolume,
    updateDeviceEndpointBinders
} from './cchub.actions';
import { AudioDevicesRoles, AudioDevicesRolesInternal } from 'wam-wrapper';
import { toastExpired } from '../../notification/+state/notification.actions';

export interface CChubState {
    effectsInitialized: boolean;
    ccHubAttached: boolean;
    ccHubInitialized: boolean;
    firmwareVersion: string;
    firmwareUpgrading: boolean;
    ccHubSn: string | undefined;
    wamConfig: IAudioConfiguration | undefined;
    endpointBinders: IDeviceEndpointBinder[];
    headsetConnected: boolean;
    headsetDisconnectedAndTimedOut: boolean;
    ccWebUsbConnectionStatus: IWebUsbConnectionStatus;
    ccHubAudioDeviceConnectionStatus: { [label: string]: IDeviceEndpointBinder };
    ccHubUsbButtons: {
        [label: string]: {
            state: UsbButtonStateActionEnum;
            status: ConnectionStatusActionEnum;
            peripheral: IPeripheralButton;
        };
    };
    muteStatus: { [peripheral: string]: { muted: boolean; muteOnAnswer: boolean } };
    volumeStatus: { [peripheral: string]: { volume: number } };
    ccHubHeadsetOverride: boolean;
    ccHubRadioHeadsetOverride: boolean;
    alertToHeadsets: boolean;
    alertToDevice: boolean;
    irrToHeadsets: boolean;
    irrToDevice: boolean;
}

const initialState: CChubState = {
    effectsInitialized: false,
    ccHubAttached: false,
    ccHubInitialized: false,
    firmwareVersion: undefined,
    firmwareUpgrading: false,
    ccHubSn: undefined,
    wamConfig: undefined,
    endpointBinders: [],
    headsetConnected: false,
    headsetDisconnectedAndTimedOut: false,
    ccWebUsbConnectionStatus: { connectionState: false },
    ccHubAudioDeviceConnectionStatus: {},
    ccHubUsbButtons: {},
    muteStatus: {
        [AudioDevicesRoles.HEADSET_1_MIC]: { muted: false, muteOnAnswer: false },
        [AudioDevicesRoles.HEADSET_2_MIC]: { muted: false, muteOnAnswer: false },
        [AudioDevicesRolesInternal.RPI_OUT]: { muted: false, muteOnAnswer: false },
    },
    volumeStatus: {
        [AudioDevicesRoles.HEADSET_1_MIC]: { volume: 5 },
        [AudioDevicesRoles.HEADSET_2_MIC]: { volume: 5 },
        [AudioDevicesRolesInternal.RPI_IN]: { volume: 5 },
        [AudioDevicesRoles.HEADSET_1_PHONE_SPEAKER]: { volume: 5 },
        [AudioDevicesRoles.HEADSET_2_PHONE_SPEAKER]:  { volume: 5 },
        [AudioDevicesRolesInternal.RPI_OUT]:  { volume: 5 }
    },
    ccHubHeadsetOverride: false,
    ccHubRadioHeadsetOverride: false,
    alertToHeadsets: true,
    alertToDevice: false,
    irrToHeadsets: true,
    irrToDevice: false
};
export const ccHubFeature = createFeature({
    name: 'cchub',
    reducer: createReducer(
        initialState,
        on(ccHubEffectsInitialized, (state): CChubState => {
            return { ...state, effectsInitialized: true };
        }),
        on(toastExpired, (state, { ref }): CChubState => {
            return ref === 'headsetTimeout' ? { ...state, headsetDisconnectedAndTimedOut: true } : { ...state };
        }),
        on(ccHubAttachedEvent, (state, { isAttached }): CChubState => ({ ...state, ccHubAttached: isAttached })),
        on(ccHubInitialized, (state) => ({ ...state, ccHubInitialized: true })),
        on(ccHubSerialNumber, (state, { sn }): CChubState => ({ ...state, ccHubSn: sn })),
        on(ccWebUsbConnectionStatus, (state, { event }): CChubState => ({ ...state, ccWebUsbConnectionStatus: event })),
        on(ccHubUsbButtonState, (state, { event }): CChubState => {
            let ccHubUsbButtons = { ...state.ccHubUsbButtons };
            ccHubUsbButtons[event.peripheral.buttonId] = { ...ccHubUsbButtons[event.peripheral.buttonId], peripheral: event.peripheral, state: event.action };
            return { ...state, ccHubUsbButtons: ccHubUsbButtons };
        }),
        on(ccHubUsbButtonConnectionStatus, (state, { event }): CChubState => {
            let ccHubUsbButtons = { ...state.ccHubUsbButtons };
            ccHubUsbButtons[event.peripheral.buttonId] = { ...ccHubUsbButtons[event.peripheral.buttonId], peripheral: event.peripheral, status: event.action };
            return { ...state, ccHubUsbButtons: ccHubUsbButtons };
        }),
        on(ccHubAudioDeviceConnectionEvent, (state, { event }): CChubState => {
            let ccHubAudioDeviceConnectionStatus = { ...state.ccHubAudioDeviceConnectionStatus };
            ccHubAudioDeviceConnectionStatus[`${event.peripheral.deviceendpoint.label} channel:  ${event.peripheral.deviceendpoint.channel}`] = event.peripheral;
            return { ...state, ccHubAudioDeviceConnectionStatus: ccHubAudioDeviceConnectionStatus };
        }),
        on(ccHubHeadsetConnectedEvent, (state, { isConnected }): CChubState => ({ ...state, headsetConnected: isConnected, headsetDisconnectedAndTimedOut: isConnected ? false : state.headsetDisconnectedAndTimedOut })),
        on(fetchWamConfigSuccess, (state, { config }): CChubState => ({ ...state, wamConfig: config })),
        on(updateDeviceEndpointBinders, (state, { endpointBinders }): CChubState => ({ ...state, endpointBinders: endpointBinders })),
        on(muteAction, (state, { peripheral, isMuted }): CChubState => {
            let muteStatus = { ...state.muteStatus };
            muteStatus[peripheral] = { ...muteStatus[peripheral], muted: isMuted !== undefined ? isMuted : !muteStatus[peripheral].muted };
            return { ...state, muteStatus: muteStatus };
        }),
        on(muteOnAnswerUpdate, (state, { peripheral, muteOnAnswer }): CChubState => {
            let muteStatus = { ...state.muteStatus };
            muteStatus[peripheral] = { ...muteStatus[peripheral], muteOnAnswer: muteOnAnswer };
            return { ...state, muteStatus: muteStatus };
        }),
        on(overrideCcHubHeadsetRequirement, (state, { required }): CChubState => ({ ...state, ccHubHeadsetOverride: required })),
        on(rpiDebugToggle, (state, { enabled }): CChubState => ({ ...state, ccHubRadioHeadsetOverride: enabled })),
        on(setPeripheralVolume, (state, { peripheral, volume }): CChubState => {
            let volumeStatus = { ...state.volumeStatus };
            volumeStatus[peripheral] = { volume: volume };
            return { ...state, volumeStatus: volumeStatus };
        }),
        on(alertToHeadsets, (state, { alert }): CChubState =>  ({ ...state, alertToHeadsets: alert })),
        on(alertToDevice, (state, { alert }): CChubState => ({ ...state, alertToDevice: alert })),
        on(irrToHeadsets, (state, { alert }): CChubState =>  ({ ...state, irrToHeadsets: alert })),
        on(irrToDevice, (state, { alert }): CChubState => ({ ...state, irrToDevice: alert })),
        on(setFirmwareVersion, (state, { value }): CChubState => ({ ...state, firmwareVersion: value})),
        on(setFirmwareUpgrading, (state, { value }): CChubState => ({ ...state, firmwareUpgrading: value}))
    )
});
