/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HealthService } from './health.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { ServiceName } from '../../../assets/config/environment';
import { LocationEvent } from '../../location/model/location';
import { map } from 'rxjs/operators';
import { SortFunctions } from '../../call/util/sort-functions';

@Injectable({ providedIn: 'root' })
export class CtcAdapterService extends HealthService {
    private readonly LOCATION_REBID_URL = `/api/v1/locations/rebid`;
    private readonly LOCATION_LATEST_URL = `/api/v1/locations/call/latest`;
    private readonly LOCATION_MANUAL_ALI_URL = `/api/v1/locations/manuallocation/`;

    constructor(
        protected http: HttpClient,
        protected env: EnvironmentService
    ) {
        super(
            http,
            ServiceName.CTC_ADAPTER,
            env.environment.SERVICES[ServiceName.CTC_ADAPTER] ? env.environment.SERVICES[ServiceName.CTC_ADAPTER] : ['/ctc-adapter']
        );
    }

    public load(callId: string) {
        return this.http.get<LocationEvent[]>(`${this.activeUrl$.value}${this.LOCATION_LATEST_URL}/${callId}`, {}).pipe(
            map((locationEvents) => this._splitLocationByProvider(locationEvents)),
            map(({ standard, enhanced }) =>
                standard.sort(SortFunctions.newestLocationEventSort).slice(0, 20)
                    .concat(enhanced.sort(SortFunctions.newestLocationEventSort).slice(0, 20))),
        );
    }

    private _splitLocationByProvider = (locationEvents: LocationEvent[]) => locationEvents.reduce((acc, curr) => {
        (curr.provider === 'standard' ? acc.standard : acc.enhanced).push(curr);
        return acc;
    }, { standard:[] as LocationEvent[], enhanced:[] as LocationEvent[] });

    public rebid(callId: string) {
        return this.http.post(`${this.activeUrl$.value}${this.LOCATION_REBID_URL}/${callId}`, {});
    }

    public manualQuery(number: string) {
        return this.http.post<LocationEvent>(`${this.activeUrl$.value}${this.LOCATION_MANUAL_ALI_URL}${encodeURIComponent(number)}`, {});
    }
}
