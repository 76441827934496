/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Report2 } from './report-2';

enum MessageType {
    DEVICE_ID = 0x00
}
export class InputReport2 extends Report2 {
    public static readonly MessageType = MessageType;

    public constructor(buffer: ArrayBuffer) {
        super(buffer);
    }

    public typeString(): string {
        return MessageType[this.type];
    }
}
