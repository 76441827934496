/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

enum MessageType {
    CONFIGURATION = 0x00,
    CONTROL = 0x01,
    EVENT = 0x02
}

export class Report1 extends DataView {
    public static readonly REPORT_ID = 1;
    public static readonly MessageType = MessageType;
    public constructor(buffer?: ArrayBuffer) {
        super(buffer ? buffer : new ArrayBuffer(5));
    }
    public get reportId(): number {
        return Report1.REPORT_ID;
    }
    protected set type(type: MessageType) {
        this.setUint8(0, type);
    }
    public get type(): number {
        return this.getUint8(0);
    }
    public typeString(): string {
        return MessageType[this.type];
    }

    protected set id(id: number) {
        this.setUint8(1, id);
    }
    public get id(): number {
        return this.getUint8(1);
    }
    public idString(): string {
        return `0x${this.id.toString(16).padStart(2, '0')}`;
    }

    protected set payload1(byte: number) {
        this.setUint8(2, byte);
    }
    protected get payload1(): number {
        return this.getUint8(2);
    }
    public payload1String(): string {
        return `0x${this.payload1.toString(16).padStart(2, '0')}`;
    }

    protected set payload2(byte: number) {
        this.setUint8(3, byte);
    }
    protected get payload2(): number {
        return this.getUint8(3);
    }
    public payload2String(): string {
        return `0x${this.payload2.toString(16).padStart(2, '0')}`;
    }
    protected set payload3(byte: number) {
        this.setUint8(4, byte);
    }
    protected get payload3(): number {
        return this.getUint8(4);
    }
    public payload3String(): string {
        return `0x${this.payload3.toString(16).padStart(2, '0')}`;
    }

    public logMessage() {
        return `${this.typeString()} ${this.idString()} ${this.payload1String()} ${this.payload2String()} ${this.payload3String()}`;
    }
}
