/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Message, MessageType } from 'CalltakingCoreApi';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { delay, filter } from 'rxjs/operators';
import * as uuid from 'uuid';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { selectMessageEffectInitialized, selectMessageStateInitializationTime } from '../+state/message.selectors';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { BehaviorSubject } from 'rxjs';
import { updateMessageSubscriptions } from '../+state/message.actions';

export abstract class MessageService implements StompConsumer {
    protected type: MessageType;
    protected MESSAGE_NEW_TOPIC: string;
    protected MESSAGE_EDIT_TOPIC: string;
    protected MESSAGE_DELETE_TOPIC: string;
    protected API_BASE_URL = '/api/imc/v1/';
    private readonly newReceipt = uuid.v4();
    private readonly editReceipt = uuid.v4();
    private readonly deleteReceipt = uuid.v4();

    private subscriptions: {[topic:string]: { [receipt:string]: boolean} } = {};
    private subscriptions$ = new BehaviorSubject<{[topic:string]: { [receipt:string]: boolean} }>(this.subscriptions);

    protected constructor(type: MessageType, stompService: StompService, store: Store) {
        this.type = type;
        this.MESSAGE_NEW_TOPIC = `/user/topic/v1/message/${this.type}/new`;
        this.MESSAGE_EDIT_TOPIC = `/user/topic/v1/message/${this.type}/*/edit`;
        this.MESSAGE_DELETE_TOPIC = `/user/topic/v1/message/${this.type}/*/delete`;
        this.subscriptions = {
            [this.MESSAGE_NEW_TOPIC]: {
                [this.newReceipt]: false
            },
            [this.MESSAGE_EDIT_TOPIC]: {
                [this.editReceipt]: false
            },
            [this.MESSAGE_DELETE_TOPIC]: {
                [this.deleteReceipt]: false
            }
        };

        stompService.watchAsync(this.MESSAGE_NEW_TOPIC, this.newReceipt, this).subscribe((message: IMessage) =>
            this.newMessage(JSON.parse(message.body)));
        stompService.watchAsync(this.MESSAGE_EDIT_TOPIC, this.editReceipt, this).subscribe((message: IMessage) =>
            this.editMessage(JSON.parse(message.body)));
        stompService.watchAsync(this.MESSAGE_DELETE_TOPIC, this.deleteReceipt, this).pipe(delay(1000)).subscribe((message: IMessage) =>
            this.deleteMessage(JSON.parse(message.body)));
        store.select(selectMessageEffectInitialized).pipe(filter((init) => init)).subscribe(() => this.effectsInitialized());
        this.subscriptions$.subscribe((subscriptionState) =>
            store.dispatch(updateMessageSubscriptions({subscriptions: subscriptionState})));
        // Trigger (re)initialization of the message state
        store.select(selectMessageStateInitializationTime).pipe(filter((val) => !!val)).subscribe(() =>
            this.websocketInitialized());
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Message topic ${topic} subscription success: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = true;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Message topic ${topic} subscription end: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = false;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Message topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    protected abstract newMessage(message: Message): void;
    protected abstract editMessage(message: Message): void;
    protected abstract deleteMessage(message: Message): void;
    protected abstract effectsInitialized(): void;
    protected abstract websocketInitialized(): void;
}
