/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnnouncementService } from '../services/announcement.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { persistAnnouncement, receivedAnnouncement, sendAnnouncement, sendAnnouncementFail, sendAnnouncementSuccess } from './announcement.actions';
import { selectUserMap, selectUsername } from '../../user/+state/user.selectors';
import { NotifyingAnnouncement } from '../model/notifying-announcement';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { newSystemErrorMessage } from '../../messages/+state/message.actions';
import { AnnouncementAlertService } from '../services/announcement-alert.service';
import { v4 } from 'uuid';
import { concatLatestFrom } from '@ngrx/operators';
import { selectPrimaryConnectionClusterName } from '../../configuration/+state/configuration.selectors';

@Injectable()
export class AnnouncementEffects {
    constructor(
        private announcementService: AnnouncementService,
        private announcementAlertService: AnnouncementAlertService,
        private store: Store,
        private actions$: Actions
    ) {}

    sendAnnouncement$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendAnnouncement),
            concatLatestFrom(() => this.store.select(selectPrimaryConnectionClusterName)),
            switchMap(([{ announcement }, clusterName]) =>
                this.announcementService.sendAnnouncement(announcement, clusterName).pipe(
                    map(() => sendAnnouncementSuccess()),
                    catchError((err: Error) => of(sendAnnouncementFail({ payload: err.message })))
                )
            )
        )
    );

    sendAnnouncementSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendAnnouncementSuccess),
            map(() =>
                displayToastNotification({
                    message: 'Announcement sent successfully',
                    level: ToastType.success
                })
            )
        )
    );

    receivedAnnouncement$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(receivedAnnouncement),
            concatLatestFrom(() => this.store.select(selectUserMap)),
            map(([{ announcement }, users]) => {
                let notifyingAnnouncement = { ...announcement } as NotifyingAnnouncement;
                notifyingAnnouncement.elementId = notifyingAnnouncement.elementId ? notifyingAnnouncement.elementId : v4();
                let user = users[notifyingAnnouncement.from];
                notifyingAnnouncement.fromUser = {
                    firstname: user?.firstName,
                    lastname: user?.lastName,
                    username: notifyingAnnouncement.from
                };
                notifyingAnnouncement.unread = true;
                return persistAnnouncement({ announcement: notifyingAnnouncement });
            })
        )
    );

    monitorAnnouncements$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(receivedAnnouncement),
                concatLatestFrom(() => this.store.select(selectUsername)),
                filter(([{ announcement }, username]) => Boolean(announcement.from !== username)),
                tap(() => this.announcementAlertService.playAlert())
            ),
        { dispatch: false }
    );

    errorNotification$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(newSystemErrorMessage),
            filter(({ message }) => message.type === 'SYSTEM_ERROR'),
            map(({ message }) => displayToastNotification({ message: message?.message, level: ToastType.error }))
        )
    );

    errorToastNotification$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(displayToastNotification),
            filter(({ level }) => level === ToastType.error),
            map(({ message }) => {
                let notifyingAnnouncement: NotifyingAnnouncement = {
                    elementId: v4(),
                    agencyId: undefined,
                    from: 'system-error',
                    message: message,
                    system: true,
                    fromUser: {
                        username: 'system-error',
                        firstname: 'System',
                        lastname: 'Error'
                    },
                    timestamp: new Date().toUTCString(),
                    chatParticipants: [],
                    unread: true
                };
                return persistAnnouncement({ announcement: notifyingAnnouncement });
            })
        )
    );
}
