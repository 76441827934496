/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Store } from '@ngrx/store';
import { fetchHotkeys, hotKeyEvent } from '../+state/configuration.actions';
import { selectConfigurationEffectInitialized, selectHotkeys } from '../+state/configuration.selectors';
import { filter } from 'rxjs/operators';
import { HotKey } from '../../core/model/hotkey';

@Injectable({
    providedIn: 'root'
})
export class HotkeyService {
    constructor(private store: Store, private hotkeysService: HotkeysService) {
        this.store
            .select(selectConfigurationEffectInitialized)
            .pipe(filter((init) => init))
            .subscribe(() => this.store.dispatch(fetchHotkeys()));
        this.store.select(selectHotkeys).subscribe((hotkeys) => hotkeys?.forEach((hotkey) => this.registerHotkey(hotkey)));
    }

    registerHotkey(hotkey: HotKey) {
        this.hotkeysService.add(
            new Hotkey(
                hotkey.combo.toLowerCase(),
                () => {
                    this.store.dispatch(hotKeyEvent({ hotkey: hotkey }));
                    return false;
                },
                ['INPUT', 'TEXTAREA']
            )
        );
    }
}
