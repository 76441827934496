/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum STATE {
    END = 0x00,
    BEGIN = 0x01
}
enum DEVICE {
    JACK_BOX = 0x00
}
export class PlaybackAudioMessage extends ControlMessage {
    public static readonly STATE = STATE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.PLAYBACK_AUDIO;
    }

    public withState(activity: STATE) {
        this.payload2 = activity;
        return this;
    }

    override payload1String(): string {
        return DEVICE[this.payload1];
    }

    override payload2String(): string {
        return STATE[this.payload2];
    }
}
