/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum DEVICE {
    JACKBOX_1 = 0x00,
    JACKBOX_2 = 0x01,
    JACKBOX_3 = 0x02,
    LTR_RELAY = 0x03,
    USER_RELAY = 0x04,
    RPI_OFFHOOOK = 0x05,
    RPI_JACKSENSE = 0x06,
    RADIO_HEADSET_JACKSENSE_OUTPUT = 0x07,
    RADIO_HEADSET_PTT_OUTPUT = 0x08,
    SELECT_RADIO_SPEAKER_LED = 0x09,
    SELECT_TELEPHONE_SPEAKER_LED = 0x0a,
    UNSELECT_1_SPEAKER_LED = 0x0b,
    UNSELECT_2_SPEAKER_LED = 0x0c
}

enum STATE {
    INACTIVE = 0x00,
    ACTIVE = 0x01
}

export class TestOutputMessage extends ControlMessage {
    public static readonly DEVICE = DEVICE;
    public static readonly STATE = STATE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.TEST_OUTPUT;
    }

    public withDevice(state: DEVICE) {
        this.payload1 = state;
        return this;
    }

    public withState(state: STATE) {
        this.payload2 = state;
        return this;
    }

    override payload1String(): string {
        return DEVICE[this.payload1];
    }

    override payload2String(): string {
        return STATE[this.payload2];
    }
}
