/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

export class ConfigurationErrorMessage extends ConfigurationMessage {

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ConfigurationMessage.ConfigurationMessageIdentifier.ERROR;
    }

    override payload1String(): string {
        return ConfigurationMessage.ConfigurationMessageIdentifier[this.payload1];
    }
}
