/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ControlMessage} from './abstract-control-message';

enum APP_REGISTRATION {
  TEST_APP = 0x01,
  TELEPHONY_APP = 0x02,
  RADIO_APP = 0x04,
  GENERIC_APP = 0x08,
  IRR_APP = 0x10
}

export enum SESSION {
  END = 0x00,
  BEGIN = 0x01
}

export class RegisterMessage extends ControlMessage {
  public static readonly APP_REGISTRATION = APP_REGISTRATION;
  public static readonly SESSION = SESSION;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.REGISTER;
  }

  public withApp(app: APP_REGISTRATION) {
    this.payload1 = app;
    return this;
  }

  public withSession(session: SESSION) {
    this.payload2 = session;
    return this;
  }

  override payload1String(): string {
    return APP_REGISTRATION[this.getUint8(2)];
  }

  override payload2String(): any {
    return SESSION[this.getUint8(3)];
  }
}
