/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum STATUS {
  END = 0x00,
  BEGIN = 0x01
}

export class TestModeMessage extends ControlMessage {
    public static readonly STATUS = STATUS;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ControlMessage.ControlMessageIdentifier.TEST_MODE;
        this.payload1 = 0x00;
    }

    public withStatus(status: STATUS) {
        this.payload2 = status;
        return this;
    }

    override payload2String(): string {
        return STATUS[this.payload2];
    }
}
