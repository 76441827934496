/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { IntercomAlertService } from '../services/intercom-alert.service';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { IntercomService } from '../services/intercom.service';
import {
    newInstantMessage,
    requestChat,
    selectChat,
    sendIntercomMessage,
    sendIntercomMessageFail,
    sendIntercomMessageSuccess,
    startChat,
    updateChat
} from './intercom.actions';
import { selectIntercomChatMap } from './intercom.selectors';
import { selectMemoizedUserAuthenticationRecord, selectUserMap, selectUsername } from '../../user/+state/user.selectors';
import { IntercomChat, IntercomMessage, IntercomParticipant } from '../model/intercom-chat';
import { ChatFunctions } from '../util/chat-functions';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class IntercomEffects {
    constructor(
        private messageService: IntercomService,
        private messageAlertService: IntercomAlertService,
        private store: Store,
        private actions$: Actions
    ) {}

    sendIntercomMessage$: Observable<{}> = createEffect(() =>
        this.actions$.pipe(
            ofType(sendIntercomMessage),
            switchMap(({ chat, message }) =>
                this.messageService.instantMessage(chat, message).pipe(
                    map(() => sendIntercomMessageSuccess()),
                    catchError((err: Error) => of(sendIntercomMessageFail({ payload: err.message })))
                )
            )
        )
    );

    monitorAlerts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(newInstantMessage),
                concatLatestFrom(() => this.store.select(selectUsername)),
                filter(([{ message }, username]) => Boolean(message.from !== username)),
                tap(() => this.messageAlertService.playAlert())
            ),
        { dispatch: false }
    );

    requestChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestChat),
            concatLatestFrom(() => this.store.select(selectMemoizedUserAuthenticationRecord)),
            filter(([, user]) => Boolean(user)),
            switchMap(([{ users }, user]) => {
                let participants: IntercomParticipant[] = users.map((agent) => ({
                    type: 'USER',
                    name: agent.username,
                    firstname: agent.firstName,
                    lastname: agent.lastName
                }));
                if (user) {
                    participants = participants
                        .concat({
                            type: 'USER',
                            name: user.username,
                            firstname: user.firstName,
                            lastname: user.lastName
                        })
                        .sort((a, b) => a.name.localeCompare(b.name) || a.type.localeCompare(b.type));
                }
                let chat: IntercomChat = {
                    id: ChatFunctions.generateUUID(JSON.stringify(participants)),
                    timestamp: new Date().toISOString(),
                    intercomParticipants: participants,
                    messages: [],
                    notifying: false
                };
                return [startChat({ chat }), selectChat({ chatId: chat.id })];
            })
        )
    );

    createChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(newInstantMessage),
            concatLatestFrom(() => [this.store.select(selectIntercomChatMap), this.store.select(selectUserMap)]),
            filter(([{ message }, chatMap]) => !chatMap[message.chatId]),
            map(([{ message }, , userMap]) => {
                let intercomParticipants = message.chatParticipants.map((participant) => ({ ...participant }) as IntercomParticipant);
                intercomParticipants.forEach((participant) => {
                    let user = userMap[participant.name];
                    participant.firstname = user ? user.firstName : participant.name;
                    participant.lastname = user ? user.lastName : ' ';
                });
                let fromUser = userMap[message.from];
                let intercomMessage = { ...message } as IntercomMessage;
                intercomMessage.fromUser = {
                    username: message.from,
                    firstname: fromUser ? fromUser.firstName : message.from,
                    lastname: fromUser ? fromUser.lastName : ' ',
                };
                return startChat({
                    chat: {
                        id: message.chatId,
                        intercomParticipants: intercomParticipants,
                        timestamp: message.timestamp,
                        messages: [intercomMessage],
                        notifying: true
                    }
                });
            })
        )
    );

    updateChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(newInstantMessage),
            concatLatestFrom(() => [this.store.select(selectIntercomChatMap), this.store.select(selectUserMap), this.store.select(selectUsername)]),
            filter(([{ message }, chatMap]) => Boolean(chatMap[message.chatId])),
            map(([{ message }, chatMap, userMap, username]) => {
                let chat = { ...chatMap[message.chatId] } as IntercomChat;
                let fromUser = userMap[message.from];
                let intercomMessage = { ...message } as IntercomMessage;
                intercomMessage.fromUser = {
                    username: message.from,
                    firstname: fromUser ? fromUser.firstName : message.from,
                    lastname: fromUser ? fromUser.lastName : ' ',
                };
                chat.messages = chat.messages.concat(intercomMessage);
                chat.notifying = Boolean(username && message.from !== username);
                chat.timestamp = message.timestamp;
                return updateChat({ chat: chat });
            })
        )
    );
}
