/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ControlMessage } from './abstract-control-message';

enum STATE {
    DISCONNECT = 0x00,
    CONNECT = 0x01
}

export class IrrPlaybackToPhoneMessage extends ControlMessage {
  public static readonly STATE = STATE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.IRR_PLAYBACK_TO_PHONE;
  }

  public withState(activity: STATE) {
    this.payload2 = activity;
    return this;
  }

  override payload2String(): string {
    return STATE[this.payload2];
  }
}
