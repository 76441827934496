/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Call, RingDownConfigFormData } from 'CalltakingCoreApi';

export interface Ringdown extends RingDownConfigFormData {
    call?: Call;
}

export enum RingdownStatus {
    IDLE = 'Idle',
    CONNECTED = 'Connected',
    ON_HOLD = 'On Hold',
    RINGING = 'Ringing',
}

export enum RingdownAction {
    CALL = 'Call',
    JOIN = 'Join',
    UNHOLD = 'Unhold',
    ADD_TO_CALL = 'Add to Call',
    NO_ACTION = 'No action'
}

export const RingdownActionsMap = new Map<RingdownStatus, RingdownAction>([
    [RingdownStatus.IDLE, RingdownAction.CALL],
    [RingdownStatus.CONNECTED, RingdownAction.JOIN],
    [RingdownStatus.ON_HOLD, RingdownAction.UNHOLD]
]);

export const RingdownActionIconMap = new Map<RingdownAction, string>([
    [RingdownAction.CALL, 'ic_call'],
    [RingdownAction.JOIN, 'ic_call_incoming_phone'],
    [RingdownAction.UNHOLD, 'ic_call_hold'],
    [RingdownAction.ADD_TO_CALL, 'ic_contacts_people_add']
]);
