/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ControlMessage} from './abstract-control-message';

enum AUDIO_PATH {
  GREETING = 0x00
}

enum STATE {
  DEACTIVATE = 0x00,
  ACTIVATE = 0x01
}

export class GreetingRecordingRequestMessage extends ControlMessage {
  public static readonly AUDIO_PATH = AUDIO_PATH;
  public static readonly STATE = STATE;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ControlMessage.ControlMessageIdentifier.GREETING_RECORDING_REQUEST;
  }

  public withState(state: STATE) {
    this.payload1 = AUDIO_PATH.GREETING;
    this.payload2 = state;
    return this;
  }
}
