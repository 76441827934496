/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { linkedCallUpdate } from '../+state/call.actions';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteAgencyCallSyncService implements OnDestroy, StompConsumer {

    private readonly REMOTE_CALL_TOPIC = `/user/topic/v1/linked-call`;
    private readonly receipt = uuid.v4();
    private readonly remoteCallTopicSubscription: Subscription | undefined;

    constructor(private stompService: StompService, private store: Store) {
        this.remoteCallTopicSubscription = this.stompService.watchAsync(this.REMOTE_CALL_TOPIC, this.receipt, this).subscribe((message: IMessage) =>
            this.store.dispatch(linkedCallUpdate({call: JSON.parse(message.body)})));
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Linked Call topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Linked Call topic ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Linked Call topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.remoteCallTopicSubscription) {
            this.remoteCallTopicSubscription.unsubscribe();
        }
    }
}
