/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OutputReport1 } from '../output-report-1';
import { Report1 } from '../report-1';

enum EventMessageIdentifier {
  HEARTBEAT = 0x00,
  JACK_SENSE = 0x01,
  INPUT = 0x0e,
  LEVEL_104 = 0x18,
  LEVEL_404 = 0x0a,
  LEVEL_704 = 0x15,
  LEVEL_1004 = 0x0b,
  LEVEL_1904 = 0x16,
  LEVEL_2804 = 0x0c,
  LEVEL_6804 = 0x19,
  LEVEL_13804 = 0x1a,
  LEVEL_19804 = 0x18,
  LEVEL_PEAK = 0x0d,
  QUERY_RESPONSE_STATUS = 0x17,
  FW_RELOAD_PROGRESS = 0x10,
  RESTART = 0x12,
  HARDWARE_FAILURE = 0x14,
  OUT_OF_RANGE = 0xfe,
  ERROR = 0xff
}

export abstract class EventMessage extends Report1 {
  public static readonly EventMessageIdentifier = EventMessageIdentifier;

  protected constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.type = OutputReport1.MessageType.EVENT;
  }

  override get id() {
    return super.id;
  }

  override set id(id: EventMessageIdentifier) {
    super.id = id;
  }

  override idString(): string {
    return EventMessage.EventMessageIdentifier[this.id];
  }
}
