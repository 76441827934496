/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message } from 'CalltakingCoreApi';
import { MessageService } from './message.service';
import { deleteSmsMessage, fetchPredefinedSmsMessages, initializeSmsMessageState, newSmsMessage, updateSmsMessage } from '../+state/message.actions';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class SmsMessageService extends MessageService {

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        super('SMS', stompService, store);
    }

    protected effectsInitialized(): void {
        this.store.dispatch(fetchPredefinedSmsMessages());
    }

    protected websocketInitialized(): void {
        this.store.dispatch(initializeSmsMessageState());
    }

    protected deleteMessage(message: Message): void {
        this.store.dispatch(deleteSmsMessage({ message: message }));
    }

    protected editMessage(message: Message): void {
        this.store.dispatch(updateSmsMessage({ message: message }));
    }

    protected newMessage(message: Message): void {
        this.store.dispatch(newSmsMessage({ message: message }));
    }

    public requestSmsMessages(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_BASE_URL}${callId}/load/${this.type}`);
        return this.http.get<Message[]>(url, { observe: 'body', responseType: 'json' });
    }
}
