/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from "@ngrx/store";
import {
    AdrDiscrepancyReport,
    BcfDiscrepancyReport, ChfeDiscrepancyReport,
    EsrpDiscrepancyReport,
    LisDiscrepancyReport
} from "../model/discrepancy-report-model";

export const bcf = createAction('[DisrepancyReport] Bcf', props<{clusterName: string, bcfReport: BcfDiscrepancyReport}>());
export const bcfSuccess = createAction('[DiscrepancyReport] Bcf Success', props<{clusterName: string}>());
export const bcfFail = createAction('[DiscrepancyReport] Bcf Failure', props<{payload: string}>());
export const esrp = createAction('[DiscrepancyReport] Esrp', props<{clusterName: string, esrpReport: EsrpDiscrepancyReport}>());
export const esrpSuccess = createAction('[DiscrepancyReport] Esrp Success', props<{clusterName: string}>());
export const esrpFail = createAction('[DiscrepancyReport] Esrp Failure', props<{payload: string}>());
export const lis = createAction('[DiscrepancyReport] Lis', props<{clusterName: string, lisReport: LisDiscrepancyReport}>());
export const lisSuccess = createAction('[DiscrepancyReport] Lis Success', props<{clusterName: string}>());
export const lisFail = createAction('[DiscrepancyReport] Lis Failure', props<{payload: string}>());
export const adr = createAction('[DiscrepancyReport] Adr', props<{clusterName: string, adrReport: AdrDiscrepancyReport}>());
export const adrSuccess = createAction('[DiscrepancyReport] Adr Success', props<{clusterName: string}>());
export const adrFail = createAction('[DiscrepancyReport] Adr Failure', props<{payload: string}>());
export const chfe = createAction('[DiscrepancyReport] Chfe', props<{clusterName: string, chfeReport: ChfeDiscrepancyReport}>());
export const chfeSuccess = createAction('[DiscrepancyReport] Chfe Success', props<{clusterName: string}>());
export const chfeFail = createAction('[DiscrepancyReport] Chfe Failure', props<{payload: string}>());
export const requestStatusUpdate = createAction('[DiscrepancyReport] Request Status Update', props<{clusterName: string, agencyName: string, discrepancyReportId: string}>());
export const requestStatusUpdateSuccess = createAction('[DiscrepancyReport] Request Status Update Success', props<{discrepancyReportId: string}>());
export const requestStatusUpdateFail = createAction('[DiscrepancyReport] Request Status Update Failure', props<{payload: string}>());
