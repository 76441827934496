/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeature, createReducer, on } from '@ngrx/store';
import { updateOpenTabs, updateRequestedTab, updateTabIndex } from './main.actions';

export interface MainState {
    openTabs: string[];
    tabIndex: number;
    requestedTab: string | undefined;
}

const initialState: MainState = {
    openTabs: [],
    tabIndex: 0,
    requestedTab: undefined,
};

export const mainFeature = createFeature({
    name: 'main',
    reducer: createReducer(
        initialState,
        on(updateTabIndex, (state, { index }): MainState => {
            return { ...state, tabIndex: index };
        }),
        on(updateOpenTabs, (state, { openTabs }) => {
            let tabIndex = state.tabIndex;
            let requestedTab = state.requestedTab;
            if (state.requestedTab) {
                let selectedTabIndex = openTabs.indexOf(state.requestedTab);
                tabIndex = selectedTabIndex >= 0 ? selectedTabIndex : tabIndex;
            }
            return { ...state, openTabs: openTabs, requestedTab: requestedTab, tabIndex: tabIndex };
        }),
        on(updateRequestedTab, (state, { label }) => {
            let tabIndex = state.tabIndex;
            let requestedTab = label;
            if (requestedTab) {
                let selectedTabIndex = state.openTabs.indexOf(requestedTab);
                tabIndex = selectedTabIndex >= 0 ? selectedTabIndex : tabIndex;
            }
            return { ...state, requestedTab: requestedTab, tabIndex: tabIndex };
        })
    )
});
