/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { newInstantMessage } from '../+state/intercom.actions';
import { InstantMessage } from 'CalltakingCoreApi';
import { IntercomChat } from '../model/intercom-chat';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class IntercomService implements StompConsumer, OnDestroy {
    private readonly IM_MESSAGE_NEW_TOPIC = '/user/topic/v1/instant-message/new';
    private readonly API_BASE_URL = '/api/imc/v1/';
    private readonly imReceipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.topicSubscription = this.stompService.watchAsync(this.IM_MESSAGE_NEW_TOPIC, this.imReceipt, this).subscribe((message: IMessage) => {
            this.store.dispatch(newInstantMessage({ message: JSON.parse(message.body) }));
        });
    }

    public ngOnDestroy() {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Instant Message topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Instant Message ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Message topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    public instantMessage(chat: IntercomChat, message: string) {
        let im: Partial<InstantMessage> = {
            chatId: chat.id,
            chatParticipants: chat.intercomParticipants,
            message: message
        };

        const url = this.clusterUrlProviderService.getCtcUrl();
        return this.http.post(`${url}${this.API_BASE_URL}im`, im);
    }
}
