/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { ConfigurationMessage } from './abstract-configuration-message';

enum AUDIO_SOURCE {
  PHONE_RECEPTION = 0x01,
  ALERTS = 0x02,
  AUX_AUDIO = 0x04,
  JACKBOX_MICROPHONES = 0x08,
  RPI_AUDIO = 0x10
}

export class AGCMessage extends ConfigurationMessage {
    public static readonly AUDIO_SOURCE = AUDIO_SOURCE;

    constructor(arrayBuffer?: ArrayBuffer) {
        super(arrayBuffer);
        this.id = ConfigurationMessage.ConfigurationMessageIdentifier.AGC;
    }

    public withAudioSource(audioSource: AUDIO_SOURCE, enabled: boolean) {
        if (enabled) {
            this.payload1 = this.payload1 | audioSource;
        } else {
            this.payload1 = this.payload1 & ~audioSource;
        }
        return this;
    }

    public get sources(): { [source: string]: boolean } {
        return Object.fromEntries(
            Object.entries(AUDIO_SOURCE)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => [key, Boolean(this.payload1 & Number(value))])
        );
    }

    override payload1String(): string {
        return Object.entries(this.sources)
            .filter(([key, value]) => value)
            .map(([key, value]) => key)
            .join(', ');
    }
}
