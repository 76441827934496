/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Inject, Injectable, OnDestroy, RendererFactory2 } from '@angular/core';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { StompService } from '../../core/services/stomp.service';
import { Store } from '@ngrx/store';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import { fetchLocationLayout, fetchServiceResponders, receivedLocationEvent } from '../+state/location.actions';
import { DOCUMENT } from '@angular/common';
import { LocationEvent } from '../model/location';
import { filter } from 'rxjs/operators';
import { selectLocationEffectInitialized } from '../+state/location.selectors';

@Injectable({
    providedIn: 'root'
})
export class LocationService implements OnDestroy, StompConsumer {
    private readonly LOCATION_TOPIC = `/user/topic/v1/call/location`;
    private readonly receipt = uuid.v4();
    private readonly topicSubscription: Subscription | undefined;

    constructor(
        private stompService: StompService,
        private store: Store,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2
    ) {
        this.topicSubscription = this.stompService
            .watchAsync(this.LOCATION_TOPIC, this.receipt, this)
            .subscribe((message: IMessage) => this.store.dispatch(receivedLocationEvent({ locationEvent: JSON.parse(message.body) as LocationEvent })));

        this.store
            .select(selectLocationEffectInitialized)
            .pipe(filter((init) => init))
            .subscribe(() => {
                this.store.dispatch(fetchLocationLayout());
                this.store.dispatch(fetchServiceResponders());
            });
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Location topic ${topic} subscription success: ${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Location topic ${topic} subscription end: ${receiptId}`);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Location topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
    }

    public createScriptTag(script: string) {
        let renderer = this.rendererFactory.createRenderer(null, null);
        const precompiledTemplates = renderer.createElement('script');
        precompiledTemplates.type = 'text/javascript';
        precompiledTemplates.text = script;
        renderer.appendChild(this.document.body, precompiledTemplates);
    }
}
