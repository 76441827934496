/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

enum BEHAVIOR {
  RESUMES_AFTER_CALL = 0x00,
  SILENCED_AFTER_CALL = 0x01
}

export class AuxAudioResumeMessage extends ConfigurationMessage {
  public static readonly BEHAVIOR = BEHAVIOR;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.AUX_AUDIO_RESUMES;
  }

  public withBehavior(behavior: BEHAVIOR) {
    this.payload1 = behavior;
    return this;
  }

  override payload1String(): string {
      return BEHAVIOR[this.payload1];
  }
}
