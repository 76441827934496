/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { StompService } from '../../core/services/stomp.service';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { Message as IMessage } from '@stomp/stompjs';
import * as uuid from 'uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    deleteCall,
    initializeCallState,
    newCall,
    requestReleaseCall,
    updateActiveCall,
    updateCall,
    updateCallSubscriptions,
    updateSelectedCall
} from '../+state/call.actions';
import { Call, ExpectedCall, UIDialSource } from 'CalltakingCoreApi';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, filter, map, take } from 'rxjs/operators';
import {
    selectAbandonedCalls,
    selectACDAnswerSuccess,
    selectCallStateInitializationTime,
    selectComputeActiveCallId,
    selectHoldSuccess,
    selectReleaseSuccess
} from '../+state/call.selectors';
import { selectComputeSelectedCallId } from '../../main/+state/main.selectors';
import { SupervisorSettings } from '../../configuration/model/ui-settings';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { selectClusterConfigurationMap } from '../../configuration/+state/configuration.selectors';

@Injectable({
    providedIn: 'root'
})
export class CallService implements OnDestroy, StompConsumer {
    private readonly CALL_NEW_TOPIC = `/user/topic/v1/call/new`;
    private readonly CALL_EDIT_TOPIC = `/user/topic/v1/call/*/edit`;
    private readonly CALL_DELETE_TOPIC = `/user/topic/v1/call/*/delete`;
    private readonly API_ICC_URL = `/api/icc/v1/`;
    private readonly API_IMC_URL = `/api/imc/v1/`;

    private readonly newReceipt = uuid.v4();
    private readonly editReceipt = uuid.v4();
    private readonly deleteReceipt = uuid.v4();

    private subscriptions: { [topic: string]: { [receipt: string]: boolean } } = {
        [this.CALL_NEW_TOPIC]: {
            [this.newReceipt]: false
        },
        [this.CALL_EDIT_TOPIC]: {
            [this.editReceipt]: false
        },
        [this.CALL_DELETE_TOPIC]: {
            [this.deleteReceipt]: false
        }
    };
    private subscriptions$ = new BehaviorSubject<{ [topic: string]: { [receipt: string]: boolean } }>(this.subscriptions);
    private readonly newCallTopicSubscription: Subscription | undefined;
    private readonly editCallTopicSubscription: Subscription | undefined;
    private readonly deleteCallTopicSubscription: Subscription | undefined;
    private abandonedCalls!: Call[];

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.newCallTopicSubscription = this.stompService
            .watchAsync(this.CALL_NEW_TOPIC, this.newReceipt, this)
            .subscribe((message: IMessage) => this.store.dispatch(newCall({ call: JSON.parse(message.body) })));
        this.editCallTopicSubscription = this.stompService
            .watchAsync(this.CALL_EDIT_TOPIC, this.editReceipt, this)
            .subscribe((message: IMessage) => this.store.dispatch(updateCall({ call: JSON.parse(message.body) })));
        // delay deletes a second to avoid slightly out of order events from recreating deleted records
        this.deleteCallTopicSubscription = this.stompService
            .watchAsync(this.CALL_DELETE_TOPIC, this.deleteReceipt, this)
            .pipe(delay(1000))
            .subscribe((message: IMessage) => this.store.dispatch(deleteCall({ call: JSON.parse(message.body) })));
        this.store.select(selectAbandonedCalls).subscribe((calls) => (this.abandonedCalls = calls));

        this.subscriptions$.subscribe((subscriptionState) => this.store.dispatch(updateCallSubscriptions({ subscriptions: subscriptionState })));

        // Trigger (re)initialization of each cluster's call state
        this.store.select(selectClusterConfigurationMap)
            .pipe(filter((clusterMap) => Boolean(Object.keys(clusterMap).length)), take(1))
            .subscribe((clusterMap) =>
                Object.keys(clusterMap).forEach((clusterName) =>
                    this.store.select(selectCallStateInitializationTime(clusterName))
                        .pipe(filter((val) => !!val))
                        .subscribe((init) => this.store.dispatch(initializeCallState({ clusterName: clusterName, initializationTime: init })))));

        // Compute and set activeCallId
        this.store.select(selectComputeActiveCallId).subscribe((activeCallId) => this.store.dispatch(updateActiveCall({ callId: activeCallId })));

        // Compute and set selectedCallId
        this.store.select(selectComputeSelectedCallId).subscribe((selectedCallId) => this.store.dispatch(updateSelectedCall({ callId: selectedCallId })));

        // Monitor for Hold Success
        this.store
            .select(selectHoldSuccess)
            .pipe(
                filter((val) => !!val),
                map((action) => action as Action)
            )
            .subscribe((action) => this.store.dispatch(action));

        // Monitor for Release Success
        this.store
            .select(selectReleaseSuccess)
            .pipe(filter((val) => !!val))
            .subscribe((action) => this.store.dispatch(action));

        // Monitor for Answer Success and dispatch completion event
        this.store
            .select(selectACDAnswerSuccess)
            .pipe(filter((val) => !!val))
            .subscribe((action) => this.store.dispatch(action));
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Call topic ${topic} subscription success: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = true;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Call topic ${topic} subscription end: ${receiptId}`);
        let subscriptions = JSON.parse(JSON.stringify(this.subscriptions));
        subscriptions[topic][receiptId] = false;
        this.subscriptions = subscriptions;
        this.subscriptions$.next(this.subscriptions);
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Call topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    public ngOnDestroy() {
        if (this.newCallTopicSubscription) {
            this.newCallTopicSubscription.unsubscribe();
        }
        if (this.editCallTopicSubscription) {
            this.editCallTopicSubscription.unsubscribe();
        }
        if (this.deleteCallTopicSubscription) {
            this.deleteCallTopicSubscription.unsubscribe();
        }
    }

    public requestCalls(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}load`);
        return this.http.get<Call[]>(url, { observe: 'body', responseType: 'json' });
    }

    public answer(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/answer`);
        return this.http.post(url, {});
    }

    public join(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/barge-in`);
        return this.http.post(url, {});
    }

    public silentMonitor(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/silent-monitor`);
        return this.http.post(url, {});
    }

    public dial(number: string, clusterName: string, source: UIDialSource, label?: string, id?: string, destinations?: string[]) {
        let params = new HttpParams();
        params = label ? params.append('label', label) : params;
        params = id ? params.append('directoryContactId', id) : params;
        params = source ? params.append('uiDialSource', source) : params;
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}dial/${encodeURIComponent(number)}`);
        return this.http.post<ExpectedCall>(url, {'destinations': destinations}, { params: params, observe: 'body', responseType: 'json' });
    }

    public conference(
        clusterName: string,
        callId: string,
        number: string,
        source: UIDialSource,
        label?: string,
        id?: string,
        blindTransfer?: boolean,
        supervisorAssistance?: SupervisorSettings,
        destinations?: string[]
    ) {
        let params = new HttpParams();
        params = label ? params.append('label', label) : params;
        params = id ? params.append('directoryContactId', id) : params;
        params = source ? params.append('uiDialSource', source) : params;
        params = blindTransfer ? params.append('blindTransfer', blindTransfer) : params;
        params = supervisorAssistance ? params.append('supervisorAssistance', true) : params;
        params = supervisorAssistance ? params.append('deafen', supervisorAssistance.callerDeafened) : params;
        params = supervisorAssistance ? params.append('suppressRingback', !supervisorAssistance.ringback) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/conference/${encodeURIComponent(number)}`);
        return this.http.post(url, {'destinations': destinations}, { params: params });
    }

    public volume(clusterName: string, callId: string, participantId: string, volume?: number, gain?: number) {
        let params = new HttpParams();
        params = volume ? params.append('volume', String(volume)) : params;
        params = gain ? params.append('gain', String(gain)) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/volume/${participantId}`);
        return this.http.post(url, {}, { params: params });
    }

    public hookFlash(clusterName: string, callId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/hook-flash`);
        return this.http.post(url, {});
    }

    public mute(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/mute/${participantId}`);
        return this.http.post(url, {});
    }

    public unmute(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/unmute/${participantId}`);
        return this.http.post(url, {});
    }

    public deafen(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/deafen/${participantId}`);
        return this.http.post(url, {});
    }

    public undeafen(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/undeafen/${participantId}`);
        return this.http.post(url, {});
    }

    public redial(callId: string, clusterName: string, number: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/redial/${encodeURIComponent(number)}`);
        return this.http.post<ExpectedCall>(url, {});
    }

    public redialAbandoned(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/redial-abandoned`);
        return this.http.post(url, {});
    }

    public releaseAllAbandoned() {
        this.abandonedCalls.forEach((call) => this.store.dispatch(requestReleaseCall({ callId: call.uuid, clusterName: call.clusterName })));
    }

    public release(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/release`);
        return this.http.post(url, {});
    }

    public releaseParticipant(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/release/${participantId}`);
        return this.http.post(url, {});
    }

    public releasePendingParticipant(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/release-dialed-contact/${participantId}`);
        return this.http.post(url, {});
    }

    public releaseNetworkParticipant(callId: string, clusterName: string, participantId: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/network-release/${participantId}`);
        return this.http.post(url, {});
    }

    public hold(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/hold`);
        return this.http.post(url, {});
    }

    public unhold(callId: string, clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/unhold`);
        return this.http.post(url, {});
    }

    public tddMessage(callId: string, clusterName: string | undefined, message: string, predefined?: boolean, language?: string) {
        let params = new HttpParams();
        params = predefined ? params.append('predefined', predefined) : params;
        params = language ? params.append('predefinedLanguage', language) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/send-tdd-message`);
        return this.http.post(url, message, { params: params });
    }

    public rttMessage(callId: string, clusterName: string | undefined, message: string, predefined?: boolean, language?: string) {
        let params = new HttpParams();
        params = predefined ? params.append('predefined', predefined) : params;
        params = language ? params.append('predefinedLanguage', language) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/send-rtt-message`);
        return this.http.post(url, message, { params: params });
    }

    public smsMessage(callId: string, clusterName: string | undefined, message: string, predefined?: boolean, language?: string) {
        let params = new HttpParams();
        params = predefined ? params.append('predefined', predefined) : params;
        params = language ? params.append('predefinedLanguage', language) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_IMC_URL}call/${callId}/send-sms`);
        return this.http.post(url, { message: message }, { params: params });
    }

    public dtmfMessage(callId: string, clusterName: string | undefined, message: string) {
        let params = new HttpParams();
        params = message ? params.append('digit', String(message)) : params;

        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/send-dtmf`);
        return this.http.post(url, {}, { params: params });
    }

    public supervisor(callId: string, clusterName: string | undefined) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}${callId}/supervisor`);
        return this.http.post(url, {});
    }

    public createTextCall(number: string, clusterName: string, source?: UIDialSource) {
        let params = new HttpParams();
        params = source ? params.append('uiDialSource', source) : params;
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, `${this.API_ICC_URL}create-text-call/${number}`);
        return this.http.post(url, {}, { params: params });
    }
}
