/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createSelector, createSelectorFactory } from '@ngrx/store';
import {
    arrayFilterMemoize,
    selectRingdownCalls,
    selectSelectedCallIsCama,
    selectSelectedCallStandardLocationEvent,
    selectStandardLocationAdrSpeedDials
} from '../../call/+state/call.selectors';
import { Ringdown, RingdownStatus } from '../directory-ringdowns-table/Ringdown';
import { selectSpeedDialLayoutMap, selectSuggestedFoldersMap } from './directory.selectors';
import { selectRingdowns } from './directory.selectors';
import { DirectoryItem, DirectoryItemReference } from '../model/directory-item';
import { selectDirectorySuggestedSpeedDialEnabled } from '../../configuration/+state/configuration.selectors';
import { selectServiceRespondersEsnMap } from '../../location/+state/location.selectors';
import { Call } from 'CalltakingCoreApi';
import { CallFunctions } from '../../call/util/call-functions';
import { DirectoryFunctions } from '../util/directory-functions';


export const selectSelectedCallSuggestedSpeedDialLayoutEsn = createSelector(
    selectSelectedCallStandardLocationEvent,
    selectServiceRespondersEsnMap,
    (locationEvent, esnMap) => (locationEvent && locationEvent?.camaEsn ? esnMap[locationEvent.camaEsn]?.esn : undefined)
);

export const selectStandardLocationEsnSpeedDials = createSelector(
    selectSpeedDialLayoutMap,
    selectSelectedCallSuggestedSpeedDialLayoutEsn,
    (speedDialLayoutMap, speedDialEsn) => (speedDialLayoutMap[speedDialEsn] ? speedDialLayoutMap[speedDialEsn].speedDials : [])
);

export const selectSuggestedSpeedDials = createSelector(
    selectDirectorySuggestedSpeedDialEnabled,
    selectSelectedCallIsCama,
    selectStandardLocationAdrSpeedDials,
    selectStandardLocationEsnSpeedDials,
    (enabled, isCama, adrSpeedDials, esnSpeedDials) => (enabled ? (isCama ? esnSpeedDials : adrSpeedDials) : [])
);

export const selectShouldShowSuggestedSpeedDials = createSelector(selectSuggestedSpeedDials, (speedDials) => !!speedDials.length);

export const selectSuggestedSpeedDialMap = createSelector(selectSuggestedSpeedDials, (suggested) =>
    suggested.reduce((arr, curr) => ({ ...arr, [curr.uuid]: curr }), {})
);

export const selectSuggestedDirectoryItems = (directoryItemReferences: DirectoryItemReference[]) =>
    createSelector(
        selectSuggestedFoldersMap,
        selectSuggestedSpeedDialMap,
        (foldersMap, speedDialMap: any) =>
            directoryItemReferences
                .map((reference) => (reference.type === 'FOLDER' ? foldersMap[reference.uuid] : speedDialMap[reference.uuid]))
                .filter((v) => !!v) as DirectoryItem[]
    );

export const selectRingDownsWithCalls = createSelectorFactory<object, Ringdown[]>(arrayFilterMemoize)(selectRingdowns, selectRingdownCalls, (ringDowns: Ringdown[], ringDownCalls: Call[]) =>
    ringDowns.map((ringDown) => ({ ...ringDown, call: ringDownCalls.find((call: Call) => CallFunctions.hasParticipantWithNumber(call, ringDown.did)) } as Ringdown))
);

const selectGetRingdownByNumber = (did: string)=> createSelector(selectRingDownsWithCalls, (ringdowns: Ringdown[]) => ringdowns.find((ringdown: Ringdown) => did.includes(ringdown.did)));
export const selectDisplayToastForRingdownNotAvailable = (did: string) => createSelector(selectGetRingdownByNumber(did), (ringdown) => ringdown && DirectoryFunctions.getRingDownStatusBasedOnCallStatus(ringdown) !== RingdownStatus.IDLE);
