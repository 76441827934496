/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { InputReport2 } from './input-report-2';

export class DeviceIdentificationMessage extends InputReport2 {

    get hardware() {
        return `${this.getUint16(2)}`;
    }

    get firmware() {
        return `${this.getUint16(4)}.${this.getUint16(6)}.${this.getUint16(8)}`;
    }

    get serial() {
        return new TextDecoder().decode(new Uint8Array(
            this.buffer,
            10,
            this.totalBytes - 8
        ));
    }

    override logMessage(): string {
        return `USB Device: \nHardware: ${this.hardware} \nFirmware: ${this.firmware} \nSerial: ${this.serial}`;
    }
}
