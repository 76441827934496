/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import {ConfigurationMessage} from "./abstract-configuration-message";

enum BOOST {
  NONE = 0x00,
  PLUS_6DB = 0x01,
  PLUS_12DB = 0x02,
  MINUS_6DB = 0x04
}

export class JackboxBoostMessage extends ConfigurationMessage {
  public static readonly RECEIVE_BOOST = BOOST;
  public static readonly TRANSMIT_BOOST = BOOST;

  constructor(arrayBuffer?: ArrayBuffer) {
    super(arrayBuffer);
    this.id = ConfigurationMessage.ConfigurationMessageIdentifier.JACK_BOX_AUDIO_BOOST;
  }

  public withBoost(receiveBoost: BOOST, transmitBoost: BOOST) {
    this.payload1 = (transmitBoost << 4) | receiveBoost; // combine nibbles to form a byte
    return this;
  }

  override payload1String(): string {
      return BOOST[this.payload1];
  }
}
