/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AsteriskPasswordRevokedEvent, Configuration } from 'CalltakingCoreApi';
import {
    fetchApplicationLinks,
    fetchAudibleAlerts,
    fetchAvailableLanguages,
    fetchCallAlerts,
    fetchCtcConfiguration,
    fetchLayoutConfiguration,
    fetchQueueConfiguration,
    fetchUiConfiguration,
    fetchUserSelectableRoles,
    hasInitialized,
    sipPeerPasswordRevokedEvent,
    updatePrimaryDataConnection
} from '../+state/configuration.actions';
import { selectConfigurationEffectInitialized, selectHasInitialized, selectNextPrimaryClusterName } from '../+state/configuration.selectors';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { AudibleAlerts } from '../../call/model/audible-alert';
import { ClusterUrlProviderService } from '../../core/services/cluster-url-provider.service';
import { selectConnectionStatus } from '../../call/+state/media.selectors';
import { ConnectionState } from '../../core/model/connectionState';
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import { StompConsumer } from '../../core/abstract/stomp-consumer';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { StompService } from '../../core/services/stomp.service';
import { selectDirectoryEffectInitialized } from '../../directory/+state/directory.selectors';
import { fetchDirectory } from '../../directory/+state/directory.actions';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService implements StompConsumer, OnDestroy {
    private readonly CTC_CONFIG_URL = '/api/config/v1/load';
    private readonly CTC_VERSION_URL = '/api/config/v1/version';
    private readonly CTC_PEER_URL = '/api/config/v1/asterisk-peer';
    private readonly CTC_PEER_PASSWORD_URL = '/api/config/v1/asterisk-password';

    private readonly PEER_PASSWORD_REVOKED_TOPIC = `/user/topic/v1/call/asterisk/password/revoke`;
    private readonly receipt = uuid.v4();
    private readonly subscription: Subscription | undefined;

    constructor(
        private http: HttpClient,
        private stompService: StompService,
        private store: Store,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.store.select(selectDirectoryEffectInitialized).pipe(filter((init) => init))
            .subscribe(() => this.store.dispatch(fetchDirectory()));

        this.subscription = this.stompService
            .watchAsync(this.PEER_PASSWORD_REVOKED_TOPIC, this.receipt, this)
            .subscribe((message: IMessage) => this.handlePeerPasswordRevokedEvent(JSON.parse(message.body)));

        this.store
            .select(selectConfigurationEffectInitialized)
            .pipe(filter((init) => init))
            .subscribe(() => {
                this.store.dispatch(fetchCtcConfiguration());
                this.store.dispatch(fetchUiConfiguration());
                this.store.dispatch(fetchApplicationLinks());
                this.store.dispatch(fetchLayoutConfiguration());
                this.store.dispatch(fetchAudibleAlerts());
                this.store.dispatch(fetchCallAlerts());
                this.store.dispatch(fetchQueueConfiguration());
                this.store.dispatch(fetchAvailableLanguages());
                this.store.dispatch(fetchUserSelectableRoles());
            });

        this.store
            .select(selectConnectionStatus)
            .pipe(concatLatestFrom(() => this.store.select(selectHasInitialized)))
            .subscribe(([status, initialized]) => {
                if ((!initialized && status === ConnectionState.PARTIAL) || status === ConnectionState.FULL) {
                    this.store.dispatch(hasInitialized());
                }
            });

        this.store.select(selectNextPrimaryClusterName)
            .pipe(debounceTime(100), tap((clusterName) => console.debug(`Primary Cluster Selected: ${clusterName}`)))
            .subscribe((clusterName) => this.store.dispatch(updatePrimaryDataConnection( { clusterName: clusterName })));
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Configuration peer password expiry topic ${topic} subscription success: ${receiptId}`);
    }
    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Configuration peer password expiry topic ${topic} subscription end: ${receiptId}`);
    }
    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Configuration peer password expiry topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private handlePeerPasswordRevokedEvent(event: AsteriskPasswordRevokedEvent) {
        this.store.dispatch(sipPeerPasswordRevokedEvent({ event: event }));
    }

    public requestCtcConfiguration() {
        const url = this.clusterUrlProviderService.getCtcUrl();
        return this.http.get<Configuration>(`${url}${this.CTC_CONFIG_URL}`, {
            observe: 'body',
            responseType: 'json'
        });
    }

    public requestCtcPeerConfiguration(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.CTC_PEER_URL);
        return this.http.get(url, { observe: 'body', responseType: 'text' });
    }

    public requestCtcPeerPassword(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.CTC_PEER_PASSWORD_URL);
        return this.http.get(url, { observe: 'body', responseType: 'text' });
    }

    public requestCtcVersion(clusterName: string) {
        const url = this.clusterUrlProviderService.getClusterSpecificUrl(clusterName, this.CTC_VERSION_URL);
        return this.http.get(url, { observe: 'body', responseType: 'text' });
    }

    public requestAlerts() {
        return this.http.get<AudibleAlerts>(`assets/data/call-alerts.json`, { observe: 'body', responseType: 'json' });
    }
}
